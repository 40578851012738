import React, { useEffect, useState } from 'react';
import AutoCompleteDropDown from '../AutoComplete/AutoCompleteDropDown';
import { TOption } from '../../types/TOption';

export interface ITimeZoneProps {
  stateValue: number;
  setStateValue: React.Dispatch<React.SetStateAction<number>>;
  disabled?: boolean;
}

const GMT_TIMEZONES: TOption[] = [
  { label: 'GMT-12', value: '-12' },
  { label: 'GMT-11', value: '-11' },
  { label: 'GMT-10', value: '-10' },
  { label: 'GMT-09', value: '-9' },
  { label: 'GMT-08', value: '-8' },
  { label: 'GMT-07', value: '-7' },
  { label: 'GMT-06', value: '-6' },
  { label: 'GMT-05', value: '-5' },
  { label: 'GMT-04', value: '-4' },
  { label: 'GMT-03', value: '-3' },
  { label: 'GMT-02', value: '-2' },
  { label: 'GMT-01', value: '-1' },
  { label: 'GMT+00', value: '0' },
  { label: 'GMT+01', value: '1' },
  { label: 'GMT+02', value: '2' },
  { label: 'GMT+03', value: '3' },
  { label: 'GMT+04', value: '4' },
  { label: 'GMT+05', value: '5' },
  { label: 'GMT+06', value: '6' },
  { label: 'GMT+07', value: '7' },
  { label: 'GMT+08', value: '8' },
  { label: 'GMT+09', value: '9' },
  { label: 'GMT+10', value: '10' },
  { label: 'GMT+11', value: '11' },
  { label: 'GMT+12', value: '12' },
];

const TimezoneSelect: React.FC<ITimeZoneProps> = ({ stateValue, setStateValue, disabled }) => {
  const [selectedOption, setSelectedOption] = useState<TOption[]>(
    GMT_TIMEZONES.filter((timezone) => timezone.value === stateValue.toString()).map((timezone) => ({
      label: timezone.label,
      value: timezone.value,
    })),
  );

  const handleSelectChange = (option: TOption[]) => {
    setSelectedOption(option);
    setStateValue(Number(option[0].value));
  };

  useEffect(() => {
    if (stateValue)
      setSelectedOption(
        GMT_TIMEZONES.filter((timezone) => timezone.value === stateValue.toString()).map((timezone) => ({
          label: timezone.label,
          value: timezone.value,
        })),
      );
  }, [stateValue]);

  return (
    <AutoCompleteDropDown
      dataTestId="select-timezone"
      isMultipleSelection={false}
      options={GMT_TIMEZONES}
      selectedOptions={selectedOption}
      setSelectedOptions={handleSelectChange}
      placeHolder={''}
      disabled={disabled}
      showCreateNewElement={false}
      useTextHighLight={false}
      style={{ width: 'auto' }}
    />
  );
};

export default TimezoneSelect;
