/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { WppInlineMessage, WppSpinner } from '@wppopen/components-library-react';
import { BoxContentCenter, TextHighlight } from '../../../shared/components';

export interface IContentStreamContentStatusCellProps {
  hasWarning: boolean;
  isLoading: boolean;
}

const ContentStreamContentStatusCell: React.FC<IContentStreamContentStatusCellProps> = ({ hasWarning, isLoading }) => {
  const renderContent = () => {
    if (isLoading) {
      return <WppSpinner size="s" />;
    }
    if (hasWarning) {
      return <WppInlineMessage size="s" message="" type="error" showTooltipFrom={10} data-testid="warning-icon" />;
    }
    return '';
  };

  return <BoxContentCenter justify="flex-start">{renderContent()}</BoxContentCenter>;
};

export default ContentStreamContentStatusCell;
