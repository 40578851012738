/**
 * Use this to call BFF endpoints
 */

import { HttpService } from '@core/http-service';
import { BrandGetDTO, BrandPutDTO } from '@connected-core-system/utils/brand-http-dto';
import { interpolateWithValues } from '../../../shared/utils';

import { BFF_ENDPOINTS, HttpResolver } from '../../../shared/api';
import { SecondTierSidebarItem } from '../../SecondTierSidebar/types';
import { IBrandPageRepository } from '../types';
export class BrandPageRepository implements IBrandPageRepository {
  constructor(private readonly httpService: HttpService) {}

  async createBrand(organisationId: string, name: string, brandUrl?: string): Promise<string> {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_BRANDS_GET, organisationId);
    const newBrand = await this.httpService.post<BrandGetDTO>(url, {
      name: name,
      brandUrl: brandUrl,
    });
    return newBrand.data.id;
  }

  async updateBrand(organisationId: string, item: SecondTierSidebarItem, brandUrl?: string): Promise<BrandGetDTO> {
    const brandToBeUpdated: BrandPutDTO = {
      name: item.name,
      status: item.status,
      brandUrl: brandUrl,
    };
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_BRANDS_POST, organisationId, item.id);
    const brandUpdated = await this.httpService.put<BrandGetDTO>(url, brandToBeUpdated);
    return brandUpdated.data;
  }
}

export default new BrandPageRepository(HttpResolver.getHttpService());
