import { HttpService } from '@core/http-service';
import { interpolateWithValues } from '../../../shared/utils';
import { BFF_ENDPOINTS, HttpResolver } from '../../../shared/api';
import { IContentStreamPageRepository } from '../types/IContentStreamPageRepository';
import { ContentStreamGetDTO } from '@connected-core-system/utils/content-stream-http-dto';
import { ContentTemplateGetDTO } from '@connected-core-system/utils/content-http-dto';

export class ContentStreamPageRepository implements IContentStreamPageRepository {
  constructor(private readonly httpService: HttpService) {}

  listContentStreams(organisationId: string, isFallbackContentStream?: boolean) {
    const url =
      interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_CONTENT_STREAM, organisationId) +
      (typeof isFallbackContentStream === 'boolean' ? `?isFallbackContent=${isFallbackContentStream}` : '');
    return this.httpService.get<ContentStreamGetDTO[]>(url);
  }

  listContentStreamsByChannel(organisationId: string, channelId: string) {
    const url = interpolateWithValues(BFF_ENDPOINTS.CHANNEL, organisationId) + `/${channelId}/content-streams`;
    return this.httpService.get<ContentStreamGetDTO[]>(url);
  }

  updatePublishState(organisationId: string, contentStreamId: string, isPublished: boolean) {
    const url =
      interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_CONTENT_STREAM, organisationId) +
      `/${contentStreamId}/is-published`;
    const body = { isPublished: isPublished };
    return this.httpService.put<boolean>(url, body);
  }

  listContents(organisationId: string, contentIds: string[]) {
    const url = interpolateWithValues(BFF_ENDPOINTS.CONTENT_BY_ID, organisationId, contentIds.join(','));
    return this.httpService.get<ContentTemplateGetDTO[]>(url);
  }
}

export default new ContentStreamPageRepository(HttpResolver.getHttpService());
