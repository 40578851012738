import { HttpService } from '@core/http-service';
import { ChannelPostDTO, ChannelPutDTO, ChannelDTO } from '@connected-core-system/utils/channel-http-dto';
import { interpolateWithValues } from '../../../shared/utils';
import { IOrganisationUnit } from '../../../shared/types';
import { HttpResolver, BFF_ENDPOINTS } from '../../../shared/api';
import { IChannelCreateEditRepository } from '../types/IChannelCreateEditRepository';
import { BrandGetDTO } from '@connected-core-system/utils/brand-http-dto';
import { QRCodeTemplateDTO } from '@connected-core-system/utils/qrcodetemplate-http-dto';
import { ContentStreamGetDTO } from '@connected-core-system/utils/content-stream-http-dto';

export class ChannelCreateEditRepository implements IChannelCreateEditRepository {
  constructor(private readonly httpService: HttpService) {}

  fetchUnits(organisationId: string) {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_UNITS_GET, organisationId);
    return this.httpService.get<Array<IOrganisationUnit & { id: string }>>(url);
  }

  fetchBrands(organisationId: string) {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_BRANDS_GET, organisationId);
    return this.httpService.get<BrandGetDTO[]>(url);
  }

  postChannel(channel: ChannelPostDTO) {
    const url = interpolateWithValues(BFF_ENDPOINTS.CHANNEL, channel.organisationId);
    return this.httpService.post<ChannelDTO>(url, channel);
  }

  updateChannel(channel: ChannelPutDTO) {
    const url = interpolateWithValues(BFF_ENDPOINTS.CHANNEL, channel.organisationId);
    return this.httpService.put<ChannelDTO>(`${url}/${channel.channelId}`, channel);
  }

  async fetchQRStyles(organisationId: string) {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_QRCODES_GET, organisationId);
    return await this.httpService.get<QRCodeTemplateDTO[]>(url);
  }

  async getFallbackContentStream(organisationId: string, fallbackContentStreamId: string) {
    const url =
      interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_CONTENT_STREAM, organisationId) + `/${fallbackContentStreamId}`;
    return await this.httpService.get<ContentStreamGetDTO>(url);
  }
}

export default new ChannelCreateEditRepository(HttpResolver.getHttpService());
