import { TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { BoxContent } from '../../../../shared/components';

export const ContainerBare = styled.div(({ expanded, smallScreen }: { expanded: boolean; smallScreen: boolean }) => ({
  height: '100%',
  borderRight: '1px solid #e7eaee',
  display: 'grid',
  gridTemplateRows: 'minmax(8%, auto) minmax(8%, auto) 1fr auto',
  boxSizing: 'border-box',
  rowGap: '.8rem',
  justifyContent: expanded ? 'center' : 'stretch',
  position: expanded && smallScreen ? 'absolute' : 'relative',
  zIndex: expanded && smallScreen ? 2 : 1,
  background: 'white',
}));

export const ContainerOs = styled.div(({ expanded, smallScreen }: { expanded: boolean; smallScreen: boolean }) => ({
  height: '100%',
  borderRight: '1px solid #e7eaee',
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
  boxSizing: 'border-box',
  rowGap: '.8rem',
  justifyContent: expanded ? 'center' : 'stretch',
  position: expanded && smallScreen ? 'absolute' : 'relative',
  zIndex: expanded && smallScreen ? 2 : 1,
  background: 'white',
}));

export const SpaceExpandedResized = styled.div({
  height: '100%',
  width: '6rem',
});

export const MenuSectionBare = styled(BoxContent)(({ expanded }: { expanded: boolean }) => ({
  transition: 'all 0.1s',
  width: expanded ? '26rem' : '6rem',
  minWidth: '6.4rem',
  maxWidth: expanded ? '26rem' : '6.4rem',
  padding: expanded ? '0 1.6rem' : '0',
}));

export const MenuSectionOs = styled(BoxContent)(({ expanded }: { expanded: boolean }) => ({
  transition: 'all 0.1s',
  width: expanded ? '240px' : '64px',
  padding: expanded ? '0 1.6rem' : '0',
}));

MenuSectionBare.defaultProps = {
  align: 'center',
  flex: true,
  direction: 'column',
  w: '100%',
};

MenuSectionOs.defaultProps = {
  align: 'center',
  flex: true,
  direction: 'column',
  w: '100%',
};

export const FooterContainerBare = styled(MenuSectionBare)({
  alignSelf: 'flex-end',
});

export const FooterContainerOs = styled(MenuSectionOs)({
  alignSelf: 'flex-end',
});

export const DivisionTitleBare = styled(BoxContent)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey800'],
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: '1rem',
  lineHeight: '2rem',
  height: 'auto',
  width: 'auto',
  paddingLeft: '1rem',
  marginTop: '2rem',
}));

export const DivisionTitleOs = styled(BoxContent)(({ expanded }: { expanded: boolean }) => ({
  color: 'var(--wpp-grey-color-700)',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: '700',
  fontSize: 'var(--wpp-typography-2xs-strong-font-size, 10px)',
  lineHeight: '2rem',
  height: 'auto',
  width: 'auto',
  paddingLeft: expanded ? '1.2rem' : 0,
  textAlign: expanded ? 'left' : 'center',
  paddingTop: '1.6rem',
}));

export const AppTitleOs = styled.div({
  fontSize: '1.6rem',
  lineHeight: '2.4rem',
  marginTop: '.8rem',
  fontWeight: 'bold',
  alignSelf: 'flex-start',
  paddingLeft: '.8rem',
});
