import { useState } from 'react';
import { WppActionButton, WppIconPlus } from '@wppopen/components-library-react';
import WarningIconMessage from '../../../shared/components/WarningIconMessage/WarningIconMessage';
import { translate } from '../../../shared/locales';
import { StatusEnum } from '../../../shared/types';
import { useSelectedTabState } from '../hooks/useSelectedTabState';
import { ContentsTableDataType } from '../types/ContentsTableDataType';
import { tableTypeEnum } from '../utils/contentStreamContentUtils';
import { tabContentValue } from '../utils/contentStreamCreateUtils';
import SelectedContentTable from './SelectedContentTable/SelectedContentTable';
import SelectedContentsDrawer from './SelectedContentsDrawer';
import { Margin, MarginOs } from './styles/Content.styled';
import { WppStyledCard } from './styles/SelectedContent.styled';
import { TitleText } from './styles/Targets.styled';
import PlatformWrapper from '../../../shared/components/PlatformWrapper/PlatformWrapper';

export interface ISelectedContentProps {
  selectedContent: Partial<ContentsTableDataType>[];
  isLoading: boolean;
  setSelectedContent: (selectedContents: Partial<ContentsTableDataType>[]) => void;
  selectedContentToPreview: Partial<ContentsTableDataType> | null;
  setSelectedContentToPreview: (selectedContent: Partial<ContentsTableDataType>) => void;
  isInvalidContentTab: boolean;
}

const SelectedContents: React.FC<ISelectedContentProps> = ({
  selectedContent,
  setSelectedContent,
  selectedContentToPreview,
  setSelectedContentToPreview,
  isLoading,
  isInvalidContentTab,
}) => {
  const [isSideModalOpen, setIsSideModalOpen] = useState<boolean>(false);
  const { selectedTab } = useSelectedTabState();

  const invalidContentState = () => {
    return selectedContent.every((content) => content?.status !== StatusEnum.ACTIVE);
  };

  return (
    <>
      <PlatformWrapper componentBare={Margin} componentOs={MarginOs}>
        <WppStyledCard>
          <TitleText type="m-strong" data-testid="title-contents-modal">
            {translate('txtSelectedContents')}
          </TitleText>
          {(isLoading || (selectedContent && selectedContent.length > 0)) && (
            <SelectedContentTable
              selectedItems={selectedContent}
              setSelectedItems={setSelectedContent}
              selectedContentToPreview={selectedContentToPreview}
              setSelectedContentToPreview={setSelectedContentToPreview}
              tableType={tableTypeEnum.SELECTED_CONTENTS}
              isLoading={isLoading}
              forceReloadDimensions={selectedContent && selectedContent.length > 0 && selectedTab === tabContentValue}
            />
          )}
          {isInvalidContentTab && invalidContentState() && (
            <div style={{ marginTop: selectedContent && selectedContent.length > 0 ? '24px' : '0px' }}>
              <WarningIconMessage message={translate('txtInvalidContentWarning')} />
            </div>
          )}
          <WppActionButton
            data-testid="add-content-button"
            style={{
              position: 'relative',
              marginTop: selectedContent.length || (isInvalidContentTab && invalidContentState()) ? '30px' : '0px',
            }}
            disabled={isLoading}
            onClick={() => setIsSideModalOpen(true)}>
            <WppIconPlus slot="icon-start" />
            {translate('btnAdd', { something: translate('tabContent') })}
          </WppActionButton>
        </WppStyledCard>
      </PlatformWrapper>

      {isSideModalOpen && (
        <SelectedContentsDrawer
          selectedContent={selectedContent}
          setSelectedContent={setSelectedContent}
          isSideModalOpen={isSideModalOpen}
          setIsSideModalOpen={setIsSideModalOpen}
        />
      )}
    </>
  );
};

export default SelectedContents;
