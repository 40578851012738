import { AuthenticatedUserDTO } from '@connected-core-system/utils/user-http-dto';
import { SetCallback } from '../../../shared/store';

export interface IAthorizationState {
  userInfo: (AuthenticatedUserDTO & { isAuthenticated: boolean }) | null;
}

export const initialState: IAthorizationState = {
  userInfo: null,
};

const actions = (set: SetCallback<IAthorizationState>) => ({
  setUserInfo: (userInfo: IAthorizationState['userInfo']) => set((state) => ({ ...state, userInfo: userInfo })),
});

export const slice = (set: SetCallback<IAthorizationState>) => ({
  ...initialState,
  ...actions(set),
});

export type IAuthorizationActions = ReturnType<typeof actions>;

export default {
  slice,
  initialState,
};
