import { IUpdatedInfoRepository } from '../types/IUpdatedInfoRepository';
import repoInstance from './UpdatedInfoRepository';

export class UpdatedInfoManager {
  constructor(private repo: IUpdatedInfoRepository) {}

  async getUserDetails(userEmail: string) {
    try {
      const userResponse = await this.repo.fetchUserDetails(userEmail);
      return userResponse.data;
    } catch (error) {
      return this.handleError(error);
    }
  }
  handleError(error?: unknown) {
    throw error;
  }
}

export default new UpdatedInfoManager(repoInstance);
