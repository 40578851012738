/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { WppTypography } from '@wppopen/components-library-react';
import { BoxContent, BoxContentCenter } from '../../../shared/components';
import { Button, Typography } from './EmptyState.styled';

export interface IEmptyStateProps {
  title: string;
  titleIcon?: React.ReactNode;
  text: string;
  buttonText: string;
  hasButton?: boolean;
  buttonAction?: () => void;
  buttonIcon?: React.ReactNode;
}

const EmptyState: React.FC<IEmptyStateProps> = ({
  title,
  text,
  buttonText,
  buttonAction,
  buttonIcon,
  titleIcon,
  hasButton = true,
}) => {
  return (
    <BoxContentCenter>
      <BoxContent flex bg="grey000" align="center" justify="center" h="90%" radius={8} direction="column">
        <div>{titleIcon}</div>
        <div>
          <BoxContent marg="8 0">
            <WppTypography type="2xl-heading">{title}</WppTypography>
          </BoxContent>
        </div>
        <Typography type="l-body">{text}</Typography>
        <div>
          {hasButton && (
            <BoxContent marg="12 0 0 0" align="center" flex justify="center">
              <Button onClick={() => buttonAction?.()} variant="primary">
                {buttonIcon}
                {buttonText}
              </Button>
            </BoxContent>
          )}
        </div>
      </BoxContent>
    </BoxContentCenter>
  );
};

export default EmptyState;
