import React, { useEffect, useState } from 'react';
import { WppInput, WppIconClock } from '@wppopen/components-library-react';
import { isValidTime } from '../../utils/timeUtils';
import { translate } from '../../locales';

export interface ITimeInputProps {
  stateValue: string;
  setStateValue: React.Dispatch<React.SetStateAction<string>> | ((value: string) => void);
  disabled?: boolean;
  errorMessage?: string;
  setErrorMessage?: (errorMessage: string) => void;
  placeholder?: string;
}

const TimeInput: React.FC<ITimeInputProps & typeof WppInput.defaultProps> = ({
  stateValue,
  setStateValue,
  disabled,
  errorMessage,
  setErrorMessage,
  placeholder,
}) => {
  const [time, setTime] = useState<string>(stateValue);
  const [lastTime, setLastTime] = useState<string>('');
  const [lastStateValue, setLastStateValue] = useState<string>(stateValue);
  const [localErrorMessage, setLocalErrorMessage] = useState<string>('');

  useEffect(() => {
    if (disabled) {
      setTime('');
      setLocalErrorMessage('');
      setErrorMessage?.('');
    }
  }, [disabled, setErrorMessage]);

  const handleChange = (val: string) => {
    if (val === time) return;

    if (!isValidTime(val)) {
      setStateValue('');
      setLastStateValue('');
    }

    if (val.length === 2 && lastTime.length !== 3 && val.indexOf(':') === -1) {
      val = val + ':';
    }

    if (val.length === 2 && lastTime.length === 3) {
      val = val.slice(0, 1);
    }

    setLastTime(val);
    setTime(val);
    setStateValue(val.length === 5 ? val : '');
    setLastStateValue(val.length === 5 ? val : '');
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLWppInputElement>) => {
    const charCode = event.charCode;

    const char = String.fromCharCode(charCode);

    const [hours = '', minutes = ''] = time.split(':');
    const currentLength = event.currentTarget.value.length;

    if (currentLength < 2 && (char === ':' || hours + char > '23')) {
      event.preventDefault();
    } else if (currentLength >= 3 && minutes + char > '59') {
      event.preventDefault();
    } else if (!(charCode >= 48 && charCode <= 57) || currentLength >= 5) {
      event.preventDefault();
    }
  };

  const handleBlur = () => {
    if (stateValue !== time) {
      setLocalErrorMessage(translate('txtEnterValidTime'));
    } else {
      setLocalErrorMessage('');
      if (errorMessage === translate('txtEnterValidTime')) setErrorMessage?.('');
    }
  };

  useEffect(() => {
    if (localErrorMessage && !errorMessage) {
      setErrorMessage?.(localErrorMessage);
    }
  }, [localErrorMessage, errorMessage, setErrorMessage]);

  useEffect(() => {
    if (lastTime !== stateValue && stateValue.length === 5) {
      setTime(stateValue);
    }

    if (!stateValue && lastStateValue) {
      setTime(stateValue);
    }
  }, [stateValue, lastTime, lastStateValue]);

  const getErrorMessage = () => {
    if (disabled) return '';
    else if (localErrorMessage) return localErrorMessage;
    else return errorMessage;
  };

  const getErrorMessageType = () => {
    if (disabled) return undefined;
    else if (localErrorMessage || errorMessage) return 'error';
    else return undefined;
  };

  return (
    <WppInput
      name="time"
      data-testid="input-time"
      placeholder={placeholder || '00:00'}
      value={time}
      disabled={disabled}
      onWppChange={(event: CustomEvent) => handleChange(event.detail.value)}
      onKeyPress={handleKeyPress}
      message={getErrorMessage()}
      messageType={getErrorMessageType()}
      onWppBlur={handleBlur}>
      <WppIconClock slot="icon-start" aria-label="Clock icon" />
    </WppInput>
  );
};

export default TimeInput;
