/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { WppSelect } from '@wppopen/components-library-react';
import { DSListItem } from '../../DSComponents';
import { LabelConfig } from '@wppopen/components-library';
import { StyledWppSelect } from './SelectDropDownBottom.styled';

export interface ISelectDropDownBottomProps {
  options: Array<{ value: string | number; label: string }>;
  value: string | number;
  labelConfig?: LabelConfig;
}

const SelectDropDownBottom: React.FC<ISelectDropDownBottomProps & typeof WppSelect.defaultProps> = ({
  options,
  onSelect,
  value,
  labelConfig,
  ...otherProps
}) => {
  return (
    <StyledWppSelect value={value} labelConfig={labelConfig} {...otherProps}>
      {options.map((o) => (
        <DSListItem key={o.value} value={o.value} disabled={o.value === 'NO_QR_STYLE_SELECTED'}>
          <p slot="label">{o.label}</p>
        </DSListItem>
      ))}
    </StyledWppSelect>
  );
};

export default SelectDropDownBottom;
