import { RowDragEvent } from 'ag-grid-community';
import { ContentStreamTableDataType } from '../../features/ContentStream/types/ContentStreamTableDataType';
import { ContentsTableDataType } from '../../features/ContentStreamCreate/types/ContentsTableDataType';

export const sortDataArrayByOrder = (data: ContentStreamTableDataType[] | Partial<ContentsTableDataType>[]) => {
  data.sort(function (a, b) {
    const valA = a.order == null ? '' : a.order;
    const valB = b.order == null ? '' : b.order;
    return Number(valA) - Number(valB);
  });
};

export const reorderDataTableArray = (
  array: ContentStreamTableDataType[] | Partial<ContentsTableDataType>[],
  row: RowDragEvent,
) => {
  const tempArray = array ? array : [];
  const lastIndex = tempArray.findIndex((dataRow) => dataRow.id === row.node.data.id);
  const actualPosition = row.overIndex < 0 ? array.length - 1 : row.overIndex;
  const difference = lastIndex - actualPosition;
  tempArray[lastIndex].order = (actualPosition + 1).toString();
  if (difference > 0) {
    for (let index = actualPosition; index < lastIndex; index++) {
      tempArray[index].order = (Number(tempArray[index].order ?? 0) + 1).toString();
    }
  } else if (difference < 0) {
    for (let index = actualPosition; index > lastIndex; index--) {
      tempArray[index].order = (Number(tempArray[index].order ?? 0) - 1).toString();
    }
  }
  sortDataArrayByOrder(tempArray);
  return tempArray;
};
