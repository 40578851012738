import React from 'react';
import {
  FallbackAssociationHeader,
  FallbackMainText,
  FallbackTitleText,
  WppStyledCard,
} from './styles/FallbackAssociationCard.styled';
import { translate } from '../../../shared/locales';
import { WppIconWarning } from '@wppopen/components-library-react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface FallbackAssociationCardProsps {}

const FallbackAssociationCard: React.FC<FallbackAssociationCardProsps> = () => {
  return (
    <WppStyledCard>
      <FallbackAssociationHeader>
        <WppIconWarning slot="icon-start" />
        <FallbackTitleText type="m-strong">{translate('txtFallbackAssociationTitle')}</FallbackTitleText>
      </FallbackAssociationHeader>
      <FallbackMainText type="s-body">{translate('txtFallbackAssociation')}</FallbackMainText>
    </WppStyledCard>
  );
};

export default FallbackAssociationCard;
