import { WppInlineMessage } from '@wppopen/components-library-react';
import React from 'react';
import { ContainerCard } from './ErrorIconMessage.styled';

export interface IErrorIconMessageProps extends React.PropsWithChildren {
  message: string;
  textColor?: string;
}

const ErrorIconMessage: React.FC<IErrorIconMessageProps> = ({ message }) => {
  return (
    <ContainerCard variant="secondary" size="s">
      <WppInlineMessage size="s" message={message} type="error" />
    </ContainerCard>
  );
};
export default ErrorIconMessage;
