import styled from '@emotion/styled';
import { WppIconWarning, WppTab } from '@wppopen/components-library-react';

export const TabContainer = styled.div(({ show = false }: { show: boolean }) => ({
  display: show ? 'initial' : 'none',
}));

export const StyledWppTab = styled(WppTab)({
  '--tab-width': '100%',
});

export const IconWarning = styled(WppIconWarning)({ marginRight: '9px' });
