/* eslint-disable @typescript-eslint/no-empty-interface */
import { CellTextTruncate } from '../../../shared/components';
import React from 'react';
import { ChannelTableDataType } from '../types/ChannelTableDataType';

export interface IChannelNameCellProps {
  name: ChannelTableDataType['name'];
  searchTerm?: string;
}

const ChannelNameCell: React.FC<IChannelNameCellProps> = ({ name, searchTerm }) => {
  return <CellTextTruncate text={name} textHighlightProps={{ subString: searchTerm }} />;
};

export default ChannelNameCell;
