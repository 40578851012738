import React from 'react';
import { WppIconCross, WppIconSearch, WppInput, WppButton } from '@wppopen/components-library-react';
import { BoxContent } from '../../../shared/components';
import { translate } from '../../../shared/locales/i18n';
import { useSelectedBrandState, useSelectedChannelState, useSelectedUnitState } from '../hooks';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar';
import { MenuItemEnum } from '../../../shared/types';
import { useIsChannelModalOpenedState } from '../../ChannelCreateEdit/hooks/useIsChannelModalOpenedState';
export interface IChannelHeaderProps {
  onSearch: (tex: string) => void;
  searchTerm: string;
  location: MenuItemEnum;
}

const ChannelHeader: React.FC<IChannelHeaderProps> = ({ searchTerm, onSearch, location }) => {
  const { setIsChannelModalOpened } = useIsChannelModalOpenedState();
  const { selectedSecondTierItem } = useSecondTierSelectedItemState();
  const { setSelectedBrandId } = useSelectedBrandState();
  const { setSelectedUnitId } = useSelectedUnitState();
  const { setSelectedChannel } = useSelectedChannelState();

  const setItemAndNavigate = () => {
    setSelectedBrandId(location === MenuItemEnum.BRANDS ? selectedSecondTierItem?.id : null);
    setSelectedUnitId(location === MenuItemEnum.UNIT ? selectedSecondTierItem?.id : null);
    setSelectedChannel(null);
    setIsChannelModalOpened(true);
  };

  const renderLeft = () => {
    return (
      <BoxContent flex marg="0 0 36 0">
        <BoxContent w={location === MenuItemEnum.CONTENT_STREAM ? '100%' : 'auto'}>
          <WppInput
            data-testid="search-channel"
            name="search-channel"
            placeholder={translate('txtPlaceholderSearch')}
            value={searchTerm}
            onWppChange={(e: CustomEvent) => onSearch(e.detail.value)}>
            <WppIconSearch slot="icon-start" aria-label="Search icon" />
            <WppIconCross slot="icon-end" aria-label="Clear icon" onClick={() => onSearch('')} />
          </WppInput>
        </BoxContent>
      </BoxContent>
    );
  };

  const renderRight = () => {
    if (location === MenuItemEnum.CONTENT_STREAM) {
      return null;
    } else
      return (
        <BoxContent flex justify="flex-end" marg="0 0 36 0">
          <WppButton onClick={() => setItemAndNavigate()}>{translate('txtCreateChannel')}</WppButton>
        </BoxContent>
      );
  };

  return (
    <BoxContent flex align="center">
      <>{renderLeft()}</>
      <>{renderRight()}</>
    </BoxContent>
  );
};

export default ChannelHeader;
