import styled from '@emotion/styled';
import {
  WppCheckbox,
  WppTypography,
  WppIconTrash,
  WppAccordion,
  WppPillGroup,
  WppPill,
} from '@wppopen/components-library-react';
import { TStyledDefaultProps } from '@connected-core-system/utils/frontend';

export const TargetsCard = styled(WppAccordion)(({ theme }: TStyledDefaultProps) => ({
  backgroundColor: theme?.palette?.['grey100'],
  marginBottom: 12,
  width: 'calc(100% - 12px)',
  borderRadius: 12,
  paddingRight: 12,
  position: 'relative',
  '&::part(section)': { width: '100%' },
  '&::part(title-wrapper)': {
    paddingLeft: 12,
  },
}));

export const ContainerContent = styled.div({
  marginLeft: -10,
  width: '100%',
});

export const ContainerLabel = styled.div({
  display: 'inline-block',
});

export const Label = styled(WppTypography)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey800'],
}));

export const StyledWppCheckbox = styled(WppCheckbox)({
  marginLeft: 12,
});

export const StyledWppPillGroup = styled(WppPillGroup)({
  paddingRight: 8,
  paddingTop: 8,
  '&::part(content)': {
    display: 'block',
  },
});

export const StyledWppPill = styled(WppPill)({
  paddingTop: 4,
});

export const InputContainer = styled.div({
  width: 'calc(50% - 8px)',
  minWidth: 100,
  display: 'inline-block',
  paddingRight: 8,
  paddingTop: 8,
});

export const StyledWppIconTrash = styled(WppIconTrash)({
  position: 'absolute',
  right: 12,
  top: 8,
  cursor: 'pointer',
});

export const ContainerPills = styled.div({
  display: 'inline-block',
  width: '100%',
  maxWidth: 550,
  paddingTop: 8,
  paddingRight: 12,
  paddingBottom: 8,
});

export const ContainerFields = styled.div({
  display: 'inline-block',
  width: '100%',
  maxWidth: 400,
  paddingTop: 8,
  paddingRight: 12,
  paddingBottom: 8,
});

export const ErrorMessage = styled.div({
  paddingTop: 8,
});
