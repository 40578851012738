import { BrandGetDTO } from '@connected-core-system/utils/brand-http-dto';
import { ColumnDefinition } from '../../shared/components';
import { formatDate, formatTime, DateFormatsEnum, isArrayEmpty } from '../../shared/utils';
import { ChannelTableDataType } from './types/ChannelTableDataType';
import { OrganisationUnitGetDTO } from '@connected-core-system/utils/organisation-unit-http-dto';

const channelColumnDefinitions: {
  [key in keyof ChannelTableDataType]: ColumnDefinition & {
    fraction?: number;

    minwidth?: number;
  };
} = {
  id: {
    field: 'id',
    minWidth: 56,
    fraction: 0.02,
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  name: { field: 'name', sortable: true, cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' } },
  status: { field: 'status', sortable: true, fraction: 0.1 },
  unit: { field: 'unit', sortable: true, fraction: 0.1, cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' } },
  brand: { field: 'brand', sortable: true, fraction: 0.1, cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' } },
  tags: { field: 'tags', fraction: 0.1 },
  channelTags: { field: 'channelTags' },
  channelContentStreams: { field: 'channelContentStreams' },
  lastUpdate: { field: 'lastUpdate', sortable: true, fraction: 0.1 },
};

export const getColumns = (columnsToExclude?: Array<keyof typeof channelColumnDefinitions>) => {
  const allColumns = channelColumnDefinitions;
  const filteredColumns: { [x: string]: any } = {};

  if (isArrayEmpty(columnsToExclude)) return allColumns;
  else {
    for (const column in allColumns) {
      if (!columnsToExclude?.includes(column as keyof typeof allColumns)) {
        filteredColumns[column] = allColumns[column as keyof typeof allColumns];
      }
    }
    return filteredColumns;
  }
};

export const formatLastUpdatedDate = (lastUpdate: Date) => {
  return {
    date: formatDate(lastUpdate, { format: DateFormatsEnum['LLL dd yyyy'] }),
    time: formatTime(lastUpdate, { withRegion: true }),
  };
};

export const filterChannels = (
  channels: Partial<ChannelTableDataType>[],
  brandData: BrandGetDTO[],
  unitData: OrganisationUnitGetDTO[],
  searchTerm: string,
) => {
  return channels.filter((c) => {
    return (
      c.name?.toLocaleLowerCase().includes(searchTerm.toLowerCase()) ||
      c.status?.toLocaleLowerCase().includes(searchTerm.toLowerCase()) ||
      brandData
        .find((brand) => brand.id === c.brand)
        ?.name.toLocaleLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      unitData
        .find((unit) => unit.id === c.unit)
        ?.name.toLocaleLowerCase()
        .includes(searchTerm.toLowerCase())
    );
  });
};
