import { SetCallback } from '../../../shared/store';
import { ChannelTableDataType } from '../types/ChannelTableDataType';

type Nullable<T> = T | null | undefined;

export interface IChannelPageState {
  selectedBrandId: Nullable<string>;
  selectedUnitId: Nullable<string>;
  selectedChannel: Nullable<ChannelTableDataType>;
  tryAgain: boolean;
}

export const initialState: IChannelPageState = {
  selectedBrandId: null,
  selectedUnitId: null,
  selectedChannel: null,
  tryAgain: false,
};

const actions = (set: SetCallback<IChannelPageState>) => ({
  setSelectedBrandId: (brandId: Nullable<string>) => set((state) => ({ ...state, selectedBrandId: brandId })),
  setSelectedUnitId: (UnitId: Nullable<string>) => set((state) => ({ ...state, selectedUnitId: UnitId })),
  setSelectedChannel: (channel: Nullable<ChannelTableDataType>) =>
    set((state) => ({ ...state, selectedChannel: channel })),
  setTryAgain: (tryAgain: boolean) => set((state) => ({ ...state, tryAgain: tryAgain })),
});

export const slice = (set: SetCallback<IChannelPageState>) => ({
  ...initialState,
  ...actions(set),
});

export type IChannelPageActions = ReturnType<typeof actions>;

export default {
  slice,
  initialState,
};
