/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { formatName } from '../utils';
import { translate } from '../../../shared/locales';
import { ButtonContainer, ButtonInnerContainer, StyledWppButton } from './styles/Button.styled';

export interface IButtonProps {
  name: string;
  onClick?: () => void;
  disabled?: boolean;
}

const Button: React.FC<IButtonProps> = ({ name, onClick, disabled = false }) => {
  return (
    <ButtonContainer>
      <ButtonInnerContainer>
        <StyledWppButton variant="primary" className="custom-button" disabled={disabled} onClick={onClick}>
          {translate('txtCreateNewSomething', { something: formatName(name, name === 'QR Styles') })}
        </StyledWppButton>
      </ButtonInnerContainer>
    </ButtonContainer>
  );
};

export default Button;
