import { useContext, useEffect, useState } from 'react';
import { EnvironmentConfigurationContext } from '../contexts/EnvironmentConfigurationContext';

export const useHostName = () => {
  const environmentConfiguration = useContext(EnvironmentConfigurationContext);
  const appLocation = environmentConfiguration['appLocation'] as string;

  const [baseHostName, setBaseHostName] = useState('');
  useEffect(() => {
    const currentHostName = appLocation;
    setBaseHostName(currentHostName.replace('backoffice', '{organisationDomain}'));
  }, [appLocation]);

  return baseHostName;
};
