import { HttpService } from '@core/http-service';
import { UserInfoDTO } from '@connected-core-system/utils/user-http-dto';
import { interpolateWithValues } from '../../../shared/utils';
import { BFF_ENDPOINTS, HttpResolver } from '../../../shared/api';
import { IUpdatedInfoRepository } from '../types/IUpdatedInfoRepository';

export class UpdatedInfoRepository implements IUpdatedInfoRepository {
  constructor(private readonly httpService: HttpService) {}

  fetchUserDetails(userEmail: string) {
    const url = interpolateWithValues(BFF_ENDPOINTS.USER_DETAILS, userEmail);
    return this.httpService.get<UserInfoDTO>(url);
  }
}

export default new UpdatedInfoRepository(HttpResolver.getHttpService());
