import { SetCallback } from '../../../shared/store';
import { ContentStreamTableDataType } from '../../ContentStream/types/ContentStreamTableDataType';
import { tabDetailsValue } from '../utils/contentStreamCreateUtils';

export interface IContentStreamCreateEditState {
  isAddTargetButtonOpened: boolean;
  selectedContentStream?: ContentStreamTableDataType | null;
  selectedTab: string;
  isContentStreamModalOpened: boolean;
  isFallbackContentStream: boolean;
  contentStreamModalZIndex: number;
}

export const initialState: IContentStreamCreateEditState = {
  isAddTargetButtonOpened: false,
  selectedTab: tabDetailsValue,
  isContentStreamModalOpened: false,
  isFallbackContentStream: false,
  contentStreamModalZIndex: 10,
};

const actions = (set: SetCallback<IContentStreamCreateEditState>) => ({
  setIsAddTargetButtonOpened: (open: boolean) => set((state) => ({ ...state, isAddTargetButtonOpened: open })),
  setSelectedContentStream: (contentStream: IContentStreamCreateEditState['selectedContentStream']) =>
    set((state) => ({ ...state, selectedContentStream: contentStream })),
  setSelectedTab: (tab: string) => set((state) => ({ ...state, selectedTab: tab })),
  setIsContentStreamModalOpened: (open: boolean) => set((state) => ({ ...state, isContentStreamModalOpened: open })),
  setIsFallbackContentStream: (isFallBack: boolean) =>
    set((state) => ({ ...state, isFallbackContentStream: isFallBack })),
  setContentStreamModalZIndex: (zIndex: number) => set((state) => ({ ...state, contentStreamModalZIndex: zIndex })),
});

export const slice = (set: SetCallback<IContentStreamCreateEditState>) => ({
  ...initialState,
  ...actions(set),
});

export type IContentStreamCreateEditActions = ReturnType<typeof actions>;

export default {
  slice,
  initialState,
};
