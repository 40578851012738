import React from 'react';

import { WppCard, WppInput } from '@wppopen/components-library-react';
import { BoxContent } from '../../../shared/components';
import { translate } from '../../../shared/locales/i18n';
import { StatusEnum } from '../../../shared/types';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar';
import { SecondTierSidebarItem } from '../../SecondTierSidebar/types';
import { UpdatedCardInfo } from '../../UpdatedInfo';
import { useBrandValueChangesState } from '../hooks';
import { MainContentContainer } from './styles/BrandPage.styled';
import SendToCmsCard from './SendToCmsCard';
import { BrandFallbackInput } from './styles/BrandPage.styled';
import { isStringNotEmpty } from '../utils/brandPageUtils';

export interface IBrandPageProps {
  newBrandTitle: string;
  setNewBrandTitle: (brandTitle: string) => void;
  brandUrl: string | undefined;
  setBrandUrl: (brandFallback: string | undefined) => void;
  hasErrorMessage: boolean;
  setHasErrorMessage: (hasErrorMessage: boolean) => void;
}

const TabDetails: React.FC<IBrandPageProps> = ({
  newBrandTitle,
  setNewBrandTitle,
  brandUrl,
  setBrandUrl,
  hasErrorMessage,
  setHasErrorMessage,
}) => {
  const { selectedSecondTierItem } = useSecondTierSelectedItemState();
  const { setBrandValueChanges } = useBrandValueChangesState();

  const isCreateMode = !!(selectedSecondTierItem?.id === 'new');

  const handleInputChange = (title: string) => {
    setNewBrandTitle(title);
    if (selectedSecondTierItem?.name !== undefined) {
      if (selectedSecondTierItem.status !== StatusEnum.UNPUBLISHED)
        setBrandValueChanges(title !== selectedSecondTierItem?.name);
      else setBrandValueChanges(isStringNotEmpty(title));
    }
  };

  const handleBrandWebsiteChange = (url: string) => {
    setBrandUrl(url !== '' ? url : undefined);
    if (hasErrorMessage && url === '') setHasErrorMessage(!hasErrorMessage);
    if (selectedSecondTierItem && (selectedSecondTierItem as any)?.brandUrl) {
      setBrandValueChanges(url !== (selectedSecondTierItem as any)?.brandUrl);
    } else setBrandValueChanges(isStringNotEmpty(url));
  };

  const getInputPlaceHolder = () => {
    if (selectedSecondTierItem)
      return selectedSecondTierItem.status === StatusEnum.UNPUBLISHED
        ? translate('txtEnterYourBrandName')
        : selectedSecondTierItem.name;
    else return translate('txtEnterYourBrandName');
  };

  return (
    <MainContentContainer>
      <WppCard>
        <BoxContent>
          <WppInput
            data-testid="input-brand-title"
            autoFocus
            name="title"
            labelConfig={{ text: translate('txtTitle') }}
            size="m"
            required
            placeholder={getInputPlaceHolder()}
            value={newBrandTitle}
            onWppChange={(event: CustomEvent) => handleInputChange(event.detail.value)}
          />
          <BrandFallbackInput
            data-testid="input-brand-website"
            autoFocus
            name="brand-website"
            labelConfig={{ text: translate('txtBrandFallbackTitle') }}
            size="m"
            placeholder={translate('txtBrandFallbackPlaceHolder')}
            value={brandUrl ?? ''}
            required
            onWppChange={(event: CustomEvent) => {
              handleBrandWebsiteChange(event.detail.value);
            }}
            message={hasErrorMessage ? translate('txtValidURL') : ''}
            messageType={hasErrorMessage ? 'error' : undefined}
          />
        </BoxContent>
      </WppCard>
      <BoxContent>
        <UpdatedCardInfo
          userEmail={selectedSecondTierItem?.updatedBy}
          createMode={isCreateMode}
          lastUpdated={(selectedSecondTierItem as SecondTierSidebarItem & { updatedAt: Date }).updatedAt}
        />
        <SendToCmsCard />
      </BoxContent>
    </MainContentContainer>
  );
};

export default TabDetails;
