import { WppSpinner } from '@wppopen/components-library-react';
import React, { useEffect, useState } from 'react';
import { StyledIFrame, WppStyledContainer } from './IframeComponent.styled';

export interface IframeComponentProps {
  title: string;
  src: string;
}

const IframeComponent: React.FC<IframeComponentProps> = ({ title, src }) => {
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    if (src) {
      setIsLoaded(false);
    }
  }, [src]);

  const handleIframeLoad = () => {
    setIsLoaded(true);
  };

  return (
    <WppStyledContainer>
      {!isLoaded ? <WppSpinner size="l" /> : null}
      <StyledIFrame id={'my-iframe'} title={title} src={src} onLoad={handleIframeLoad} />
    </WppStyledContainer>
  );
};

export default IframeComponent;
