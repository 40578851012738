import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import {
  IContentStreamCreateEditActions,
  IContentStreamCreateEditState,
} from '../sliceStore/contentStreamCreateEditSliceStore';

export const useSelectedContentStreamState = () => {
  const selectedContentStream = useSliceState<IContentStreamCreateEditState, 'selectedContentStream'>(
    'selectedContentStream',
  );
  const setSelectedContentStream = useSliceSetter<IContentStreamCreateEditActions, 'setSelectedContentStream'>(
    'setSelectedContentStream',
  );

  return { selectedContentStream, setSelectedContentStream };
};
