import { ContentTemplateGetDTO } from '@connected-core-system/utils/content-http-dto';
import { TableLocation, tableFallbackDrawer } from '../contentStreamUtils';
import { ContentStreamTableDataType } from '../types/ContentStreamTableDataType';
import { IContentStreamPageRepository } from '../types/IContentStreamPageRepository';
import repoInstance from './ContentStreamPageRepository';

type Nullable<T> = T | null | undefined;

export class ContentStreamPageManager {
  constructor(private repo: IContentStreamPageRepository) {}

  async listContentStream(
    isContentStreamPage: boolean,
    organisationId: string,
    channelId: Nullable<string>,
    location: TableLocation,
  ): Promise<ContentStreamTableDataType[]> {
    const contents = isContentStreamPage
      ? await this.repo.listContentStreams(organisationId)
      : location === tableFallbackDrawer
      ? await this.repo.listContentStreams(organisationId, true)
      : channelId
      ? await this.repo.listContentStreamsByChannel(organisationId, channelId)
      : null;

    const dataTable = contents
      ? contents.data.map((content, index) => {
          return {
            id: content.id,
            order: (index + 1).toString(),
            name: content.name,
            campaignName: content.campaign.name,
            campaignId: content.campaign.id,
            status: content.status,
            tags: content?.contentStreamTags
              ? content.contentStreamTags.map((t) => {
                  return t.tag;
                })
              : [],
            isPublished: content.isPublished,
            lastUpdate: new Date(content.lastUpdate),
            updatedBy: content.updatedBy,
            contentStreamTags: content?.contentStreamTags || [],
            scheduleTargetRule: content.targetingSchedule,
            timeTargetRule: content.targetingTime,
            scanHistoryTargetRule: content.targetingScanHistory,
            locationTargetRule: content.targetingLocation,
            isFallback: content.isFallbackContent,
            linkedChannelList: content.contentStreamChannel,
            linkedContent: content.contentStreamContent,
            linkedStory: content.storyContentId,
          };
        })
      : [];

    return dataTable;
  }

  async updatePublishState(organisationId: string, contentStreamId: string, contentState: boolean): Promise<boolean> {
    const contentUpdatedState = await this.repo.updatePublishState(organisationId, contentStreamId, contentState);
    return contentUpdatedState.data;
  }

  async listContents(organisationId: string, contentIds: string[]): Promise<ContentTemplateGetDTO[]> {
    const contents = await this.repo.listContents(organisationId, contentIds);
    return contents.data.filter((element) => element != null);
  }

  handleError(error?: unknown) {
    throw error;
  }
}

export default new ContentStreamPageManager(repoInstance);
