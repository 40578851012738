/* eslint-disable @typescript-eslint/no-empty-interface */
import { StatusForLocationEnum } from '@connected-core-system/utils/types';
import { WppTag } from '@wppopen/components-library-react';
import React from 'react';
import { LocationTableDataType } from '../../types/LocationTableDataType';

export interface ILocationStatusCellProps {
  status: LocationTableDataType['status'];
}

const LocationStatusCell: React.FC<ILocationStatusCellProps> = ({ status }) => {
  const color = status === StatusForLocationEnum.INCLUDE ? 'positive' : 'negative';

  return <WppTag variant={color} label={status} />;
};

export default LocationStatusCell;
