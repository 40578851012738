import { ExceptionCode, UniquePropertyExistsException } from '@core/exceptions';
import { SecondTierSidebarItem } from '../../SecondTierSidebar/types';
import repoInstance, { OrganisationUnitPageRepository } from './OrganisationUnitPageRepository';

export class OrganisationUnitPageManager {
  constructor(private repo: OrganisationUnitPageRepository) {}

  async createNewOrganisationUnit(organisationId: string, name: string) {
    try {
      const newUnitId = await this.repo.createOrganisationUnit(organisationId, name);
      return newUnitId;
    } catch (error) {
      if ((error as UniquePropertyExistsException).code === ExceptionCode.UniquePropertyExistsException) {
        throw new UniquePropertyExistsException('name', 'value');
      }
      return this.handleError(error);
    }
  }

  async updateOrganisationUnit(organisationId: string, item: SecondTierSidebarItem) {
    try {
      const updatedUnit = await this.repo.updateOrganisationUnit(organisationId, item);
      return updatedUnit;
    } catch (error) {
      if ((error as UniquePropertyExistsException).code === ExceptionCode.UniquePropertyExistsException) {
        throw new UniquePropertyExistsException('name', 'value');
      }
      return this.handleError(error);
    }
  }

  handleError(error?: unknown) {
    throw error;
  }
}

export default new OrganisationUnitPageManager(repoInstance);
