/* eslint-disable @typescript-eslint/no-empty-interface */
import { useState } from 'react';
import { translate } from '../../../shared/locales';
import { ContentsTableDataType } from '../types/ContentsTableDataType';
import { TitleText } from './styles/Targets.styled';
import ContentsTable from './ContentsTable/ContentsTable';
import { DrawerActionButtons } from '../../../shared/components';
import { StyledWppSideModal } from './styles/SelectedStoryDrawer.styled';

export interface ISelectedStoryDraweProps {
  selectedStory: Partial<ContentsTableDataType>[];
  setSelectedStory: (selectedStory: Partial<ContentsTableDataType>[]) => void;
  isSideModalOpen: boolean;
  setIsSideModalOpen: (isSideModalOpen: boolean) => void;
}
const SelectedStoryDrawer: React.FC<ISelectedStoryDraweProps> = ({
  selectedStory,
  setSelectedStory,
  isSideModalOpen,
  setIsSideModalOpen,
}) => {
  const [selectedStoryState, setSelectedStoryState] = useState<Partial<ContentsTableDataType>[]>(selectedStory);

  const handleSaveStoryModal = () => {
    if (selectedStoryState) setSelectedStory(selectedStoryState);
    setIsSideModalOpen(false);
  };

  const handleOnSelectRows = (rows: Partial<ContentsTableDataType>[]) => {
    if (rows.length) setSelectedStoryState([rows[0]]);
  };

  return (
    <StyledWppSideModal
      data-testid="story-modal"
      open={isSideModalOpen}
      disableOutsideClick
      onWppSideModalClose={() => setIsSideModalOpen(false)}
      size="m">
      <TitleText type="xl-heading" slot="header" data-testid={'title-stories-modal'}>
        {translate('txtStories')}
      </TitleText>
      <div slot="body">
        <ContentsTable
          dataType={'story'}
          data-testid={'story-table'}
          columnsToExclude={[
            'id',
            'collectionType',
            'deleteContent',
            'deleteStory',
            'order',
            'cmsId',
            'collectionType',
            'status',
          ]}
          handleOnSelectRows={handleOnSelectRows}
          listSelectedRowId={
            selectedStoryState?.length > 0 && selectedStoryState[0].id ? [selectedStoryState[0].id] : ['']
          }
          selectionType={'single'}
        />
      </div>
      <DrawerActionButtons
        variant="primary"
        size="s"
        onClick={() => handleSaveStoryModal()}
        disabled={
          (!selectedStory || selectedStory.length === 0) && (!selectedStoryState || selectedStoryState.length === 0)
        }
        buttonText={translate('btnSave')}
      />
    </StyledWppSideModal>
  );
};

export default SelectedStoryDrawer;
