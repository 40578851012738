import { useEffect, useState } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { ThemeProvider } from '@emotion/react';

import { theme } from '../../shared/theme/theme';

import { MenuItemEnum } from '../../shared/types';
import { EnvironmentConfigurationContext } from '../../shared/contexts/EnvironmentConfigurationContext';

import { AnalyticsPage } from '../../features/Analytics';
import BrandPage from '../../features/BrandPage/views/BrandPage';
import { ChannelPage } from '../../features/Channel';
import ContentStreamPage from '../../features/ContentStream/views/ContentStreamPage';
import { HomePage } from '../../features/Home';
import QRStylePage from '../../features/QRStyle/views/QRStylePage';
import SecondLayerHomePage from '../../features/SecondLayerHomePage/views/SecondLayerHomePage';
import OrganisationUnitPage from '../../features/UnitPage/UnitPage';
import { ContentPage } from '../../shared/components';
import { setLanguage } from '../../shared/locales/i18n';
import { EnumLanguages } from '../../shared/locales/EnumLanguages';

export interface IAppRoutes {
  baseUrl: string;
  isAuthenticated: boolean;
  errorAuth: boolean;
  environmentConfiguration: { [key: string]: object };
}

const AppWrapper = ({ environmentConfiguration, baseUrl, isAuthenticated, errorAuth }: IAppRoutes) => {
  const [languageLoaded, setlLnguageLoaded] = useState(false);

  useEffect(() => {
    setLanguage(EnumLanguages.EN_US);
    setlLnguageLoaded(true);
  }, []);

  if (!languageLoaded) {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <EnvironmentConfigurationContext.Provider value={environmentConfiguration}>
        <BrowserRouter basename={baseUrl}>
          <Routes>
            <Route index element={<Navigate to="/organisation" />} />

            <Route path="/organisation" element={<HomePage isAuthenticated={isAuthenticated} errorAuth={errorAuth} />}>
              <Route index element={<ContentPage />} />

              <Route path={`:organisationId/${MenuItemEnum.UNIT}`} element={<SecondLayerHomePage />}>
                <Route path={`:unitId`} element={<OrganisationUnitPage />} />
              </Route>

              <Route path={`:organisationId/${MenuItemEnum.BRANDS}`} element={<SecondLayerHomePage />}>
                <Route path={`:brandId`} element={<BrandPage />} />
              </Route>

              <Route path={`:organisationId/${MenuItemEnum.QRSTYLES}`} element={<SecondLayerHomePage />}>
                <Route index element={<QRStylePage />} />
                <Route path={`:qrstyleId`} element={<QRStylePage />} />
              </Route>

              <Route path={`:organisationId/${MenuItemEnum.CHANNELS}`} element={<ChannelPage />} />
              <Route path={`:organisationId/${MenuItemEnum.CONTENT_STREAM}`} element={<ContentStreamPage />} />
              <Route path={`:organisationId/${MenuItemEnum.ANALYTICS}`} element={<AnalyticsPage />} />
            </Route>
            <Route path="organisation/:organisationId" element={<ChannelPage />} />
          </Routes>
        </BrowserRouter>
      </EnvironmentConfigurationContext.Provider>
    </ThemeProvider>
  );
};

export default AppWrapper;
