type Comparator<T> = (a: T, b: T) => number;

function isObject(object: unknown) {
  return object != null && typeof object === 'object';
}

export function compareArraysWithoutSort(arr1: Array<unknown>, arr2: Array<unknown>) {
  if (!arr1 || !arr2 || arr1.length !== arr2.length) return false;

  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
}

export function compareArrays(arr1: Array<unknown>, arr2: Array<unknown>) {
  if (!arr1 || !arr2 || arr1.length !== arr2.length) return false;

  arr1.sort();
  arr2.sort();

  for (let i = 0; i < arr1.length; i += 1) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
}

export const compareSortedArraysByKeys = <T>(
  array1: T[],
  array2: T[],
  sortComparator: Comparator<T>,
  keyExtractors: ((item: T) => unknown)[] = [],
) => {
  if (array1.length !== array2.length) return false;

  const sortedArray1 = [...array1];
  const sortedArray2 = [...array2];

  sortedArray1.sort(sortComparator);
  sortedArray2.sort(sortComparator);

  for (let index = 0; index < sortedArray1.length; index++) {
    const values1 = keyExtractors.length
      ? keyExtractors.map((keyExtractor) => keyExtractor(sortedArray1[index]))
      : sortedArray1[index];
    const values2 = keyExtractors.length
      ? keyExtractors.map((keyExtractor) => keyExtractor(sortedArray2[index]))
      : sortedArray2[index];

    if (!compareObjects(values1, values2)) {
      return false;
    }
  }

  return true;
};

export function compareDates(date1: Date | null, date2: Date | null) {
  const date1Time = date1 && date1.getTime();
  const date2Time = date2 && date2.getTime();
  return date1Time === date2Time;
}

export function compareObjects(object1: unknown, object2: unknown) {
  if (object1 != null && typeof object1 === 'object' && object2 != null && typeof object2 === 'object') {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);

    if (keys1.length !== keys2.length) {
      return false;
    }

    for (const key of keys1) {
      const key1 = key as keyof typeof object1;
      const key2 = key as keyof typeof object2;
      const val1: unknown = object1[key1];
      const val2: unknown = object2[key2];
      const areObjects = isObject(val1) && isObject(val2);
      const areArrays = Array.isArray(val1) && Array.isArray(val2);
      const areDates = val1 instanceof Date && val2 instanceof Date;
      if (
        (areArrays && !compareArrays(val1, val2)) ||
        (areObjects && !areDates && !compareObjects(val1, val2)) ||
        (areDates && !compareDates(val1, val2)) ||
        (!areObjects && !areDates && val1 !== val2)
      ) {
        return false;
      }
    }
    return true;
  }
  return false;
}
