import { translate } from '../../../shared/locales';
import { QRBackgroundOptions } from '../types/QRStyleTypes';

export const getColorErrorMessage = (
  color: { color: string; offset: number }[],
  backgroundOptions: QRBackgroundOptions | undefined,
  isGradientTypeSelected: boolean,
) => {
  const errorMessage = ['', ''];

  if (backgroundOptions?.gradient) {
    if (
      color[0]?.color?.toLowerCase() === backgroundOptions?.gradient?.colorStops[0]?.color.toLowerCase() ||
      color[0]?.color?.toLowerCase() === backgroundOptions?.gradient?.colorStops[1]?.color.toLowerCase()
    ) {
      errorMessage[0] = translate('txtColorErrorMessage');
    } else {
      errorMessage[0] = '';
    }
    if (
      isGradientTypeSelected &&
      (color[1]?.color?.toLowerCase() === backgroundOptions?.gradient?.colorStops[0]?.color?.toLowerCase() ||
        color[1]?.color?.toLowerCase() === backgroundOptions?.gradient?.colorStops[2]?.color?.toLowerCase())
    ) {
      errorMessage[1] = translate('txtColorErrorMessage');
    } else {
      errorMessage[1] = '';
    }
  } else {
    if (color[0]?.color?.toLowerCase() === backgroundOptions?.color?.toLowerCase()) {
      errorMessage[0] = translate('txtColorErrorMessage');
    } else {
      errorMessage[0] = '';
    }
    if (isGradientTypeSelected && color[1]?.color?.toLowerCase() === backgroundOptions?.color?.toLowerCase()) {
      errorMessage[1] = translate('txtColorErrorMessage');
    } else {
      errorMessage[1] = '';
    }
  }

  return errorMessage;
};
