import {
  WppIconOrganization,
  WppIconDataHistogram,
  WppIconDesign,
  WppIconQrCode,
  WppIconGallery,
  WppIconScan,
  WppIconTaskListSquare,
  WppIconExternalLink,
} from '@wppopen/components-library-react';
import { IOrganisation, MenuItemEnum } from '../../../shared/types';
import { translate } from '../../../shared/locales/i18n';
import { StatusEnum } from '@connected-core-system/utils/types';

const MAX_WINDOW_SIZE = 1024;

export const getUpperMenuItems = () => [
  { id: MenuItemEnum.UNIT, value: translate('txtUnit'), Icon: WppIconOrganization },
  { id: MenuItemEnum.BRANDS, value: translate('txtBrands'), Icon: WppIconDesign },
  { id: MenuItemEnum.QRSTYLES, value: translate('txtQrStyles'), Icon: WppIconQrCode },
];

export const getContentMenuItems = () => [
  {
    id: MenuItemEnum.CMS,
    value: translate('txtCms'),
    Icon: WppIconTaskListSquare,
    SecondIcon: WppIconExternalLink,
  },
  { id: MenuItemEnum.CHANNELS, value: translate('txtChannels'), Icon: WppIconScan },
  { id: MenuItemEnum.CONTENT_STREAM, value: translate('txtContentStream'), Icon: WppIconGallery },
];

export const getLowerMenuItems = () => [
  { id: MenuItemEnum.ANALYTICS, value: translate('txtAnalytics'), Icon: WppIconDataHistogram },
];

export const organisations: IOrganisation[] = [
  { id: '000', status: StatusEnum.ACTIVE, title: 'Organisation 000', domain: 'org000' },
  { id: '111', status: StatusEnum.ACTIVE, title: 'Organisation 111', domain: 'org111' },
  { id: '222', status: StatusEnum.ACTIVE, title: 'Organisation 222', domain: 'org222' },
  { id: '333', status: StatusEnum.ACTIVE, title: 'Organisation 333', domain: 'org333' },
];

export const shouldCloseMenu = () => {
  const { width } = getWindowDimensions();
  if (width < MAX_WINDOW_SIZE) {
    return true;
  }
  if (width > MAX_WINDOW_SIZE) {
    return false;
  }
  return false;
};

export const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const findOrganisationById = (organisations: IOrganisation[], organisationId: string) => {
  return organisations.find((o) => o.id === organisationId);
};

export const filterOrganisations = (organisations: IOrganisation[], searchTerm: string) => {
  if (!organisations || organisations.length === 0) {
    return [];
  }
  if (!searchTerm) {
    return organisations;
  }
  return organisations.filter((o) => o.title.toUpperCase().includes(searchTerm.toUpperCase()));
};
