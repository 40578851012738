/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import SecondTierSidebar from '../../SecondTierSidebar/views/SecondTierSidebar';
import { ContentPage } from '../../../shared/components';

import { Outlet } from 'react-router-dom';
import { Container } from './SecondLayerHomePage.styled';

export interface ISecondLayerHomePageProps {}

const SecondLayerHomePage: React.FC<ISecondLayerHomePageProps> = () => {
  return (
    <Container>
      <SecondTierSidebar />
      <ContentPage>
        <Outlet />
      </ContentPage>
    </Container>
  );
};

export default SecondLayerHomePage;
