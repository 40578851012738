import { SetCallback } from '../../../shared/store';
import {
  QRCornersDotsOptions,
  QRCornersSquareOptions,
  QRDotsOptions,
  QRImageOptions,
  QRBackgroundOptions,
  QROptions,
  QRCodeOptions,
  IQRValueChanges,
} from '../types/QRStyleTypes';

export interface IQRStyleState {
  title: string;
  dotsOptions?: QRDotsOptions;
  cornersDotOptions?: QRCornersDotsOptions;
  cornersSquareOptions?: QRCornersSquareOptions;
  image?: string;
  imageOptions?: QRImageOptions;
  backgroundOptions?: QRBackgroundOptions;
  qrOptions?: QROptions;
  qrCodeOptions?: QRCodeOptions;
  valueChanges: IQRValueChanges | null;
  hasAnyQRStyle?: boolean;
}

export const initialState: IQRStyleState = {
  title: 'New QR Style',
  valueChanges: null,
  hasAnyQRStyle: false,
};

const actions = (set: SetCallback<IQRStyleState>) => ({
  setTitle: (title: string) => set((state) => ({ ...state, title })),
  setDotsOptions: (dotsOptions: QRDotsOptions) => set((state) => ({ ...state, dotsOptions })),
  setCornersDotOptions: (cornersDotOptions: QRCornersDotsOptions) => set((state) => ({ ...state, cornersDotOptions })),
  setCornersSquareOptions: (cornersSquareOptions: QRCornersSquareOptions) =>
    set((state) => ({ ...state, cornersSquareOptions })),
  setImage: (image: string) => set((state) => ({ ...state, image })),
  setImageOptions: (imageOptions: QRImageOptions) => set((state) => ({ ...state, imageOptions })),
  setBackgroundOptions: (backgroundOptions: QRBackgroundOptions) => set((state) => ({ ...state, backgroundOptions })),
  setQrOptions: (qrOptions: QROptions) => set((state) => ({ ...state, qrOptions })),
  setQRCodeOptions: (qrCodeOptions: QRCodeOptions) => set((state) => ({ ...state, qrCodeOptions })),
  setValueChanges: (valueChanges: IQRValueChanges | null) => set((state) => ({ ...state, valueChanges })),
  setHasAnyQRStyle: (value: boolean) => set((state) => ({ ...state, hasAnyQRStyle: value })),
});

export const slice = (set: SetCallback<IQRStyleState>) => ({
  ...initialState,
  ...actions(set),
});

export type IQRStyleActions = ReturnType<typeof actions>;

export default {
  slice,
  initialState,
};
