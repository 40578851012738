/* eslint-disable @typescript-eslint/no-empty-interface */
import { BoxContentCenter } from '../../../../shared/components';
import React from 'react';
import { ContentsTableDataType } from '../../types/ContentsTableDataType';
import { StyledWppTypography } from '../styles/StatusCell.styled';
import { WppIconError } from '@wppopen/components-library-react';
import { StatusEnum } from '@connected-core-system/utils/types';
import { translate } from '../../../../shared/locales';

export interface IStatusCellProps {
  status: ContentsTableDataType['status'];
}

const StatusCell: React.FC<IStatusCellProps> = ({ status }) => {
  const showDeletedText = () => {
    return (
      <>
        <StyledWppTypography type="s-body"> {translate('txtDeleted')}</StyledWppTypography>
        <WppIconError />
      </>
    );
  };

  const showUnpublishedText = () => {
    return (
      <>
        <StyledWppTypography type="s-body"> {translate('txtUnpublished')}</StyledWppTypography>
        <WppIconError />
      </>
    );
  };
  return (
    <BoxContentCenter justify="flex-end">
      {status === StatusEnum.ARCHIVED
        ? showDeletedText()
        : status === StatusEnum.UNPUBLISHED
        ? showUnpublishedText()
        : ''}
    </BoxContentCenter>
  );
};

export default StatusCell;
