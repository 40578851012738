import { SetCallback } from '../../../shared/store';

export interface IChannelCreateEditState {
  isChannelModalOpened: boolean;
  fallbackTryAgain: boolean;
}

export const initialState: IChannelCreateEditState = {
  isChannelModalOpened: false,
  fallbackTryAgain: false,
};

const actions = (set: SetCallback<IChannelCreateEditState>) => ({
  setIsChannelModalOpened: (open: boolean) => set((state) => ({ ...state, isChannelModalOpened: open })),
  setFallbackTryAgain: (tryAgain: boolean) => set((state) => ({ ...state, fallbackTryAgain: tryAgain })),
});

export const slice = (set: SetCallback<IChannelCreateEditState>) => ({
  ...initialState,
  ...actions(set),
});

export type IChannelCreateEditActions = ReturnType<typeof actions>;

export default {
  slice,
  initialState,
};
