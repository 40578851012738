/* eslint-disable @typescript-eslint/no-empty-interface */
import { Container, ContainerCard } from './styles/ContentStreamForm.styled';
import { WppActionButton, WppIconPlus } from '@wppopen/components-library-react';
import { useEffect, useState } from 'react';
import { BoxContent, CardWithOptions } from '../../../shared/components';

import { translate } from '../../../shared/locales';
import { useIsAddTargetButtonOpenedState } from '../hooks/useIsAddTargetButtonOpenedState';
import TargetSchedule from './TargetSchedule';
import { ExternalContainer, TargetingText, TitleText } from './styles/Targets.styled';
import LinkedChannels from './LinkedChannels';
import { ChannelTableDataType } from '../../Channel/types/ChannelTableDataType';
import { ICardOption } from '../../../shared/types/ICardOption';
import { isCardInViewport, validateTabTargetsToPublish } from '../utils/contentStreamCreateUtils';
import { isArrayEmpty } from '../../../shared/utils';
import TargetLocation from './TargetLocation';
import TargetTime from './TargetTime';
import TargetScanHistory from './TargetScanHistory';
import {
  TargetingLocationDTO,
  TargetingScanHistoryDTO,
  TargetingScheduleDTO,
  TargetingTimeDTO,
} from '@connected-core-system/utils/content-stream-http-dto';
import FallbackAssociationCard from './FallbackAssociationCard';
import WarningIconMessage from '../../../shared/components/WarningIconMessage/WarningIconMessage';
import { IContentStreamRequestsDoneStates } from '../types/IContentStreamRequestsDoneStates';

export interface ITargetsProps {
  selectedLinkedChannels: Partial<ChannelTableDataType>[];
  setSelectedLinkedChannels: (selectedLinkedChannels: Partial<ChannelTableDataType>[]) => void;
  isFallbackContentStream: boolean;
  targetingLocation: TargetingLocationDTO[];
  setTargetingLocation: (targetingLocation: TargetingLocationDTO[]) => void;
  targetingSchedule: TargetingScheduleDTO | null;
  setTargetingSchedule: (targetingSchedule: TargetingScheduleDTO | null) => void;
  targetingScanHistory: TargetingScanHistoryDTO | null;
  setTargetingScanHistory: (targetingScanHistory: TargetingScanHistoryDTO | null) => void;
  targetingTime: TargetingTimeDTO[];
  setTargetingTime: (targetingSchedule: TargetingTimeDTO[]) => void;
  isInvalidTargetSchedule: boolean;
  isInvalidTargetTime: boolean;
  isInvalidTargetLocation: boolean;
  isInvalidTargetScanHistory: boolean;
  isInvalidTargetsTab: boolean;
  setIsInvalidTargetsTab: (isInvalidTargetsTab: boolean) => void;
  setIsInvalidTargetSchedule: (isInvalidTargetschedule: boolean) => void;
  setIsInvalidTargetTime: (isInvalidTargetTime: boolean) => void;
  setIsInvalidTargetLocation: (isInvalidTargetLocation: boolean) => void;
  setIsInvalidTargetScanHistory: (isInvalidTargetScanHistory: boolean) => void;
  setRequestsDone: (requestsDone: React.SetStateAction<IContentStreamRequestsDoneStates>) => void;
}

const Targets: React.FC<ITargetsProps> = ({
  selectedLinkedChannels,
  setSelectedLinkedChannels,
  isFallbackContentStream,
  targetingLocation,
  setTargetingLocation,
  targetingSchedule,
  setTargetingSchedule,
  targetingTime,
  setTargetingTime,
  targetingScanHistory,
  setTargetingScanHistory,
  isInvalidTargetSchedule,
  isInvalidTargetTime,
  isInvalidTargetLocation,
  isInvalidTargetScanHistory,
  isInvalidTargetsTab,
  setIsInvalidTargetsTab,
  setIsInvalidTargetSchedule,
  setIsInvalidTargetTime,
  setIsInvalidTargetLocation,
  setIsInvalidTargetScanHistory,
  setRequestsDone,
}) => {
  const [isTargetingTimeOpen, setIsTargetingTimeOpen] = useState(targetingTime.length > 0);
  const [isScheduleTargetingOpen, setIsScheduleTargetingOpen] = useState<boolean>(!!targetingSchedule);
  const [isLocationTargetingOpen, setIsLocationTargetingOpen] = useState<boolean>(targetingLocation.length > 0);
  const [isScanHistoryTargetingOpen, setIsScanHistoryTargetingOpen] = useState<boolean>(!!targetingScanHistory);
  const [isCardOnScreen, setIsCardOnScreen] = useState<boolean>(false);

  useEffect(() => {
    if (isInvalidTargetsTab) {
      const isValid = validateTabTargetsToPublish(
        selectedLinkedChannels as ChannelTableDataType[],
        targetingSchedule,
        targetingTime,
        targetingLocation,
        targetingScanHistory,
        isInvalidTargetSchedule,
        isInvalidTargetTime,
        isInvalidTargetLocation,
        isInvalidTargetScanHistory,
      );
      if (isValid) setIsInvalidTargetsTab(false);
    }
  }, [
    selectedLinkedChannels,
    targetingSchedule,
    targetingTime,
    targetingLocation,
    isInvalidTargetSchedule,
    isInvalidTargetTime,
    isInvalidTargetLocation,
    isInvalidTargetsTab,
    setIsInvalidTargetsTab,
    targetingScanHistory,
    isInvalidTargetScanHistory,
  ]);

  const optionScanHistory: ICardOption & { order: number } = {
    value: translate('txtScanHistory'),
    action: () => setIsScanHistoryTargetingOpen(true),
    order: 1,
  };
  const optionSchedule: ICardOption & { order: number } = {
    value: translate('txtSchedule'),
    action: () => setIsScheduleTargetingOpen(true),
    order: 2,
  };
  const optionLocation: ICardOption & { order: number } = {
    value: translate('txtLocation'),
    action: () => setIsLocationTargetingOpen(true),
    order: 3,
  };
  const optionTime: ICardOption & { order: number } = {
    value: translate('txtTime'),
    action: () => setIsTargetingTimeOpen(true),
    order: 4,
  };

  const getOptionsInitialState = () => {
    const optionsInitialState: (ICardOption & { order: number })[] = [];
    if (!targetingSchedule) {
      optionsInitialState.push(optionSchedule);
    }
    if (!targetingTime || targetingTime.length === 0) {
      optionsInitialState.push(optionTime);
    }
    if (!targetingLocation || targetingLocation.length === 0) {
      optionsInitialState.push(optionLocation);
    }
    if (!targetingScanHistory) {
      optionsInitialState.push(optionScanHistory);
    }
    return optionsInitialState;
  };

  const [options, setOptions] = useState<(ICardOption & { order: number })[]>(getOptionsInitialState());
  const { isAddTargetButtonOpened, setIsAddTargetButtonOpened } = useIsAddTargetButtonOpenedState();

  const txtTargetingRules = translate('txtTargetingRules');
  const txtTargetingFallbackContent = translate('txtTargetingFallbackContent');

  const handleRemoveOption = (option: ICardOption) => {
    const index = options.findIndex((item) => item.value === option.value);
    if (index >= 0) {
      const newArray = [...options];
      newArray.splice(index, 1);
      setOptions(newArray);
    }
  };

  const handleAddOption = (option: ICardOption & { order: number }) => {
    const newArray = [...options];
    newArray.push(option);
    setOptions(newArray);
  };

  const setTimeInitialState = () => {
    setIsTargetingTimeOpen(false);
    setTargetingTime([]);
    setIsInvalidTargetTime(false);
  };

  const setLocationInitialState = () => {
    setIsLocationTargetingOpen(false);
    setTargetingLocation([]);
    setIsInvalidTargetLocation(false);
  };

  const setScanHistoryInitialState = () => {
    setIsScanHistoryTargetingOpen(false);
    setTargetingScanHistory(null);
    setIsInvalidTargetScanHistory(false);
  };

  const setScheduleInitialState = () => {
    setIsScheduleTargetingOpen(false);
    setTargetingSchedule(null);
    setIsInvalidTargetSchedule(false);
  };

  const handleClickAddTargetButton = (event: React.MouseEvent) => {
    const button = document.querySelector("[data-testId='action-button']");
    const cardHeight = 32 * options.length + 50;
    setIsCardOnScreen(isCardInViewport(cardHeight, button));
    event.stopPropagation();
    setIsAddTargetButtonOpened(!isAddTargetButtonOpened);
  };

  const conditionalTargetText = () => {
    if (isFallbackContentStream) return <TargetingText type="s-body">{txtTargetingFallbackContent}</TargetingText>;
    else return null;
  };

  const handleRemoveScheduleTargeting = () => {
    setScheduleInitialState();
    handleAddOption(optionSchedule);
  };

  const handleRemoveLocationTargeting = () => {
    setLocationInitialState();
    handleAddOption(optionLocation);
  };

  const handleRemoveScanHistoryTargeting = () => {
    setScanHistoryInitialState();
    handleAddOption(optionScanHistory);
  };

  const handleRemoveTimeTargeting = () => {
    setTimeInitialState();
    handleAddOption(optionTime);
  };

  const hasAnyTarget = () => {
    return isInvalidTargetsTab && !isLocationTargetingOpen && !isScheduleTargetingOpen && !isTargetingTimeOpen;
  };

  return (
    <Container flex marg="24 0 16 0">
      <BoxContent w="75%" marg="0 16 0 0">
        <ContainerCard>
          <TitleText type="m-strong">{txtTargetingRules}</TitleText>
          {conditionalTargetText()}
          <ExternalContainer>
            {isScheduleTargetingOpen && (
              <TargetSchedule
                removeScheduleTargeting={handleRemoveScheduleTargeting}
                setTargetingSchedule={setTargetingSchedule}
                targetingSchedule={targetingSchedule}
                isInvalidTargetsTab={isInvalidTargetsTab}
                setIsInvalidTargetSchedule={setIsInvalidTargetSchedule}
              />
            )}
            {isTargetingTimeOpen && (
              <TargetTime
                removeTime={handleRemoveTimeTargeting}
                setTargetingTime={setTargetingTime}
                targetingTime={targetingTime}
                isInvalidTargetsTab={isInvalidTargetsTab}
                setIsInvalidTargetTime={setIsInvalidTargetTime}
              />
            )}
            {isLocationTargetingOpen && (
              <TargetLocation
                removeLocationTargeting={handleRemoveLocationTargeting}
                setTargetingLocation={setTargetingLocation}
                targetingLocation={targetingLocation}
                isInvalidTargetsTab={isInvalidTargetsTab}
                setIsInvalidTargetLocation={setIsInvalidTargetLocation}
              />
            )}
            {isScanHistoryTargetingOpen && (
              <TargetScanHistory
                removeScanHistoryTargeting={handleRemoveScanHistoryTargeting}
                setTargetingScanHistory={setTargetingScanHistory}
                targetingScanHistory={targetingScanHistory}
                isInvalidTargetsTab={isInvalidTargetsTab}
                setIsInvalidTargetScanHistory={setIsInvalidTargetScanHistory}
              />
            )}
            {(!isArrayEmpty(options) || isFallbackContentStream) && (
              <>
                {hasAnyTarget() && <WarningIconMessage message={translate('txtAddTargetsToPublish')} />}
                <WppActionButton
                  data-testid="action-button"
                  style={{ position: 'relative', marginTop: hasAnyTarget() ? '16px' : '0px' }}
                  disabled={isFallbackContentStream}
                  onClick={handleClickAddTargetButton}>
                  <WppIconPlus slot="icon-start" />
                  {translate('btnAdd', { something: translate('txtTarget') })}
                  {isAddTargetButtonOpened && (
                    <CardWithOptions
                      data-testid="card-targets"
                      options={options.sort((a, b) => {
                        return a.order - b.order;
                      })}
                      cardAction={() => setIsAddTargetButtonOpened(false)}
                      onSelectValue={handleRemoveOption}
                      w="calc(100% + 32px)"
                      pos="absolute"
                      left={'0px'}
                      top={isCardOnScreen ? `-${32 * options.length + 20}px` : '32px'}
                    />
                  )}
                </WppActionButton>
              </>
            )}
          </ExternalContainer>
        </ContainerCard>
      </BoxContent>
      <BoxContent w="25%">
        {isFallbackContentStream && <FallbackAssociationCard />}
        <LinkedChannels
          selectedLinkedChannels={selectedLinkedChannels}
          setSelectedLinkedChannels={setSelectedLinkedChannels}
          isFallbackContentStream={isFallbackContentStream}
          isInvalidTargetsTab={isInvalidTargetsTab}
          setRequestsDone={setRequestsDone}
        />
      </BoxContent>
    </Container>
  );
};

export default Targets;
