import { ExceptionCode, UniquePropertyExistsException } from '@core/exceptions';
import repoInstance, { QRStyleRepository } from './QRStyleRepository';
import { QRCodeTemplatePutOrPostDto } from '@connected-core-system/utils/qrcodetemplate-http-dto';

export class QRStyleManager {
  constructor(private repo: QRStyleRepository) {}

  handleError(error?: unknown) {
    throw error;
  }

  async createQRStyle(organisationId: string, qrStyle: QRCodeTemplatePutOrPostDto) {
    try {
      return await this.repo.createQRStyle(organisationId, qrStyle);
    } catch (error) {
      if ((error as UniquePropertyExistsException).code === ExceptionCode.UniquePropertyExistsException) {
        throw new UniquePropertyExistsException('name', 'value');
      }
      return this.handleError(error);
    }
  }

  async updateQRStyle(organisationId: string, templateId: string, qrStyle: QRCodeTemplatePutOrPostDto) {
    try {
      const data = await this.repo.updateQRStyle(organisationId, templateId, qrStyle);
      return data;
    } catch (error) {
      if ((error as UniquePropertyExistsException).code === ExceptionCode.UniquePropertyExistsException) {
        throw new UniquePropertyExistsException('name', 'value');
      }
      return this.handleError(error);
    }
  }

  async getChannelCount(organisationId: string, qrCodeTemplateId: string) {
    try {
      const count = await this.repo.getChannelCount(organisationId, qrCodeTemplateId);
      return count;
    } catch (error) {
      return this.handleError(error);
    }
  }
}

export default new QRStyleManager(repoInstance);
