/* eslint-disable @typescript-eslint/no-empty-interface */
import { BoxContent, ContentPage, Header } from '../../../shared/components';
import ChannelTable from './ChannelTable';
import { MenuItemEnum } from '../../../shared/types';
import React from 'react';

export interface IChannelPageProps {}

const ChannelPage: React.FC<IChannelPageProps> = () => {
  return (
    <ContentPage>
      <BoxContent pad="24">
        <div>
          <BoxContent marg="0 0 24 0">
            <Header title="Channels" />
          </BoxContent>
        </div>

        <ChannelTable location={MenuItemEnum.CHANNELS} data-testid={'channel-table'} />
      </BoxContent>
    </ContentPage>
  );
};

export default ChannelPage;
