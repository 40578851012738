/* eslint-disable @typescript-eslint/no-empty-interface */
import { CellTextTruncate } from '../../../../shared/components';
import React from 'react';
import { ContentsTableDataType } from '../../types/ContentsTableDataType';

export interface INameCellProps {
  name: ContentsTableDataType['name'];
  searchTerm?: string;
}

const NameCell: React.FC<INameCellProps> = ({ name, searchTerm }) => {
  return <CellTextTruncate text={name} textHighlightProps={{ subString: searchTerm }} />;
};

export default NameCell;
