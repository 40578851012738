import styled from '@emotion/styled';
import { WppSideModal } from '@wppopen/components-library-react';
import { percentOfAppHeight } from '../../../../shared/utils';

interface ModalProps extends React.HTMLAttributes<HTMLWppSideModalElement> {
  zIndex?: number;
}

export const ModalContainer = styled(WppSideModal)<ModalProps>(({ zIndex }) => ({
  zIndex: zIndex,
  '&::part(actions-wrapper)': {
    display: 'none',
  },

  '&::part(body-wrapper)': { padding: 0 },

  '&::part(side-modal)': {
    width: '100vw',
    height: `${percentOfAppHeight(100)}`,
    backgroundColor: '#F8F9FB',
  },

  '&::part(header-container)': {
    display: 'none',
  },
}));

export const Container = styled.div({
  height: '100vh',
});
