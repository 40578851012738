/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useCallback, useState } from 'react';
import { usePromise } from '../../../shared/hooks';
import { WppActionButton, WppCard, WppIconLink } from '@wppopen/components-library-react';
import WarningIconMessage from '../../../shared/components/WarningIconMessage/WarningIconMessage';
import { translate } from '../../../shared/locales';
import { ChannelTableDataType } from '../../Channel/types/ChannelTableDataType';
import { useSelectedTabState } from '../hooks/useSelectedTabState';
import { LinkedChannelsTableDataType } from '../types/LinkedChannelsDataType';
import { tabTargetsValue } from '../utils/contentStreamCreateUtils';
import LinkedChannelsDrawer from './LinkedChannelsDrawer';
import LinkedChannelsTable from './LinkedChannelsTable/LinkedChannelsTable';
import { ChannelsHeader, ChannelsTitleText } from './styles/ContentStreamForm.styled';
import { TargetingText } from './styles/Targets.styled';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisarionState';
import { useSelectedContentStreamState } from '../hooks/useSelectedContentStreamState';
import contentCreateManager from '../services/ContentStreamCreateManager';
import { IContentStreamRequestsDoneStates } from '../types/IContentStreamRequestsDoneStates';

export interface ILinkedChannelsProps {
  selectedLinkedChannels: Partial<ChannelTableDataType>[];
  setSelectedLinkedChannels: (selectedLinkedChannels: Partial<ChannelTableDataType>[]) => void;
  isFallbackContentStream: boolean;
  isInvalidTargetsTab: boolean;
  setRequestsDone: (requestsDone: React.SetStateAction<IContentStreamRequestsDoneStates>) => void;
}

const LinkedChannels: React.FC<ILinkedChannelsProps> = ({
  selectedLinkedChannels,
  setSelectedLinkedChannels,
  isFallbackContentStream,
  isInvalidTargetsTab,
  setRequestsDone,
}) => {
  const { selectedTab } = useSelectedTabState();
  const { selectedOrganisation } = useSelectedOrganisationState();
  const { selectedContentStream } = useSelectedContentStreamState();
  const [isSideModalOpen, setIsSideModalOpen] = useState(false);

  const loadSelectedChannels = useCallback(async () => {
    if (
      selectedOrganisation?.id &&
      selectedContentStream?.linkedChannelList &&
      selectedContentStream.linkedChannelList.length
    ) {
      const channels = await contentCreateManager.listChannelsFromIds(
        selectedOrganisation.id,
        selectedContentStream.linkedChannelList,
      );
      setRequestsDone((prevLoadingStates) => ({
        ...prevLoadingStates,
        channels: true,
      }));
      setSelectedLinkedChannels(channels);
    }
    return null;
  }, [selectedOrganisation?.id, selectedContentStream?.linkedChannelList, setRequestsDone, setSelectedLinkedChannels]);

  const { ...promiseStatusSelectedChannel } = usePromise(loadSelectedChannels);

  const mapSelectedChannels = () => {
    const mappedSelectedChannels = selectedLinkedChannels?.map((channel) => {
      return {
        name: channel.name,
        status: channel.status,
        id: channel.id,
      };
    }) as LinkedChannelsTableDataType[];
    return mappedSelectedChannels;
  };

  const handleOnDelete = (id: string) => {
    let deletedChannels = [...selectedLinkedChannels];
    deletedChannels = deletedChannels.filter((channels) => channels.id !== id);
    setSelectedLinkedChannels(deletedChannels);
  };

  return (
    <>
      <WppCard>
        <ChannelsHeader marg="8 0 8 0" h="auto">
          <ChannelsTitleText type="m-strong" data-testid="title">
            {translate('txtChannel')}
          </ChannelsTitleText>
          {!isFallbackContentStream && (
            <WppActionButton
              onClick={() => {
                setIsSideModalOpen(true);
              }}
              data-testid="link-channels">
              <WppIconLink slot="icon-start" />
              {translate('txtLinkChannels')}
            </WppActionButton>
          )}
        </ChannelsHeader>
        {selectedLinkedChannels.length === 0 &&
          !promiseStatusSelectedChannel.loading &&
          (isFallbackContentStream ? (
            <TargetingText data-testid={'empty-text'} type="s-body">
              {translate('txtFallbackContentStreamNoChannels')}
            </TargetingText>
          ) : (
            <>
              {isInvalidTargetsTab && (
                <WarningIconMessage
                  message={translate('txtAddChannelsToPublish')}
                  data-testid="warning-message-linked-channels"
                />
              )}
              <TargetingText
                data-testid={'empty-text'}
                type="s-body"
                style={{ marginTop: isInvalidTargetsTab ? '12px' : '0px' }}>
                {translate('txtContentStreamChannels')}
              </TargetingText>
            </>
          ))}
        {selectedTab === tabTargetsValue && (
          <LinkedChannelsTable
            isLoading={promiseStatusSelectedChannel.loading}
            data={mapSelectedChannels()}
            onDelete={handleOnDelete}
            forceReloadDimensions={selectedTab === tabTargetsValue}
            isFallbackContent={isFallbackContentStream}
          />
        )}
      </WppCard>

      {isSideModalOpen && (
        <LinkedChannelsDrawer
          selectedLinkedChannels={selectedLinkedChannels}
          setSelectedLinkedChannels={setSelectedLinkedChannels}
          isSideModalOpen={isSideModalOpen}
          setIsSideModalOpen={setIsSideModalOpen}
        />
      )}
    </>
  );
};

export default LinkedChannels;
