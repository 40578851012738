import { useSliceState, useSliceSetter } from '../../../shared/hooks/useStore';
import { ISideMenuState, ISideMenuActions } from '../sliceStore/sideMenuSliceStore';

export const useSelectedOrganisationState = () => {
  const selectedOrganisation = useSliceState<ISideMenuState, 'selectedOrganisation'>('selectedOrganisation');
  const setSelectedOrganisation = useSliceSetter<ISideMenuActions, 'setSelectedOrganisation'>(
    'setSelectedOrganisation',
  );

  return { selectedOrganisation, setSelectedOrganisation };
};
