/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { useTheme } from '@emotion/react';
import { WppTag, WppTypography } from '@wppopen/components-library-react';
import { BoxContent } from '../../../shared/components';
import { ChannelTableDataType } from '../types/ChannelTableDataType';

import { BoxContentCenter, ITheme } from '@connected-core-system/utils/frontend';

export interface IChannelTagsCellProps {
  tags: ChannelTableDataType['tags'];
}

const ChannelTagsCell: React.FC<IChannelTagsCellProps> = ({ tags }) => {
  const theme = useTheme() as ITheme; // use this
  return (
    <BoxContentCenter justify="flex-start">
      {tags.slice(0, 1).map((tag) => (
        <WppTag variant="neutral" label={tag} key={tag} max-label-length={12} />
      ))}
      {tags.length > 1 && (
        <WppTypography type="s-midi" color={theme.palette['datavizCatDark10']}>{`+${tags.length - 1}`}</WppTypography>
      )}
    </BoxContentCenter>
  );
};

export default ChannelTagsCell;
