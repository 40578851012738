import { useCallback, useEffect } from 'react';
import { OrganisationManager } from '../services';
import { usePromise } from '../../../shared/hooks';
import { useSelectedOrganisation } from '../../SideMenu';
import { useWppOpenContext } from '../../WppOs';

export const useLoadOrganisationWppOs = () => {
  const { osContext } = useWppOpenContext();
  const { setSelectedOrganisation } = useSelectedOrganisation();

  const tenantId = osContext.tenant.id;
  const loadOrganisations = useCallback(() => {
    if (tenantId) {
      return OrganisationManager.getOrganisationByTenantId(tenantId);
    }
    return null;
  }, [tenantId]);

  const { data, done } = usePromise(loadOrganisations);

  useEffect(() => {
    if (done && data) {
      setSelectedOrganisation(data);
    }
  }, [data, done, setSelectedOrganisation]);
};
