import styled from '@emotion/styled';
import { WppSideModal } from '@wppopen/components-library-react';
import { percentOfAppHeight } from '../../../../shared/utils';

export const ModalContainer = styled(WppSideModal)({
  zIndex: 5,
  '&::part(actions-wrapper)': {
    display: 'none',
  },

  '&::part(body-wrapper)': { padding: 0 },

  '&::part(side-modal)': {
    width: '100vw',
    height: `${percentOfAppHeight(100)}`,
    backgroundColor: '#F8F9FB',
  },

  '&::part(header-container)': {
    display: 'none',
  },
});

export const Container = styled.div({
  height: '100vh',
});
