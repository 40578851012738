import React, { useCallback, useEffect, useState } from 'react';
import {
  ChannelsContainerBare,
  ChannelsContainerOs,
  ContainerBare,
  ContainerOs,
  ContentContainer,
  EmptyStateContainer,
  HeaderActionsContainer,
  HeaderContainer,
  PreviewContainerBare,
  PreviewContainerOs,
  PreviewHeader,
} from '../styles/QRStylePage.styled';
import { BoxContent, Header, HeaderActions } from '../../../shared/components';
import { translate } from '../../../shared/locales';
import { IHeaderActionsItem } from '../../../shared/components/HeaderActions/HeaderActions';
import { WppIconError, WppIconPlus, WppIconSearch, WppSpinner, WppTypography } from '@wppopen/components-library-react';
import FallbackWrapper from '../../Feedback/views/FallbackWrapper';
import DotsStyle from './DotsStyle';
import BackgroundStyle from './BackgroundStyle';
import {
  useSecondTierItemIdToSelectOnReloadState,
  useSecondTierReloadState,
  useSecondTierSelectedItemState,
} from '../../SecondTierSidebar';
import { IOrganisation, MenuItemEnum, StatusEnum } from '../../../shared/types';
import CornersDotsStyle from './CornersDotsStyle';
import CornersSquareStyle from './CornersSquareStyle';
import QROptionsStyle from './QROptionsStyle';
import ImageStyle from './ImageStyle';
import { qrStyleManager } from '../services';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisarionState';
import { useQRCodeOptionsState } from '../hooks/useQRCodeOptionsState';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';
import { UniquePropertyExistsException } from '@core/exceptions';
import { useQRTitleState } from '../hooks/useQRTitleState';
import QRTitle from './QRTitle';
import { useValueChangesState } from '../hooks/useValueChangesState';
import { useHasAnyQRStyleState } from '../hooks/useHasAnyQRStyleState';
import EmptyState from '../../../shared/components/EmptyState/EmptyState';
import { useTheme } from '@emotion/react';
import { ITheme } from '@connected-core-system/utils/frontend';
import { SecondTierSidebarItem } from '../../SecondTierSidebar/types';
import { formatName } from '../../SecondTierSidebar/utils';
import QRStylePreview from './QRStylePreview';
import PlatformWrapper from '../../../shared/components/PlatformWrapper/PlatformWrapper';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IQRStyleProps {}

const NEW_QRSTYLE_ID = 'new';

const QRStylePage: React.FC<IQRStyleProps> = () => {
  const theme = useTheme() as ITheme;
  const [channelCount, setChannelCount] = useState<number | null>(null);
  const [promise, setPromise] = useState({
    loading: false,
    error: false,
    success: false,
    successToastMessage: '',
    errorToastMessage: '',
  });
  const [loadingChannelCount, setLoadingChannelCount] = useState<boolean>(false);

  const { setItemIdToSelectOnReload } = useSecondTierItemIdToSelectOnReloadState();
  const { selectedSecondTierItem, setSelectedSecondTierItem } = useSecondTierSelectedItemState();
  const { selectedOrganisation } = useSelectedOrganisationState();
  const { selectedSideMenuItem, setSelectedSideMenuItem } = useSelectedSideMenuItemState();
  const { qrCodeOptions } = useQRCodeOptionsState();
  const { title } = useQRTitleState();
  const [hasError, setHasError] = useState<boolean>(false);
  const [hasDotStyleError, setHasDotStyleError] = useState<boolean>(false);
  const [hasCornerSquareError, setHasCornerSquareError] = useState<boolean>(false);
  const [hasConerDotError, setHasCornerDotError] = useState<boolean>(false);
  const { valueChanges, setValueChanges } = useValueChangesState();
  const [disableSaveButton, setDisableSaveButton] = useState<boolean>(true);
  const { setSecondTierReload } = useSecondTierReloadState();
  const { hasAnyQRStyle } = useHasAnyQRStyleState();

  useEffect(() => {
    const hasError = hasDotStyleError || hasCornerSquareError || hasConerDotError;
    const valueChanged = valueChanges?.newTitle || valueChanges?.newValue;
    setDisableSaveButton(hasError || !title || !valueChanged);
    setHasError(hasError);
  }, [hasConerDotError, hasCornerSquareError, hasDotStyleError, title, valueChanges]);

  const getTagColor = () => {
    return selectedSecondTierItem?.status === StatusEnum.ACTIVE ? 'positive' : 'neutral';
  };

  const getChannelsLinked = useCallback(async () => {
    if (selectedOrganisation && selectedSecondTierItem) {
      setLoadingChannelCount(true);
      qrStyleManager
        .getChannelCount(selectedOrganisation.id, selectedSecondTierItem.id)
        .then((count) => {
          setChannelCount(count ?? 0);
        })
        .catch(() => {
          setPromise({
            loading: false,
            success: false,
            error: true,
            errorToastMessage: translate('txtSomethingWentWrongTryAgain'),
            successToastMessage: '',
          });
        })
        .finally(() => {
          setLoadingChannelCount(false);
        });
    }
  }, [selectedOrganisation, selectedSecondTierItem]);

  const renderLoading = () => {
    return (
      <BoxContent data-testid="loading-state">
        <WppSpinner size="m" />
      </BoxContent>
    );
  };

  const save: IHeaderActionsItem = {
    title: translate('txtSave'),
    type: 'primary',
    disabled: disableSaveButton,
    loading: promise.loading,
    action: () => {
      setPromise({ error: false, success: false, successToastMessage: '', errorToastMessage: '', loading: true });
      if (selectedSecondTierItem?.status === StatusEnum.UNPUBLISHED && selectedOrganisation) {
        createQRStyle(selectedOrganisation);
      } else {
        if (selectedSecondTierItem?.id && selectedOrganisation) {
          updateQRStyle(selectedOrganisation);
        }
      }
    },
  };

  const createQRStyle = (selectedOrganisation: IOrganisation) => {
    qrStyleManager
      .createQRStyle(selectedOrganisation.id, {
        name: title,
        configuration: qrCodeOptions || {},
        status: StatusEnum.ACTIVE,
      })
      .then((response) => {
        if (response instanceof UniquePropertyExistsException) {
          setPromise({
            loading: false,
            success: false,
            error: true,
            errorToastMessage: translate('txtQRStyleNameUsed'),
            successToastMessage: '',
          });
        } else {
          setPromise({
            loading: false,
            success: true,
            error: false,
            errorToastMessage: '',
            successToastMessage: translate('txtQRStyleCreationSuccess'),
          });
          setSecondTierReload(true);
          setItemIdToSelectOnReload(selectedSecondTierItem?.id ?? null);
          setSelectedSideMenuItem(MenuItemEnum.QRSTYLES);
          setValueChanges({ newTitle: false, newValue: false });
          setDisableSaveButton(true);
        }
      })
      .catch(() => {
        setPromise({
          loading: false,
          success: false,
          error: true,
          errorToastMessage: translate('txtSomethingWentWrongTryAgain'),
          successToastMessage: '',
        });
      });
  };

  const updateQRStyle = (selectedOrganisation: IOrganisation) => {
    const templateId = selectedSecondTierItem?.id ? selectedSecondTierItem.id : '';
    qrStyleManager
      .updateQRStyle(selectedOrganisation.id, templateId, {
        name: title,
        configuration: qrCodeOptions || {},
        status: StatusEnum.ACTIVE,
        templateId: templateId,
      })
      .then((response) => {
        if (response instanceof UniquePropertyExistsException) {
          setPromise({
            loading: false,
            success: false,
            error: true,
            errorToastMessage: translate('txtQRStyleNameUsed'),
            successToastMessage: '',
          });
        } else {
          setPromise({
            loading: false,
            success: true,
            error: false,
            errorToastMessage: '',
            successToastMessage: translate('txtQRStyleUpdateSuccess'),
          });
          setSecondTierReload(true);
          setDisableSaveButton(true);
          setItemIdToSelectOnReload(templateId ?? null);
          setSelectedSideMenuItem(MenuItemEnum.QRSTYLES);
          setValueChanges({ newTitle: false, newValue: false });
        }
      })
      .catch(() => {
        setPromise({
          loading: false,
          success: false,
          error: true,
          errorToastMessage: translate('txtSomethingWentWrongTryAgain'),
          successToastMessage: '',
        });
      });
  };

  const renderEmptyContent = () => {
    const createQRStyle = () => {
      if (selectedSideMenuItem) {
        const newItem: SecondTierSidebarItem = {
          id: NEW_QRSTYLE_ID,
          name: translate('txtNewSomething', { something: formatName(selectedSideMenuItem, true) }),
          status: StatusEnum.UNPUBLISHED,
        };
        setSelectedSecondTierItem(newItem);
      }
    };

    return (
      <EmptyStateContainer data-testid="empty-state-container">
        <EmptyState
          title={translate('txtNoQRStylesCreatedTitle')}
          titleIcon={
            <WppIconSearch slot="icon-start" aria-label="Search icon" color={theme.palette['grey1000']} size="m" />
          }
          text={translate('txtNoQRStylesCreated')}
          buttonText={translate('txtCreateQRStyle')}
          buttonIcon={<WppIconPlus slot="icon-start" />}
          buttonAction={createQRStyle}
        />
      </EmptyStateContainer>
    );
  };

  const renderContent = () => {
    return selectedSecondTierItem ? (
      <PlatformWrapper componentBare={ContainerBare} componentOs={ContainerOs} data-testid="qrstyle-page">
        <FallbackWrapper {...promise} />

        <HeaderContainer>
          <Header
            title={title || translate('txtDefaultQRStyleTitle')}
            tag={selectedSecondTierItem.status}
            tagColor={getTagColor()}
          />
        </HeaderContainer>
        <HeaderActionsContainer>
          {promise.loading ? renderLoading() : <HeaderActions actions={[save]} />}
        </HeaderActionsContainer>

        <ContentContainer>
          <BoxContent direction="column">
            <QRTitle />
            <DotsStyle setHasError={setHasDotStyleError} />
            <CornersSquareStyle setHasError={setHasCornerSquareError} />
            <CornersDotsStyle setHasError={setHasCornerDotError} />
            <BackgroundStyle />
            <ImageStyle />
            <QROptionsStyle />
          </BoxContent>
          <BoxContent>
            <PlatformWrapper
              componentBare={ChannelsContainerBare}
              componentOs={ChannelsContainerOs}
              flex
              align="center"
              justify="space-between">
              <WppTypography type="xs-body">{translate('txtChannelsLinked')}</WppTypography>
              {loadingChannelCount ? (
                <WppSpinner size="m" />
              ) : (
                <WppTypography type="m-strong">{channelCount}</WppTypography>
              )}
            </PlatformWrapper>
            <PlatformWrapper componentBare={PreviewContainerBare} componentOs={PreviewContainerOs}>
              <PreviewHeader>
                <WppTypography type="m-strong">{translate('txtPreview')}</WppTypography>
                {hasError && <WppIconError />}
              </PreviewHeader>

              <QRStylePreview />
            </PlatformWrapper>
          </BoxContent>
        </ContentContainer>
      </PlatformWrapper>
    ) : null;
  };

  useEffect(() => {
    getChannelsLinked();
  }, [getChannelsLinked]);

  return hasAnyQRStyle || selectedSecondTierItem?.id === NEW_QRSTYLE_ID ? renderContent() : renderEmptyContent();
};

export default QRStylePage;
