import { StatusEnum } from '@connected-core-system/utils/types';
import { ColumnDefinition } from '../../../shared/components';
import { isArrayEmpty, formatDate, DateFormatsEnum, formatTime, interpolateWithValues } from '../../../shared/utils';
import { ContentsTableDataType } from '../types/ContentsTableDataType';
import { ContentStreamContent } from '../types/ContentFormField';
import { ICardOption } from '../../../shared/types/ICardOption';

const contentsColumnDefinitions: {
  [key in keyof ContentsTableDataType]: ColumnDefinition & {
    fraction?: number;

    minwidth?: number;
  };
} = {
  id: {
    field: 'id',
    minWidth: 56,
    fraction: 0.02,
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  order: { field: 'order', minWidth: 100, fraction: 0.01, rowDrag: true },
  name: { field: 'name', sortable: false, cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' } },
  status: { field: '', sortable: false, fraction: 0.01, minWidth: 150, cellStyle: { textAlign: 'center' } },
  collectionType: {
    field: 'template',
    sortable: false,
    fraction: 0.1,
    minWidth: 150,
    cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' },
  },
  updatedAt: { field: 'lastModified', sortable: false, fraction: 0.1, minWidth: 150 },
  languages: {
    field: 'languages',
    sortable: false,
    fraction: 0.1,
    minWidth: 110,
    cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' },
  },
  deleteContent: { field: '', minWidth: 64, fraction: 0.02 },
  deleteStory: { field: '', minWidth: 64, fraction: 0.02 },
  cmsId: { field: '', minWidth: 64, fraction: 0.02 },
};

export const getColumnsContents = (columnsToExclude?: Array<keyof typeof contentsColumnDefinitions>) => {
  const allColumns = contentsColumnDefinitions;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const filteredColumns: { [x: string]: any } = {};

  if (isArrayEmpty(columnsToExclude)) return allColumns;
  else {
    for (const column in allColumns) {
      if (!columnsToExclude?.includes(column as keyof typeof allColumns)) {
        filteredColumns[column] = allColumns[column as keyof typeof allColumns];
      }
    }
    return filteredColumns;
  }
};

export enum tableTypeEnum {
  STORIES_SELECTION = 'story',
  SELECTED_STORY = 'selectedStory',
  CONTENTS_SELECTION = 'contents',
  SELECTED_CONTENTS = 'selectedContents',
}

export const formatLastModifiedDate = (lastModified: Date) => {
  return {
    date: formatDate(lastModified, { format: DateFormatsEnum['LLL dd yyyy'] }),
    time: formatTime(lastModified, { withRegion: true }),
  };
};

export const filterContents = (contents: Partial<ContentsTableDataType>[], searchTerm: string) => {
  return contents.filter((c) => {
    return (
      c.name?.toLocaleLowerCase().includes(searchTerm.toLowerCase()) ||
      (c.languages ? c.languages.map((language) => language.locale).join(', ') : '')
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      c.collectionType?.toLocaleLowerCase().includes(searchTerm.toLowerCase())
    );
  });
};
export const sortDataArrayByOrder = (data: Partial<ContentsTableDataType>[]) => {
  data.sort(function (a, b) {
    const valA = a.order == null ? Number.MAX_SAFE_INTEGER : a.order;
    const valB = b.order == null ? Number.MAX_SAFE_INTEGER : b.order;
    return Number(valA) - Number(valB);
  });
};

export const validateTabContentToPublish = (
  contents: Partial<ContentsTableDataType>[],
  story: Partial<ContentsTableDataType>[],
) => {
  if (!story?.length || story[0].status !== StatusEnum.ACTIVE) return false;
  if (!contents?.length || contents.every((content) => content?.status !== StatusEnum.ACTIVE)) return false;
  return true;
};

export const getContentPreviewUrl = (baseHostName: string, domain: string, orgID: string, cmsId: string) => {
  return interpolateWithValues(baseHostName, domain) + `/consumer/preview/${orgID}/${cmsId.trim()}`;
};

export const createContents = (selectedContent: Partial<ContentsTableDataType>[]) => {
  const contents = selectedContent?.map((content, index) => {
    return {
      priority: selectedContent.length - index - 1,
      contentId: content.id,
    };
  }) as ContentStreamContent[];
  return contents;
};

export const getSortedLanguagesText = (
  languages: Array<{ locale: string; status: StatusEnum }>,
  defaultLanguage: string,
): string => {
  const options = getLanguageOptions(languages, defaultLanguage);
  return options.map((option) => option.value).join(', ');
};

export const getLanguageOptions = (
  languages: Array<{ locale: string; status: StatusEnum }>,
  defaultLanguage: string,
): ICardOption[] => {
  const options: ICardOption[] = [];
  languages.forEach((language) => {
    options.push({
      value: language.locale,
      label: language.locale,
      status: language.status,
      defaultOption: defaultLanguage === language.locale,
    } as ICardOption);
  });

  const sortedOptions = sortLanguageOptions(options);

  return sortedOptions;
};

export const sortLanguageOptions = (options: ICardOption[]): ICardOption[] => {
  return options.sort((a, b) => {
    const aIsDefault = a.defaultOption ? 1 : 0;
    const bIsDefault = b.defaultOption ? 1 : 0;
    const aIsActive = a.status === StatusEnum.ACTIVE ? 1 : 0;
    const bIsActive = b.status === StatusEnum.ACTIVE ? 1 : 0;

    if (aIsDefault && bIsDefault) {
      return 0;
    }

    if (aIsDefault && aIsActive) {
      return -1;
    }

    if (bIsDefault && bIsActive) {
      return 1;
    }

    if (aIsDefault && !aIsActive && bIsActive) {
      return 1;
    }

    if (bIsDefault && !bIsActive && aIsActive) {
      return -1;
    }

    return bIsActive - aIsActive || bIsDefault - aIsDefault;
  });
};
