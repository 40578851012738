/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { BoxContentCenter, Table } from '../../../../shared/components';
import { ContentsTableDataType } from '../../types/ContentsTableDataType';
import NameCell from './NameCell';
import TemplateCell from './TemplateCell';
import LanguageCell from './LanguagesCell';
import LastModifiedCell from './LastModifiedCell';
import { useCallback, useMemo, useState } from 'react';
import ContentsHeader from './ContentsHeader';
import { WppIconSearch, WppSpinner, WppTypography } from '@wppopen/components-library-react';
import { useSelectedOrganisation } from '../../../SideMenu';
import ContentStreamCreateManager from '../../services/ContentStreamCreateManager';
import { usePromise } from '../../../../shared/hooks';
import EmptyState from '../../../../shared/components/EmptyState/EmptyState';
import { translate } from '../../../../shared/locales';
import { isArrayEmpty, percentOfAppHeight } from '../../../../shared/utils';
import { useTheme } from '@emotion/react';
import { ITheme } from '@connected-core-system/utils/frontend';
import { filterContents, getColumnsContents } from '../../utils/contentStreamContentUtils';
import StatusCell from './StatusCell';
import { useSelectedContentStreamState } from '../../hooks/useSelectedContentStreamState';
import { mapIdsFromContentArray } from '../../utils/contentStreamCreateUtils';
import { UNCLICKABLE_TABLE_IDS } from '../../../../shared/Constants/Constants';

export interface IContentsTableProps {
  columnsToExclude: Array<keyof ContentsTableDataType>;
  handleOnSelectRows?: (rows: Partial<ContentsTableDataType>[]) => void;
  listSelectedRowId?: string[];
  selectionType?: 'single' | 'multiple' | undefined;
  dataType: 'contents' | 'story';
}

const ContentsTable: React.FC<IContentsTableProps> = ({
  columnsToExclude,
  handleOnSelectRows,
  listSelectedRowId = [],
  selectionType,
  dataType,
}) => {
  const contentsColumnDefinitions = getColumnsContents(columnsToExclude);
  const theme = useTheme() as ITheme;
  const [searchTerm, setSearch] = useState('');
  const [tryAgain, setTryAgain] = useState(false);
  const { selectedOrganisation } = useSelectedOrganisation();
  const { selectedContentStream } = useSelectedContentStreamState();

  const loadDataTable = useCallback(() => {
    if (tryAgain) setTryAgain(false);
    if (selectedOrganisation && dataType === 'contents') {
      const existentContentIds = mapIdsFromContentArray(selectedContentStream?.linkedContent);
      return ContentStreamCreateManager.listContents(selectedOrganisation.id, existentContentIds);
    }
    if (selectedOrganisation && dataType === 'story') {
      const existentStoryId = selectedContentStream?.linkedStory || '';
      return ContentStreamCreateManager.listStory(selectedOrganisation.id, existentStoryId);
    }
    return null;
  }, [
    tryAgain,
    selectedOrganisation,
    dataType,
    selectedContentStream?.linkedContent,
    selectedContentStream?.linkedStory,
  ]);

  const { data, ...promiseStatusOptions } = usePromise(loadDataTable);

  contentsColumnDefinitions['name'].cellRenderer = ({ data }: { data: ContentsTableDataType }) => (
    <NameCell name={data.name} searchTerm={searchTerm} />
  );

  if (contentsColumnDefinitions['status']) {
    contentsColumnDefinitions['status'].cellRenderer = ({ data }: { data: ContentsTableDataType }) => (
      <StatusCell status={data.status} />
    );
  }

  contentsColumnDefinitions['updatedAt'].cellRenderer = ({ data }: { data: ContentsTableDataType }) => (
    <LastModifiedCell updatedAt={data.updatedAt} />
  );

  if (contentsColumnDefinitions['collectionType']) {
    contentsColumnDefinitions['collectionType'].cellRenderer = ({ data }: { data: ContentsTableDataType }) => (
      <TemplateCell collectionType={data.collectionType} />
    );
  }

  if (contentsColumnDefinitions['languages']) {
    contentsColumnDefinitions['languages'].cellRenderer = ({ data }: { data: ContentsTableDataType }) => (
      <LanguageCell languages={data.languages} />
    );
  }

  const filteredContents = useMemo(() => {
    if (data) {
      return filterContents(data, searchTerm);
    }
    return [];
  }, [data, searchTerm]);

  const onSelectRows = (rows: Partial<ContentsTableDataType>[]) => {
    const channelsSelectedButOutsideFilter: Partial<ContentsTableDataType>[] = [];
    for (let i = 0; i < listSelectedRowId.length; i++) {
      if (!filteredContents.find((channel: Partial<ContentsTableDataType>) => channel.id === listSelectedRowId[i])) {
        const channel = data?.find((channel: Partial<ContentsTableDataType>) => channel.id === listSelectedRowId[i]);
        if (channel) channelsSelectedButOutsideFilter.push(channel);
      }
    }
    handleOnSelectRows?.(channelsSelectedButOutsideFilter.concat(rows));
  };

  const renderEmpty = () => {
    if (promiseStatusOptions?.done) {
      if (isArrayEmpty(data) && dataType === 'story') {
        return (
          <EmptyState
            title={translate('txtNoStoriesYet')}
            titleIcon={
              <WppIconSearch slot="icon-start" aria-label="Search icon" color={theme.palette['grey1000']} size="m" />
            }
            text={translate('txtNoStoriesCreated')}
            buttonText={translate('txtTryAgain')}
            buttonAction={() => {
              setTryAgain(true);
            }}
          />
        );
      }
      if (isArrayEmpty(data) && dataType === 'contents') {
        return (
          <EmptyState
            title={translate('txtNoContentsYet')}
            titleIcon={
              <WppIconSearch slot="icon-start" aria-label="Search icon" color={theme.palette['grey1000']} size="m" />
            }
            text={translate('txtNoContentsCreated')}
            buttonText={translate('txtTryAgain')}
            buttonAction={() => {
              setTryAgain(true);
            }}
          />
        );
      }
      if (!filteredContents.length) {
        return (
          <BoxContentCenter>
            <WppTypography>{`No results found with "${searchTerm}"`}</WppTypography>
          </BoxContentCenter>
        );
      }
    }
    return null;
  };

  const renderLoading = () => {
    return (
      <BoxContentCenter>
        <WppSpinner size="l" />
      </BoxContentCenter>
    );
  };

  const renderError = () => {
    return (
      <EmptyState
        title={translate('txtSomethingWentWrong')}
        text={translate('txtErrorMessage')}
        buttonText={translate('txtTryAgain')}
        buttonAction={() => {
          setTryAgain(true);
        }}
      />
    );
  };

  return (
    <>
      <ContentsHeader onSearch={setSearch} searchTerm={searchTerm} />
      <Table
        fixedHeight={`calc(${percentOfAppHeight(100)} - 24rem)`}
        searchable
        data-testid={'contents-table'}
        searchTerm={searchTerm || ''}
        displayData={filteredContents}
        selectedRowsId={listSelectedRowId}
        columnDefs={Object.values(contentsColumnDefinitions)}
        onSelectRows={onSelectRows}
        hasHeader={true}
        selectionType={selectionType}
        rowMultiSelectWithClick={true}
        renderLoading={renderLoading}
        renderEmptyState={renderEmpty}
        renderError={renderError}
        isLoading={promiseStatusOptions?.loading}
        hasError={promiseStatusOptions?.error}
        columnsToPreventSelection={[
          {
            column: 'languages',
            elementsIds: [UNCLICKABLE_TABLE_IDS.languageMenuButton, UNCLICKABLE_TABLE_IDS.languageMenuIcon],
          },
        ]}
      />
    </>
  );
};

export default ContentsTable;
