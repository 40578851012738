/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { translate } from '../../locales/i18n';
import { ActionButton } from './HeaderActions.styled';

export interface IHeaderActionsItem {
  title: string;
  type: 'primary' | 'secondary' | 'destructive';
  disabled?: boolean;
  loading?: boolean;
  icon?: React.ReactNode;
  action: () => void;
}

export interface IHeaderActionsProps {
  actions: Array<IHeaderActionsItem>;
}

const HeaderActions: React.FC<IHeaderActionsProps> = ({ actions }) => {
  return (
    <>
      {actions.map(
        (a) =>
          a.title !== translate('txtArchive') && (
            <ActionButton variant={a.type} onClick={a.action} key={a.title} disabled={a.disabled} loading={a.loading}>
              {a.icon}
              {a.title}
            </ActionButton>
          ),
      )}
    </>
  );
};

export default HeaderActions;
