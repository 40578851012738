/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useState } from 'react';
import { WppIconTrash } from '@wppopen/components-library-react';
import { AutoCompleteDropDown, BoxContent } from '../../../shared/components';
import { TOption } from '../../../shared/types/TOption';
import {
  ContainerCategoryField,
  ContainerTagField,
  ContainerTrashIconButton,
  TrashIconButton,
} from './styles/TagCategoryForm.styled';
import { getCategoryOptions, getTagOptions } from '../tagCategoryUtils';
import { TTagCategoryFormField } from '../types/TagCategoryFormField';
import { translate } from '../../../shared/locales';
import { ITagCategory } from '../../../shared/types';

export interface ITagCategoryFieldProps {
  handleSelectCategory: (fieldId: string, v: TOption) => void;
  handleSelectTags: (fieldId: string, v: TOption[]) => void;
  removeCategory: (fieldId: string) => void;
  tagCategoryOptions: ITagCategory[];
  setTagCategoryOptions: (options: ITagCategory[]) => void;
  tagCategoryFormField: TTagCategoryFormField;
  loadingCategories: boolean;
}

const TagCategoryField: React.FC<ITagCategoryFieldProps> = ({
  handleSelectCategory,
  handleSelectTags,
  removeCategory,
  tagCategoryFormField,
  tagCategoryOptions,
  setTagCategoryOptions,
  loadingCategories,
}) => {
  const [tagOptions, setTagOptions] = useState(
    getTagOptions(tagCategoryOptions, tagCategoryFormField.value).filter((option) => !!option.label),
  );
  const [categoryOptions, setCategoryOptions] = useState(getCategoryOptions(tagCategoryOptions));
  const handleWppChangeCategory = (selectedCategory: TOption[]) => {
    handleSelectCategory(tagCategoryFormField.id, selectedCategory[0]);
  };
  useEffect(() => {
    setCategoryOptions(getCategoryOptions(tagCategoryOptions));
    setTagOptions(getTagOptions(tagCategoryOptions, tagCategoryFormField.value).filter((option) => !!option.label));
  }, [tagCategoryFormField.value, tagCategoryOptions]);

  const handleWppChangeTags = (selectedTags: TOption[]) => {
    handleSelectTags(tagCategoryFormField.id, selectedTags);
  };

  const handleCreateNewTag = (newOption: TOption) => {
    newOption.value = `id-temp-${new Date().getTime().toString()}`;
    const newTag: ITagCategory = {
      id: newOption.value,
      name: newOption.label,
      organisationId: '',
      categoryId: tagCategoryFormField.value,
      categoryName: tagCategoryFormField.label,
    };

    setTagCategoryOptions([...tagCategoryOptions, newTag]);

    handleSelectTags(tagCategoryFormField.id, [
      ...tagCategoryFormField.tags.filter((t) => t.label !== newOption.label),
      newOption,
    ]);
  };

  const handleCreateNewCategory = (newOption: TOption) => {
    newOption.value = `id-temp-${new Date().getTime().toString()}`;
    const newCategory: ITagCategory = {
      id: '',
      name: '',
      organisationId: '',
      categoryId: newOption.value,
      categoryName: newOption.label,
    };
    setTagCategoryOptions([...tagCategoryOptions, newCategory]);
    handleSelectCategory(tagCategoryFormField.id, newOption);
  };

  const renderSelect = (
    multiple = false,
    options: Array<TOption>,
    type: 'cat' | 'tag',
    formField: TTagCategoryFormField,
  ) => {
    const isRederingTag = type === 'tag';
    const disable = isRederingTag && !formField.label;
    let placeHolderTxt = '';
    if (loadingCategories) {
      placeHolderTxt = translate('txtLoading', { something: '' });
    } else if (isRederingTag) {
      placeHolderTxt = disable
        ? translate('txtPlaceholderCategoryFirst')
        : formField.tags.length > 0
        ? `${formField.tags.length} selected`
        : translate('txtPlaceholderSelect', { something: 'the tags' });
    } else {
      placeHolderTxt = formField.label ? formField.label : translate('txtChooseCategory');
    }

    const value = isRederingTag
      ? formField.tags
      : formField.label
      ? [{ value: formField.value, label: formField.label }]
      : [];

    return (
      <AutoCompleteDropDown
        dataTestId={`${formField.id}-${type}`}
        isMultipleSelection={multiple}
        placeHolder={placeHolderTxt}
        disabled={disable}
        options={options}
        selectedOptions={value}
        setSelectedOptions={isRederingTag ? handleWppChangeTags : handleWppChangeCategory}
        showCreateNewElement
        showTags={isRederingTag}
        handleCreateNewOption={(newItem) =>
          isRederingTag ? handleCreateNewTag(newItem) : handleCreateNewCategory(newItem)
        }
      />
    );
  };

  if (!tagCategoryOptions) {
    return null;
  }

  return (
    <BoxContent pad="16" marg="20 0" bg="grey100" radius={8}>
      <ContainerCategoryField>
        {renderSelect(false, categoryOptions, 'cat', tagCategoryFormField)}
      </ContainerCategoryField>
      <ContainerTagField>
        {tagCategoryFormField.label && renderSelect(true, tagOptions, 'tag', tagCategoryFormField)}
        {!tagCategoryFormField.label && renderSelect(true, [], 'tag', tagCategoryFormField)}
      </ContainerTagField>
      <ContainerTrashIconButton>
        <TrashIconButton
          slot="trigger-button"
          onClick={() => removeCategory(tagCategoryFormField.id)}
          data-testid="icon-remove">
          <WppIconTrash />
        </TrashIconButton>
      </ContainerTrashIconButton>
    </BoxContent>
  );
};

export default TagCategoryField;
