import { MakeNull } from '@connected-core-system/utils/cms-http-dto';

export enum BreakPointsEnum {
  bp320 = 'bp320',
  bp480 = 'bp480',
  bp768 = 'bp768',
  bp960 = 'bp960',
}

export interface IPalette {
  light: string;
  dark: string;
  primary: string;
  secondary: string;
  description: string;
  primaryButtonBackground: string;
  primaryButtonText: string;
  secondaryButtonBackground: string;
  secondaryButtonText: string;
  textColor: string;
  backgroundTopGradient: string;
  backgroundMiddleGradient: string;
  backgroundBottomGradient: string;
  submitButtonBackground: string;
  submitButtonText: string;
  [key: string]: string;
}

export type ITypographyProps = Partial<
  MakeNull<{
    fontFamily: string;
    fontFamilyRegular: string;
    fontFamilyBold: string;
    fontStyle: string;
    fontSize: number;
    fontWeight: string | number;
    lineHeight: string | number;
  }>
>;

export interface ITheme {
  palette: IPalette;
  applyDropShadow: boolean;
  typography: {
    [key: string]: ITypographyProps;
  };
  layout: {
    headerBreakPoints: {
      bp320: string;
      bp480: string;
      bp768: string;
      bp960: string;
    };
  };
}
