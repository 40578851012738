/* eslint-disable @typescript-eslint/no-empty-interface */
import { PillGroupChangeEvent } from '@wppopen/components-library';
import { translate } from '../../../shared/locales';
import { BoxContent, BoxContentCenter, TimeInput } from '../../../shared/components';
import { useEffect, useState } from 'react';
import { DayOfWeekEnum } from '@connected-core-system/utils/types';
import {
  Label,
  ContainerLabel,
  StyledWppCheckbox,
  InputContainer,
  ContainerContent,
  ContainerFields,
  ContainerPills,
  TargetsCard,
  StyledWppIconTrash,
  StyledWppPillGroup,
  StyledWppPill,
  ErrorMessage,
} from './styles/Time.styled';
import { TargetingTimeDTO } from '@connected-core-system/utils/content-stream-http-dto';
import { getTargetTime, mapExistingTargetingTime } from '../utils/contentStreamTargetTimeUtils';
import { isArrayEmpty } from '../../../shared/utils';
import ErrorIconMessage from '../../../shared/components/ErrorIconMessage/ErrorIconMessage';
export interface ITargetingTimeProps {
  removeTime: () => void;
  targetingTime: TargetingTimeDTO[];
  setTargetingTime: (targetingSchedule: TargetingTimeDTO[]) => void;
  isInvalidTargetsTab: boolean;
  setIsInvalidTargetTime: (isInvalidTargetTime: boolean) => void;
}
const TargetTime: React.FC<ITargetingTimeProps> = ({
  removeTime,
  targetingTime,
  setTargetingTime,
  isInvalidTargetsTab,
  setIsInvalidTargetTime,
}) => {
  const { timeDay, startTime, endTime } = mapExistingTargetingTime(targetingTime);
  const [isChecked, setIsChecked] = useState(!startTime && !endTime);
  const [targetTimeDay, setTargetTimeDay] = useState<DayOfWeekEnum[]>(timeDay);
  const [targetStartTime, setTargetStartTime] = useState(startTime);
  const [targetEndTime, setTargetEndTime] = useState(endTime);
  const [endTimeError, setEndTimeError] = useState<string>('');
  const [startTimeError, setStartTimeError] = useState<string>('');

  const handleAllDayCheckbox = (checked: boolean) => {
    setIsChecked(checked);
    if (checked) {
      setTargetStartTime('');
      setTargetEndTime('');
    }
  };

  useEffect(() => {
    let newEndTimeError = '';
    if (targetEndTime !== '' && targetEndTime <= targetStartTime) {
      newEndTimeError = translate('txtEnterValidEndTime');
    }
    setEndTimeError(newEndTimeError);
  }, [targetEndTime, targetStartTime, endTimeError]);

  useEffect(() => {
    const data = getTargetTime(targetStartTime, endTimeError ? '' : targetEndTime, targetTimeDay);
    setTargetingTime(data);
  }, [targetEndTime, targetStartTime, targetTimeDay, setTargetingTime, endTimeError]);

  useEffect(() => {
    setIsInvalidTargetTime(targetingTime.length === 0 || !!endTimeError || !!startTimeError);
  }, [targetingTime, endTimeError, startTimeError, setIsInvalidTargetTime]);

  return (
    <TargetsCard
      expandedByDefault
      size="s"
      text={translate('txtTime')}
      data-testid="expandable-target-time"
      withDivider={false}>
      <StyledWppIconTrash onClick={() => removeTime()} data-testid="icon-remove" />
      <ContainerContent>
        <ContainerPills>
          <div>
            <ContainerLabel>
              <Label type="s-strong">{translate('txtWeekdays')}</Label>
            </ContainerLabel>
          </div>
          <StyledWppPillGroup
            type="multiple"
            value={targetTimeDay}
            data-testid="wpp-pill"
            onWppChange={(e: CustomEvent<PillGroupChangeEvent>) => {
              setTargetTimeDay(e.detail.value as DayOfWeekEnum[]);
            }}>
            <StyledWppPill label={translate('txtMon')} value={DayOfWeekEnum.MONDAY} />
            <StyledWppPill label={translate('txtTue')} value={DayOfWeekEnum.TUESDAY} />
            <StyledWppPill label={translate('txtWed')} value={DayOfWeekEnum.WEDNESDAY} />
            <StyledWppPill label={translate('txtThu')} value={DayOfWeekEnum.THURSDAY} />
            <StyledWppPill label={translate('txtFri')} value={DayOfWeekEnum.FRIDAY} />
            <StyledWppPill label={translate('txtSat')} value={DayOfWeekEnum.SATURDAY} />
            <StyledWppPill label={translate('txtSun')} value={DayOfWeekEnum.SUNDAY} />
          </StyledWppPillGroup>
          {isArrayEmpty(targetingTime) && isInvalidTargetsTab && (
            <ErrorMessage data-testid="warning-message">
              <ErrorIconMessage message={translate('txtInvalidEmptySelection')} />
            </ErrorMessage>
          )}
        </ContainerPills>
        <ContainerFields>
          <BoxContent flex align="end">
            <ContainerLabel>
              <Label type="s-strong">{translate('txtPeriod')}</Label>
            </ContainerLabel>
            <StyledWppCheckbox
              data-testid="all-day-checkbox"
              checked={isChecked}
              labelConfig={{ text: translate('txtAllDay') }}
              name="All Day"
              onWppChange={({ detail: { checked } }) => handleAllDayCheckbox(checked)}
            />
          </BoxContent>
          <BoxContentCenter justify="flex-start" align="flex-start">
            <InputContainer>
              <TimeInput
                stateValue={targetStartTime}
                disabled={isChecked}
                setStateValue={setTargetStartTime}
                errorMessage={startTimeError}
                setErrorMessage={setStartTimeError}
              />
            </InputContainer>
            <InputContainer>
              <TimeInput
                stateValue={targetEndTime}
                disabled={isChecked}
                setStateValue={setTargetEndTime}
                errorMessage={endTimeError}
                setErrorMessage={setEndTimeError}
                placeholder="23:59"
              />
            </InputContainer>
          </BoxContentCenter>
        </ContainerFields>
      </ContainerContent>
    </TargetsCard>
  );
};

export default TargetTime;
