import { TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { WppTypography } from '@wppopen/components-library-react';
import { BoxContent } from '../../../../shared/components';

export const Container = styled.div({
  display: 'none',
});

export const LogoutContainerBare = styled(BoxContent)({
  height: '8rem',
  padding: '2.6rem 0',
});

export const LogoutContainerOs = styled(BoxContent)({
  padding: '.5rem .8rem',
  marginTop: '.8rem',
});

export const HearderText = styled(WppTypography)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey700'],
}));
