import styled from '@emotion/styled';
import { WppIconTrash, WppSpinner } from '@wppopen/components-library-react';

export const WppStyledWppProgressIndicator = styled(WppSpinner)({
  marginLeft: '0.5rem',
});

export const StyledWppIconTrash = styled(WppIconTrash)({
  position: 'absolute',
  left: '50%',
  transform: 'translateX(-50%)',
  cursor: 'pointer',
});
