import { ExceptionBase } from '../exception.base';
import { ExceptionCode, ExceptionMessages } from '../enum';

export class NotSupportedException extends ExceptionBase {
  static readonly message = ExceptionMessages.NotSupported;

  constructor(message = NotSupportedException.message, cause?: Error) {
    super(message, cause);
  }

  readonly code = ExceptionCode.NotSupported;
}
