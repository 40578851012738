import React, { useEffect, useState } from 'react';

import { ChannelContentStreamPostDTO } from '@connected-core-system/utils/channel-http-dto';
import { StatusEnum, interpolateWithValues } from '@connected-core-system/utils/types';
import { TabsChangeEventDetail } from '@wppopen/components-library';
import { WppActionButton, WppButton, WppIconCross, WppTypography } from '@wppopen/components-library-react';

import ChannelDetailForm from './ChannelDetailForm';
import { BoxContent, ContentPage, Header, Tabs } from '../../../shared/components';
import ConfirmationModal from '../../../shared/components/Modals/ConfirmationModal';
import { TabContainer } from '../../../shared/components/Tabs/Tabs.styled';
import { translate } from '../../../shared/locales';
import { IPromiseStatus } from '../../../shared/types';
import { useSelectedBrandState, useSelectedChannelState, useSelectedUnitState } from '../../Channel/hooks';
import { useChannelTableTryAgainState } from '../../Channel/hooks/useChannelTableTryAgainState';
import { ContentStreamTable } from '../../ContentStream';
import { tableContentStreamPriority } from '../../ContentStream/contentStreamUtils';
import { ContentStreamTableDataType } from '../../ContentStream/types/ContentStreamTableDataType';
import FallbackWrapper from '../../Feedback/views/FallbackWrapper';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisarionState';
import {
  channelFormTabs,
  generateNewChannelObject,
  getQrCodeUrl,
  getTagColor,
  tabContentStreamPriorityValue,
  tabDetailsValue,
  transformReturnedChannelToChannelForm,
  areObjectsEqualChannelCreateForm,
  areObjectsEqualChannelEditForm,
  validateChannelForm,
} from '../channelCreateUtils';
import { useIsChannelModalOpenedState } from '../hooks/useIsChannelModalOpenedState';
import channelManager from '../services/ChannelCreateEditManager';
import { TCategoryFormField } from '../types/CategoryFormField';
import { ChannelFormCreate } from '../types/ChannelCreate';
import { FormHeader, MainContentTableContainer, StyledBoxContent } from './styles/ChannelForm.styled';
import { useHostName } from '../../../shared/hooks';
import { UniquePropertyExistsException } from '@core/exceptions';
import { IChannelRequestsDoneStates } from '../types/IChannelRequestsDoneStates';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IChannelFormProps {}

const ChannelForm: React.FC<IChannelFormProps> = () => {
  const [promise, setPromise] = useState<IPromiseStatus>({
    loading: false,
    error: false,
    success: false,
    successToastMessage: '',
    errorToastMessage: '',
  });
  const { selectedOrganisation } = useSelectedOrganisationState();
  const { setIsChannelModalOpened } = useIsChannelModalOpenedState();
  const { selectedBrandId } = useSelectedBrandState();
  const { selectedUnitId } = useSelectedUnitState();
  const { selectedChannel, setSelectedChannel } = useSelectedChannelState();
  const [selectedTab, setSelectedTab] = useState('details');
  const [selectedFallbackState, setSelectedFallbackState] = useState<ContentStreamTableDataType | null>(null);
  const { setTryAgain } = useChannelTableTryAgainState();
  const [exitConfirmationModalOpened, setExitConfirmationModalOpened] = useState(false);
  const [reloadTagCategory, setReloadTagCategory] = useState<boolean>(false);
  const baseHostName = useHostName();
  const [channel, setChannel] = useState<ChannelFormCreate & { channelURL?: string; status: StatusEnum }>(
    selectedChannel
      ? transformReturnedChannelToChannelForm(
          selectedChannel,
          selectedOrganisation?.id ?? '',
          getQrCodeUrl(baseHostName, selectedOrganisation?.domain ?? '', selectedChannel.id),
        )
      : generateNewChannelObject(selectedOrganisation),
  );
  const [requestsDone, setRequestsDone] = useState<IChannelRequestsDoneStates>({
    tags: false,
  });

  useEffect(() => {
    if (!selectedChannel) {
      setChannel({
        brandId: '',
        channelTags: [],
        name: '',
        organisationId: '',
        unitId: '',
        status: StatusEnum.UNPUBLISHED,
        channelContentStreams: [],
        isOrganisationShortDomain: !!selectedOrganisation?.vanityDomain,
      });
    }
  }, [selectedChannel, selectedOrganisation?.vanityDomain]);

  const [channelTagsFromScreen, setChannelTagsFromScreen] = useState<TCategoryFormField[]>([]);

  const validChannel = validateChannelForm(channel, selectedUnitId ?? '', selectedBrandId ?? '');

  const handleSave = async () => {
    try {
      setPromise({
        loading: true,
        error: false,
        success: false,
        successToastMessage: '',
        errorToastMessage: '',
      });
      if (channel && validChannel && selectedOrganisation) {
        const channelUrl = getQrCodeUrl(baseHostName, selectedOrganisation?.domain);
        const domainUrl = channel.isOrganisationShortDomain
          ? `https://${selectedOrganisation?.vanityDomain}`
          : interpolateWithValues(baseHostName, selectedOrganisation?.domain ?? '');
        const body = {
          name: channel.name,
          brandId: selectedBrandId ? selectedBrandId : channel.brandId,
          unitId: selectedUnitId ? selectedUnitId : channel.unitId,
          organisationId: selectedOrganisation.id,
          qrCodeTemplateId: channel.qrCodeTemplateId ?? '',
          channelTags: [],
          fallbackContentStreamId: selectedFallbackState?.id,
          channelContentStreams: channel.channelContentStreams,
          isOrganisationShortDomain: channel.isOrganisationShortDomain,
        };
        const res = selectedChannel
          ? await channelManager.updateChannel(body, channelTagsFromScreen, selectedChannel.id, channelUrl, domainUrl)
          : await channelManager.saveChannel(body, channelTagsFromScreen, channelUrl, domainUrl);
        if (res) {
          setTryAgain(true);
          setReloadTagCategory(true);
          if (selectedOrganisation) {
            setChannel({
              channelTags: res.channelTags,
              unitId: res.unitId,
              brandId: res.brandId,
              status: res.status,
              name: res.name,
              qrCodeTemplateId: res.qrCodeTemplateId,
              organisationId: res?.organisationId,
              fallbackContentStreamId: res.fallbackContentStreamId,
              channelURL: res.channelURL,
              updatedBy: res.updatedBy,
              updatedAt: res.updatedAt,
              channelContentStreams: res.channelContentStreams,
              shortUrlId: res.shortUrlId,
              isOrganisationShortDomain: res.isOrganisationShortDomain,
            });
            setSelectedChannel({
              id: res.id,
              channelTags: res.channelTags,
              unit: res.unitId,
              brand: res.brandId,
              status: res.status,
              name: res.name,
              qrCodeTemplateId: res.qrCodeTemplateId,
              tags: res.channelTags?.map((tag) => tag.tag),
              fallbackContentStreamId: res.fallbackContentStreamId,
              channelContentStreams: res.channelContentStreams,
              updatedBy: res.updatedBy,
              updatedAt: res.updatedAt,
              lastUpdate: res.lastUpdate,
              shortUrlId: res.shortUrlId,
              isOrganisationShortDomain: res.isOrganisationShortDomain,
            });
          }
          setPromise({
            loading: false,
            error: false,
            success: true,
            successToastMessage: selectedChannel
              ? translate('txtChangesSaved')
              : translate('txtSuccessCreated', { something: translate('txtChannel') }),
            errorToastMessage: '',
          });
        }
      }
    } catch (error) {
      let errorMessage: string;
      if (error instanceof UniquePropertyExistsException) {
        errorMessage = translate('txtTitleUsed');
      } else {
        errorMessage = translate('txtErrorMessage');
      }
      setPromise({
        loading: false,
        error: true,
        success: false,
        successToastMessage: '',
        errorToastMessage: errorMessage,
      });
    }
  };

  const txtNewChannel = translate('txtNewSomething', { something: translate('txtChannel') });

  const txtChannel = translate('txtChannel');
  const btnSave = translate('btnSave');

  const areObjectsEqual = selectedChannel
    ? areObjectsEqualChannelEditForm(
        channel,
        selectedBrandId ? selectedBrandId : channel.brandId,
        selectedUnitId ? selectedUnitId : channel.unitId,
        selectedFallbackState?.id || channel?.fallbackContentStreamId || '',
        channelTagsFromScreen,
        transformReturnedChannelToChannelForm(
          selectedChannel,
          selectedOrganisation?.id ?? '',
          getQrCodeUrl(baseHostName, selectedOrganisation?.domain ?? '', selectedChannel.id),
        ),
        requestsDone,
      )
    : areObjectsEqualChannelCreateForm(
        channel,
        channelTagsFromScreen,
        selectedFallbackState?.id || channel?.fallbackContentStreamId || '',
      );

  const handleTabChange = (event: CustomEvent<TabsChangeEventDetail>) => {
    setSelectedTab(event.detail.value);
  };

  const handleCloseButtonClick = () => {
    if (!areObjectsEqual) setExitConfirmationModalOpened(true);
    else {
      setSelectedChannel(null);
      setIsChannelModalOpened(false);
    }
  };

  const handleConfirmationModal = () => {
    setExitConfirmationModalOpened(false);
  };

  const handleCancelConfirmationModal = () => {
    setExitConfirmationModalOpened(false);
    setSelectedChannel(null);
    setIsChannelModalOpened(false);
  };

  const handleRowDrag = (data: ContentStreamTableDataType[]) => {
    const newChannelContentStreamOrder = { ...channel };
    newChannelContentStreamOrder.channelContentStreams = data.map((contentStream, index) => {
      return {
        contentStreamId: contentStream.id,
        priority: data.length - index - 1,
      } as ChannelContentStreamPostDTO;
    });
    setChannel(newChannelContentStreamOrder);
  };

  const contentStreamPriorityTable = () => {
    return (
      <MainContentTableContainer>
        <ContentStreamTable
          location={tableContentStreamPriority}
          data-testid={'content-stream-table-channel'}
          forceReloadDimensions={selectedTab === tabContentStreamPriorityValue}
          handleRowDrag={handleRowDrag}
        />
      </MainContentTableContainer>
    );
  };

  const detailsFormContent = () => {
    return (
      <ChannelDetailForm
        channel={channel}
        setChannel={setChannel}
        setChannelTagsFromScreen={setChannelTagsFromScreen}
        promise={promise}
        selectedFallbackState={selectedFallbackState}
        setSelectedFallbackState={setSelectedFallbackState}
        reloadTagCategory={reloadTagCategory}
        setReloadTagCategory={setReloadTagCategory}
        areObjectsEqual={areObjectsEqual}
        qrCodeDataFlag={selectedChannel?.isOrganisationShortDomain}
        requestsDone={requestsDone}
        setRequestsDone={setRequestsDone}
      />
    );
  };

  const exitEditModal = () => {
    return (
      exitConfirmationModalOpened && (
        <ConfirmationModal
          title={translate('txtConfirmationModalLeaveEditTitle')}
          text={translate('txtConfirmationModalLeaveEditText')}
          buttonCancelText={translate('txtButtonModalCancel')}
          buttonText={translate('txtButtonModal')}
          buttonAction={handleConfirmationModal}
          buttonCancelAction={handleCancelConfirmationModal}
          isOpen={exitConfirmationModalOpened}
          setIsOpen={setExitConfirmationModalOpened}
          isWarning={false}
        />
      )
    );
  };

  return (
    <ContentPage>
      {exitEditModal()}
      <FallbackWrapper {...promise} />
      <FormHeader h="auto" pad="16 42" flex justify="space-between" align="flex-end" bg="grey000">
        <WppTypography type="m-strong">{txtChannel}</WppTypography>
        <WppActionButton data-testid="exit-button" slot="trigger-button" onClick={handleCloseButtonClick}>
          <WppIconCross />
        </WppActionButton>
      </FormHeader>
      <BoxContent h="auto" pad="24 32">
        <Header
          title={selectedChannel ? selectedChannel.name : txtNewChannel}
          tag={channel.status}
          tagColor={getTagColor(channel.status)}>
          <BoxContent flex justify="flex-end">
            <WppButton
              data-testid="save-button"
              onClick={handleSave}
              disabled={selectedChannel ? !validChannel || areObjectsEqual : !validChannel}
              loading={promise.loading}>
              {btnSave}
            </WppButton>
          </BoxContent>
        </Header>
        <StyledBoxContent marg="24 0">
          <Tabs
            tabs={channelFormTabs()}
            handleTabChange={handleTabChange}
            selectedTab={selectedTab}
            data-testId={'tabs-component'}
          />
        </StyledBoxContent>
        <TabContainer show={selectedTab === tabDetailsValue}> {detailsFormContent()}</TabContainer>
        <TabContainer show={selectedTab === tabContentStreamPriorityValue}>{contentStreamPriorityTable()}</TabContainer>
      </BoxContent>
    </ContentPage>
  );
};

export default ChannelForm;
