import axios from 'axios';
import { AuthenticatedUserDTO } from '@connected-core-system/utils/user-http-dto';
import { IAuthorizationRepository } from '../types/IAuthorizationRepository';
import { BFF_ENDPOINTS } from '../../../shared/api';

export class AuthorizationRepository implements IAuthorizationRepository {
  fetchUserInfo(token: string) {
    return axios.get<AuthenticatedUserDTO>(BFF_ENDPOINTS.USER_ME, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }
}

export default new AuthorizationRepository();
