/* eslint-disable @typescript-eslint/no-empty-function */
import { zustandStore as useStore, Store } from '../store/store';

export function useSliceSetter<T, K extends keyof T>(actionName: keyof Store): T[K] {
  const action = useStore((state) => state[actionName as unknown as keyof Store]);
  return action as T[K];
}

export function useSliceState<T, K extends keyof T>(stateName: K): T[K] {
  const sliceState = useStore((state) => state[stateName as unknown as keyof Store]);
  return sliceState as T[K];
}
