import { IOrganisation } from '../../../shared/types/IOrganisation';
import { SetCallback } from '../../../shared/store';
import { MenuItemEnum } from '../../../shared/types';

export interface ISideMenuState {
  isMenuOpened: boolean;
  isSmallScreen: boolean;
  selectedOrganisation?: IOrganisation | null;
  selectedMenuItem: MenuItemEnum | null;
}

export const initialState: ISideMenuState = {
  isMenuOpened: true,
  isSmallScreen: false,
  selectedMenuItem: null,
};

const actions = (set: SetCallback<ISideMenuState>) => ({
  switchSideMenu: (open: boolean) => set((state) => ({ ...state, isMenuOpened: open })),
  switchIsSmallScreen: (isSmall: boolean) => set((state) => ({ ...state, isSmallScreen: isSmall })),

  setSelectedMenuItem: (menuItem: MenuItemEnum | null) => set((state) => ({ ...state, selectedMenuItem: menuItem })),

  setSelectedOrganisation: (organisation: ISideMenuState['selectedOrganisation']) =>
    set((state) => ({ ...state, selectedOrganisation: organisation })),
});

export const slice = (set: SetCallback<ISideMenuState>) => ({
  ...initialState,
  ...actions(set),
});

export type ISideMenuActions = ReturnType<typeof actions>;

export default {
  slice,
  initialState,
};
