/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { WppSpinner } from '@wppopen/components-library-react';
import { BoxContentCenter, TextHighlight } from '../../../shared/components';

export interface IChannelLoadingCellProps {
  cellName: string;
  searchTerm?: string;
  isLoading: boolean;
}

const ChannelLoadingCell: React.FC<IChannelLoadingCellProps> = ({ cellName, searchTerm, isLoading }) => {
  return (
    <BoxContentCenter justify="flex-start">
      {!isLoading ? (
        <TextHighlight fullString={cellName} subString={searchTerm ? searchTerm : ''} />
      ) : (
        <WppSpinner size="s" />
      )}
    </BoxContentCenter>
  );
};

export default ChannelLoadingCell;
