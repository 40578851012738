/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useRef, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { CellClickedEvent, ColDef, RowDragEndEvent, Column, RowNode } from 'ag-grid-community';
import { WppSpinner } from '@wppopen/components-library-react';
import { useContainerDimensions } from '../../hooks';
import DefaultHeaderCell from './DefaultHeaderCell';
import { TableDefaultCell } from './TableDefaultCell';
import { TableContainer } from './Table.styled';
import { calculateDynamicTableColumnsWidth } from './tableUtils';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import '@wppopen/components-library/dist/collection/ag-theme-wpp.css';
import '../../../app/app.module.scss';
import { compareArrays, isArrayEmpty } from '../../utils';
import { BaseTableDataType } from './BaseTableDataType';

export type ColumnDefinition = ColDef;
export interface ITableProps<T extends BaseTableDataType> {
  searchTerm?: string;
  searchable?: boolean;
  displayData: Array<T>;
  columnDefs: Array<ColDef>;
  fixedHeight?: string | number;
  onSelectRows?: (selectedRows: Array<T>) => void;
  renderLoading?: () => React.ReactNode;
  renderEmptyState?: () => React.ReactNode;
  renderError?: () => React.ReactNode;
  hasError?: boolean;
  isLoading?: boolean;
  enableDrag?: boolean;
  handleCellClicked?: (data: T & BaseTableDataType) => void;
  backgroundColor?: string;
  selectedRowsId?: Array<string | undefined>;
  rowMultiSelectWithClick?: boolean;
  hasHeader?: boolean;
  adjustWidth?: number;
  rowHeight?: number;
  delayUseDimensions?: number;
  selectionType?: 'single' | 'multiple' | undefined;
  handleRowDrag?: (row: RowDragEndEvent) => void;
  columnsToPreventSelection?: Array<{ column: string; elementsIds: Array<string> | null }>;
}
export function Table<T>({
  displayData,
  columnDefs,
  fixedHeight: fixedHight,
  searchTerm,
  onSelectRows,
  renderLoading,
  renderEmptyState,
  renderError,
  hasError,
  isLoading,
  handleCellClicked,
  enableDrag = false,
  backgroundColor,
  selectedRowsId = [],
  rowMultiSelectWithClick = false,
  rowHeight = 60,
  hasHeader = true,
  adjustWidth = 8,
  delayUseDimensions = 100,
  selectionType = 'multiple',
  handleRowDrag,
  columnsToPreventSelection = [],
}: React.PropsWithChildren<ITableProps<T & BaseTableDataType>>) {
  const gridRef = useRef<AgGridReact<T & BaseTableDataType>>(null);
  const cardRef = useRef(null);
  const cardDimenssions = useContainerDimensions(cardRef, delayUseDimensions);
  const [selectedIds, setSelectedIds] = useState<(string | undefined)[]>(selectedRowsId);
  const handleRowsSelect = () => {
    onSelectRows?.(gridRef?.current?.api?.getSelectedRows() as Array<T & BaseTableDataType>);
  };

  const setSelectedRows = () => {
    setSelectedIds(selectedRowsId);
    gridRef?.current?.api?.forEachNode((node) => {
      node.setSelected(!!node.data && !!node.data.id && selectedRowsId.includes(node.data.id));
    });
  };

  useEffect(() => {
    if (!compareArrays(selectedRowsId, selectedIds)) {
      gridRef?.current?.api?.forEachNode((node) => {
        node.setSelected(!!node.data && !!node.data.id && selectedRowsId?.includes(node.data.id));
      });
      setSelectedIds(selectedRowsId);
    }
  }, [selectedRowsId, gridRef, selectedIds]);

  const handleCellClick = (event: CellClickedEvent<T & BaseTableDataType, any>) => {
    const { node, column, data } = event;

    if (!data) return;

    const columnUnselectable = columnsToPreventSelection.find(
      (selectedColumn) => selectedColumn.column === column.getColId(),
    );

    if (!columnUnselectable) {
      handleNodeSelectionAndClick(node, data, !!columnsToPreventSelection.length);
      return;
    }

    const columnUnselectedHasElementsIds = columnUnselectable?.elementsIds && !!columnUnselectable.elementsIds.length;
    const target = event.event?.target as HTMLElement;

    if (columnUnselectedHasElementsIds && !columnUnselectable.elementsIds?.includes(target.id)) {
      handleNodeSelectionAndClick(node, data, !!columnsToPreventSelection.length);
    }
  };

  const handleNodeSelectionAndClick = (
    node: RowNode,
    data: T & BaseTableDataType,
    suppressRowClickSelection: boolean,
  ) => {
    if (suppressRowClickSelection) node.setSelected(selectionType === 'single' || !node.isSelected());
    handleCellClicked?.(data);
  };

  const renderContent = () => {
    if (hasError) {
      return renderError?.();
    }
    if (isLoading) {
      return renderLoading?.();
    }
    if (isArrayEmpty(displayData) && !isLoading) {
      return renderEmptyState?.();
    }
    return (
      <AgGridReact
        ref={gridRef}
        rowData={displayData}
        rowHeight={rowHeight ? rowHeight : 60}
        suppressRowClickSelection={!!columnsToPreventSelection.length}
        onCellClicked={handleCellClick}
        suppressPropertyNamesCheck={true}
        columnDefs={calculateDynamicTableColumnsWidth(columnDefs, cardDimenssions.width - adjustWidth)}
        headerHeight={hasHeader ? 47 : 0}
        defaultColDef={{
          headerComponent: DefaultHeaderCell,
          sortable: false,
          cellRenderer: ({ value, search }: ColDef['cellRenderer']) => (
            <TableDefaultCell value={value} searchTerm={search} />
          ),
          cellRendererParams: {
            search: searchTerm,
          },
        }}
        rowSelection={!selectionType ? 'multiple' : selectionType}
        rowDragMultiRow
        rowDragEntireRow={enableDrag}
        onRowDragEnd={(e) => handleRowDrag?.(e)}
        onRowDragLeave={(e) => handleRowDrag?.(e)}
        animateRows
        rowDragManaged
        suppressPaginationPanel
        loadingOverlayComponent={() => <WppSpinner size="m" />}
        rowClassRules={{
          'with-error': (data: any) => (data.data ? !!data.data.meta?.error : false),
        }}
        domLayout={!fixedHight ? 'autoHeight' : 'normal'}
        onRowSelected={handleRowsSelect}
        rowMultiSelectWithClick={rowMultiSelectWithClick}
        onRowDataUpdated={() => setSelectedRows()}
      />
    );
  };
  return (
    <TableContainer className="ag-theme-wpp" ref={cardRef} fixedHight={fixedHight} backgroundColor={backgroundColor}>
      {renderContent()}
    </TableContainer>
  );
}
