/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { WppActionButton, WppIconPlus, WppSpinner, WppTypography } from '@wppopen/components-library-react';
import TagCategoryField from './TagCategoryField';
import { BoxContent, BoxContentCenter } from '../../../shared/components';
import channelCreateManager from '../services/TagCategoryManager';
import usePromise from '../../../shared/hooks/usePromise';
import { mountNewCategoryFormField, updateFields } from '../tagCategoryUtils';
import { TTagCategoryFormField, TOption } from '../types/TagCategoryFormField';
import { translate } from '../../../shared/locales';
import { ContainerCategories } from './styles/TagCategoryForm.styled';
import { ICategoryTag, ITagCategory } from '../../../shared/types';
import { BackofficeAppContext } from '../../../app/context/BackofficeAppContext';

export interface ITagCategoryFormProps {
  organisationId: string;
  setCategoryTags: (entityTags: TTagCategoryFormField[]) => void;
  existentEntityTags?: ICategoryTag[];
  loadedTagCategory: boolean;
  setLoadedTagCategory: (hasLoadedTagCategory: boolean) => void;
  reloadTagCategory: boolean;
  setReloadTagCategory: (reload: boolean) => void;
}

const TagCategoryForm: React.FC<ITagCategoryFormProps> = ({
  organisationId,
  setCategoryTags,
  existentEntityTags,
  loadedTagCategory,
  setLoadedTagCategory,
  reloadTagCategory,
  setReloadTagCategory,
}) => {
  const loadOrganisationTags = useCallback(() => {
    if (organisationId) {
      if (reloadTagCategory) setReloadTagCategory(false);
      return channelCreateManager.fetchOrganisationTags(organisationId);
    }
    return null;
  }, [organisationId, reloadTagCategory, setReloadTagCategory]);

  const { data, ...promiseStatus } = usePromise(loadOrganisationTags);

  const [fields, setFields] = useState<Array<TTagCategoryFormField>>([mountNewCategoryFormField()]);
  const [tagCategoriesFormOptions, setTagCategoriesFormOptions] = useState<ITagCategory[]>([]);

  const { insideWppOs } = useContext(BackofficeAppContext);

  useEffect(() => {
    if (data && !loadedTagCategory) {
      setTagCategoriesFormOptions(data);
      if (existentEntityTags && existentEntityTags.length > 0 && data.length) {
        const loadedFields: Array<TTagCategoryFormField> = [];
        for (let i = 0; i < existentEntityTags.length; i++) {
          const category = existentEntityTags[i].category?.trim();
          if (category) {
            const existentField = loadedFields.find((field) => field.label === category);
            const tag = data.find((t) => t.name === existentEntityTags[i].tag.trim() && t.categoryName === category);
            if (tag)
              if (existentField) {
                existentField.tags.push({
                  label: tag.name,
                  value: tag.id,
                });
              } else {
                loadedFields.push(mountNewCategoryFormField(tag.categoryId, tag.categoryName, [tag], i));
              }
          }
        }
        setCategoryTags(loadedFields);
        if (loadedFields.length > 0) setFields(loadedFields);
      }
      setLoadedTagCategory(true);
    }
  }, [data, existentEntityTags, loadedTagCategory, setCategoryTags, setLoadedTagCategory, tagCategoriesFormOptions]);

  const updateTagCategories = (updatedFields: Array<TTagCategoryFormField>) => {
    setCategoryTags([...updatedFields]);
  };

  const handleAddCategory = () => {
    if (data) {
      const newField = mountNewCategoryFormField();
      updateTagCategories([...fields, newField]);
      setFields([...fields, newField]);
    }
  };

  const handleSelectCategory = (fieldId: string, value: TOption) => {
    const updatedFields = updateFields(fieldId, 'cat', fields, value);
    updateTagCategories([...updatedFields]);
    setFields([...updatedFields]);
  };

  const handleSelectTags = (fieldId: string, value: TOption[]) => {
    const updatedFields = updateFields(fieldId, 'tag', fields, value);
    updateTagCategories([...updatedFields]);
    setFields([...updatedFields]);
  };

  const handleRemoveCategory = (fieldId: string) => {
    const updatedFields = fields.filter((f) => f.id !== fieldId);
    if (updatedFields.length === 0) {
      setFields([mountNewCategoryFormField()]);
    } else {
      setFields([...updatedFields]);
    }
    updateTagCategories([...updatedFields]);
  };

  const renderContent = () => {
    if (promiseStatus.loading) {
      return (
        <BoxContentCenter>
          <WppSpinner size="m" />
        </BoxContentCenter>
      );
    }
    return (
      <>
        <div>
          {fields.map((f) => (
            <TagCategoryField
              key={f.id}
              tagCategoryFormField={f}
              tagCategoryOptions={tagCategoriesFormOptions ? tagCategoriesFormOptions : []}
              setTagCategoryOptions={setTagCategoriesFormOptions}
              loadingCategories={promiseStatus.loading}
              handleSelectCategory={handleSelectCategory}
              handleSelectTags={handleSelectTags}
              removeCategory={handleRemoveCategory}
            />
          ))}
        </div>
        <div>
          <WppActionButton onClick={handleAddCategory} data-testid="btn-add-category">
            <WppIconPlus slot="icon-start" />
            {translate('btnAdd', { something: translate('txtCategory') })}
          </WppActionButton>
        </div>
      </>
    );
  };

  return (
    <BoxContent marg={insideWppOs ? '0 0 10 0' : '16 0'}>
      <ContainerCategories bg="grey000">
        <WppTypography type="m-strong">{translate('txtCategories')}</WppTypography>
        {renderContent()}
      </ContainerCategories>
    </BoxContent>
  );
};

export default TagCategoryForm;
