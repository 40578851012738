import styled from '@emotion/styled';

export const FeedBackContainer = styled.div({
  minHeight: 'inherit',
});

export const Container = styled.div(({ showSideMenu = true }: { showSideMenu?: boolean }) => ({
  display: 'grid',
  minHeight: 'inherit',

  gridTemplateColumns: `${showSideMenu ? 'auto 1fr' : '1fr'}`,
}));
