/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { ContentStreamTableDataType } from '../types/ContentStreamTableDataType';
import { WppTypography } from '@wppopen/components-library-react';
import { useTheme } from '@emotion/react';
import { BoxContentCenter, ITheme } from '@connected-core-system/utils/frontend';

export interface IContentStreamOrderCellProps {
  order: ContentStreamTableDataType['order'];
}

const ContentStreamOrderCell: React.FC<IContentStreamOrderCellProps> = ({ order }) => {
  const theme = useTheme() as ITheme;
  return (
    <BoxContentCenter justify="flex-start">
      <WppTypography type="s-midi" color={theme.palette['datavizCatDark10']}>
        {order}
      </WppTypography>
    </BoxContentCenter>
  );
};

export default ContentStreamOrderCell;
