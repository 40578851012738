import { waitFor as _waitFor, waitForOptions, render, act, fireEvent } from '@testing-library/react';

const waitFor = <T>(callback: () => T | Promise<T>, options?: waitForOptions): Promise<T> => {
  // Overwrite default options
  const mergedOptions = {
    ...options,
    timeout: 2000,
  };

  return _waitFor(callback, mergedOptions);
};

export { waitFor, render, act, fireEvent };
