import { ExceptionCode } from '../enum';
import { ExceptionBase } from '../exception.base';

export class UniquePropertyExistsException extends ExceptionBase {
  readonly code = ExceptionCode.UniquePropertyExistsException;

  constructor(propertyName: string, propertyValue: string) {
    super(`${propertyName} with value ${propertyValue} already exists.`);
  }
}
