export const DEFAULT_COLOR = '#231A1A';
export const DEFAULT_COLOR_BACKGROUND = '#ffffff';

export const QRSTYLE_FORM = {
  colorType: 'colorType',
  rotation: 'rotation',
  gradient: 'gradient',
  dotsType: 'dotsType',
  cornersDotsType: 'cornersDotsType',
  cornerSquareStyle: 'cornerSquareStyle',
};

export const UNCLICKABLE_TABLE_IDS = {
  languageMenuIcon: 'language-menu-icon',
  languageMenuButton: 'language-menu-button',
  publishIcon: 'publish-icon',
};
