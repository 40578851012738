import React, { useEffect, useState } from 'react';

import { TargetingScanHistoryDTO } from '@connected-core-system/utils/content-stream-http-dto';
import { ConditionComparatorEnum, LevelEnum } from '@connected-core-system/utils/types';
import { WppButton, WppIconPlus, WppInput } from '@wppopen/components-library-react';
import { AutoCompleteDropDown } from '../../../shared/components';
import ErrorIconMessage from '../../../shared/components/ErrorIconMessage/ErrorIconMessage';
import { translate } from '../../../shared/locales';
import { TOption } from '../../../shared/types/TOption';
import { useSelectedTabState } from '../hooks/useSelectedTabState';
import { mapExistingLevel, mapExistingOperator, tabTargetsValue } from '../utils/contentStreamCreateUtils';
import ScanHistoryTable from './ScanHistoryTable/ScanHistoryTable';
import {
  ContainerContent,
  ContainerScanHistorySelection,
  ContainerSelectDropDown,
  ContainerTable,
  StyledWppIconTrash,
  TargetsCard,
} from './styles/ScanHistory.styled';

export interface ITargetScanHistoryProps {
  removeScanHistoryTargeting: () => void;
  targetingScanHistory: TargetingScanHistoryDTO | null;
  setTargetingScanHistory: (targetingScanHistory: TargetingScanHistoryDTO) => void;
  isInvalidTargetsTab: boolean;
  setIsInvalidTargetScanHistory: (isInvalidTargetScanHistory: boolean) => void;
}

const TargetScanHistory: React.FC<ITargetScanHistoryProps> = ({
  removeScanHistoryTargeting,
  targetingScanHistory,
  setTargetingScanHistory,
  isInvalidTargetsTab,
  setIsInvalidTargetScanHistory,
}) => {
  const levelOptions: TOption[] = [
    { value: LevelEnum.ORGANISATION, label: LevelEnum.ORGANISATION },
    { value: LevelEnum.BRAND, label: LevelEnum.BRAND },
    { value: LevelEnum.CHANNEL, label: LevelEnum.CHANNEL },
  ];

  const operatorOptions: TOption[] = [
    { value: ConditionComparatorEnum.LESSER_THAN, label: 'Less than' },
    { value: ConditionComparatorEnum.LESSER_THAN_OR_EQUAL, label: 'Less or equal to' },
    { value: ConditionComparatorEnum.EQUAL, label: 'Equal to' },
    { value: ConditionComparatorEnum.GREATER_THAN_OR_EQUAL, label: 'More or equal to' },
    { value: ConditionComparatorEnum.GREATER_THAN, label: 'More than' },
    { value: ConditionComparatorEnum.IN_RANGE, label: 'In range' },
  ];

  const { selectedTab } = useSelectedTabState();
  const [selectedLevel, setSelectedLevel] = useState<TOption[] | null>(
    mapExistingLevel(levelOptions, targetingScanHistory?.level),
  );

  const [selectedOperator, setSelectedOperator] = useState<TOption[] | null>(
    mapExistingOperator(operatorOptions, targetingScanHistory?.operator),
  );
  const [numberOfScans, setNumberOfScans] = useState<string>(
    targetingScanHistory?.numberOfScans.value ? targetingScanHistory.numberOfScans.value.toString() : '',
  );
  const [levelError, setLevelError] = useState<string>('');
  const [operatorError, setOperatorError] = useState<string>('');
  const [numberOfScansError, setNumberOfScansError] = useState<string>('');
  const [minNumberOfScansError, setMinNumberOfScansError] = useState<string>('');
  const [maxNumberOfScansError, setMaxNumberOfScansError] = useState<string>('');
  const [minNumberOfScans, setMinNumberOfScans] = useState<string>(
    targetingScanHistory?.numberOfScans.minValue ? targetingScanHistory.numberOfScans.minValue.toString() : '',
  );
  const [maxNumberOfScans, setMaxNumberOfScans] = useState<string>(
    targetingScanHistory?.numberOfScans.maxValue ? targetingScanHistory.numberOfScans.maxValue.toString() : '',
  );
  const [addButtonIsDisabled, setAddButtonIsDisabled] = useState<boolean>(!!targetingScanHistory);
  const [addedScanHistory, setAddedScanHistory] = useState<TargetingScanHistoryDTO | null>(
    targetingScanHistory
      ? ({
          level: selectedLevel && (selectedLevel[0].value as LevelEnum),
          operator: selectedOperator && (selectedOperator[0].label as ConditionComparatorEnum),
          numberOfScans: {
            value: Number(numberOfScans),
            minValue: Number(minNumberOfScans),
            maxValue: Number(maxNumberOfScans),
          },
        } as TargetingScanHistoryDTO)
      : null,
  );

  useEffect(() => {
    setIsInvalidTargetScanHistory(
      !!levelError || !!operatorError || !!numberOfScansError || !!minNumberOfScansError || !!maxNumberOfScansError,
    );
  }, [
    levelError,
    maxNumberOfScansError,
    minNumberOfScansError,
    numberOfScansError,
    operatorError,
    setIsInvalidTargetScanHistory,
  ]);

  const handleLevelChange = (option: TOption[]) => {
    setSelectedLevel(option);
  };

  const handleOperatorChange = (option: TOption[]) => {
    setSelectedOperator([{ value: option[0].value, label: option[0].label }]);
  };

  useEffect(() => {
    if (selectedOperator) {
      if (selectedOperator[0].value === ConditionComparatorEnum.IN_RANGE) {
        setNumberOfScans('');
      } else {
        setMaxNumberOfScans('');
        setMinNumberOfScans('');
      }
    }
  }, [selectedOperator]);

  const getLevelPlaceholder = () => {
    if (selectedLevel) {
      if (!selectedLevel[0].label) return 'Level';
      return selectedLevel[0].label;
    }
    return 'Level';
  };

  const getOperatorPlaceholder = () => {
    if (selectedOperator) {
      if (!selectedOperator[0].label) return 'Operator';
      return selectedOperator[0].label;
    }
    return 'Operator';
  };

  const isFieldEmpty = (
    field: TOption[] | null,
    errorSetter: {
      (value: React.SetStateAction<string>): void;
    },
    errorMessage: string,
  ) => {
    if (!field || !field[0].value) {
      errorSetter(errorMessage);
      return true;
    }
    errorSetter('');
    return false;
  };

  const isInvalidNumber = (
    number: string,
    errorSetter: {
      (value: React.SetStateAction<string>): void;
    },
    errorMessage: string,
  ) => {
    if (!number) {
      errorSetter(errorMessage);
      return true;
    }
    errorSetter('');
    return false;
  };

  const validateScanHistoryObject = () => {
    const errorMessage = translate('txtInvalidEmptyField');
    const errorInvalidNumber = translate('txtSelectValidNumber');

    const checkEmptyField = (
      field: TOption[] | null,
      setError: {
        (value: React.SetStateAction<string>): void;
      },
    ) => {
      if (isFieldEmpty(field, setError, errorMessage)) {
        return false;
      }
      return true;
    };

    const checkInvalidNumber = (
      number: string,
      setError: {
        (value: React.SetStateAction<string>): void;
      },
    ) => {
      if (isInvalidNumber(number, setError, errorMessage) || Number(number) < 1) {
        setError(errorInvalidNumber);
        return false;
      }
      return true;
    };

    if (!checkEmptyField(selectedLevel, setLevelError)) {
      return false;
    }

    if (!checkEmptyField(selectedOperator, setOperatorError)) {
      return false;
    }

    if (selectedOperator?.[0].value === ConditionComparatorEnum.IN_RANGE) {
      if (
        !checkInvalidNumber(minNumberOfScans, setMinNumberOfScansError) ||
        !checkInvalidNumber(maxNumberOfScans, setMaxNumberOfScansError)
      ) {
        return false;
      }
      if (Number(minNumberOfScans) > Number(maxNumberOfScans)) {
        setMaxNumberOfScansError(errorInvalidNumber);
        setMinNumberOfScansError(errorInvalidNumber);
        return false;
      }
    } else {
      if (!checkInvalidNumber(numberOfScans, setNumberOfScansError)) {
        return false;
      }
    }

    return true;
  };

  const addScanHistory = () => {
    if (validateScanHistoryObject()) {
      const newScanHistory = {
        level: selectedLevel && (selectedLevel[0].value as LevelEnum),
        operator: selectedOperator && (selectedOperator[0].label as ConditionComparatorEnum),
        numberOfScans: {
          value: Number(numberOfScans),
          minValue: Number(minNumberOfScans),
          maxValue: Number(maxNumberOfScans),
        },
      } as TargetingScanHistoryDTO;
      setAddedScanHistory(newScanHistory);
      setTargetingScanHistory({
        ...newScanHistory,
        operator: selectedOperator && (selectedOperator[0].value as ConditionComparatorEnum),
      });
      setAddButtonIsDisabled(true);
    }
  };

  const handleDelete = () => {
    setAddedScanHistory(null);
    setAddButtonIsDisabled(false);
  };

  return (
    <TargetsCard
      expandedByDefault
      size="s"
      text={translate('txtScanHistory')}
      data-testid="expandable-target-scan-history"
      withDivider={false}>
      <StyledWppIconTrash onClick={removeScanHistoryTargeting} data-testid="icon-remove" />
      <ContainerContent style={{ width: '100%' }}>
        <ContainerScanHistorySelection data-testid="container-location">
          <ContainerSelectDropDown>
            <AutoCompleteDropDown
              isMultipleSelection={false}
              options={levelOptions}
              selectedOptions={selectedLevel ?? []}
              setSelectedOptions={handleLevelChange}
              placeHolder={getLevelPlaceholder()}
              showCreateNewElement={false}
              useTextHighLight={false}
              disabled={addButtonIsDisabled}
              isSearchAllowed={false}
              errorMessage={levelError}
            />
            <AutoCompleteDropDown
              isMultipleSelection={false}
              options={operatorOptions}
              selectedOptions={selectedOperator ?? []}
              setSelectedOptions={handleOperatorChange}
              placeHolder={getOperatorPlaceholder()}
              showCreateNewElement={false}
              useTextHighLight={false}
              errorMessage={operatorError}
              isSearchAllowed={false}
              disabled={addButtonIsDisabled}
            />
            {!selectedOperator ||
            (selectedOperator && selectedOperator[0].value !== ConditionComparatorEnum.IN_RANGE) ? (
              <WppInput
                autoFocus
                data-testid="title-input"
                name="numberOfScans"
                placeholder="Choose the required number of scans to display this content."
                required
                disabled={addButtonIsDisabled}
                type="number"
                message={numberOfScansError}
                messageType={numberOfScansError ? 'error' : undefined}
                value={numberOfScans}
                onWppChange={(event: CustomEvent) => setNumberOfScans(event.detail.value)}
                style={{ flexGrow: 1 }}
              />
            ) : (
              <>
                <WppInput
                  disabled={addButtonIsDisabled}
                  autoFocus
                  data-testid="title-input"
                  name="numberOfScansMinValue"
                  placeholder="From"
                  required
                  type="number"
                  value={minNumberOfScans}
                  message={minNumberOfScansError}
                  messageType={minNumberOfScansError ? 'error' : undefined}
                  onWppChange={(event: CustomEvent) => {
                    setMinNumberOfScans(event.detail.value);
                  }}
                  style={{ width: '25%' }}
                />
                <WppInput
                  disabled={addButtonIsDisabled}
                  autoFocus
                  data-testid="title-input"
                  name="numberOfScansMaxValue"
                  placeholder="To"
                  type="number"
                  required
                  value={maxNumberOfScans}
                  message={maxNumberOfScansError}
                  messageType={maxNumberOfScansError ? 'error' : undefined}
                  onWppChange={(event: CustomEvent) => setMaxNumberOfScans(event.detail.value)}
                  style={{ width: '25%' }}
                />
              </>
            )}
            <WppButton
              style={{ maxHeight: '40px', marginRight: 0 }}
              onClick={() => addScanHistory()}
              disabled={addButtonIsDisabled}>
              <WppIconPlus slot="icon-start" />
              Add
            </WppButton>
          </ContainerSelectDropDown>
        </ContainerScanHistorySelection>
        <ContainerTable>
          {selectedTab === tabTargetsValue && addedScanHistory && (
            <ScanHistoryTable
              data-testid="scan-history-table"
              data={[addedScanHistory]}
              onDelete={handleDelete}
              forceReloadDimensions={selectedTab === tabTargetsValue}
            />
          )}
        </ContainerTable>
        {isInvalidTargetsTab && targetingScanHistory === null && (
          <div data-testid="error-message">
            <ErrorIconMessage message={translate('txtInvalidEmptySelectionLocation')} />
          </div>
        )}
      </ContainerContent>
    </TargetsCard>
  );
};

export default TargetScanHistory;
