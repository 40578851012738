import styled from '@emotion/styled';
import { WppButton } from '@wppopen/components-library-react';

export const DrawerActionButtonsDiv = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
});

export const DrawerActionButton = styled(WppButton)({
  alignItems: 'end',
  marginLeft: 12,
});
