/* eslint-disable @typescript-eslint/no-empty-interface */
import { WppTypography } from '@wppopen/components-library-react';
import React from 'react';
import { HighlightedText } from './TextHighlight.styled';

export interface ITextHighlightProps {
  fullString: string;
  subString: string;
  typographyProps?: typeof WppTypography.defaultProps;
}

const TextHighlight: React.FC<ITextHighlightProps> = ({ subString, fullString, typographyProps }) => {
  const splittedValue = fullString?.toLowerCase().split(subString?.toLowerCase());
  if (subString.length > 0 && splittedValue.length > 1) {
    const firstPart = splittedValue.at(0) as string;

    return (
      <WppTypography type="s-body" {...typographyProps}>
        {Array.from(fullString).splice(0, firstPart?.length)}
        <HighlightedText type="s-body">
          {Array.from(fullString).splice(firstPart.length, subString?.length)}
        </HighlightedText>
        {Array.from(fullString).splice(firstPart.length + subString.length, fullString.length)}
      </WppTypography>
    );
  }
  return (
    <WppTypography type="s-body" {...typographyProps}>
      {fullString}
    </WppTypography>
  );
};

export default TextHighlight;
