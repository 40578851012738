import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import {
  IContentStreamCreateEditActions,
  IContentStreamCreateEditState,
} from '../sliceStore/contentStreamCreateEditSliceStore';

export const useSelectedTabState = () => {
  const selectedTab = useSliceState<IContentStreamCreateEditState, 'selectedTab'>('selectedTab');
  const setSelectedTab = useSliceSetter<IContentStreamCreateEditActions, 'setSelectedTab'>('setSelectedTab');

  return { selectedTab, setSelectedTab };
};
