import { SetCallback } from '../../../shared/store';
import { ToastMessage } from '../../../app/types';

type Nullable<T> = T | null | undefined;

export interface IToastMessageState {
  toastMessage: Nullable<ToastMessage>;
}

export const initialState: IToastMessageState = {
  toastMessage: null,
};

const actions = (set: SetCallback<IToastMessageState>) => ({
  setToastMessage: (item: Nullable<ToastMessage>) => set((state) => ({ ...state, toastMessage: item })),
});

export const slice = (set: SetCallback<IToastMessageState>) => ({
  ...initialState,
  ...actions(set),
});

export type IToastMessageActions = ReturnType<typeof actions>;

export default {
  slice,
  initialState,
};
