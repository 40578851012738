/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useState } from 'react';
import { StyledWppTree, StyledWppProgressIndicator, TreeContainer } from './styles/Tree.styled';
import { SecondTierSidebarItem } from '../types';
import { generateData, getDataWithoutChildren, treeActiveId, treeArchivedId } from '../utils';
import { useSecondTierSelectedItemState } from '../hooks/useSecondTierSelectedItemState';
import { useSecondTierItemIdToSelectOnReloadState } from '../hooks/useSecondTierItemIdToSelectOnReloadState';
import { compareArrays } from '../../../shared/utils';
import EmptyState from '../../../shared/components/EmptyState/EmptyState';
import { translate } from '../../../shared/locales';
import { useSecondTierReloadState } from '../hooks/useSecondTierReloadState';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';
import { MenuItemEnum } from '../../../shared/types';
import { TreeType } from '@wppopen/components-library';
import { useValueChangesState } from '../../QRStyle/hooks/useValueChangesState';
import { useBrandValueChangesState } from '../../BrandPage/hooks/useBrandValueChangesState';
import ConfirmationModal from '../../../shared/components/Modals/ConfirmationModal';
import { useUnitValueChangesState } from '../../UnitPage/hooks/useUnitValueChangesState';

export interface ITreeProps {
  searchFieldValue: string;
  itemList: SecondTierSidebarItem[];
  loading: boolean;
  error: boolean;
}

const Tree: React.FC<ITreeProps> = ({ searchFieldValue, itemList, loading, error }) => {
  const { setSelectedSecondTierItem } = useSecondTierSelectedItemState();
  const { setSecondTierReload } = useSecondTierReloadState();
  const { itemIdToSelectOnReload, setItemIdToSelectOnReload } = useSecondTierItemIdToSelectOnReloadState();
  const [previousItemList, setPreviousItemList] = useState<SecondTierSidebarItem[]>([]);
  const [activeListOpen, setActiveListOpen] = useState<boolean>(true);
  const [archivedListOpen, setArchivedListOpen] = useState<boolean>(false);
  const { selectedSideMenuItem } = useSelectedSideMenuItemState();
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const { valueChanges, setValueChanges } = useValueChangesState();
  const { brandValueChanges, setBrandValueChanges } = useBrandValueChangesState();
  const { unitValueChanges, setUnitValueChanges } = useUnitValueChangesState();
  const [clickedItem, setClickedItem] = useState<SecondTierSidebarItem | undefined>();

  useEffect(() => {
    if (itemList && itemList.length > 0 && !compareArrays(itemList, previousItemList)) {
      const itemToSelect = itemIdToSelectOnReload
        ? itemList.find((item) => item.id === itemIdToSelectOnReload)
        : itemList[0];

      setSelectedSecondTierItem(itemToSelect ? itemToSelect : itemList[0]);
      setPreviousItemList(itemList);
    }
  }, [itemList, itemIdToSelectOnReload, setSelectedSecondTierItem, setItemIdToSelectOnReload, previousItemList]);

  const checkConfirmationModal = (item: SecondTierSidebarItem | undefined) => {
    if (valueChanges?.newTitle === true || valueChanges?.newValue === true || unitValueChanges || brandValueChanges) {
      setOpenConfirmationModal(true);
    } else {
      setSelectedSecondTierItem(item);
      setOpenConfirmationModal(false);
    }
  };

  const handleClick = (event: CustomEvent) => {
    if (event.detail.reason !== 'search') {
      const itemId = event.detail.currentItem?.id;
      if (itemId === treeActiveId) {
        setActiveListOpen(!activeListOpen);
      } else if (itemId === treeArchivedId) {
        setArchivedListOpen(!archivedListOpen);
      } else {
        const item = itemList.find((activeItem) => activeItem.id === itemId);
        setClickedItem(item);
        checkConfirmationModal(item);
      }
    }
  };

  const handleConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const handleCancelConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setUnitValueChanges(false);
    setBrandValueChanges(false);
    setValueChanges({ newTitle: false, newValue: false });
    setSelectedSecondTierItem(clickedItem);
  };

  const getTreeData = (): TreeType[] =>
    selectedSideMenuItem === MenuItemEnum.QRSTYLES
      ? getDataWithoutChildren(itemList)
      : generateData(itemList, activeListOpen, archivedListOpen);

  return (
    <TreeContainer>
      <ConfirmationModal
        title={translate('txtConfirmationModalLeaveEditTitle')}
        text={translate('txtConfirmationModalLeaveEditText')}
        buttonCancelText={translate('txtButtonModalCancel')}
        buttonText={translate('txtButtonModal')}
        buttonAction={handleConfirmationModal}
        buttonCancelAction={handleCancelConfirmationModal}
        isOpen={openConfirmationModal}
        setIsOpen={setOpenConfirmationModal}
        isWarning={false}
        data-testid="confirmation-modal"
      />
      {error && (
        <EmptyState
          title={translate('txtSomethingWentWrong')}
          text={translate('txtErrorMessage')}
          buttonText={translate('txtTryAgain')}
          buttonAction={() => {
            setSecondTierReload(true);
          }}
        />
      )}
      {loading && <StyledWppProgressIndicator variant="circle" />}
      {itemList.length > 0 && (
        <StyledWppTree
          search={searchFieldValue}
          data={getTreeData()}
          onWppChange={handleClick}
          disableOpenCloseAnimation
          data-testid="StyledWppTree"
        />
      )}
    </TreeContainer>
  );
};

export default Tree;
