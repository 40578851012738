/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { ICardOption } from '../../types/ICardOption';
import { Card, ICardStyles, SelectTextBare, SelectTextOs } from './CardWithOptions.styled';
import PlatformWrapper from '../PlatformWrapper/PlatformWrapper';

export interface ICardWithOptionsProps extends ICardStyles {
  options: Array<ICardOption>;
  onSelectValue?: (value: ICardOption) => void;
  cardAction?: () => void;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  isLoading?: boolean;
  renderLoading?: () => React.ReactNode;
  hasError?: boolean;
  renderError?: () => React.ReactNode;
  isEmpty?: boolean;
  renderEmpty?: () => React.ReactNode;
}

const CardWithOptions: React.FC<ICardWithOptionsProps> = ({
  options,
  onSelectValue,
  cardAction,
  onMouseEnter,
  onMouseLeave,
  isLoading,
  renderLoading,
  hasError,
  renderError,
  isEmpty,
  renderEmpty,
  ...props
}) => {
  const renderContent = () => {
    if (isLoading) {
      return renderLoading?.();
    }
    if (hasError) {
      return renderError?.();
    }
    if (isEmpty) {
      return renderEmpty?.();
    }
    return (
      <>
        {options.map((option) => (
          <PlatformWrapper
            componentBare={SelectTextBare}
            componentOs={SelectTextOs}
            {...props}
            data-testid={option.value}
            key={option.value}
            type="s-body"
            onClick={() => {
              option.action?.();
              onSelectValue?.(option);
            }}>
            {option.label ? option.label : option.value}
          </PlatformWrapper>
        ))}
      </>
    );
  };
  return (
    <Card
      data-testid="card-with-options"
      onMouseEnter={() => {
        onMouseEnter?.();
      }}
      onMouseLeave={() => {
        onMouseLeave?.();
      }}
      {...props}
      onClick={() => cardAction?.()}>
      {renderContent()}
    </Card>
  );
  // eslint-disable-next-line react/jsx-no-useless-fragment
};

export default CardWithOptions;
