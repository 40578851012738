import { translate } from '../../../shared/locales';
import ExpandableCard from '../../../shared/components/ExpandableCard/ExpandableCard';
import { BoxContent } from '../../../shared/components';
import { LastGridContainer, StyledQROptionsLabel, StyledSelectDropDown } from '../styles/ExpandablesStyle.styled';
import { useCallback, useEffect, useState } from 'react';
import { useQROptionsStyleState } from '../hooks/useQROptionsStyleState';
import { ErrorCorrectionLevel, Options } from 'qr-code-styling';
import { QROptions } from '../types/QRStyleTypes';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar';
import { useValueChangesState } from '../hooks/useValueChangesState';
import { WppSelectCustomEvent } from '@wppopen/components-library/dist/types/components';
import { SelectChangeEventDetail } from '@wppopen/components-library';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IQROptionsStyleProps {}

const QR_OPTIONS: Array<{ value: ErrorCorrectionLevel; label: string }> = [
  { value: 'L', label: 'Low' },
  { value: 'M', label: 'Medium' },
  { value: 'Q', label: 'Quartile' },
  { value: 'H', label: 'High' },
];

const DEFAULT_ERROR_CORRECTION = 'Q' as ErrorCorrectionLevel;

const QROptionsStyle: React.FC<IQROptionsStyleProps> = () => {
  const [qrOptionsType, setQrOptionsType] = useState<ErrorCorrectionLevel>(DEFAULT_ERROR_CORRECTION);

  const { setQrOptions } = useQROptionsStyleState();
  const { selectedSecondTierItem } = useSecondTierSelectedItemState();
  const { valueChanges, setValueChanges } = useValueChangesState();

  const handleChange = (e: WppSelectCustomEvent<SelectChangeEventDetail>) => {
    setQrOptionsType(e.detail.value as ErrorCorrectionLevel);
    setValueChanges({ ...valueChanges, newValue: true });
  };

  useEffect(() => {
    setQrOptions({
      errorCorrectionLevel: qrOptionsType,
      typeNumber: 0,
      mode: 'Byte',
    });
  }, [qrOptionsType, setQrOptions]);

  const fillStyleFields = useCallback(() => {
    if ((selectedSecondTierItem as any)?.configuration) {
      const options: Options = (selectedSecondTierItem as any)?.configuration;
      const qrOptions: QROptions = options.qrOptions;

      if (qrOptions?.errorCorrectionLevel) setQrOptionsType(qrOptions?.errorCorrectionLevel);
    } else {
      setQrOptionsType(DEFAULT_ERROR_CORRECTION);
    }
  }, [selectedSecondTierItem]);

  useEffect(() => {
    fillStyleFields();
  }, [fillStyleFields]);

  return (
    <ExpandableCard title={translate('txtQROptionsTitle')}>
      {' '}
      <BoxContent direction="column" data-testid="qrOptions-style-container">
        <LastGridContainer>
          <StyledQROptionsLabel type="s-strong">{translate('txtQROptionsLabel')}</StyledQROptionsLabel>
          <div>&nbsp;</div>
          <StyledSelectDropDown options={QR_OPTIONS} value={qrOptionsType} onWppChange={(e) => handleChange(e)} />
        </LastGridContainer>
      </BoxContent>
    </ExpandableCard>
  );
};

export default QROptionsStyle;
