/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useContext, useState } from 'react';
import { ICardOption } from '../../types/ICardOption';
import { StatusEnum } from '@connected-core-system/utils/types';
import { WppActionButton, WppIconChevron, WppIconSearch, WppDivider } from '@wppopen/components-library-react';
import {
  MenuContentWrapper,
  StyledWppInput,
  StyledItem,
  StyledWppIconError,
  StyledMenuContext,
  StyledWppListItem,
} from './MenuGroup.styled';
import { ConditionalTooltip } from '..';
import { translate } from '../../locales';
import { UNCLICKABLE_TABLE_IDS } from '../../Constants/Constants';
import { BackofficeAppContext } from '../../../app/context/BackofficeAppContext';

export interface IMenuGroupProps {
  options: Array<ICardOption>;
  isLoading?: boolean;
  renderLoading?: () => React.ReactNode;
  hasError?: boolean;
  renderError?: () => React.ReactNode;
  isEmpty?: boolean;
  renderEmpty?: () => React.ReactNode;
  isSearchAllowed?: boolean;
  customIcon?: React.ReactNode;
}

const MenuGroup: React.FC<IMenuGroupProps> = ({
  options,
  isLoading = false,
  renderLoading,
  hasError = false,
  renderError,
  isEmpty = false,
  renderEmpty,
  isSearchAllowed = true,
  customIcon,
  ...props
}) => {
  const [search, setSearch] = useState<string>('');
  const [filteredOptions, setFilteredOptions] = useState<ICardOption[]>(options);
  const { insideWppOs } = useContext(BackofficeAppContext);

  const handleSearch = (event: CustomEvent) => {
    const value = event.detail.value;
    setSearch(value);
    const filtered = options.filter((option) => option.label?.toLowerCase().includes(value.toLowerCase()));
    if (filtered) setFilteredOptions(filtered);
  };

  const renderSearch = () => (
    <>
      <StyledWppInput
        name="serch"
        placeholder="Search"
        value={search}
        onWppChange={handleSearch}
        size="s"
        data-testid="language-search">
        <WppIconSearch slot="icon-start" aria-label="Search icon" />
      </StyledWppInput>
      <WppDivider></WppDivider>
    </>
  );

  const renderMenu = () => {
    if (isLoading) {
      return renderLoading?.();
    }
    if (hasError) {
      return renderError?.();
    }
    if (isEmpty) {
      return renderEmpty?.();
    }
    return (
      <MenuContentWrapper data-testid="content-list-wrapper">
        {isSearchAllowed && renderSearch()}
        {filteredOptions.map((option, index) => (
          <StyledWppListItem
            key={option.status ? `${option.value}-${option.status}-${index}` : `${option.value}-${index}`}
            data-testid={option.value}>
            <StyledItem slot="label" defaultItem={!!option.defaultOption}>
              {option.status && option.status !== StatusEnum.ACTIVE && (
                <ConditionalTooltip
                  showTooltip={true}
                  tooltipText={
                    option.status === StatusEnum.UNPUBLISHED ? translate('txtUnpublished') : translate('txtDeleted')
                  }
                  config={{ placement: 'left' }}>
                  <StyledWppIconError size="s" />
                </ConditionalTooltip>
              )}
              {option.label ? option.label : option.value}
            </StyledItem>
          </StyledWppListItem>
        ))}
      </MenuContentWrapper>
    );
  };
  return (
    <StyledMenuContext listWidth="196px" data-testid="menu-context">
      <WppActionButton slot="trigger-element" id={UNCLICKABLE_TABLE_IDS.languageMenuButton}>
        {customIcon ? (
          customIcon
        ) : (
          <WppIconChevron
            direction="down"
            color={insideWppOs ? undefined : '#5E00B5'}
            id={UNCLICKABLE_TABLE_IDS.languageMenuIcon}
          />
        )}
      </WppActionButton>
      {renderMenu()}
    </StyledMenuContext>
  );
};

export default MenuGroup;
