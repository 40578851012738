/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useState } from 'react';
import { WppActionButton, WppCard, WppTypography } from '@wppopen/components-library-react';
import { BoxContent, ConditionalTooltip } from '../../../shared/components';
import { translate } from '../../../shared/locales';
import { copyTextToClipboard } from '../../../shared/utils';
import { IconCopyBare, IconCopyOs } from './styles/ChannelQRCode.styled';
import { PlaceholderText, LinkContainer } from './styles/LinkUrl.styled';
import PlatformWrapper from '../../../shared/components/PlatformWrapper/PlatformWrapper';

export interface ILinkUrlProps {
  url?: string;
}

const LinkUrl: React.FC<ILinkUrlProps> = ({ url }) => {
  const [textCopied, setTextCopied] = useState<boolean>(false);

  return (
    <WppCard>
      <BoxContent>
        <WppTypography type="m-strong">{translate('txtLink')}</WppTypography>
        <LinkContainer flex justify="space-between" align="center" marg="0 0 4 0">
          {url && <WppTypography type="s-body">{url}</WppTypography>}
          {!url && <PlaceholderText type="s-body">{translate('txtChannelLinkPlaceholder')}</PlaceholderText>}
          <ConditionalTooltip
            showTooltip={textCopied}
            tooltipText={translate('txtTextCopied')}
            config={{ showOnCreate: true }}>
            <WppActionButton
              onClick={async () => {
                copyTextToClipboard(url)
                  .then(() => {
                    setTextCopied(true);
                  })
                  .catch((error) => {
                    setTextCopied(false);
                  });
              }}
              disabled={!url}
              data-testid="copy-btn-link">
              <PlatformWrapper
                componentBare={IconCopyBare}
                componentOs={IconCopyOs}
                aria-label="Copy icon"
                copyEnabled={!!url}
              />
            </WppActionButton>
          </ConditionalTooltip>
        </LinkContainer>
      </BoxContent>
    </WppCard>
  );
};

export default LinkUrl;
