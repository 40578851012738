/* eslint-disable @typescript-eslint/no-empty-interface */
import { BoxContent } from '../../../../shared/components';
import React, { useContext } from 'react';
import { ContentsTableDataType } from '../../types/ContentsTableDataType';
import { StyledWppIconTrash } from '../styles/DeleteCell.styled';
import { WppActionButton } from '@wppopen/components-library-react';
import { useTheme } from '@emotion/react';
import { ITheme } from '@connected-core-system/utils/frontend';
import { BackofficeAppContext } from '../../../../app/context/BackofficeAppContext';

export interface IDeleteCellContentProps {
  contentId: ContentsTableDataType['id'];
  handleClick: (contentId: string) => void;
}

const DeleteCellContent: React.FC<IDeleteCellContentProps> = ({ contentId, handleClick }) => {
  const theme = useTheme() as ITheme;
  const { insideWppOs } = useContext(BackofficeAppContext);

  return (
    <BoxContent flex align="center">
      <WppActionButton slot="trigger-button" onClick={() => handleClick(contentId)}>
        <StyledWppIconTrash
          color={insideWppOs ? undefined : theme.palette['primary500']}
          data-testid="icon-remove"></StyledWppIconTrash>
      </WppActionButton>
    </BoxContent>
  );
};

export default DeleteCellContent;
