import { HttpService } from '@core/http-service';
import { GetPowerBIEmbedDTO } from '@connected-core-system/utils/bff-http-dto';
import { interpolateWithValues } from '../../../shared/utils';
import { BFF_ENDPOINTS, HttpResolver } from '../../../shared/api';
import { IAnalyticsRepository } from '../types';

export class AnalyticsRepository implements IAnalyticsRepository {
  constructor(private readonly httpService: HttpService) {}

  async getEmbedUrl(organisationId: string): Promise<GetPowerBIEmbedDTO> {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_ANALYITCS_GET, organisationId);
    const response = await this.httpService.get<GetPowerBIEmbedDTO>(url);
    return response.data;
  }
}

export default new AnalyticsRepository(HttpResolver.getHttpService());
