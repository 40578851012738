import styled from '@emotion/styled';
import {
  WppActionButton,
  WppCard,
  WppIconChevron,
  WppIconCross,
  WppTag,
  WppTypography,
} from '@wppopen/components-library-react';
import { DSListItem } from '../../DSComponents';
import { BoxContent } from '..';
import { keyframes } from '@emotion/react';

export interface WppStyledChevronIconProps {
  isOpened: boolean;
}

export interface WppStyledListItemProps {
  isSelected: boolean;
  isMultipleSelection: boolean;
}

export interface StyledItemListContainerProps {
  maxItems: number;
}

const rotateAnimationOpen = keyframes`
    from {
      transform: rotate(90deg);
    }
    to {
      transform: rotate(-90deg);
    }
  `;

const rotateAnimationClose = keyframes`
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(90deg);
    }
  `;

export const WppStyledChevronIcon = styled(WppIconChevron)<WppStyledChevronIconProps>(({ isOpened }) => ({
  animation: `${isOpened ? `${rotateAnimationOpen} 0.2s linear` : `${rotateAnimationClose} 0.2s linear`}`,
  transform: `rotate(${isOpened ? '-90deg' : '90deg'})`,
}));

export const WppStyledCard = styled(WppCard)({
  position: 'absolute',
  width: '100%',
  zIndex: 9999,
  marginTop: 5,
  '&::part(card)': {
    padding: 0,
  },
});

export const WppStyledBoxContentHeader = styled(BoxContent)({
  display: 'flex',
  justifyContent: 'space-between',
  borderBottom: '1px solid #E7EAEE',
  padding: 8,
  minHeight: 50,
  alignItems: 'center',
});

export const WppStyledBoxContentBottom = styled(BoxContent)({
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  borderTop: '1px solid #E7EAEE',
  padding: 8,
  minHeight: 50,
  alignItems: 'center',
});
export const WppStyledBtn = styled(WppActionButton)({ color: '#343A3F' });
export const WppStyledTypoCreateNew = styled(WppTypography)({ color: '#5E00B5', wordBreak: 'normal' });

export const WppStyledHeaderTypography = styled(WppTypography)({
  color: '#697077',
  marginLeft: 6,
  wordBreak: 'normal',
});

export const WppStyledListItem = styled(DSListItem)<WppStyledListItemProps>(({ isSelected, isMultipleSelection }) => ({
  padding: '3px 5px 0px 5px',
  width: '100%',
  boxSizing: 'border-box',
  '&::part(item)': {
    width: '100%',
    backgroundColor: `${isSelected && !isMultipleSelection ? '#EEE8FF' : '#FFF'}`,
  },
  '&:hover::part(item)': {
    backgroundColor: `${isSelected && !isMultipleSelection ? '#EEE8FF' : '#F4F5F7'}`,
  },
}));

export const ContainerSelectedItem = styled.div({
  position: 'relative',
  display: 'inline-block',
});

export const MainContainer = styled.div({
  position: 'relative',
});

export const StyledWppTag = styled(WppTag)({
  marginRight: 4,
  marginTop: 4,
  paddingRight: 24,
});

export const StyledWppIconCross = styled(WppIconCross)({
  position: 'absolute',
  right: 8,
  top: 8,
  cursor: 'pointer',
});

export const StyledItemListContainer = styled.div<StyledItemListContainerProps>`
  padding-bottom: 3px;
  ${({ maxItems }) => `
    max-height: ${maxItems * 35 + 20}px;
  `}
  overflow: auto;
`;
