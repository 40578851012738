import { useAuthBackofficeOs } from '../../features/Authorization';
import { useLoadOrganisationWppOs } from '../../features/Organisation/hooks/useLoadOrganisationWppOs';
import { useWppOpenContext } from '../../features/WppOs';
import AppWrapper from './AppWrapper';
import { ContainerOs } from './styles/AppWrapper.styled';

interface IAppOsProps {
  environmentConfiguration: { [key: string]: object };
}

const AppOs: React.FC<IAppOsProps> = ({ environmentConfiguration }) => {
  const { isAuthenticated, error } = useAuthBackofficeOs();
  const { osContext } = useWppOpenContext();

  useLoadOrganisationWppOs();

  return (
    <ContainerOs id="app-os-container">
      <AppWrapper
        baseUrl={osContext.baseUrl}
        isAuthenticated={isAuthenticated}
        errorAuth={error}
        environmentConfiguration={environmentConfiguration}
      />
    </ContainerOs>
  );
};

export default AppOs;
