import React, { useState } from 'react';
import { WppSideModal } from '@wppopen/components-library-react';
import { translate } from '../../../shared/locales';
import { tableFallbackDrawer } from '../../ContentStream/contentStreamUtils';
import { ContentStreamTableDataType } from '../../ContentStream/types/ContentStreamTableDataType';
import ContentStreamTable from '../../ContentStream/views/ContentStreamTable';
import { TitleText } from '../../ContentStreamCreate/views/styles/Targets.styled';
import { Column } from 'ag-grid-community';
import { BaseTableDataType, DrawerActionButtons } from '../../../shared/components';

export interface ILinkedFallbackDrawerProps {
  selectedLinkedFallback: ContentStreamTableDataType | null;
  setSelectedLinkedFallback: (selectedLinkedFallback: ContentStreamTableDataType) => void;
  isSideModalOpen: boolean;
  setIsSideModalOpen: (isSideModalOpen: boolean) => void;
}

const LinkedFallbackDrawer: React.FC<ILinkedFallbackDrawerProps> = ({
  selectedLinkedFallback,
  setSelectedLinkedFallback,
  isSideModalOpen,
  setIsSideModalOpen,
}) => {
  const [selectedFallbackState, setSelectedFallbackState] = useState<ContentStreamTableDataType | null>(
    selectedLinkedFallback,
  );
  const onRowClicked = (data: Partial<ContentStreamTableDataType> & BaseTableDataType) => {
    setSelectedFallbackState(data as ContentStreamTableDataType);
  };

  const handleSaveLinkedFallback = () => {
    if (selectedFallbackState) setSelectedLinkedFallback(selectedFallbackState);
    setIsSideModalOpen(false);
  };

  return (
    <WppSideModal
      data-testid={'fallback-modal'}
      open={isSideModalOpen}
      disableOutsideClick
      onWppSideModalClose={() => setIsSideModalOpen(false)}
      size="xl">
      <TitleText type="xl-heading" slot="header" data-testid={'title-modal'}>
        {translate('txtFallbackUpCase')}
      </TitleText>
      <div slot="body">
        <ContentStreamTable
          location={tableFallbackDrawer}
          data-testid={'content-stream-table'}
          onRowClicked={onRowClicked}
          listSelectedRowsId={selectedFallbackState?.id ? [selectedFallbackState.id] : []}
        />
      </div>
      <DrawerActionButtons
        variant="primary"
        size="s"
        onClick={() => handleSaveLinkedFallback()}
        disabled={selectedLinkedFallback?.id === selectedFallbackState?.id}
        buttonText={translate('btnSave')}
      />
    </WppSideModal>
  );
};

export default LinkedFallbackDrawer;
