import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { IChannelPageActions, IChannelPageState } from '../sliceStore/ChannelSlice';

export const useSelectedBrandState = () => {
  const selectedBrandId = useSliceState<IChannelPageState, 'selectedBrandId'>('selectedBrandId');
  const setSelectedBrandId = useSliceSetter<IChannelPageActions, 'setSelectedBrandId'>('setSelectedBrandId');

  return { selectedBrandId, setSelectedBrandId };
};

export const useSelectedUnitState = () => {
  const selectedUnitId = useSliceState<IChannelPageState, 'selectedUnitId'>('selectedUnitId');
  const setSelectedUnitId = useSliceSetter<IChannelPageActions, 'setSelectedUnitId'>('setSelectedUnitId');

  return { selectedUnitId, setSelectedUnitId };
};
