import styled from '@emotion/styled';
import { WppTree, WppProgressIndicator } from '@wppopen/components-library-react';

export const TreeContainer = styled.div({
  padding: '1.6rem',
  paddingTop: '0rem',
  overflowY: 'auto',
  overflowX: 'hidden',
});

export const StyledWppTree = styled(WppTree)({
  padding: '0rem',
  '&::part(tree-container)': {
    padding: '0rem',
  },

  maxWidth: '100%',
  overflowX: 'hidden',
});

export const StyledWppProgressIndicator = styled(WppProgressIndicator)({
  marginTop: '60%',
  marginLeft: '35%',
});
