/* eslint-disable @typescript-eslint/no-empty-interface */
import { BoxContentCenter, TextHighlight } from '../../../shared/components';
import React from 'react';

export interface IContentStreamFallbackCellProps {
  type: 'Fallback' | 'Targeted';
}

const ContentStreamFallbackCell: React.FC<IContentStreamFallbackCellProps> = ({ type }) => {
  return (
    <BoxContentCenter justify="flex-start">
      <TextHighlight fullString={type} subString="" />
    </BoxContentCenter>
  );
};

export default ContentStreamFallbackCell;
