/* eslint-disable @typescript-eslint/no-empty-interface */
import { BoxContent, ContentPage, Header } from '../../../shared/components';
import React from 'react';
import ContentStreamTable from './ContentStreamTable';
import { translate } from '../../../shared/locales';
import { tableContentStreamPage } from '../contentStreamUtils';

export interface IContentStreamPageProps {}

const ContentStreamPage: React.FC<IContentStreamPageProps> = () => {
  return (
    <ContentPage>
      <BoxContent pad="24">
        <div>
          <BoxContent marg="0 0 24 0">
            <Header title={translate('txtContentStream')} />
          </BoxContent>
        </div>
        <ContentStreamTable location={tableContentStreamPage} data-testid={'content-stream-table'} />
      </BoxContent>
    </ContentPage>
  );
};

export default ContentStreamPage;
