import styled from '@emotion/styled';
import { WppSelect, WppProgressIndicator } from '@wppopen/components-library-react';
import { DSListItem } from '../../../../shared';
import { BoxContent } from '../../../../shared/components';

export const OrganisationsListContainer = styled(BoxContent)({
  marginTop: '1.6rem',
});

export const SearchInputContainer = styled.div({
  width: '22rem',
  maxHeight: '24rem',
  overflowY: 'auto',
  padding: '1.2rem 1.6rem',
});

export const SyledWppSelect = styled(WppSelect)({
  whiteSpace: 'nowrap',
});

export const SyledWppListItem = styled(DSListItem)({
  width: '100%',
});

export const SyledWppProgressIndicator = styled(WppProgressIndicator)({
  paddingLeft: '7rem',
  marginTop: '5rem',
  marginBottom: '5rem',
});
