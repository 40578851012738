/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { WppAvatar, WppTooltip, WppTypography } from '@wppopen/components-library-react';
import { IOrganisation } from '../../../shared/types/IOrganisation';
import { BoxContent } from '../../../shared/components';
import { getStringEllipsed } from './../../../shared/utils';
import { useTheme } from '@emotion/react';
import { ITheme } from '@connected-core-system/utils/frontend';
import { SyledWppListItem } from './styles/SelectOrganisation.styled';

export interface IOrgarnisationOptionProps {
  organisation: IOrganisation;
  maxLenght: number;
  active: boolean;
}

const OrganisationOption: React.FC<IOrgarnisationOptionProps> = ({ organisation, maxLenght, active }) => {
  const theme = useTheme() as ITheme;

  return (
    <SyledWppListItem value={organisation.id} data-testid={`'org-option-'${organisation.id}`} active={active}>
      <p slot="label">
        <BoxContent flex align="center">
          <BoxContent marg="0 8 0 0" flex justify="center">
            <WppAvatar size="xs" src={organisation.filePath} color={organisation?.avatarColor} />
          </BoxContent>
          <div>
            <WppTypography type="s-body" color={theme.palette['grey800']}>
              {organisation?.title.length > maxLenght ? (
                <WppTooltip title={organisation.title} config={{ placement: 'right' }}>
                  {getStringEllipsed(organisation.title, maxLenght)}
                </WppTooltip>
              ) : (
                getStringEllipsed(organisation.title, maxLenght)
              )}
            </WppTypography>
          </div>
        </BoxContent>
      </p>
    </SyledWppListItem>
  );
};

export default OrganisationOption;
