import styled from '@emotion/styled';
import { WppMenuContext, WppIconError, WppInput } from '@wppopen/components-library-react';
import { DSListItem } from '../../DSComponents';

interface StyledItemProps {
  defaultItem: boolean;
}

export const StyledItem = styled.p<StyledItemProps>`
  ${({ defaultItem }) => `
    font-weight: ${defaultItem ? 'bold' : 'normal'};
    display: flex;
    align-items: center;
  `}
`;

export const StyledWppListItem = styled(DSListItem)({
  '&::part(item)': {
    cursor: 'default',
  },
  '&:hover::part(item)': {
    backgroundColor: 'white',
  },
});

export const StyledWppIconError = styled(WppIconError)({
  marginRight: '6px',
});

export const StyledWppInput = styled(WppInput)({
  '--wpp-input-border-style': 'none',
});

export const MenuContentWrapper = styled.div({
  maxHeight: '190px',
});

export const StyledMenuContext = styled(WppMenuContext)({
  display: 'flex !important',
});
