import styled from '@emotion/styled';
import { WppButton, WppTypography } from '@wppopen/components-library-react';

export const Button = styled(WppButton)({
  maxWidth: '75%',
  '&::part(button)': {
    width: '26rem',
    height: '4rem',
  },
});

export const Typography = styled(WppTypography)({
  textAlign: 'center',
  maxWidth: '56rem',
  whiteSpace: 'normal',
});
