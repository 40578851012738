import { BoxContent } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { WppButton, WppCard, WppFilterButton } from '@wppopen/components-library-react';
import { percentOfAppHeight } from '../../../../shared/utils';

export const WppSyledFilterButton = styled(WppFilterButton)({
  '&::part(button)': {
    height: '4rem',
  },
});

export const WppStyledCardBare = styled(WppCard)({
  '&::part(card)': {
    padding: '2.4rem 2.8rem',
  },
});

export const WppStyledCardOs = styled(WppCard)({
  '&::part(card)': {
    padding: '1.6rem',
  },
});

export const HeaderButtonsContainer = styled(BoxContent)({
  display: 'flex',
  justifyContent: 'flex-end',
  columnGap: 12,
  marginBottom: 36,
  width: 'auto',
});

export const WppStyledCardNoContent = styled(WppCard)({
  '&::part(card)': {
    padding: '2.4rem 2.8rem',
    maxHeight: `calc(${percentOfAppHeight(90)} - 130px)`,
    minHeight: `calc(${percentOfAppHeight(90)} - 130px)`,
    display: 'flex',
    alignItems: 'center',
  },
});
export const ButtonCreateContentStream = styled(WppButton)({
  '&::part(button)': {
    width: '29.5rem',
    height: '4rem',
  },
});
