import { BoxContent, TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { WppTypography } from '@wppopen/components-library-react';

export const LinkContainer = styled(BoxContent)({
  gap: '1.5rem',
});

export const PlaceholderText = styled(WppTypography)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey700'],
  wordBreak: 'break-word',
}));
