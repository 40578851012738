import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import {
  IContentStreamCreateEditActions,
  IContentStreamCreateEditState,
} from '../sliceStore/contentStreamCreateEditSliceStore';

export const useContentStreamModalZIndexState = () => {
  const contentStreamModalZIndex = useSliceState<IContentStreamCreateEditState, 'contentStreamModalZIndex'>(
    'contentStreamModalZIndex',
  );
  const setContentStreamModalZIndex = useSliceSetter<IContentStreamCreateEditActions, 'setContentStreamModalZIndex'>(
    'setContentStreamModalZIndex',
  );

  return { contentStreamModalZIndex, setContentStreamModalZIndex };
};
