import { IOrganisation } from '../../../shared/types';

export const addOrganisationOrderedInList = (list: IOrganisation[], item: IOrganisation) => {
  for (let i = 0; i < list.length; i++) {
    if (list[i].title.localeCompare(item.title) > 0) {
      item.avatarColor = getRandomRGB();
      return [...list.slice(0, i), item, ...list.slice(i)];
    }
  }
  return [...list, item];
};

export const replaceOrganisationInList = (list: IOrganisation[], item: IOrganisation) => {
  for (let i = 0; i < list.length; i++) {
    if (list[i].id === item.id) {
      item.avatarColor = list[i].avatarColor;
      if (!item.filePath) {
        item.filePath = list[i].filePath;
        item.fileSize = list[i].fileSize;
        item.fileType = list[i].fileType;
        item.fileName = list[i].fileName;
      }
      list[i] = item;
      break;
    }
  }
  return list;
};

export const getRandomRGB = (): string => {
  const r = Math.floor(Math.random() * 256);
  const g = Math.floor(Math.random() * 256);
  const b = Math.floor(Math.random() * 256);
  return `rgb(${r}, ${g}, ${b})`;
};
