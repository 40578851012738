import { SetCallback } from '../../../shared/store';

export enum FormType {
  create = 'create',
  edit = 'edit',
}

export interface IOrganisationCreateState {
  isModalOpened: boolean;
  organisationFormType?: FormType;
}

export const initialState: IOrganisationCreateState = {
  isModalOpened: false,
  organisationFormType: FormType.create,
};

const actions = (set: SetCallback<IOrganisationCreateState>) => ({
  setOpenModal: (open: boolean) => set((state) => ({ ...state, isModalOpened: open })),
  setOpenModalWithAction: (open: boolean, organisationFormType?: FormType) =>
    set((state) => ({ ...state, isModalOpened: open, organisationFormType: organisationFormType || FormType.create })),
});

export const slice = (set: SetCallback<IOrganisationCreateState>) => ({
  ...initialState,
  ...actions(set),
});

export type IOrganisationCreateActions = ReturnType<typeof actions>;

export default {
  slice,
  initialState,
};
