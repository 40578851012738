import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { IOrganisationUnitPageActions, IOrganisationUnitPageState } from '../sliceStore';

export const useUnitValueChangesState = () => {
  const unitValueChanges = useSliceState<IOrganisationUnitPageState, 'unitValueChanges'>('unitValueChanges');
  const setUnitValueChanges = useSliceSetter<IOrganisationUnitPageActions, 'setUnitValueChanges'>(
    'setUnitValueChanges',
  );

  return { unitValueChanges, setUnitValueChanges };
};
