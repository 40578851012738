/* eslint-disable @typescript-eslint/no-empty-interface */
import { AxiosRequestConfig } from 'axios';

export interface IHttpDefaultConfig extends AxiosRequestConfig {}

export const HTTP_DEFAULT_CONFIG: IHttpDefaultConfig = {
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json',
  },
};

export const HTTP_FORM_DATA_CONFIG: IHttpDefaultConfig = {
  ...HTTP_DEFAULT_CONFIG,
  headers: {
    Accept: '*',
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
  },
};
