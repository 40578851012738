import { TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { WppAccordion, WppIconTrash } from '@wppopen/components-library-react';

export const TargetsCard = styled(WppAccordion)(({ theme }: TStyledDefaultProps) => ({
  backgroundColor: theme?.palette?.['grey100'],
  marginBottom: 12,
  width: 'calc(100% - 12px)',
  borderRadius: 12,
  paddingRight: 12,
  position: 'relative',
  '&::part(section)': { width: '100%' },
  '&::part(title-wrapper)': {
    paddingLeft: 12,
  },
}));

export const ContainerScanHistorySelection = styled.div({
  marginTop: 10,
  display: 'flex',
  justifyContent: 'space-between',
});

export const StyledWppIconTrash = styled(WppIconTrash)({ position: 'absolute', right: 12, top: 8, cursor: 'pointer' });

export const ContainerContent = styled.div({
  width: '100%',
});

export const ContainerSelectDropDown = styled.div({
  width: '100%',
  minWidth: '100px',
  marginRight: '6px',
  display: 'flex',
  gap: '10px',

  '> div': {
    width: '18%',
  },
});

export const ContainerInput = styled.div({ width: '85%', position: 'relative' });

export const ContainerTable = styled.div({ marginTop: '20px', width: '100%' });
