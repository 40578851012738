/* eslint-disable @typescript-eslint/no-empty-interface */
import { useContext } from 'react';
import { useContentStreamModalZIndexState } from '../hooks/useContentStreamModalZIndexState';
import { useIsContentStreamModalOpenedState } from '../hooks/useIsContentStreamModalOpenedState';
import ContentStreamForm from './ContentStreamForm';
import { Container, ModalContainer } from './styles/ContentStreamFormModal.styled';
import { BackofficeAppContext } from '../../../app/context/BackofficeAppContext';

export interface IContentStreamFormModalProps {}

const ContentStreamFormModal: React.FC<IContentStreamFormModalProps> = () => {
  const { isContentStreamModalOpened } = useIsContentStreamModalOpenedState();
  const { contentStreamModalZIndex } = useContentStreamModalZIndexState();
  const { insideWppOs } = useContext(BackofficeAppContext);

  return (
    <ModalContainer
      open={isContentStreamModalOpened}
      disableOutsideClick
      zIndex={contentStreamModalZIndex}
      style={insideWppOs ? { top: '64px' } : {}}>
      <Container slot="body">{isContentStreamModalOpened && <ContentStreamForm />}</Container>
    </ModalContainer>
  );
};

export default ContentStreamFormModal;
