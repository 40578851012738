import { useEffect, useState } from 'react';
import authManager from '../services/AuthorizationManager';
import { useUserInfo } from './useUserInfo';
import { USER_SERVICE_ENDPOINTS } from '../../../shared/api';
import { getWindowHref, interpolateWithValues, isArrayEmpty, replaceURL } from '../../../shared/utils';

export const useAuthBackofficeBare = () => {
  const { userInfo, setUserInfo } = useUserInfo();
  const [error, setError] = useState(false);
  const localToken = authManager.getAuthToken();

  useEffect(() => {
    const redirectToLogin = () => {
      const currentPath = getWindowHref();
      const newUrl = interpolateWithValues(USER_SERVICE_ENDPOINTS.LOGIN_PATH, currentPath.split('?token=')[0]);
      replaceURL(newUrl);
    };

    const getUserInfo = async (token?: string) => {
      try {
        const user = await authManager.getUserInfo(token);

        if (user) {
          const token = localToken || authManager.getAuthToken();
          setUserInfo({ ...user, isAuthenticated: !!(token && !!user?.email && !isArrayEmpty(user.roles)) });
        } else {
          handleAuthError();
        }
      } catch (e) {
        handleAuthError();
      }
    };

    const handleAuthError = () => {
      authManager.clearAuthStoredData();
      setError(true);
      redirectToLogin();
    };

    const checkUserAuthenticated = () => {
      if (userInfo?.isAuthenticated) return;

      if (!localToken) {
        redirectToLogin();
      } else if (!userInfo?.isAuthenticated) {
        getUserInfo(localToken);
      }
    };

    if (window.location.search.includes('token')) {
      const token = window.location.search.split('token=')[1];
      authManager.saveUserToken(token);

      if (!userInfo?.isAuthenticated) {
        getUserInfo(token);
      }
    } else {
      checkUserAuthenticated();
    }
  }, [localToken, setUserInfo, userInfo]);

  return {
    isAuthenticated: !!userInfo?.isAuthenticated,
    error: error,
  };
};
