/* eslint-disable @typescript-eslint/no-empty-interface */
import { WppMenuContext, WppActionButton, WppIconMore } from '@wppopen/components-library-react';
import { ConditionalTooltip } from '../../../shared/components';
import { useSliceSetter } from '../../../shared/hooks/useStore';
import { translate } from '../../../shared/locales/i18n';
import { DSListItem } from '../../../shared';
import React from 'react';
import { IOrganisationCreateActions, FormType } from '../../OrganisationCreate/sliceStore/organisationCreateSliceStore';
import { useSelectedOrganisation } from '../../SideMenu';

export interface IOrganisationMoreActionsProps {}

const OrganisationMoreActions: React.FC<IOrganisationMoreActionsProps> = () => {
  const { selectedOrganisation } = useSelectedOrganisation();
  const setOpenModalWithAction = useSliceSetter<IOrganisationCreateActions, 'setOpenModalWithAction'>(
    'setOpenModalWithAction',
  );

  const disableActions = !selectedOrganisation;

  const handleEdit = () => {
    if (!disableActions) {
      setOpenModalWithAction(true, FormType.edit);
    }
  };

  return (
    <WppMenuContext listWidth="24rem">
      <WppActionButton slot="trigger-element">
        <ConditionalTooltip showTooltip={disableActions} tooltipText={translate('txtMustSelectOrganisation')}>
          <WppIconMore direction="horizontal" />
        </ConditionalTooltip>
      </WppActionButton>
      <DSListItem onWppChangeListItem={() => handleEdit()} disabled={disableActions}>
        <p slot="label">{translate('txtEditOrganisation')}</p>
      </DSListItem>
    </WppMenuContext>
  );
};

export default OrganisationMoreActions;
