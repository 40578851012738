import React, { FunctionComponent } from 'react';
import { BoxContent, ITheme } from '@connected-core-system/utils/frontend';
import { WppTypography, WppIconSortByIncrease, WppIconSortByDecrease } from '@wppopen/components-library-react';
import { IHeaderParams } from 'ag-grid-community';
import { useTheme } from '@emotion/react';

export enum TableSortTypesEnum {
  asc = 'asc',
  desc = 'desc',
}

const DefaultHeaderCell: FunctionComponent<IHeaderParams> = ({ enableSorting, column, setSort, displayName }) => {
  const theme = useTheme() as ITheme;
  const handleDefaultHeaderCellClick = () => {
    if (!enableSorting) return;

    let nextSortType: TableSortTypesEnum;
    const selectedSort = column.getSort();
    if (!selectedSort) {
      nextSortType = TableSortTypesEnum.asc;
    } else {
      nextSortType = selectedSort === TableSortTypesEnum.asc ? TableSortTypesEnum.desc : TableSortTypesEnum.asc;
    }
    setSort(nextSortType);
  };

  const getColor = (isActive: boolean): string => (isActive ? theme.palette['grey1000'] : theme.palette['grey600']);

  return (
    <BoxContent flex align="center" onClick={() => handleDefaultHeaderCellClick()}>
      <WppTypography type="s-strong">{displayName}</WppTypography>
      {enableSorting && (
        <BoxContent flex justify="center" marg="0 0 0 4" direction="column">
          {!column.isSortDescending() && <WppIconSortByIncrease size="s" color={getColor(column.isSortAscending())} />}
          {column.isSortDescending() && <WppIconSortByDecrease size="s" color={getColor(column.isSortDescending())} />}
        </BoxContent>
      )}
    </BoxContent>
  );
};

export default DefaultHeaderCell;
