/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect } from 'react';
import { useToastState } from '../hooks/useToastState';

export interface IFallbackWrapperProps {
  content?: () => React.ReactNode | null | undefined;
  error: boolean | undefined | null;
  errorToastMessage?: string;
  successToastMessage?: string;
  showEmptyState?: boolean | undefined | null;
  loading?: boolean | undefined | null;
  success: boolean | undefined | null;
}

const FallbackWrapper: React.FC<IFallbackWrapperProps> = ({
  error,
  content,
  success,
  errorToastMessage,
  successToastMessage,
  showEmptyState,
}) => {
  const { setToastDefaultSuccess, setToastDefaultError } = useToastState();
  useEffect(() => {
    if (error && errorToastMessage) {
      setToastDefaultError(errorToastMessage);
    }
  }, [error, errorToastMessage, setToastDefaultError]);

  useEffect(() => {
    if (success && successToastMessage) {
      setToastDefaultSuccess(successToastMessage);
    }
  }, [success, successToastMessage, setToastDefaultSuccess]);

  if (!showEmptyState || (success && content)) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{content && content()}</>;
  }
  return null;
};

export default FallbackWrapper;
