import { createContext } from 'react';

interface BackofficeAppContextType {
  insideWppOs: boolean;
}

const defaultContextValue: BackofficeAppContextType = {
  insideWppOs: false,
};

const BackofficeAppContext = createContext<BackofficeAppContextType>(defaultContextValue);

export { BackofficeAppContext };
