import { useCallback } from 'react';
import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { IToastMessageActions, IToastMessageState } from '../sliceStore';
import { translate } from '../../../shared/locales';

export const useToastState = () => {
  const setToastMessage = useSliceSetter<IToastMessageActions, 'setToastMessage'>('setToastMessage');
  const toastMessage = useSliceState<IToastMessageState, 'toastMessage'>('toastMessage');
  const setToastDefaultSuccess = useCallback(
    (secondaryText: string) => {
      setToastMessage({
        primaryText: translate('txtSuccess'),
        secondaryText,
        type: 'success',
      });
    },
    [setToastMessage],
  );

  const setToastDefaultError = useCallback(
    (secondaryText: string) => {
      setToastMessage({
        primaryText: translate('txtError'),
        secondaryText,
        type: 'error',
      });
    },
    [setToastMessage],
  );

  return {
    toastMessage,
    setToastMessage,
    setToastDefaultSuccess,
    setToastDefaultError,
  };
};
