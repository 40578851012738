import React, { useCallback, useEffect, useRef, useState } from 'react';
import QRCodeStyling, { Options } from 'qr-code-styling';
import { QrPreview } from '../../../features/QRStyle/styles/QRStylePage.styled';
import { useQRCodeOptionsState } from '../../../features/QRStyle/hooks/useQRCodeOptionsState';

export interface IQRCodePreviewProps {
  configuration: Options;
}

const IDEAL_RATIO_VALUE = 50; // This value can be changed if needed.

const QRCodePreview: React.FC<IQRCodePreviewProps> = ({ configuration }) => {
  const { setQRCodeOptions } = useQRCodeOptionsState();

  const [qrCode] = useState<QRCodeStyling>(new QRCodeStyling(configuration));

  const ref = useRef(null);

  useEffect(() => {
    if (ref.current) {
      qrCode.append(ref.current);
    }
  }, [qrCode, ref]);

  useEffect(() => {
    if (!qrCode) return;
    qrCode.update(configuration);
  }, [qrCode, configuration]);

  useEffect(() => {
    if (configuration) setQRCodeOptions(configuration);
  }, [configuration, setQRCodeOptions]);

  const handleImagePreviewSize = useCallback(() => {
    const { imageOptions, image } = configuration;
    if (imageOptions && image) {
      const { margin, imageSize } = imageOptions;

      if (Number(margin) / Number(imageSize) > IDEAL_RATIO_VALUE) {
        qrCode.update({ ...configuration, image: '' });
      }
    }
  }, [configuration, qrCode]);

  useEffect(() => {
    handleImagePreviewSize();
  }, [handleImagePreviewSize]);

  return (
    <QrPreview data-testid="qr-code-preview">
      <div ref={ref} />
    </QrPreview>
  );
};

export default QRCodePreview;
