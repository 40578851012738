import { SetCallback } from '../../../shared/store';
import { SecondTierSidebarItem } from '../types';

type Nullable<T> = T | null | undefined;

export interface ISecondTierSidebarState {
  selectedSecondTierItem?: SecondTierSidebarItem | null;
  selectedOrganisationUnit?: string | null;
  secondTierReload: boolean;
  itemIdToSelectOnReload: string | null;
}

export const initialState: ISecondTierSidebarState = {
  selectedSecondTierItem: null,
  secondTierReload: false,
  itemIdToSelectOnReload: null,
};

const actions = (set: SetCallback<ISecondTierSidebarState>) => ({
  setSelectedSecondTierItem: (item: Nullable<SecondTierSidebarItem>) =>
    set((state) => ({ ...state, selectedSecondTierItem: item })),
  setSelectedOrganisationUnit: (organisationUnitId: string) =>
    set((state) => ({ ...state, selectedOrganisationUnit: organisationUnitId })),
  setSecondTierReload: (reload: boolean) => set((state) => ({ ...state, secondTierReload: reload })),
  setItemIdToSelectOnReload: (itemId: Nullable<string>) =>
    set((state) => ({ ...state, itemIdToSelectOnReload: itemId })),
});

export const slice = (set: SetCallback<ISecondTierSidebarState>) => ({
  ...initialState,
  ...actions(set),
});

export type ISecondTierSidebarActions = ReturnType<typeof actions>;

export default {
  slice,
  initialState,
};
