import { ExceptionBase } from '../exception.base';
import { ExceptionCode, ExceptionMessages } from '../enum';

export class NotFoundException extends ExceptionBase {
  static readonly message = ExceptionMessages.NotFound;

  constructor(message = NotFoundException.message, cause?: Error) {
    super(message, cause);
  }

  readonly code = ExceptionCode.NotFound;
}
