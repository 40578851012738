import React from 'react';
import { ContainerCard, InlineMessage } from './WarningIconMessage.styled';

export interface IWarningIconMessageProps extends React.PropsWithChildren {
  message: string;
}

const WarningIconMessage: React.FC<IWarningIconMessageProps> = ({ message }) => {
  return (
    <ContainerCard variant="secondary" size="s">
      <InlineMessage
        size="m"
        message={message}
        type="warning"
        style={{
          '--wpp-inline-message-warning-text-color-m': '#121619',
          '--wpp-inline-message-warning-background-color': 'none',
          '--wpp-inline-message-icon-margin': '0px 6px 0px 0px',
        }}
      />
    </ContainerCard>
  );
};
export default WarningIconMessage;
