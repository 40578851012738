/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { DrawerActionButtonsDiv, DrawerActionButton } from './DrawerActionButtons.styled';
import { WppButton } from '@wppopen/components-library-react';

export interface IDrawerActionButtons {
  buttonText: string;
}
const DrawerActionButtons: React.FC<IDrawerActionButtons & typeof WppButton.defaultProps> = ({
  buttonText,
  ...actionButtonProps
}) => {
  return (
    <DrawerActionButtonsDiv slot="actions">
      <DrawerActionButton {...actionButtonProps}>{buttonText}</DrawerActionButton>
    </DrawerActionButtonsDiv>
  );
};

export default DrawerActionButtons;
