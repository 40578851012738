/* eslint-disable @typescript-eslint/no-empty-function */
import { ConditionalTooltip } from '..';
import { getStringEllipsed } from '../../utils';
import TextHighlight, { ITextHighlightProps } from '../TextHighlight/TextHighlight';
import React, { useRef, useState, useEffect } from 'react';
import { Container } from './CellTextTruncate.style';

export interface IChannelNameCellProps {
  text: string;
  charSize?: number;
  textHighlightProps: Partial<ITextHighlightProps>;
}

const CellTextTruncate: React.FC<IChannelNameCellProps> = ({ text, charSize = 9, textHighlightProps }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [truncatedText, setTruncatedText] = useState('');

  useEffect(() => {
    const currentContainerRef = containerRef?.current;
    if (currentContainerRef) {
      const getWidthAndTruncate = () => {
        const newTextSize = Math.trunc(currentContainerRef.offsetWidth / charSize);
        setTruncatedText(getStringEllipsed(text, newTextSize));
        setShowTooltip(text.length > newTextSize);
      };

      getWidthAndTruncate();

      const resizeObserver = new ResizeObserver(getWidthAndTruncate);

      resizeObserver.observe(currentContainerRef);

      return () => {
        resizeObserver.unobserve(currentContainerRef);
      };
    }
    return;
  }, [text, charSize]);

  return (
    <Container ref={containerRef}>
      <ConditionalTooltip showTooltip={showTooltip} tooltipText={text} config={{ placement: 'bottom' }}>
        <TextHighlight fullString={truncatedText} subString={textHighlightProps.subString ?? ''} />
      </ConditionalTooltip>
    </Container>
  );
};

export default CellTextTruncate;
