import axios, { AxiosRequestConfig } from 'axios';
import { HttpService } from '@core/http-service';
import { CONFIG } from './apiUtils';
import storage, { USER_TOKEN_LOCAL_KEY } from '../utils/storage';
import { createBrowserHistory } from 'history';

export class HttpResolver {
  private static httpService: HttpService;

  public static getHttpService(): HttpService {
    if (!HttpResolver.httpService) {
      const axiosInstance = axios.create(CONFIG);
      const history = createBrowserHistory();

      axiosInstance.interceptors.request.use((config: AxiosRequestConfig) => {
        return {
          ...config,
          headers: {
            ...config.headers,
            Authorization: `Bearer ${storage.getToken()}`,
          },
        };
      });

      axiosInstance.interceptors.response.use(
        (response) => {
          return response;
        },
        (error) => {
          if (error.response && error.response.status === 401) {
            storage.clear(USER_TOKEN_LOCAL_KEY);
            history.push('/organisation');
            history.go(0);
          }
          return Promise.reject(error);
        },
      );

      const httpService = new HttpService(axiosInstance);
      HttpResolver.httpService = httpService;
    }

    return HttpResolver.httpService;
  }
}
