/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useState, useRef } from 'react';
import { WppTag } from '@wppopen/components-library-react';
import { Container, Typography } from './Header.styled';
import { StatusEnum } from '../../types';
import ConditionalTooltip from '../ConditionalTooltip/ConditionalTooltip';
import { getStringEllipsed } from '../../utils';
import { useContainerDimensions } from '../../hooks';

export interface IHeaderProps {
  title: string;
  tag?: StatusEnum;
  tagColor?: 'positive' | 'warning' | 'neutral';
}

const Header: React.FC<IHeaderProps & React.PropsWithChildren> = (props) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const containerRef = useRef(null);
  const dimenssions = useContainerDimensions(containerRef);

  useEffect(() => {
    setShowTooltip(getStringEllipsed(props.title, Math.floor(dimenssions.width * 0.06)) !== props.title);
  }, [props.title, dimenssions.width]);

  return (
    <Container ref={containerRef}>
      <ConditionalTooltip showTooltip={showTooltip} tooltipText={props.title}>
        <Typography
          tag="h1"
          type="3xl-heading"
          tagArchived={props.tag === 'Archived'}
          id="shared-components-header-typography">
          {getStringEllipsed(props.title, Math.floor(dimenssions.width * 0.06))}
        </Typography>
      </ConditionalTooltip>
      <WppTag variant={props.tagColor} label={props.tag} />
      {props.children}
    </Container>
  );
};

export default Header;
