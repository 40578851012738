/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { usePromise } from '../../../shared/hooks/usePromise';
import { secondTierSidebarManager } from '../services';
import { SecondTierSidebarContainerBare, SecondTierSidebarContainerOs } from './styles/SecondTierSidebar.styled';
import Button from './Button';
import SearchField from './SearchField';
import Tree from './Tree';
import Title from './Title';
import { MenuItemEnum, StatusEnum } from '../../../shared/types';
import { SecondTierSidebarItem } from '../types';
import { translate } from '../../../shared/locales';
import { formatName } from '../utils';
import { useSecondTierReloadState } from '../hooks/useSecondTierReloadState';
import { useSecondTierSelectedItemState } from '../hooks/useSecondTierSelectedItemState';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisarionState';
import { useSecondTierItemIdToSelectOnReloadState } from '../hooks/useSecondTierItemIdToSelectOnReloadState';
import { useHasAnyQRStyleState } from '../../QRStyle/hooks/useHasAnyQRStyleState';
import { isArrayEmpty } from '../../../shared/utils';
import { useUnitValueChangesState } from '../../UnitPage/hooks/useUnitValueChangesState';
import ConfirmationModal from '../../../shared/components/Modals/ConfirmationModal';
import { useBrandValueChangesState } from '../../BrandPage/hooks';
import PlatformWrapper from '../../../shared/components/PlatformWrapper/PlatformWrapper';
import { getUpperMenuItems } from '../../SideMenu/utils/sideMenuUtils';

export interface ISecondTierSidebarProps {}

const SecondTierSidebar: React.FC<ISecondTierSidebarProps> = () => {
  const [searchFieldValue, setSearchFieldValue] = useState('');
  const [openConfirmationModal, setOpenConfirmationModal] = useState<boolean>(false);
  const { secondTierReload, setSecondTierReload } = useSecondTierReloadState();
  const { setItemIdToSelectOnReload } = useSecondTierItemIdToSelectOnReloadState();
  const { selectedSecondTierItem, setSelectedSecondTierItem } = useSecondTierSelectedItemState();
  const { selectedSideMenuItem } = useSelectedSideMenuItemState();
  const { selectedOrganisation } = useSelectedOrganisationState();
  const { setHasAnyQRStyle } = useHasAnyQRStyleState();
  const { unitValueChanges, setUnitValueChanges } = useUnitValueChangesState();
  const { brandValueChanges, setBrandValueChanges } = useBrandValueChangesState();
  const navigate = useNavigate();

  useEffect(() => {
    if (
      selectedSecondTierItem &&
      (selectedSideMenuItem === MenuItemEnum.UNIT ||
        selectedSideMenuItem === MenuItemEnum.BRANDS ||
        selectedSideMenuItem === MenuItemEnum.QRSTYLES)
    ) {
      navigate(`${selectedSecondTierItem.id}`);
    }
  }, [navigate, selectedSecondTierItem, selectedSideMenuItem]);

  useEffect(() => {
    if (selectedOrganisation && selectedSideMenuItem) {
      setSelectedSecondTierItem(null);
    }
  }, [selectedOrganisation, selectedSideMenuItem, setItemIdToSelectOnReload, setSelectedSecondTierItem]);

  const loadUnitData = useCallback(() => {
    if (secondTierReload) {
      setSecondTierReload(false);
    }

    setSearchFieldValue('');

    if (selectedSideMenuItem === MenuItemEnum.UNIT && selectedOrganisation) {
      return secondTierSidebarManager.getUnitList(selectedOrganisation.id);
    }
    return null;
  }, [selectedSideMenuItem, selectedOrganisation, secondTierReload, setSecondTierReload]);

  const loadBrandData = useCallback(() => {
    if (secondTierReload) {
      setSecondTierReload(false);
    }

    setSearchFieldValue('');

    if (selectedSideMenuItem === MenuItemEnum.BRANDS && selectedOrganisation) {
      return secondTierSidebarManager.getBrandList(selectedOrganisation.id);
    }

    return null;
  }, [selectedSideMenuItem, selectedOrganisation, secondTierReload, setSecondTierReload]);

  const loadQrCodeData = useCallback(() => {
    if (secondTierReload) {
      setSecondTierReload(false);
    }

    setSearchFieldValue('');

    if (selectedSideMenuItem === MenuItemEnum.QRSTYLES && selectedOrganisation) {
      return secondTierSidebarManager.getQRStyleList(selectedOrganisation.id);
    }

    return null;
  }, [selectedSideMenuItem, selectedOrganisation, secondTierReload, setSecondTierReload]);

  const { data: dataUnit, ...promiseStatusUnit } = usePromise(loadUnitData);
  const { data: dataBrand, ...promiseStatusBrand } = usePromise(loadBrandData);
  const { data: dataQrCode, ...promiseStatusQrCode } = usePromise(loadQrCodeData);

  const getData = () => {
    if (selectedSideMenuItem === MenuItemEnum.UNIT && dataUnit) return dataUnit.data;
    if (selectedSideMenuItem === MenuItemEnum.BRANDS && dataBrand) return dataBrand.data;
    if (selectedSideMenuItem === MenuItemEnum.QRSTYLES && dataQrCode) return dataQrCode.data;
    return [];
  };

  const getPromiseStatus = () => {
    if (selectedSideMenuItem === MenuItemEnum.UNIT) return promiseStatusUnit;
    if (selectedSideMenuItem === MenuItemEnum.BRANDS) return promiseStatusBrand;
    if (selectedSideMenuItem === MenuItemEnum.QRSTYLES) return promiseStatusQrCode;
    return null;
  };

  const shouldRenderData = !!(selectedSideMenuItem && !getPromiseStatus()?.loading && !getPromiseStatus()?.error);

  const addNewItem = () => {
    if (selectedSideMenuItem) {
      const newItem: SecondTierSidebarItem = {
        id: 'new',
        name: translate('txtNewSomething', { something: formatName(selectedSideMenuItem, true) }),
        status: StatusEnum.UNPUBLISHED,
      };
      setSelectedSecondTierItem(newItem);
    }
  };

  const handleClickButtonCreateNew = () => {
    if (unitValueChanges || brandValueChanges) {
      setOpenConfirmationModal(true);
    } else {
      addNewItem();
      setOpenConfirmationModal(false);
    }
  };

  useEffect(() => {
    if (selectedSideMenuItem === MenuItemEnum.QRSTYLES && isArrayEmpty(dataQrCode?.data)) {
      setHasAnyQRStyle(false);
    } else {
      setHasAnyQRStyle(true);
    }
  }, [dataQrCode?.data, selectedSideMenuItem, setHasAnyQRStyle]);

  const handleConfirmationModal = () => {
    setOpenConfirmationModal(false);
  };

  const handleCancelConfirmationModal = () => {
    setOpenConfirmationModal(false);
    setUnitValueChanges(false);
    setBrandValueChanges(false);
    addNewItem();
  };

  const getSelectedMenuItemTitle = () => {
    const menuItems = getUpperMenuItems();
    const selectedItem = menuItems.find((item) => item.id === selectedSideMenuItem);
    return selectedItem ? selectedItem.value : '';
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {selectedSideMenuItem && (
        <PlatformWrapper
          componentBare={SecondTierSidebarContainerBare}
          componentOs={SecondTierSidebarContainerOs}
          selectedSideMenuItem={selectedSideMenuItem}>
          <ConfirmationModal
            title={translate('txtConfirmationModalLeaveEditTitle')}
            text={translate('txtConfirmationModalLeaveEditText')}
            buttonCancelText={translate('txtButtonModalCancel')}
            buttonText={translate('txtButtonModal')}
            buttonAction={handleConfirmationModal}
            buttonCancelAction={handleCancelConfirmationModal}
            isOpen={openConfirmationModal}
            setIsOpen={setOpenConfirmationModal}
            isWarning={false}
          />
          <Title name={getSelectedMenuItemTitle()} />
          <SearchField value={searchFieldValue} setValue={setSearchFieldValue} />
          <Tree
            searchFieldValue={searchFieldValue}
            itemList={shouldRenderData ? getData() : []}
            loading={!!getPromiseStatus()?.loading}
            error={!!getPromiseStatus()?.error}
          />
          <Button
            name={getSelectedMenuItemTitle()}
            onClick={handleClickButtonCreateNew}
            disabled={!!getPromiseStatus()?.loading || selectedSecondTierItem?.id === 'new'}
          />
        </PlatformWrapper>
      )}
    </>
  );
};

export default SecondTierSidebar;
