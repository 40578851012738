/* eslint-disable @typescript-eslint/no-empty-interface */
import { ConditionComparatorEnum, StatusForLocationEnum, TypeLocationEnum } from '@connected-core-system/utils/types';
import { WppSpinner, WppInput, WppTypography, WppIconCross } from '@wppopen/components-library-react';
import { AutoCompleteDropDown, BoxContentCenter, CardWithOptions } from '../../../shared/components';
import React, { useEffect, useState } from 'react';
import { translate } from '../../../shared/locales';
import { ICardOption } from '../../../shared/types/ICardOption';
import {
  ContainerInput,
  ContainerlocationSelection,
  ContainerSelectDropDown,
  ContainerTable,
  TargetsCard,
} from './styles/Location.styled';
import { ContainerContent, StyledWppIconTrash } from './styles/Schedule.styled';
import { TargetingLocationDTO } from '@connected-core-system/utils/content-stream-http-dto';
import { LocationTableDataType } from '../types/LocationTableDataType';
import { isArrayEmpty } from '../../../shared/utils';
import LocationTable from './LocationTable/LocationTable';
import { isCardInViewport, tabTargetsValue } from '../utils/contentStreamCreateUtils';
import ContentCreateManager from '../services/ContentStreamCreateManager';
import EmptyState from '../../../shared/components/EmptyState/EmptyState';
import { useSelectedTabState } from '../hooks/useSelectedTabState';
import { GetLocationDTO } from '@connected-core-system/utils/bff-http-dto';
import ErrorIconMessage from '../../../shared/components/ErrorIconMessage/ErrorIconMessage';
import { TOption } from '../../../shared/types/TOption';

export interface ITargetLocationProps {
  removeLocationTargeting: () => void;
  targetingLocation: TargetingLocationDTO[];
  setTargetingLocation: (targetingLocation: TargetingLocationDTO[]) => void;
  isInvalidTargetsTab: boolean;
  setIsInvalidTargetLocation: (isInvalidTargetLocation: boolean) => void;
}

const TargetLocation: React.FC<ITargetLocationProps> = ({
  removeLocationTargeting,
  targetingLocation,
  setTargetingLocation,
  isInvalidTargetsTab,
  setIsInvalidTargetLocation,
}) => {
  const locationTypeOptions: TOption[] = [
    { value: ConditionComparatorEnum.EQUAL, label: translate('txtInclude') },
    { value: ConditionComparatorEnum.NOT_EQUAL, label: translate('txtExclude') },
  ];
  const { selectedTab } = useSelectedTabState();
  const [selectedLocationType, setSelectedLocationType] = useState<TOption[]>([locationTypeOptions[0]]);

  const [location, setLocation] = useState<string | undefined>('');
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isCardOpen, setIsCardOpen] = useState<boolean>(false);
  const [locationsData, setlocationsData] = useState<LocationTableDataType[]>(
    targetingLocation.map((location) => {
      return {
        name: location.location,
        status:
          location.conditionComparatorEnum === ConditionComparatorEnum.EQUAL
            ? StatusForLocationEnum.INCLUDE
            : StatusForLocationEnum.EXCLUDE,
      } as LocationTableDataType;
    }),
  );
  const [options, setOptions] = useState<ICardOption[]>([]);
  const [locations, setLocations] = useState<GetLocationDTO[]>([]);
  const [promise, setPromise] = useState({
    loading: false,
    error: false,
    success: false,
    empty: false,
  });

  const handleInputChange = (search: string | undefined) => {
    setPromise({
      loading: true,
      error: false,
      success: false,
      empty: false,
    });
    setLocation(search);
  };

  const handleLocationTypeChange = (option: TOption[]) => {
    setSelectedLocationType([{ value: option[0].value as ConditionComparatorEnum, label: option[0].label }]);
  };

  useEffect(() => {
    if (!location) return;
    const input = document.querySelector("[data-testId='input-location']") as HTMLInputElement;
    ContentCreateManager.getSuggestedLocations(location)
      .then((result) => {
        if (result && result.search === input.value) {
          setOptions(result.options);
          setLocations(result.locations);
          setPromise({
            loading: false,
            error: false,
            success: true,
            empty: !!isArrayEmpty(result.options),
          });
        }
      })
      .catch(() => {
        if (location === input.value) {
          setPromise({
            loading: false,
            error: true,
            success: true,
            empty: false,
          });
        }
      });
  }, [location]);

  useEffect(() => {
    setIsInvalidTargetLocation(targetingLocation.length === 0);
  }, [targetingLocation, setIsInvalidTargetLocation]);

  const handleSelectedLocation = (option: ICardOption) => {
    setIsInputFocused(false);
    setIsCardOpen(false);
    const index = targetingLocation.findIndex((item) => item.location === option.value);

    if (index >= 0) {
      handleUpdate(index);
      return;
    }
    const locationInformation = locations.find((location) => location.locationString === option.value);
    const locationToSave: TargetingLocationDTO = {
      conditionComparatorEnum: selectedLocationType[0].value as ConditionComparatorEnum,
      location: option.value,
      typeLocationEnum: option.value.split(',').length === 2 ? TypeLocationEnum.COUNTRY : TypeLocationEnum.CITY,
      city: locationInformation?.city,
      subdivision: locationInformation?.subdivision,
      countryCode: locationInformation?.countryCode,
      geometryId: locationInformation?.geometryId,
    };
    saveLocation(locationToSave);
  };

  const handleUpdate = (index: number) => {
    const newArraySelectedLocations = [...targetingLocation];
    newArraySelectedLocations[index].conditionComparatorEnum = selectedLocationType[0].value as ConditionComparatorEnum;
    setTargetingLocation(newArraySelectedLocations);
    const newArrayLocationData = [...locationsData];
    newArrayLocationData[index].status =
      selectedLocationType[0].value === ConditionComparatorEnum.EQUAL
        ? StatusForLocationEnum.INCLUDE
        : StatusForLocationEnum.EXCLUDE;
    setlocationsData(newArrayLocationData);
  };

  const saveLocation = (location: TargetingLocationDTO) => {
    const newArray = [...targetingLocation];
    newArray.push(location);
    setTargetingLocation(newArray);
    saveLocationData(newArray);
  };

  const handleDelete = (name: string) => {
    let newArrayTargetingLocation = [...targetingLocation];
    newArrayTargetingLocation = newArrayTargetingLocation.filter((element) => element.location !== name);
    let newArrayLocationData = [...locationsData];
    newArrayLocationData = newArrayLocationData.filter((element) => element.name !== name);
    setTargetingLocation(newArrayTargetingLocation);
    setlocationsData(newArrayLocationData);
  };

  const saveLocationData = (selectedLocations: TargetingLocationDTO[]) => {
    const data = selectedLocations.map((location) => {
      return {
        name: location.location,
        status:
          location.conditionComparatorEnum === ConditionComparatorEnum.EQUAL
            ? StatusForLocationEnum.INCLUDE
            : StatusForLocationEnum.EXCLUDE,
      } as LocationTableDataType;
    });
    setlocationsData(data);
  };

  const renderLoading = () => {
    return (
      <BoxContentCenter data-testid="loading-state">
        <WppSpinner size="m" />
      </BoxContentCenter>
    );
  };

  const renderError = () => {
    return (
      <EmptyState
        title={translate('txtSomethingWentWrong')}
        text={translate('txtErrorMessage')}
        buttonText={translate('txtTryAgain')}
        buttonAction={() => {
          setLocation('');
          setIsInputFocused(false);
          setIsCardOpen(false);
        }}
      />
    );
  };

  const renderEmpty = () => {
    return (
      <BoxContentCenter data-testid="empty-state">
        <WppTypography>{`${translate('txtNoResultsForSearch')} "${location}"`}</WppTypography>
      </BoxContentCenter>
    );
  };

  return (
    <TargetsCard
      expandedByDefault
      size="s"
      text={translate('txtLocation')}
      data-testid="expandable-target-location"
      withDivider={false}>
      <StyledWppIconTrash onClick={removeLocationTargeting} data-testid="icon-remove" />
      <ContainerContent style={{ width: '100%' }}>
        <ContainerlocationSelection data-testid="container-location">
          <ContainerSelectDropDown>
            <AutoCompleteDropDown
              isMultipleSelection={false}
              options={locationTypeOptions}
              selectedOptions={selectedLocationType}
              setSelectedOptions={handleLocationTypeChange}
              placeHolder={locationTypeOptions[0].label}
              showCreateNewElement={false}
              useTextHighLight={false}
              isSearchAllowed={false}
            />
          </ContainerSelectDropDown>
          <ContainerInput>
            <WppInput
              data-testid="input-location"
              name="location"
              placeholder={translate('txtSearchLocation')}
              value={location}
              onWppChange={(e) => handleInputChange(e.detail.value)}
              onWppFocus={() => {
                setIsInputFocused(true);
              }}
              onWppBlur={() => setIsInputFocused(false)}>
              <WppIconCross slot="icon-end" aria-label="Clear icon" onClick={() => setLocation('')} />
            </WppInput>
            {(isInputFocused || isCardOpen) && !!location && (
              <CardWithOptions
                data-testid="card-options"
                options={options}
                onSelectValue={handleSelectedLocation}
                w="100%"
                pos="absolute"
                onMouseEnter={() => setIsCardOpen(true)}
                onMouseLeave={() => setIsCardOpen(false)}
                isLoading={promise.loading}
                renderLoading={renderLoading}
                hasError={promise.error}
                renderError={renderError}
                isEmpty={promise.empty}
                renderEmpty={renderEmpty}
              />
            )}
          </ContainerInput>
        </ContainerlocationSelection>
        <ContainerTable>
          {selectedTab === tabTargetsValue && (
            <LocationTable
              data-testid="location-table"
              data={locationsData}
              handleDelete={handleDelete}
              forceReloadDimensions={selectedTab === tabTargetsValue}
            />
          )}
        </ContainerTable>
        {isInvalidTargetsTab && isArrayEmpty(targetingLocation) && (
          <div data-testid="error-message">
            <ErrorIconMessage message={translate('txtInvalidEmptySelectionLocation')} />
          </div>
        )}
      </ContainerContent>
    </TargetsCard>
  );
};

export default TargetLocation;
