import { BrandGetDTO } from '@connected-core-system/utils/brand-http-dto';
import { ChannelTableDataType } from '../types/ChannelTableDataType';
import { IChannelPageRepository } from '../types/IChannelPageRepository';
import repoInstance from './ChannelPageRepository';
import { OrganisationUnitGetDTO } from '@connected-core-system/utils/organisation-unit-http-dto';

type Nullable<T> = T | null | undefined;

export class ChannelPageManager {
  constructor(private repo: IChannelPageRepository) {}

  async listChannels(
    organisationId: string,
    brandId: Nullable<string>,
    unitId: Nullable<string>,
  ): Promise<ChannelTableDataType[]> {
    const channels = await this.repo.listChannels(organisationId, brandId, unitId);
    const dataTable = channels.data.map((channel) => {
      return {
        id: channel.id,
        name: channel.name,
        brand: channel.brandId,
        unit: channel.unitId,
        status: channel.status,
        tags: channel.channelTags
          ? channel.channelTags.map((channelTag) => {
              return channelTag.tag;
            })
          : [],
        fallbackContentStreamId: channel.fallbackContentStreamId,
        channelTags: channel.channelTags,
        qrCodeTemplateId: channel.qrCodeTemplateId,
        channelContentStreams: channel.channelContentStreams,
        lastUpdate: new Date(channel.lastUpdate),
        updatedBy: channel.updatedBy,
        updatedAt: channel.updatedAt,
        isOrganisationShortDomain: channel.isOrganisationShortDomain,
        shortUrlId: channel.shortUrlId,
      } as ChannelTableDataType;
    });
    return dataTable;
  }

  async getBrands(organisationId: string, brandIds: string[]): Promise<BrandGetDTO[]> {
    const res = await this.repo.getBrands(organisationId, brandIds.toString());
    return res.data;
  }
  async getUnits(organisationId: string, unitIds: string[]): Promise<OrganisationUnitGetDTO[]> {
    const res = await this.repo.getUnits(organisationId, unitIds.toString());
    return res.data;
  }

  handleError(error?: unknown) {
    throw error;
  }
}

export default new ChannelPageManager(repoInstance);
