import styled from '@emotion/styled';
import { WppAutocomplete, WppCard, WppTypography, WppSideModal, WppButton } from '@wppopen/components-library-react';
import { BoxContent } from '../../../../shared/components';
import { TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import { percentOfAppHeight } from '../../../../shared/utils';

export const ContainerCard = styled(WppCard)({
  '&::part(card)': {
    overflow: 'visible',
  },
});

export const Container = styled(BoxContent)({
  overflowY: 'visible',
  maxHeight: `calc(${percentOfAppHeight(100)} - 20rem)`,
});

export const FormContainer = styled.div({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  columnGap: 32,
  rowGap: 32,
  boxSizing: 'border-box',
});

export const FormHeader = styled(BoxContent)({
  boxShadow: '0px 1px 4px rgba(52, 58, 63, 0.12)',
});

export const StyledMultiSelect = styled(WppAutocomplete)({
  '&::part(dropdown)': {
    minHeight: 60,
    position: 'relative',
    zIndex: 3,
  },
});

export const ContaienrCampaigns = styled(BoxContent)({
  boxShadow: '0px 0px 1px rgba(52, 58, 63, 0.1), 0px 1px 5px rgba(52, 58, 63, 0.1)',
  borderRadius: 8,
});

export const ConditionalContentContainer = styled(BoxContent)({
  overflow: 'visible',
});

export const ContentContainer = styled(BoxContent)({
  overflow: 'auto',
});

export const MenuSection = styled(BoxContent)(({ expanded }: { expanded: boolean }) => ({
  transition: 'all 0.1s',
  width: expanded ? '26rem' : '6rem',
  minWidth: '6.4rem',
  maxWidth: expanded ? '26rem' : '6.4rem',
  padding: expanded ? '0 1.6rem' : '0',
}));

export const CardChannels = styled(WppCard)({
  marginTop: 16,
});

export const ChannelsHeader = styled(BoxContent)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});

export const ChannelsTitleText = styled(WppTypography)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey1000'],
  width: '40%',
}));

export const ChannelsSideModal = styled(WppSideModal)({
  '&::part(card)': {
    width: '60vw',
  },
});

export const PublishButton = styled(WppButton)({
  paddingRight: 12,
});

export const StyledBoxContent = styled(BoxContent)({
  width: 'fit-content',
  whiteSpace: 'nowrap',
});
