/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { IconContainer, ItemContainerBare, ItemContainerOs, CustomText } from './styles/MenuItem.styled';
import { MenuItemEnum } from '../../../shared/types';
import { ConditionalTooltip } from '../../../shared/components';
import PlatformWrapper from '../../../shared/components/PlatformWrapper/PlatformWrapper';

export interface IMenuItemProps extends React.PropsWithChildren {
  id: MenuItemEnum;
  value: string;
  icon?: React.ReactNode;
  secondIcon?: React.ReactNode;
  onlyIcon?: boolean;
  active?: boolean;
  disabled?: boolean;
  onSelect?: (selectedMenuItem: MenuItemEnum) => void;
}

const MenuItem: React.FC<IMenuItemProps> = ({
  id,
  icon,
  secondIcon,
  onlyIcon = false,
  value,
  onSelect,
  active = false,
  disabled = false,
}) => {
  const renderOnlyIcon = () => {
    return (
      <ConditionalTooltip showTooltip={!disabled} tooltipText={value} config={{ placement: 'left' }}>
        <IconContainer onClick={() => onSelect?.(id)} data-testid={`icon-${id}`}>
          <PlatformWrapper
            componentBare={ItemContainerBare}
            componentOs={ItemContainerOs}
            onlyIcon
            pad
            checked={active}
            disabled={disabled}>
            {icon}
          </PlatformWrapper>
        </IconContainer>
      </ConditionalTooltip>
    );
  };

  const renderContent = () => {
    return (
      <PlatformWrapper
        componentBare={ItemContainerBare}
        componentOs={ItemContainerOs}
        onClick={() => onSelect?.(id)}
        checked={active}
        disabled={disabled}
        data-testid={`icon-${id}`}>
        <CustomText slot="label">{value}</CustomText>
        {icon}
        {secondIcon}
      </PlatformWrapper>
    );
  };

  return onlyIcon ? renderOnlyIcon() : renderContent();
};

export default MenuItem;
