import styled from '@emotion/styled';

export const TableContainer = styled.div(
  ({ fixedHight = '', backgroundColor = '' }: { fixedHight?: string | number; backgroundColor?: string }) => {
    return {
      '--ag-background-color': backgroundColor ? backgroundColor : '#FFF',
      '--ag-header-background-color': backgroundColor ? backgroundColor : '#FFF',
      '--ag-odd-row-background-color': backgroundColor ? backgroundColor : '#FFF',
      '--ag-icon-font-family': 'agGridAlpine',
      '--ag-icon-size': '24px',
      height: fixedHight ? fixedHight : '',
      margin: 0,
      padding: 0,
    };
  },
);
