export enum MenuItemEnum {
  BRANDS = 'Brands',
  CHANNELS = 'Channels',
  QRSTYLES = 'QRStyles',
  CMS = 'CMS',
  CONTENT_STREAM = 'ContentStream',
  UNIT = 'Units',
  USERS = 'Users',
  ROLES = 'Roles',
  ANALYTICS = 'Analytics',
}
