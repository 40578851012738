import styled from '@emotion/styled';
import { BoxContent } from '../../../../shared/components';
import { percentOfAppHeight } from '../../../../shared/utils';

export const Container = styled(BoxContent)({
  overflowY: 'auto',
  maxHeight: `calc(${percentOfAppHeight(100)} - 20rem)`,
});

export const FormHeader = styled(BoxContent)({
  boxShadow: '0px 1px 4px rgba(52, 58, 63, 0.12)',
});

export const MainContentTableContainer = styled.div({
  width: '100%',
  height: 'fit-content',
  gridColumnStart: 1,
  gridColumnEnd: 3,
  columnGap: '1.6rem',
  gridTemplateColumns: '3fr',
});

export const StyledBoxContent = styled(BoxContent)({
  width: 'fit-content',
  whiteSpace: 'nowrap',
});
