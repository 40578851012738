import { TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { WppIconRemoveCircle } from '@wppopen/components-library-react';

export const RemoveIconBare = styled(WppIconRemoveCircle)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['primary500'],
  alignItems: 'center',
}));

export const RemoveIconOs = styled(WppIconRemoveCircle)(({ theme }: TStyledDefaultProps) => ({
  alignItems: 'center',
}));

export const DeleteButton = styled.div(({ theme }: TStyledDefaultProps) => ({
  paddingTop: 8,
}));
