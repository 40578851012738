import styled from '@emotion/styled';

export const WppStyledContainer = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
});

export const StyledIFrame = styled.iframe({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  border: 'none',
});
