export const replaceURL = (newUrl: string) => {
  window.location.replace(newUrl);
};

export const getWindowHref = () => {
  return window.location.href;
};

export const copyTextToClipboard = async (text: string | undefined) => {
  if ('clipboard' in navigator && text) {
    return navigator.clipboard.writeText(text);
  }
  return Promise.reject('Clipboard API not supported');
};
