import styled from '@emotion/styled';
import { WppCard } from '@wppopen/components-library-react';

export const WppStyledCard = styled(WppCard)({
  '.ag-cell-wrapper': {
    padding: 0,
    margin: 0,
    width: '100%',
    height: '100%',
  },
});
