import React from 'react';
import ReactDOMClient from 'react-dom/client';

import singleSpaReact from 'single-spa-react';
import Main from './main';
import ErrorBoundary from './ErrorBoundary';

const reactLifeCycles = singleSpaReact({
  React,
  ReactDOMClient,
  rootComponent: Main,
  errorBoundary: () => <ErrorBoundary />,
});

export const { bootstrap, mount, unmount } = reactLifeCycles;
