import { IOrganisationUnit, ITagCategory } from '../../shared/types';
import { TTagCategoryFormField, TOption } from './types/TagCategoryFormField';
import { TagGetDTO } from '@conn-exp/tag-category-http-dto-utils';

export const transformIntoOptions = (units: (IOrganisationUnit & { id: string })[]) => {
  return units.map((u) => ({
    value: u.id,
    label: u.name,
  }));
};

export const groupTagByCategory = (tags: ITagCategory[]) => {
  const grouped = tags.reduce((current: any, next) => {
    if (next.categoryName) {
      if (current[next.categoryName]) {
        current[next.categoryName].push(next);
      } else {
        current[next.categoryName] = [next];
      }
    }
    return current;
  }, {});
  return grouped;
};

export const mountNewCategoryFormField = (value = '', label = '', tags: TagGetDTO[] = [], iteration = 0) => {
  const newField = {
    id: `id-${new Date().getTime().toString() + iteration}`,
    value: value,
    label: label,
    tags: tags ? tags.map((t: ITagCategory) => ({ label: t.name, value: t.id })) : [],
  };
  return newField;
};

export const mapCategoriesToFields = (channelTags: ITagCategory[]) => {
  // channelTags => [ { } ]
  const categoriesGrouped = groupTagByCategory(channelTags);
  const fields = Object.keys(categoriesGrouped).map((k) => {
    const catValue = (categoriesGrouped[k][0] as unknown as ITagCategory).categoryId;
    const catLabel = (categoriesGrouped[k][0] as unknown as ITagCategory).categoryName;
    return mountNewCategoryFormField(catValue, catLabel, categoriesGrouped[k]);
  });
  return fields;
};

export const getCategoryOptions = (tagCategories: ITagCategory[]): TOption[] => {
  const distinctIds: string[] = [];
  const options: TOption[] = [];
  for (let i = 0; i < tagCategories.length; i++) {
    if (!distinctIds.includes(tagCategories[i].categoryId)) {
      distinctIds.push(tagCategories[i].categoryId);
      options.push({
        label: tagCategories[i].categoryName || '',
        value: tagCategories[i].categoryId,
      });
    }
  }
  return options;
};

export const getTagOptions = (tagCategories: ITagCategory[], selectedCategoryId: string): TOption[] => {
  const tags = selectedCategoryId
    ? tagCategories
        .filter((category) => category.categoryId === selectedCategoryId)
        .map((category) => {
          return {
            label: category.name,
            value: category.id,
          };
        })
    : [];
  return tags;
};

export const updateFields = (
  fieldId: string,
  type: 'cat' | 'tag',
  fields: TTagCategoryFormField[],
  newValue: TOption | TOption[],
) => {
  const fieldUpdate = fields.find((f) => f.id === fieldId);
  if (fieldUpdate) {
    if (type === 'tag' && Array.isArray(newValue)) {
      fieldUpdate.tags = newValue;
    }

    if (type === 'cat' && !Array.isArray(newValue)) {
      fieldUpdate.tags = [];
      fieldUpdate.value = newValue.value;
      fieldUpdate.label = newValue.label;
    }
  }
  return fields;
};

export const getValue = (selectedDropdownId: string | undefined, channelSelectedId: string) => {
  if (selectedDropdownId) {
    return selectedDropdownId;
  }
  return channelSelectedId;
};
