/* eslint-disable @typescript-eslint/no-empty-interface */
import { BoxContentCenter, TextHighlight } from '../../../shared/components';
import React from 'react';
import { ContentStreamTableDataType } from '../types/ContentStreamTableDataType';

export interface IContentStreamCampaignNameCellProps {
  campaignName: ContentStreamTableDataType['campaignName'];
  searchTerm: string;
}

const ContentStreamCampaignNameCell: React.FC<IContentStreamCampaignNameCellProps> = ({ campaignName, searchTerm }) => {
  return (
    <BoxContentCenter justify="flex-start">
      <TextHighlight fullString={campaignName} subString={searchTerm} />
    </BoxContentCenter>
  );
};

export default ContentStreamCampaignNameCell;
