import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import {
  IContentStreamCreateEditActions,
  IContentStreamCreateEditState,
} from '../sliceStore/contentStreamCreateEditSliceStore';

export const useIsContentStreamModalOpenedState = () => {
  const isContentStreamModalOpened = useSliceState<IContentStreamCreateEditState, 'isContentStreamModalOpened'>(
    'isContentStreamModalOpened',
  );
  const setIsContentStreamModalOpened = useSliceSetter<
    IContentStreamCreateEditActions,
    'setIsContentStreamModalOpened'
  >('setIsContentStreamModalOpened');

  return { isContentStreamModalOpened, setIsContentStreamModalOpened };
};
