import { format as fnsFormat, intervalToDuration, getDate } from 'date-fns';
import { getSelectedLanguage, EnumLanguages, translate } from '../locales';
import { enUS, ptBR } from 'date-fns/locale';
import { interpolateWithValues } from './stringUtils';

export enum DateFormatsEnum {
  'MM/dd/yyyy' = 'MM/dd/yyyy',
  'hh:mm b' = 'hh:mm b',
  'LLL dd yyyy' = 'LLL dd yyyy',
}

type FormatOptions = {
  format?: DateFormatsEnum;
  withLocale?: boolean;
  withRegion?: boolean;
};

const DEFAULT_OPTIONS: FormatOptions = {
  format: DateFormatsEnum['MM/dd/yyyy'],
  withLocale: true,
  withRegion: false,
};
export const formatDate = (date: Date, options?: FormatOptions) => {
  const locale = getSelectedLanguage() as keyof typeof i18nLocalToDateLocale;
  const formatApply = options?.format || (DEFAULT_OPTIONS.format as string);
  const formated = fnsFormat(date, formatApply, { locale: i18nLocalToDateLocale[locale] });
  if (options?.withRegion) {
    return applyRegion(formated, locale);
  }
  return formated;
};

export const formatTime = (date: Date, options?: FormatOptions) => {
  const formatApply = options?.format || (DateFormatsEnum['hh:mm b'] as string);
  const locale = getSelectedLanguage() as keyof typeof i18nLocalToDateLocale;
  const formated = fnsFormat(date, formatApply);
  if (options?.withRegion) {
    return applyRegion(formated, locale);
  }
  return formated;
};

export const applyRegion = (formatedDate: string, locale: EnumLanguages) => {
  return `${formatedDate} ${i18nLocaleToRetion[locale]}`;
};

export const i18nLocaleToRetion = {
  [EnumLanguages.EN_US]: 'EST',
  [EnumLanguages.PT_BR]: 'EST',
};

const i18nLocalToDateLocale = {
  [EnumLanguages.EN_US]: enUS,
};

export const formatTimeAgo = (date: string | Date, dateNow = new Date()) => {
  const timeAgoCommonTexts = {
    justNow: translate('txtJustNow'),
    yesterday: translate('txtYesterday'),
    mAgo: `{minutes}m ${translate('txtAgo')}`,
    hAgo: `{hours}h ${translate('txtAgo')}`,
    hMAgo: `{hours}h{minutes}m ${translate('txtAgo')}`,
  };

  const timeAgoTextsi18n = {
    [EnumLanguages.EN_US]: {
      ...timeAgoCommonTexts,
      localeFormat: 'PP hh:mm a',
      locale: enUS,
    },
    [EnumLanguages.PT_BR]: {
      ...timeAgoCommonTexts,
      localeFormat: 'PP hh:mm',
      locale: ptBR,
    },
  };

  const language = getSelectedLanguage() as keyof typeof timeAgoTextsi18n;
  const newDate = date instanceof Date ? date : new Date(date);
  const ONE_MIN_IN_SEC = 60;
  const ONE_HOUR_IN_SEC = 60 * 60;
  const ONE_DAY_IN_SEC = 60 * 60 * 24;

  const dateNowInSec = dateNow.getTime();
  const dateInSec = newDate.getTime();

  const diffTimes = dateNowInSec / 1000 - dateInSec / 1000;

  if (diffTimes < ONE_MIN_IN_SEC) {
    return timeAgoTextsi18n[language].justNow;
  }

  if (diffTimes < ONE_HOUR_IN_SEC) {
    const { minutes } = intervalToDuration({ start: dateInSec, end: dateNowInSec });
    const value = interpolateWithValues(timeAgoTextsi18n[language].mAgo, String(minutes ?? ''));
    return value;
  }

  if (diffTimes < 24 * ONE_HOUR_IN_SEC) {
    const { hours, minutes } = intervalToDuration({ start: dateInSec, end: dateNowInSec });
    let value = '';
    if (minutes && minutes > 0) {
      value = interpolateWithValues(timeAgoTextsi18n[language].hMAgo, String(hours ?? ''), String(minutes ?? ''));
    } else {
      value = interpolateWithValues(timeAgoTextsi18n[language].hAgo, String(minutes ?? ''));
    }

    return value;
  }

  if (diffTimes < 2 * ONE_DAY_IN_SEC) {
    return timeAgoTextsi18n[language].yesterday;
  }

  return fnsFormat(newDate, timeAgoTextsi18n[language].localeFormat, { locale: timeAgoTextsi18n[language].locale });
};
