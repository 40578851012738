/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect } from 'react';
import { WppSpinner } from '@wppopen/components-library-react';
import SideMenu from '../../SideMenu/views/SideMenu';
import { Container, FeedBackContainer } from './HomePage.styled';
import { useSliceState, useSliceSetter } from '../../../shared/hooks/useStore';
import { ISideMenuState, ISideMenuActions, useSelectedOrganisation } from '../../SideMenu';
import ToastMessage from '../../Feedback/views/ToastMessage';
import { Outlet, useNavigate } from 'react-router-dom';
import { BoxContentCenter } from '../../../shared/components';
import { useIsAddTargetButtonOpenedState } from '../../ContentStreamCreate/hooks/useIsAddTargetButtonOpenedState';
import ContentStreamFormModal from '../../ContentStreamCreate/views/ContentStreamFormModal';
import ChannelFormModal from '../../ChannelCreateEdit/views/ChannelFormModal';
import useLoadStatesAfterReload from '../hooks/useLoadStatesAfterReload';
import { MenuItemEnum } from '../../../shared/types';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';

export interface IHomePageProps {
  showSideMenu?: boolean;
  isAuthenticated?: boolean;
  errorAuth?: boolean;
}

const HomePage: React.FC<IHomePageProps> = ({ showSideMenu = true, isAuthenticated, errorAuth }) => {
  const setSideMenu = useSliceSetter<ISideMenuActions, 'switchSideMenu'>('switchSideMenu');
  const isSmallScreen = useSliceState<ISideMenuState, 'isSmallScreen'>('isSmallScreen');
  const { setIsAddTargetButtonOpened } = useIsAddTargetButtonOpenedState();
  const { selectedSideMenuItem, setSelectedSideMenuItem } = useSelectedSideMenuItemState();
  const { selectedOrganisation } = useSelectedOrganisation();

  const navigate = useNavigate();

  useEffect(() => {
    if (selectedOrganisation && !selectedSideMenuItem) {
      setSelectedSideMenuItem(MenuItemEnum.UNIT);
      navigate(`${selectedOrganisation.id}/${MenuItemEnum.UNIT}`);
    }
  }, [setSelectedSideMenuItem, selectedOrganisation, selectedSideMenuItem, navigate]);

  const { isOrganisationFilled } = useLoadStatesAfterReload();

  const handleClickContainer = () => {
    if (isSmallScreen) {
      setSideMenu(false);
    }
    setIsAddTargetButtonOpened(false);
  };

  if (!isAuthenticated || errorAuth || !isOrganisationFilled) {
    return (
      <FeedBackContainer>
        <BoxContentCenter bg="grey100">
          <WppSpinner size="m" />
        </BoxContentCenter>
      </FeedBackContainer>
    );
  }
  return (
    <Container onClick={handleClickContainer} showSideMenu={showSideMenu}>
      {showSideMenu && <SideMenu />}
      <Outlet />
      <ContentStreamFormModal />
      <ChannelFormModal />
      <ToastMessage />
    </Container>
  );
};

export default HomePage;
