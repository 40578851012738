/* eslint-disable @typescript-eslint/no-empty-interface */
import { WppSideModal } from '@wppopen/components-library-react';
import { compareArrays } from '../../../shared/utils';
import { useState } from 'react';
import { translate } from '../../../shared/locales';
import { ContentsTableDataType } from '../types/ContentsTableDataType';
import ContentsTable from './ContentsTable/ContentsTable';
import { DrawerActionButtons } from '../../../shared/components';
import { TitleText } from './styles/Targets.styled';
import { sortDataArrayByOrder } from '../utils/contentStreamContentUtils';

export interface ISelectedContentsDrawerProps {
  selectedContent: Partial<ContentsTableDataType>[];
  setSelectedContent: (selectedContent: Partial<ContentsTableDataType>[]) => void;
  isSideModalOpen: boolean;
  setIsSideModalOpen: (isSideModalOpen: boolean) => void;
}

const SelectedContentsDrawer: React.FC<ISelectedContentsDrawerProps> = ({
  selectedContent,
  setSelectedContent,
  isSideModalOpen,
  setIsSideModalOpen,
}) => {
  const [selectedContentState, setSelectedContentState] = useState<Partial<ContentsTableDataType>[]>(selectedContent);

  const handleSaveContentModal = () => {
    const selectedState = [...selectedContentState];
    const contentsThatAreStillSelected = selectedContent.filter((item1) =>
      selectedState.some((item2) => item1.id === item2.id),
    );
    const contentsToBeAdded = selectedState.filter((item1) => !selectedContent.some((item2) => item1.id === item2.id));
    for (let idx = 0; idx < contentsThatAreStillSelected.length; idx++) {
      contentsThatAreStillSelected[idx].order = (idx + 1).toString();
    }
    contentsToBeAdded.forEach((addContent) => {
      for (let idx = 0; idx < contentsThatAreStillSelected.length; idx++) {
        contentsThatAreStillSelected[idx].order = (Number(contentsThatAreStillSelected[idx].order ?? 0) + 1).toString();
      }

      contentsThatAreStillSelected.unshift({ ...addContent, order: '1' });
    });
    sortDataArrayByOrder(contentsThatAreStillSelected);
    setSelectedContent([...contentsThatAreStillSelected]);
    setIsSideModalOpen(false);
  };
  return (
    <WppSideModal
      data-testid="content-modal"
      open={isSideModalOpen}
      disableOutsideClick
      onWppSideModalClose={() => setIsSideModalOpen(false)}
      size="l">
      <TitleText type="xl-heading" slot="header" data-testid={'title-contents-modal'}>
        {translate('tabContent')}
      </TitleText>
      <div slot="body">
        <ContentsTable
          dataType={'contents'}
          data-testid={'content-table'}
          columnsToExclude={['deleteContent', 'deleteStory', 'order', 'cmsId', 'collectionType', 'status']}
          handleOnSelectRows={setSelectedContentState}
          listSelectedRowId={selectedContentState.map((story) => story.id || '')}
          selectionType={'multiple'}
        />
      </div>
      <DrawerActionButtons
        variant="primary"
        data-testid="save-button"
        size="s"
        onClick={() => handleSaveContentModal()}
        disabled={compareArrays(
          selectedContentState?.map((contentState) => contentState.id),
          selectedContent?.map((content) => content.id),
        )}
        buttonText={translate('btnSaveSomething', { something: translate('txtSelection') })}
      />
    </WppSideModal>
  );
};

export default SelectedContentsDrawer;
