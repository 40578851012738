import React from 'react';
import { WppIconCross, WppIconSearch, WppInput, WppButton } from '@wppopen/components-library-react';
import { BoxContent } from '../../../shared/components';
import { translate } from '../../../shared/locales/i18n';
import { useSelectedContentStreamState } from '../../ContentStreamCreate/hooks/useSelectedContentStreamState';
import { useIsContentStreamModalOpenedState } from '../../ContentStreamCreate/hooks/useIsContentStreamModalOpenedState';
import { tableFallbackDrawer, TableLocation } from '../contentStreamUtils';
import { useIsFallbackContentStreamState } from '../../ContentStreamCreate/hooks/useIsFallbackContentStreamState';
import { HeaderButtonsContainer } from './styles/ContentStreamPage.styled';

export interface IContentStreamHeaderProps {
  onSearch: (text: string) => void;
  searchTerm: string;
  location: TableLocation;
}

const ContentStreamHeader: React.FC<IContentStreamHeaderProps> = ({ searchTerm, onSearch, location }) => {
  const { setSelectedContentStream } = useSelectedContentStreamState();
  const { setIsContentStreamModalOpened } = useIsContentStreamModalOpenedState();
  const { setIsFallbackContentStream } = useIsFallbackContentStreamState();

  const setItemAndNavigate = (isFallback: boolean) => {
    setSelectedContentStream(null);
    setIsFallbackContentStream(isFallback);
    setIsContentStreamModalOpened(true);
  };

  const renderLeft = () => {
    return (
      <BoxContent flex marg="0 0 36 0">
        <BoxContent w={location === tableFallbackDrawer ? '100%' : 'auto'}>
          <WppInput
            data-testid="search-content"
            name="search-content"
            placeholder={translate('txtPlaceholderSearch')}
            value={searchTerm}
            onWppChange={(e: CustomEvent) => onSearch(e.detail.value)}>
            <WppIconSearch slot="icon-start" aria-label="Search icon" />
            <WppIconCross slot="icon-end" aria-label="Clear icon" onClick={() => onSearch('')} />
          </WppInput>
        </BoxContent>
      </BoxContent>
    );
  };

  const renderRight = () => {
    return location === tableFallbackDrawer ? null : (
      <HeaderButtonsContainer>
        <WppButton variant="secondary" onClick={() => setItemAndNavigate(true)}>
          {translate('txtCreateFallback')}
        </WppButton>
        <WppButton onClick={() => setItemAndNavigate(false)}>{translate('txtCreateContentStream')}</WppButton>
      </HeaderButtonsContainer>
    );
  };

  return (
    <BoxContent flex align="center">
      <>{renderLeft()}</>
      <>{renderRight()}</>
    </BoxContent>
  );
};

export default ContentStreamHeader;
