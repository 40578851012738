/* eslint-disable @typescript-eslint/no-empty-interface */
import { BoxContentCenter, TableDateHourField } from '../../../../shared/components';
import React from 'react';
import { ContentsTableDataType } from '../../types/ContentsTableDataType';
import { formatLastModifiedDate } from '../../utils/contentStreamContentUtils';

export interface ILastModifiedCellProps {
  updatedAt: ContentsTableDataType['updatedAt'];
}

const LastModifiedCell: React.FC<ILastModifiedCellProps> = ({ updatedAt }) => {
  const { date, time } = formatLastModifiedDate(updatedAt);
  return (
    <BoxContentCenter justify="flex-start">
      <div>
        <TableDateHourField date={date} time={time} />
      </div>
    </BoxContentCenter>
  );
};

export default LastModifiedCell;
