import { ITheme } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { WppTypography } from '@wppopen/components-library-react';

export const HighlightedText = styled(WppTypography)((props: { theme?: ITheme }) => ({
  '&::part(typography)': {
    backgroundColor: props.theme?.palette['highlight200'],
    fontWeight: 'bold',
    color: props.theme?.palette['grey1000'],
  },
}));
