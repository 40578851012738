/* eslint-disable @typescript-eslint/no-empty-interface */

import React from 'react';
import { ContentStreamTableDataType } from '../types/ContentStreamTableDataType';
import { formatLastUpdatedDate } from '../contentStreamUtils';
import { BoxContentCenter, TableDateHourField } from '../../../shared/components';

export interface IContentStreamLastUpdateCellProps {
  lastUpdate: ContentStreamTableDataType['lastUpdate'];
}

const ContentStreamLastUpdateCell: React.FC<IContentStreamLastUpdateCellProps> = ({ lastUpdate }) => {
  const { date, time } = formatLastUpdatedDate(lastUpdate);
  return (
    <BoxContentCenter justify="flex-start">
      <div>
        <TableDateHourField date={date} time={time} />
      </div>
    </BoxContentCenter>
  );
};

export default ContentStreamLastUpdateCell;
