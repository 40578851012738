import { HttpService } from '@core/http-service';
import { GetLocationDTO } from '@connected-core-system/utils/bff-http-dto';
import { ChannelDTO } from '@connected-core-system/utils/channel-http-dto';
import { ContentTemplateGetDTO } from '@connected-core-system/utils/content-http-dto';
import { ContentStreamGetDTO, ContentStreamPostDTO } from '@connected-core-system/utils/content-stream-http-dto';
import { BFF_ENDPOINTS, HttpResolver } from '../../../shared/api';
import { IContentStreamCampaign, StatusEnum } from '../../../shared/types';
import { interpolateWithValues } from '../../../shared/utils';
import { IContentStreamCreateRepository } from '../types/IContentStreamCreateRepository';
export class ContentStreamCreateRepository implements IContentStreamCreateRepository {
  constructor(private readonly httpService: HttpService) {}

  postContentStream(organisationId: string, content: ContentStreamPostDTO) {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_CONTENT_STREAM, organisationId);
    return this.httpService.post<ContentStreamGetDTO>(url, content);
  }

  fetchOrganisationCampaigns(organisationId: string) {
    const url = interpolateWithValues(BFF_ENDPOINTS.CAMPAIGNS, organisationId);
    return this.httpService.get<IContentStreamCampaign[]>(url);
  }

  fetchLocations(search: string) {
    const url = interpolateWithValues(BFF_ENDPOINTS.LOCATIONS, search);
    return this.httpService.get<GetLocationDTO[]>(url);
  }

  listStory(organisationId: string, linkedStoryId: string) {
    const linkedStoryIdsQueryParameter = `?ids=${linkedStoryId}`;
    const collectionTypeInclude = '&collectionTypeInclude=Story';
    const status = `&status=${StatusEnum.ACTIVE}`;

    const url =
      interpolateWithValues(BFF_ENDPOINTS.CONTENT, organisationId) +
      linkedStoryIdsQueryParameter +
      collectionTypeInclude +
      status;
    return this.httpService.get<ContentTemplateGetDTO[]>(url);
  }

  listContents(organisationId: string, linkedContentsIds: string[]) {
    const linkedContentsIdsQueryParameter = `?ids=${linkedContentsIds}`;
    const collectionTypeExclude = '&collectionTypeExclude=Story';
    const status = `&status=${StatusEnum.ACTIVE}`;

    const url =
      interpolateWithValues(BFF_ENDPOINTS.CONTENT, organisationId) +
      linkedContentsIdsQueryParameter +
      collectionTypeExclude +
      status;

    return this.httpService.get<ContentTemplateGetDTO[]>(url);
  }

  listChannelsFromIds(organisaitonId: string, channelIds: string[]) {
    const url = interpolateWithValues(BFF_ENDPOINTS.CHANNEL, organisaitonId);
    return this.httpService.get<ChannelDTO[]>(`${url}?ids=${channelIds}`);
  }

  updateContentStream(contentStreamId: string, organisationId: string, content: ContentStreamPostDTO) {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_CONTENT_STREAM, organisationId);
    return this.httpService.put<ContentStreamGetDTO>(`${url}/${contentStreamId}`, content);
  }

  listContentById(organisationId: string, contentIdQueryString: string) {
    const url = interpolateWithValues(BFF_ENDPOINTS.CONTENT_BY_ID, organisationId, contentIdQueryString);
    return this.httpService.get<ContentTemplateGetDTO[]>(url);
  }
}

export default new ContentStreamCreateRepository(HttpResolver.getHttpService());
