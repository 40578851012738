/* eslint-disable @typescript-eslint/no-empty-interface */
import { WppActionButton, WppIconError } from '@wppopen/components-library-react';
import { translate } from '../../../shared/locales';
import { ContentsTableDataType } from '../types/ContentsTableDataType';
import {
  IconDown,
  IconUp,
  Margin,
  NavigateText,
  Navigation,
  PreviewCard,
  NavigationCard,
  WppStyledTypography,
  WppStyledContainer,
} from './styles/Content.styled';
import { StatusEnum } from '@connected-core-system/utils/types';
import { useHostName } from '../../../shared/hooks';
import { IOrganisation } from '../../../shared/types';
import { isArrayEmpty } from '../../../shared/utils';
import IframeComponent from '../../../shared/components/Iframe/IframeComponent';
import { getContentPreviewUrl } from '../utils/contentStreamContentUtils';

export interface IContentPreviewProps {
  selectedContents: Partial<ContentsTableDataType>[] | null;
  selectedContentToPreview: Partial<ContentsTableDataType> | null;
  setSelectedContentToPreview: (selectedContent: Partial<ContentsTableDataType>) => void;
  selectedOrganisation?: IOrganisation | null;
}
const ContentPreview: React.FC<IContentPreviewProps> = ({
  selectedContents,
  selectedContentToPreview,
  setSelectedContentToPreview,
  selectedOrganisation,
}) => {
  const baseHostName = useHostName();

  const isArrowDownDisabled = () => {
    return (
      !selectedContents ||
      selectedContents.length <= 1 ||
      !selectedContentToPreview ||
      selectedContents.indexOf(selectedContentToPreview) === selectedContents.length - 1
    );
  };

  const isArrowUpDisabled = () => {
    return (
      !selectedContents ||
      selectedContents.length <= 1 ||
      !selectedContentToPreview ||
      selectedContents.indexOf(selectedContentToPreview) === 0
    );
  };

  const handleArrowUpButtonClick = () => {
    if (selectedContents && selectedContentToPreview) {
      const previousContent = selectedContents[selectedContents.indexOf(selectedContentToPreview) - 1];
      setSelectedContentToPreview(previousContent);
    }
  };

  const handleArrowDownButtonClick = () => {
    if (selectedContents && selectedContentToPreview) {
      const nextContent = selectedContents[selectedContents.indexOf(selectedContentToPreview) + 1];
      setSelectedContentToPreview(nextContent);
    }
  };

  const renderNotAllowedToPreview = () => {
    return (
      <WppStyledContainer>
        <WppIconError />
        <WppStyledTypography type="xs-strong">{translate('txtCantLoadContent')}</WppStyledTypography>
      </WppStyledContainer>
    );
  };

  const renderIFrame = () => {
    if (isArrayEmpty(selectedContents)) return <div data-testid="empty-div"></div>;
    if (!selectedOrganisation?.domain || !selectedContentToPreview?.cmsId) {
      return renderNotAllowedToPreview();
    }
    return (
      <IframeComponent
        title={'content-preview'}
        src={getContentPreviewUrl(
          baseHostName,
          selectedOrganisation?.domain as string,
          selectedOrganisation?.id as string,
          selectedContentToPreview?.cmsId,
        )}
      />
    );
  };

  return (
    <>
      <PreviewCard>
        {selectedContentToPreview?.status === StatusEnum.ARCHIVED ||
        selectedContentToPreview?.status === StatusEnum.UNPUBLISHED
          ? renderNotAllowedToPreview()
          : renderIFrame()}
      </PreviewCard>
      <Margin>
        <NavigationCard>
          <Navigation>
            <WppActionButton
              disabled={isArrowDownDisabled()}
              onClick={handleArrowDownButtonClick}
              data-testid="arrow-down">
              <IconDown slot="icon-start" />
            </WppActionButton>
            <NavigateText>{translate('txtContentNavigation')}</NavigateText>
            <WppActionButton disabled={isArrowUpDisabled()} onClick={handleArrowUpButtonClick} data-testid="arrow-up">
              <IconUp slot="icon-end" />
            </WppActionButton>
          </Navigation>
        </NavigationCard>
      </Margin>
    </>
  );
};

export default ContentPreview;
