/* eslint-disable @typescript-eslint/no-empty-interface */
import { Table } from '../../../../shared/components';
import { generateScanString, getColumnsScanHistoryTable } from '../../utils/contentStreamCreateUtils';
import { TargetingScanHistoryDTO } from '@connected-core-system/utils/content-stream-http-dto';
import { ScanHistoryTableDataType } from '../../types/ScanHistoryTableDataType';

import ScanHistoryDeleteCell from './ScanHistoryDeleteCell';
import ScanHistoryCell from './ScanHistoryCell';

export interface IScanHistoryTableProps {
  data: TargetingScanHistoryDTO[];
  onDelete: () => void;
  forceReloadDimensions?: boolean;
}

const ScanHistoryTable: React.FC<IScanHistoryTableProps> = ({ data, onDelete, forceReloadDimensions = false }) => {
  const columnsToExclude: Array<keyof ScanHistoryTableDataType> = [];
  const scanHistoryColumnDefinitions = getColumnsScanHistoryTable(columnsToExclude);

  scanHistoryColumnDefinitions['level'].cellRenderer = ({ data }: { data: ScanHistoryTableDataType }) => (
    <ScanHistoryCell value={data.level} />
  );

  if (scanHistoryColumnDefinitions['operator'])
    scanHistoryColumnDefinitions['operator'].cellRenderer = ({ data }: { data: ScanHistoryTableDataType }) => (
      <ScanHistoryCell value={data.operator} />
    );

  scanHistoryColumnDefinitions['numberOfScans'].cellRenderer = ({ data }: { data: TargetingScanHistoryDTO }) => (
    <ScanHistoryCell value={generateScanString(data.numberOfScans)} />
  );

  scanHistoryColumnDefinitions['isDeleted'].cellRenderer = () => (
    <ScanHistoryDeleteCell data-testid="delete-cell" handleDelete={onDelete} />
  );

  return (
    <Table
      displayData={data}
      columnDefs={Object.values(scanHistoryColumnDefinitions)}
      rowHeight={48}
      hasHeader
      backgroundColor={'#f8f9fb'}
      delayUseDimensions={forceReloadDimensions ? 0 : 100}
    />
  );
};

export default ScanHistoryTable;
