import { ITheme } from '@connected-core-system/utils/frontend';

export const theme: Partial<ITheme> = {
  palette: {
    light: '#fff',
    dark: '#000',
    primary: '',
    secondary: '',
    description: '',
    primaryButtonBackground: '',
    primaryButtonText: '',
    secondaryButtonBackground: '',
    secondaryButtonText: '',
    transparent: 'transparent',
    textColor: '',
    submitButtonBackground: '',
    submitButtonText: '',
    errorMessageColor: '',
    submitButtonTextColor: '',
    backgroundTopGradient: '',
    backgroundMiddleGradient: '',
    backgroundBottomGradient: '',

    grey000: '#fff',
    grey100: '#f8f9fb',
    grey200: '#F0F2F5',
    grey300: '#e7eaee',
    grey400: '#c1c7cd',
    grey500: '#a2a9b0',
    grey600: '#8b919a',
    grey700: '#697077',
    grey800: '#4d5358',
    grey900: '#343a3f',
    grey1000: '#121619',

    primary100: '#eee8ff',
    primary200: '#e2d9ff',
    primary300: '#cab8ff',
    primary400: '#8508e8',
    primary500: '#5e00b5',
    primary600: '#47039b',
    primary700: '#2f0069',
    primary800: '#0b001c',

    danger200: '#fbe4e4',
    danger300: '#ffafa3',
    danger400: '#e81c23',
    danger500: '#c40007',
    danger600: '#94000f',

    warning200: '#f4f5f7',
    warning400: '#e07400',
    warning500: '#b35c00',

    highlight200: '#fff4b4',
    highlight400: '#f5db58',

    success200: '#e0faea',
    success400: '#209c59',
    success500: '#1b7545',

    datavizCatDark1: '#8508E8',
    datavizCatDark2: '#934074',
    datavizCatDark3: '#B91C1C',
    datavizCatDark4: '#C2410C',
    datavizCatDark5: '#A16207',
    datavizCatDark6: '#4D7C0F',
    datavizCatDark7: '#15803D',
    datavizCatDark8: '#0E7490',
    datavizCatDark9: '#4F4FBD',
    datavizCatDark10: '#565C60',

    datavizCatNeutral1: '#BC71FB',
    datavizCatNeutral2: '#C880B2',
    datavizCatNeutral3: '#F87171',
    datavizCatNeutral4: '#F48052',
    datavizCatNeutral5: '#EAB308',
    datavizCatNeutral6: '#68A512',
    datavizCatNeutral7: '#1FBC59',
    datavizCatNeutral8: '#06B6D4',
    datavizCatNeutral9: '#7B90DF',
    datavizCatNeutral10: ' #8F979D',

    datavizColorLight1: '#EED9FE',
    datavizColorLight2: '#EDD6EB',
    datavizColorLight3: '#FBE4E4',
    datavizColorLight4: '#FDEAE2',
    datavizColorLight5: '#FEF9C3',
    datavizColorLight6: '#D9F99D',
    datavizColorLight7: '#C2F5D4',
    datavizColorLight8: '#A5F3FC',
    datavizColorLight9: '#CCDCF8',
    datavizColorLight10: '#D9DCDE',
  },
};
