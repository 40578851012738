/**
 * Use this to apply business rules on data input/output
 */
import { transformChannelToSave, transformIntoOptions } from '../channelCreateUtils';
import { IChannelCreateEditRepository } from '../types/IChannelCreateEditRepository';
import channelRepositoryInstance from './ChannelCreateEditRepository';
import { TCategoryFormField } from '../types/CategoryFormField';
import { ChannelFormCreate } from '../types/ChannelCreate';
import { ChannelPutDTO } from '@connected-core-system/utils/channel-http-dto';
import { ContentStreamTableDataType } from '../../ContentStream/types/ContentStreamTableDataType';
import { ExceptionCode, UniquePropertyExistsException } from '@core/exceptions';

export class ChannelCreateEditManager {
  constructor(private repo: IChannelCreateEditRepository) {}

  async getFormSelectOptions(organisationId: string) {
    try {
      const promises = [this.repo.fetchUnits(organisationId), this.repo.fetchBrands(organisationId)];
      const response = await Promise.all(promises);
      const [units, brands] = response;
      return {
        units: transformIntoOptions(units.data),
        brands: transformIntoOptions(brands.data),
      };
    } catch (error) {
      return this.handleError(error);
    }
  }

  async saveChannel(
    channel: ChannelFormCreate,
    channelTags: TCategoryFormField[],
    channelUrl: string,
    destinationDomainUrl: string,
  ) {
    try {
      const channelSave = transformChannelToSave(channel, channelTags, destinationDomainUrl);
      const res = await this.repo.postChannel(channelSave);
      return {
        ...res.data,
        channelURL: channelUrl + res.data.id,
      };
    } catch (error) {
      if ((error as UniquePropertyExistsException).code === ExceptionCode.UniquePropertyExistsException) {
        throw new UniquePropertyExistsException('name', 'value');
      }
      return this.handleError(error);
    }
  }

  async updateChannel(
    channel: ChannelFormCreate,
    channelTags: TCategoryFormField[],
    channelId: string,
    channelUrl: string,
    destinationDomainUrl: string,
  ) {
    try {
      const channelToSave = transformChannelToSave(channel, channelTags, destinationDomainUrl);
      const updateChannel: ChannelPutDTO = {
        ...channelToSave,
        channelId: channelId,
      };
      const res = await this.repo.updateChannel(updateChannel);
      return {
        ...res.data,
        channelURL: channelUrl + res.data.id,
      };
    } catch (error) {
      if ((error as UniquePropertyExistsException).code === ExceptionCode.UniquePropertyExistsException) {
        throw new UniquePropertyExistsException('name', 'value');
      }
      return this.handleError(error);
    }
  }

  async getQRStyleList(organisationId: string) {
    try {
      const res = await this.repo.fetchQRStyles(organisationId);
      return res.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getFallbackContentStream(organisationId: string, fallbackContentStreamId: string) {
    try {
      const fallbackContentStream = await this.repo.getFallbackContentStream(organisationId, fallbackContentStreamId);
      return {
        id: fallbackContentStream.data.id,
        name: fallbackContentStream.data.name,
        campaignName: fallbackContentStream.data.campaign.name,
        campaignId: fallbackContentStream.data.campaign.id,
        status: fallbackContentStream.data.status,
        tags: fallbackContentStream.data?.contentStreamTags
          ? fallbackContentStream.data.contentStreamTags.map((t) => {
              return t.tag;
            })
          : [],
        isPublished: fallbackContentStream.data.isPublished,
        lastUpdate: new Date(fallbackContentStream.data.lastUpdate),
        updatedBy: fallbackContentStream.data.updatedBy,
        contentStreamTags: fallbackContentStream.data?.contentStreamTags || [],
        scheduleTargetRule: fallbackContentStream.data.targetingSchedule,
        timeTargetRule: fallbackContentStream.data.targetingTime,
        scanHistoryTargetRule: fallbackContentStream.data.targetingScanHistory,
        locationTargetRule: fallbackContentStream.data.targetingLocation,
        isFallback: fallbackContentStream.data.isFallbackContent,
        linkedChannelList: fallbackContentStream.data.contentStreamChannel,
        linkedContent: fallbackContentStream.data.contentStreamContent,
        linkedStory: fallbackContentStream.data.storyContentId,
      } as ContentStreamTableDataType;
    } catch (error) {
      return this.handleError(error);
    }
  }

  handleError(error: unknown) {
    throw error;
  }
}

export default new ChannelCreateEditManager(channelRepositoryInstance);
