/* eslint-disable @typescript-eslint/no-empty-interface */
import { BoxContentCenter, TableDateHourField } from '../../../shared/components';
import React from 'react';
import { ChannelTableDataType } from '../types/ChannelTableDataType';
import { formatLastUpdatedDate } from '../channelUtils';

export interface IChannelLastUpdateCellProps {
  lastUpdate: ChannelTableDataType['lastUpdate'];
}

const ChannelLastUpdateCell: React.FC<IChannelLastUpdateCellProps> = ({ lastUpdate }) => {
  const { date, time } = formatLastUpdatedDate(lastUpdate);
  return (
    <BoxContentCenter justify="flex-start">
      <div>
        <TableDateHourField date={date} time={time} />{' '}
      </div>
    </BoxContentCenter>
  );
};

export default ChannelLastUpdateCell;
