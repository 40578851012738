import { TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { WppExpandableCard } from '@wppopen/components-library-react';

export const StyledExpandableBare = styled(WppExpandableCard)(({ theme }: TStyledDefaultProps) => ({
  padding: '1.2rem 1.6rem',
  marginBottom: '1.6rem',
  width: 'calc(100% - 32px)',
}));

export const StyledExpandableOs = styled(WppExpandableCard)(({ theme }: TStyledDefaultProps) => ({
  padding: '1.2rem 1.6rem 1.6rem',
  marginBottom: '1rem',
  width: '100%',
}));
