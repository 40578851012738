/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { WppTypography } from '@wppopen/components-library-react';
import { useTheme } from '@emotion/react';
import { ITheme } from '@connected-core-system/utils/frontend';
import { ContentsTableDataType } from '../../types/ContentsTableDataType';

export interface IOrderCellProps {
  order: ContentsTableDataType['order'];
}

const OrderCell: React.FC<IOrderCellProps> = ({ order }) => {
  const theme = useTheme() as ITheme;
  return (
    <div data-testid="order-cell">
      <WppTypography type="s-midi" color={theme.palette['datavizCatDark10']}>
        {order}
      </WppTypography>
    </div>
  );
};

export default OrderCell;
