import { BoxContent, BoxContentCenter, TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';

export const ContainerBare = styled.div({
  display: 'grid',
  gridTemplateColumns: 'minmax(auto, calc(100% - 222px)) 222px',
  gridTemplateRows: '10vh 5vh 60vh',
  rowGap: 16,
  width: '100%',
  padding: '2.4rem',
  boxSizing: 'border-box',
  height: '100%',
  overflow: 'auto',
});

export const ContainerOs = styled.div({
  display: 'grid',
  gridTemplateColumns: 'minmax(auto, calc(100% - 222px)) 222px',
  gridTemplateRows: '5vh 60vh',
  rowGap: 16,
  width: '100%',
  padding: '2.4rem',
  boxSizing: 'border-box',
  height: '100%',
  overflow: 'auto',
});

export const HeaderContainer = styled.div({
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
});

export const HeaderActionsContainer = styled.div({
  justifySelf: 'flex-end',
});

export const ContentContainer = styled.div({
  height: 'fit-content',
  gridColumnStart: 1,
  gridColumnEnd: 3,
  display: 'grid',
  columnGap: '1.6rem',
  gridTemplateColumns: '2fr 1fr',
});

export const TitleContainerBare = styled(BoxContent)(({ theme }: TStyledDefaultProps) => ({
  boxSizing: 'border-box',
  padding: '1.2rem 1.6rem',
  backgroundColor: theme?.palette?.['grey000'],
  height: '9.8rem',
  borderRadius: '0.8rem',
  boxShadow: '0px 0px 1px rgba(52, 58, 63, 0.1), 0px 1px 5px rgba(52, 58, 63, 0.1)',
  marginBottom: '1.6rem',
}));

export const TitleContainerOs = styled(BoxContent)(({ theme }: TStyledDefaultProps) => ({
  boxSizing: 'border-box',
  padding: '1.2rem 1.6rem 1.6rem',
  backgroundColor: theme?.palette?.['grey000'],
  height: '9.8rem',
  borderRadius: '0.8rem',
  boxShadow: '0px 0px 1px rgba(52, 58, 63, 0.1), 0px 1px 5px rgba(52, 58, 63, 0.1)',
  marginBottom: '1rem',
}));

export const ChannelsContainerBare = styled(BoxContent)(({ theme }: TStyledDefaultProps) => ({
  padding: '1.8rem 1.6rem',
  backgroundColor: theme?.palette?.['grey000'],
  height: '5.6rem',
  borderRadius: '0.8rem',
  boxShadow: '0px 0px 1px rgba(52, 58, 63, 0.1), 0px 1px 5px rgba(52, 58, 63, 0.1)',
  marginBottom: '2.4rem',
}));

export const ChannelsContainerOs = styled(BoxContent)(({ theme }: TStyledDefaultProps) => ({
  padding: '1.2rem 1.6rem 1.6rem',
  backgroundColor: theme?.palette?.['grey000'],
  height: '5.6rem',
  borderRadius: '0.8rem',
  boxShadow: '0px 0px 1px rgba(52, 58, 63, 0.1), 0px 1px 5px rgba(52, 58, 63, 0.1)',
  marginBottom: '1rem',
}));

export const PreviewContainerBare = styled(BoxContent)(({ theme }: TStyledDefaultProps) => ({
  position: 'sticky',
  top: '0',
  padding: '1.2rem 1.6rem 1.6rem 1.6rem',
  backgroundColor: theme?.palette?.['grey000'],
  height: 'auto',
  borderRadius: '0.8rem',
  boxShadow: '0px 0px 1px rgba(52, 58, 63, 0.1), 0px 1px 5px rgba(52, 58, 63, 0.1)',
}));

export const PreviewContainerOs = styled(BoxContent)(({ theme }: TStyledDefaultProps) => ({
  position: 'sticky',
  top: '0',
  padding: '1.2rem 1.6rem 1.6rem',
  backgroundColor: theme?.palette?.['grey000'],
  height: 'auto',
  borderRadius: '0.8rem',
  boxShadow: '0px 0px 1px rgba(52, 58, 63, 0.1), 0px 1px 5px rgba(52, 58, 63, 0.1)',
}));

export const PreviewHeader = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
});

export const QrPreview = styled.div(({ theme }: TStyledDefaultProps) => ({
  display: 'flex',
  justifyContent: 'center',
  marginTop: '0.6rem',
}));

export const EmptyStateContainer = styled(BoxContentCenter)({
  paddingLeft: 38,
  paddingRight: 38,
});
