import React, { useCallback, useEffect, useState } from 'react';
import { BoxContent } from '../../../shared/components';
import { WppTypography, WppRadio } from '@wppopen/components-library-react';
import ExpandableCard from '../../../shared/components/ExpandableCard/ExpandableCard';
import {
  LastGridContainer,
  RadioContainer,
  GridContainer,
  StyledInput,
  StyledRadioGroup,
} from '../styles/ExpandablesStyle.styled';
import { QRBackgroundOptions, QRColorType, QRGradientType } from '../types/QRStyleTypes';
import { translate } from '../../../shared/locales';
import ColorField from '../../../shared/components/SelectColor/ColorField';
import { useBackgroundOptionsState } from '../hooks/useBackgroundOptionsState';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar';
import { Options } from 'qr-code-styling';
import { DEFAULT_COLOR_BACKGROUND, QRSTYLE_FORM } from '../../../shared/Constants/Constants';
import { useValueChangesState } from '../hooks/useValueChangesState';
import { WppRadioGroupCustomEvent, WppSelectCustomEvent } from '@wppopen/components-library/dist/types/components';
import { RadioGroupChangeEvent, SelectChangeEventDetail } from '@wppopen/components-library';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IBackgroundStyleProps {}

const DEFAULT_OPTIONS = {
  COLOR_TYPE: 'single' as QRColorType,
  ROTATION: '0',
  GRADIENT_TYPE: 'linear' as QRGradientType,
  COLOR: [
    {
      color: DEFAULT_COLOR_BACKGROUND,
      offset: 0,
    },
    {
      color: DEFAULT_COLOR_BACKGROUND,
      offset: 1,
    },
  ],
};

const BackgroundStyle: React.FC<IBackgroundStyleProps> = () => {
  const [colorType, setColorType] = useState<QRColorType>(DEFAULT_OPTIONS.COLOR_TYPE);
  const [rotation, setRotation] = useState<string>(DEFAULT_OPTIONS.ROTATION);
  const [gradientType, setGradientType] = useState<QRGradientType>(DEFAULT_OPTIONS.GRADIENT_TYPE);
  const [color, setColor] = useState(DEFAULT_OPTIONS.COLOR);

  const { setBackgroundOptions } = useBackgroundOptionsState();
  const { selectedSecondTierItem } = useSecondTierSelectedItemState();

  const { valueChanges, setValueChanges } = useValueChangesState();

  const isGradientTypeSelected = colorType === 'gradient';

  const handleChange = (
    field: string,
    e: WppSelectCustomEvent<SelectChangeEventDetail> | WppRadioGroupCustomEvent<RadioGroupChangeEvent>,
  ) => {
    switch (field) {
      case QRSTYLE_FORM.colorType:
        setColorType(e.target.value as QRColorType);
        break;
      case QRSTYLE_FORM.rotation:
        setRotation(e.detail.value as string);
        break;
      case QRSTYLE_FORM.gradient:
        setGradientType(e.target.value as QRGradientType);
        break;
    }
    setValueChanges({ ...valueChanges, newValue: true });
  };

  useEffect(() => {
    setBackgroundOptions({
      color: color[0].color,
      gradient: isGradientTypeSelected
        ? {
            rotation: Number(rotation),
            type: gradientType,
            colorStops: color,
          }
        : undefined,
    });
  }, [color, colorType, rotation, gradientType, isGradientTypeSelected, setBackgroundOptions]);

  const fillStyleFields = useCallback(() => {
    if ((selectedSecondTierItem as any)?.configuration) {
      const options: Options = (selectedSecondTierItem as any)?.configuration;
      const backgroundOptions: QRBackgroundOptions = options.backgroundOptions;

      const setBackgroundData = () => {
        if (backgroundOptions?.gradient) {
          setColorType('gradient');
          setGradientType(backgroundOptions.gradient.type);
          setColor(backgroundOptions.gradient.colorStops);
          if (backgroundOptions.gradient.rotation) setRotation(backgroundOptions.gradient.rotation?.toString());
        } else {
          setColorType('single');
          if (backgroundOptions?.color) setColor([{ color: backgroundOptions.color, offset: 0 }]);
        }
      };

      setBackgroundData();
    } else {
      const setDefaultOptions = () => {
        setColorType(DEFAULT_OPTIONS.COLOR_TYPE);
        setRotation(DEFAULT_OPTIONS.ROTATION);
        setGradientType(DEFAULT_OPTIONS.GRADIENT_TYPE);
        setColor(DEFAULT_OPTIONS.COLOR);
      };
      setDefaultOptions();
    }
  }, [selectedSecondTierItem]);

  useEffect(() => {
    fillStyleFields();
  }, [fillStyleFields]);

  return (
    <ExpandableCard title={translate('txtBackgroundTitle')}>
      {' '}
      <BoxContent direction="column" data-testid="background-style-container">
        <GridContainer>
          <WppTypography type="s-strong">{translate('txtColorTypeLabel')}</WppTypography>
          <div>&nbsp;</div>
          <div>&nbsp;</div>

          <StyledRadioGroup
            value={colorType}
            onWppChange={(e) => handleChange(QRSTYLE_FORM.colorType, e)}
            data-testid="color-type-radio-group"
            hasStyleField={false}>
            <RadioContainer>
              <WppRadio
                name="single-color"
                value="single"
                labelConfig={{ text: translate('txtSingleColor') }}
                required
                checked={colorType === 'single'}
              />
              <WppRadio
                name="gradient-color"
                value="gradient"
                labelConfig={{ text: translate('txtGradientColor') }}
                required
                checked={isGradientTypeSelected}
              />
            </RadioContainer>
          </StyledRadioGroup>
        </GridContainer>
        {isGradientTypeSelected && (
          <GridContainer>
            <WppTypography type="s-strong">Rotation</WppTypography>
            <WppTypography type="s-strong">Gradient type</WppTypography>
            <div>&nbsp;</div>

            <StyledInput
              type="number"
              value={rotation as string}
              onWppChange={(e) => handleChange(QRSTYLE_FORM.rotation, e)}
              data-testid="rotation-input"
            />

            <StyledRadioGroup
              value={gradientType}
              onWppChange={(e) => handleChange(QRSTYLE_FORM.gradient, e)}
              data-testid="gradient-type-radio-group">
              <RadioContainer>
                <WppRadio
                  name="linear"
                  value="linear"
                  labelConfig={{ text: 'Linear' }}
                  required
                  checked={gradientType === 'linear'}
                  data-testid="linear-radio"
                />
                <WppRadio
                  name="radial"
                  value="radial"
                  labelConfig={{ text: 'Radial' }}
                  required
                  checked={gradientType === 'radial'}
                  data-testid="radial-radio"
                />
              </RadioContainer>
            </StyledRadioGroup>
          </GridContainer>
        )}
        <LastGridContainer>
          <WppTypography type="s-strong">{translate('txtColor')}</WppTypography>
          <div>&nbsp;</div>
          <div>&nbsp;</div>

          <ColorField color={color} setColor={setColor} disabled={false} isGradient={isGradientTypeSelected} />
        </LastGridContainer>
      </BoxContent>
    </ExpandableCard>
  );
};

export default BackgroundStyle;
