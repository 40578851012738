import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { IChannelCreateEditActions, IChannelCreateEditState } from '../sliceStore/channelCreateEditSliceStore';

export const useIsChannelModalOpenedState = () => {
  const isChannelModalOpened = useSliceState<IChannelCreateEditState, 'isChannelModalOpened'>('isChannelModalOpened');
  const setIsChannelModalOpened = useSliceSetter<IChannelCreateEditActions, 'setIsChannelModalOpened'>(
    'setIsChannelModalOpened',
  );

  return { isChannelModalOpened, setIsChannelModalOpened };
};
