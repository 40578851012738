/**
 * Use this to call BFF endpoints
 */

import { HttpService } from '@core/http-service';
import { ChannelDTO } from '@connected-core-system/utils/channel-http-dto';
import { addQueryString, interpolateWithValues } from '../../../shared/utils';

import { BFF_ENDPOINTS, HttpResolver } from '../../../shared/api';

import { IChannelPageRepository } from '../types/IChannelPageRepository';
import { BrandGetDTO } from '@connected-core-system/utils/brand-http-dto';
import { OrganisationUnitGetDTO } from '@connected-core-system/utils/organisation-unit-http-dto';
type Nullable<T> = T | null | undefined;
export class ChannelPageRepository implements IChannelPageRepository {
  constructor(private readonly httpService: HttpService) {}

  listChannels(organisationId: string, brandId: Nullable<string>, unitId: Nullable<string>) {
    let url = interpolateWithValues(BFF_ENDPOINTS.CHANNEL, organisationId);
    url = addQueryString(url, {
      brandId: brandId,
      unitId: unitId,
    });
    return this.httpService.get<ChannelDTO[]>(url);
  }

  async getBrands(organisationId: string, brandIds: string) {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_BRANDS_GET, organisationId);
    return this.httpService.get<BrandGetDTO[]>(`${url}?ids=${brandIds}`);
  }
  async getUnits(organisationId: string, unitIds: string) {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_UNITS_GET, organisationId);
    return this.httpService.get<OrganisationUnitGetDTO[]>(`${url}?ids=${unitIds}`);
  }
}

export default new ChannelPageRepository(HttpResolver.getHttpService());
