/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { HourText, WppStyledTypography } from './TableDateHourField.styled';

export interface ITableDateHourFieldProps {
  date: string;
  time: string;
}

const TableDateHourField: React.FC<ITableDateHourFieldProps> = ({ date, time }) => {
  return (
    <>
      <WppStyledTypography type="s-body" tag="p">
        {date}
      </WppStyledTypography>
      <HourText type="xs-body" tag="p">
        {time}
      </HourText>
    </>
  );
};

export default TableDateHourField;
