/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useState, useEffect } from 'react';
import {
  TargetingLocationDTO,
  TargetingScheduleDTO,
  TargetingTimeDTO,
  TargetingScanHistoryDTO,
} from '@connected-core-system/utils/content-stream-http-dto';
import { WppActionButton, WppButton, WppIconCross, WppTypography } from '@wppopen/components-library-react';
import { BoxContent, ContentPage, Header, Tabs } from '../../../shared/components';
import { TabContainer } from '../../../shared/components/Tabs/Tabs.styled';
import FallbackWrapper from '../../Feedback/views/FallbackWrapper';
import { StatusEnum } from '@connected-core-system/utils/types';
import { translate } from '../../../shared/locales';
import { ChannelTableDataType } from '../../Channel/types/ChannelTableDataType';
import { TCategoryFormField } from '../../ChannelCreateEdit/types/CategoryFormField';
import { ContentStreamTableDataType } from '../../ContentStream/types/ContentStreamTableDataType';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisarionState';
import { TOption } from '../../TagCategory/types/TagCategoryFormField';
import { useSelectedContentStreamState } from '../hooks/useSelectedContentStreamState';
import { useSelectedTabState } from '../hooks/useSelectedTabState';
import contentCreateManager from '../services/ContentStreamCreateManager';
import { ContentFormCreate } from '../types/ContentFormField';
import {
  contentFormTabs,
  tabContentValue,
  tabDetailsValue,
  tabTargetsValue,
  validateContentForm,
  mapContentStreamTagToCategoryTag,
  mapLinkedChannelListToTableDataType,
  validateContentEditForm,
  validateContentStreamHasFields,
  validateTabTargetsToPublish,
  createLinkedChannels,
  transformToContentStreamTableDataType,
  getErrorMessage,
} from '../utils/contentStreamCreateUtils';
import {
  ConditionalContentContainer,
  ContentContainer,
  FormHeader,
  PublishButton,
  StyledBoxContent,
} from './styles/ContentStreamForm.styled';
import TabContent from './TabContent';
import TabDetails from './TabDetails';
import TabTargets from './TabTargets';
import { ContentsTableDataType } from '../types/ContentsTableDataType';
import { useIsContentStreamModalOpenedState } from '../hooks/useIsContentStreamModalOpenedState';
import { useContentStreamTableTryAgainState } from '../../ContentStream/hooks/useContentStreamTableTryAgainState';
import { useIsFallbackContentStreamState } from '../hooks/useIsFallbackContentStreamState';
import ConfirmationModal from '../../../shared/components/Modals/ConfirmationModal';
import ContentStreamPageManager from '../../ContentStream/services/ContentStreamPageManager';
import { createContents, validateTabContentToPublish } from '../utils/contentStreamContentUtils';
import { IContentStreamRequestsDoneStates } from '../types/IContentStreamRequestsDoneStates';
import { useFallbackChangedTryAgainState } from '../../ChannelCreateEdit/hooks/useFallbackChangedTryAgainState';

export interface IContentStreamFormProps {}

const ContentStreamForm: React.FC<IContentStreamFormProps> = () => {
  const { selectedContentStream, setSelectedContentStream } = useSelectedContentStreamState();
  const { selectedTab, setSelectedTab } = useSelectedTabState();
  const { setIsContentStreamModalOpened } = useIsContentStreamModalOpenedState();
  const { selectedOrganisation } = useSelectedOrganisationState();
  const { isFallbackContentStream } = useIsFallbackContentStreamState();
  const { setTryAgain } = useContentStreamTableTryAgainState();
  const { setFallbackTryAgain } = useFallbackChangedTryAgainState();

  const [newContent, setNewContent] = useState<ContentFormCreate>({
    id: '',
    name: selectedContentStream?.name || '',
    organisationId: '',
    campaign: null,
    isPublished: false,
    contentTags: [],
    isFallbackContent: false,
    targetingLocation: [],
    targetingSchedule: null,
    targetingTime: [],
    targetingScanHistory: null,
    selectedChannels: [],
    contents: [],
    storyContentId: null,
  });
  const [selectedCampaign, setSelectedCampaign] = useState<TOption[]>([]);
  const [selectedStory, setSelectedStory] = useState<Partial<ContentsTableDataType>[]>([]);
  const [selectedContent, setSelectedContent] = useState<Partial<ContentsTableDataType>[]>([]);
  const [selectedLinkedChannels, setSelectedLinkedChannels] = useState<Partial<ChannelTableDataType>[]>(
    mapLinkedChannelListToTableDataType(selectedContentStream?.linkedChannelList || []),
  );
  const [isInvalidTargetSchedule, setIsInvalidTargetSchedule] = useState<boolean>(false);
  const [isInvalidTargetTime, setIsInvalidTargetTime] = useState<boolean>(false);
  const [isInvalidTargetLocation, setIsInvalidTargetLocation] = useState<boolean>(false);
  const [isInvalidTargetScanHistory, setIsInvalidTargetScanHistory] = useState<boolean>(false);
  const [isInvalidTargetsTab, setIsInvalidTargetsTab] = useState<boolean>(false);

  const [reloadTagCategory, setReloadTagCategory] = useState<boolean>(false);
  const [exitConfirmationModalOpened, setExitConfirmationModalOpened] = useState(false);

  const [promise, setPromise] = useState({
    loading: false,
    error: false,
    success: false,
    publishing: false,
    saving: false,
    errorToastMessage: '',
  });

  const [targetingLocation, setTargetingLocation] = useState<TargetingLocationDTO[]>(
    selectedContentStream?.locationTargetRule || [],
  );
  const [targetingSchedule, setTargetingSchedule] = useState<TargetingScheduleDTO | null>(
    selectedContentStream?.scheduleTargetRule || null,
  );
  const [targetingScanHistory, setTargetingScanHistory] = useState<TargetingScanHistoryDTO | null>(
    selectedContentStream?.scanHistoryTargetRule || null,
  );
  const [targetingTime, setTargetingTime] = useState<TargetingTimeDTO[]>(selectedContentStream?.timeTargetRule || []);
  const [contentStreamTagsFromScreen, setContentStreamTagsFromScreen] = useState<TCategoryFormField[]>([]);
  const [isInvalidContentTab, setIsInvalidContentTab] = useState<boolean>(false);
  const [toastMessage, setToastMessage] = useState<string>('');
  const [pageLoaded, setPageLoaded] = useState<boolean>(false);
  const [isPublishedModalOpened, setIsPublishedModalOpened] = useState<boolean>(false);

  const [requestsDone, setRequestsDone] = useState<IContentStreamRequestsDoneStates>({
    campaign: false,
    tags: false,
    contents: false,
    channels: false,
  });

  const btnSave = translate('btnSave');

  useEffect(() => {
    if (!pageLoaded) {
      setPageLoaded(true);
      setSelectedTab(tabDetailsValue);
    }
  }, [pageLoaded, setSelectedTab]);

  const validContent = validateContentForm(newContent, selectedCampaign.length > 0 ? selectedCampaign[0].label : '');

  const getContentStream = () => {
    const linkedChannels = createLinkedChannels(selectedLinkedChannels);
    const contents = createContents(selectedContent);
    const body = {
      id: newContent.id,
      name: newContent.name,
      organisationId: selectedOrganisation?.id,
      campaign: {
        id: selectedCampaign[0]?.value,
        name: selectedCampaign[0]?.label,
        organisationId: selectedOrganisation?.id,
      },
      isPublished: newContent.isPublished,
      contentTags: [],
      isFallbackContent: isFallbackContentStream,
      targetingLocation: targetingLocation,
      targetingSchedule: targetingSchedule,
      targetingScanHistory: targetingScanHistory,
      targetingTime: targetingTime,
      selectedChannels: linkedChannels,
      contents: contents,
      storyContentId: selectedStory.length > 0 ? selectedStory[0].id : null,
    } as ContentFormCreate;
    return body;
  };

  const handleSave = async () => {
    const isTargetTabValid = checkTargetsTab();
    const isContentTabValid = validateTabContentToPublish(selectedContent, selectedStory);
    if (selectedContentStream?.isPublished) {
      if (isTargetTabValid && isContentTabValid) setIsPublishedModalOpened(true);
      else {
        setIsInvalidContentTab(!isContentTabValid);
        setIsInvalidTargetsTab(!isTargetTabValid);
      }
    } else saveContentStream();
  };

  const checkTargetsTab = () => {
    if (isFallbackContentStream) return true;
    const contentStream = getContentStream();
    const isTargetTabValid = validateTabTargetsToPublish(
      selectedLinkedChannels as ChannelTableDataType[],
      contentStream.targetingSchedule,
      contentStream.targetingTime,
      contentStream.targetingLocation,
      contentStream.targetingScanHistory,
      isInvalidTargetSchedule,
      isInvalidTargetTime,
      isInvalidTargetLocation,
      isInvalidTargetScanHistory,
    );
    return isTargetTabValid;
  };

  const areObjectsEqual = selectedContentStream
    ? validateContentEditForm(selectedContentStream, getContentStream(), contentStreamTagsFromScreen, requestsDone)
    : validateContentStreamHasFields(getContentStream(), contentStreamTagsFromScreen);

  const handleSaveModal = () => {
    saveContentStream();
    setIsPublishedModalOpened(false);
  };

  const canContentStreamBePublish = () => {
    if (!selectedContentStream?.isPublished) {
      const isTargetTabValid = checkTargetsTab();
      const isContentTabValid = validateTabContentToPublish(selectedContent, selectedStory);
      if (!isTargetTabValid || !isContentTabValid) {
        setIsInvalidContentTab(!isContentTabValid);
        setIsInvalidTargetsTab(!isTargetTabValid);
        return false;
      }
    }
    return true;
  };

  const customPromise = (options = {}) => {
    const defaultOptions = {
      loading: false,
      error: false,
      success: false,
      publishing: false,
      saving: false,
      errorToastMessage: '',
    };
    const mergedOptions = { ...defaultOptions, ...options };
    setPromise(mergedOptions);
  };

  const handlePublish = async () => {
    if (!canContentStreamBePublish()) {
      return;
    }
    customPromise({ loading: true, publishing: true });
    await ContentStreamPageManager.updatePublishState(
      selectedOrganisation?.id as string,
      selectedContentStream?.id as string,
      !selectedContentStream?.isPublished,
    )
      .then((res: boolean) => {
        customPromise({ success: true });
        setSelectedContentStream({ ...(selectedContentStream as ContentStreamTableDataType), isPublished: res });
        setTryAgain(true);
        setFallbackTryAgain(true);
        setToastMessage(
          res
            ? translate('txtSuccessPublished', { something: translate('txtContentStream') })
            : translate('txtSuccessUnpublished', { something: translate('txtContentStream') }),
        );
      })
      .catch(() => {
        customPromise({ error: true, errorToastMessage: translate('txtErrorMessage') });
      });
  };

  const saveContentStream = async () => {
    try {
      customPromise({ loading: true, saving: true });
      if (newContent && validContent && selectedOrganisation && selectedCampaign.length > 0) {
        const result = !selectedContentStream
          ? await contentCreateManager.saveContentStream(getContentStream(), contentStreamTagsFromScreen)
          : await contentCreateManager.updateContentStream(
              { ...getContentStream(), id: selectedContentStream.id },
              contentStreamTagsFromScreen,
            );
        if (result && result.id) {
          customPromise({ success: true });
          setSelectedContentStream(transformToContentStreamTableDataType(result));
          setToastMessage(
            selectedContentStream
              ? translate('txtSuccessUpdated', { something: translate('txtContentStream') })
              : translate('txtSuccessCreated', { something: translate('txtContentStream') }),
          );
          setTryAgain(true);
          setFallbackTryAgain(true);
          setReloadTagCategory(true);
        } else customPromise({ error: true, errorToastMessage: translate('txtErrorMessage') });
      }
    } catch (error) {
      customPromise({ error: true, errorToastMessage: getErrorMessage(error) });
    }
  };

  const handleExitButton = () => {
    !areObjectsEqual ? setExitConfirmationModalOpened(true) : setIsContentStreamModalOpened(false);
  };

  const handleConfirmationModal = () => {
    setExitConfirmationModalOpened(false);
  };

  const handleCancelConfirmationModal = () => {
    setExitConfirmationModalOpened(false);
    setIsContentStreamModalOpened(false);
  };

  const savePublishedModal = () => {
    return (
      <ConfirmationModal
        buttonCancelAction={() => setIsPublishedModalOpened(false)}
        title={translate('txtPublishedModalTitle')}
        text={translate('txtPublishedModalBody')}
        buttonText={btnSave}
        isOpen={isPublishedModalOpened}
        buttonAction={async () => {
          if (checkTargetsTab()) handleSaveModal();
          else setIsInvalidTargetsTab(true);
        }}
        isWarning={false}
        setIsOpen={setIsPublishedModalOpened}
      />
    );
  };

  const exitEditModal = () => {
    return (
      exitConfirmationModalOpened && (
        <ConfirmationModal
          title={translate('txtConfirmationModalLeaveEditTitle')}
          text={translate('txtConfirmationModalLeaveEditText')}
          buttonCancelText={translate('txtButtonModalCancel')}
          buttonText={translate('txtButtonModal')}
          buttonAction={handleConfirmationModal}
          buttonCancelAction={handleCancelConfirmationModal}
          isOpen={exitConfirmationModalOpened}
          setIsOpen={setExitConfirmationModalOpened}
          isWarning={false}
        />
      )
    );
  };

  const publishButton = () => {
    if (selectedContentStream)
      return (
        <PublishButton
          variant="secondary"
          data-testid="publish-button"
          onClick={async () => {
            handlePublish();
          }}
          disabled={(!validContent || !areObjectsEqual) && !selectedContentStream?.isPublished}
          loading={promise.publishing && promise.loading}>
          {!selectedContentStream?.isPublished ? translate('btnPublish') : translate('btnUnpublish')}
        </PublishButton>
      );
    else return;
  };

  return (
    <ContentPage>
      <ContentContainer>
        {savePublishedModal()}
        {exitEditModal()}
        <FallbackWrapper {...promise} successToastMessage={toastMessage} />
        <FormHeader h="auto" pad="16 42" flex justify="space-between" align="flex-end" bg="grey000">
          <WppTypography type="m-strong">{translate('txtContentStream')}</WppTypography>
          <WppActionButton slot="trigger-button" data-testid="exit-button" onClick={handleExitButton}>
            <WppIconCross />
          </WppActionButton>
        </FormHeader>
        <ConditionalContentContainer h="auto" pad="24 32">
          <Header
            title={selectedContentStream?.name ?? translate('txtNewContentStream')}
            tag={selectedContentStream?.name ? StatusEnum.ACTIVE : StatusEnum.UNPUBLISHED}
            tagColor={selectedContentStream?.status === StatusEnum.ACTIVE ? 'positive' : 'neutral'}>
            <BoxContent flex justify="flex-end">
              {publishButton()}
              <WppButton
                data-testid="save-button"
                onClick={async () => {
                  handleSave();
                }}
                disabled={selectedContentStream ? !validContent || areObjectsEqual : !validContent}
                loading={promise.saving && promise.loading}>
                {btnSave}
              </WppButton>
            </BoxContent>
          </Header>
          <StyledBoxContent marg="24 0">
            <Tabs
              tabs={contentFormTabs(isInvalidContentTab, isInvalidTargetsTab)}
              handleTabChange={(e) => setSelectedTab(e.detail.value)}
              selectedTab={selectedTab}
            />
          </StyledBoxContent>
          <TabContainer show={selectedTab === tabDetailsValue}>
            <TabDetails
              reloadTagCategory={reloadTagCategory}
              setReloadTagCategory={setReloadTagCategory}
              selectedContentStream={selectedContentStream}
              selectedCampaign={selectedCampaign}
              setSelectedCampaign={setSelectedCampaign}
              setContentStreamTagsFromScreen={setContentStreamTagsFromScreen}
              newContent={newContent}
              setNewContent={setNewContent}
              existentEntityTags={mapContentStreamTagToCategoryTag(selectedContentStream?.contentStreamTags || [])}
              requestsDone={requestsDone}
              setRequestsDone={setRequestsDone}
            />
          </TabContainer>
          <TabContainer show={selectedTab === tabContentValue}>
            <TabContent
              selectedStory={selectedStory}
              setSelectedStory={setSelectedStory}
              selectedContent={selectedContent}
              setSelectedContent={setSelectedContent}
              selectedContentStream={selectedContentStream}
              selectedOrganisation={selectedOrganisation}
              isInvalidContentTab={isInvalidContentTab}
              setIsInvalidContentTab={setIsInvalidContentTab}
              setRequestsDone={setRequestsDone}
            />
          </TabContainer>
          <TabContainer show={selectedTab === tabTargetsValue}>
            <TabTargets
              isFallbackContentStream={isFallbackContentStream}
              setTargetingLocation={setTargetingLocation}
              targetingLocation={targetingLocation}
              setTargetingSchedule={setTargetingSchedule}
              targetingSchedule={targetingSchedule}
              setTargetingScanHistory={setTargetingScanHistory}
              targetingScanHistory={targetingScanHistory}
              setTargetingTime={setTargetingTime}
              targetingTime={targetingTime}
              selectedLinkedChannels={selectedLinkedChannels}
              setSelectedLinkedChannels={setSelectedLinkedChannels}
              isInvalidTargetLocation={isInvalidTargetLocation}
              isInvalidTargetSchedule={isInvalidTargetSchedule}
              isInvalidTargetTime={isInvalidTargetTime}
              isInvalidTargetScanHistory={isInvalidTargetScanHistory}
              isInvalidTargetsTab={isInvalidTargetsTab}
              setIsInvalidTargetsTab={setIsInvalidTargetsTab}
              setIsInvalidTargetSchedule={setIsInvalidTargetSchedule}
              setIsInvalidTargetTime={setIsInvalidTargetTime}
              setIsInvalidTargetLocation={setIsInvalidTargetLocation}
              setIsInvalidTargetScanHistory={setIsInvalidTargetScanHistory}
              setRequestsDone={setRequestsDone}
            />
          </TabContainer>
        </ConditionalContentContainer>
      </ContentContainer>
    </ContentPage>
  );
};

export default ContentStreamForm;
