/**
 * Use this to apply business rules on data input/output
 */
import { AuthenticatedUserDTO } from '@connected-core-system/utils/user-http-dto';
import { ILocalPersistense } from '../../../../src/shared/utils';
import storage, { REDIRECT_TO_PATH_LOCAL_KEY, USER_TOKEN_LOCAL_KEY } from '../../../../src/shared/utils/storage';
import { IAuthorizationRepository } from '../types/IAuthorizationRepository';
import authorizationRepository from './AuthorizationRepository';

export class AuthorizationManager {
  constructor(private repo: IAuthorizationRepository, private localPersistence: ILocalPersistense) {}

  getAuthToken() {
    const token = this.localPersistence.getToken();
    return token;
  }

  async getUserInfo(token?: string): Promise<AuthenticatedUserDTO | null> {
    try {
      const userToken = token ?? this.getAuthToken();
      if (userToken) {
        const userInfo = await this.repo.fetchUserInfo(userToken);
        return userInfo.data;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  saveUserToken(token: string) {
    this.localPersistence.saveToken(token);
  }

  clearAuthStoredData() {
    this.localPersistence.clear(REDIRECT_TO_PATH_LOCAL_KEY);
    this.localPersistence.clear(USER_TOKEN_LOCAL_KEY);
  }
}

export default new AuthorizationManager(authorizationRepository, storage);
