/* eslint-disable @typescript-eslint/no-empty-interface */
import { TextHighlight } from '../../../../shared/components';
import React from 'react';
import { LocationTableDataType } from '../../types/LocationTableDataType';

export interface ILocationNameCellProps {
  name: LocationTableDataType['name'];
  searchTerm?: string;
}

const LocationNameCell: React.FC<ILocationNameCellProps> = ({ name, searchTerm }) => {
  return (
    <div>
      <TextHighlight fullString={name} subString={searchTerm ? searchTerm : ''} />
    </div>
  );
};

export default LocationNameCell;
