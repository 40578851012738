import { TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { WppCard, WppTypography } from '@wppopen/components-library-react';

export interface ICardStyles {
  w?: number | string;
  pos?: 'fixed' | 'relative' | 'absolute';
  top?: number | string | null;
  p?: number | string;
  left?: number | string;
}

export const Card = styled(WppCard)(({ w = '20%', pos = 'fixed', top = '', left = '' }: ICardStyles) => ({
  zIndex: 1000,
  position: pos,
  width: w,
  top: top ? top : '',
  left: left,
  '&::part(card)': {
    padding: 10,
  },
}));

export const SelectTextBare = styled(WppTypography)(({ theme, p = 6 }: TStyledDefaultProps & ICardStyles) => ({
  color: theme?.palette?.['grey800'],
  marginRight: 12,
  paddingBottom: p,
  paddingTop: p,
  paddingLeft: p,
  width: '100%',
  borderRadius: 8,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme?.palette?.['grey200'],
  },
  height: 20,
}));

export const SelectTextOs = styled(WppTypography)(({ theme, p = 6 }: TStyledDefaultProps & ICardStyles) => ({
  color: theme?.palette?.['grey800'],
  marginRight: 12,
  paddingBottom: p,
  paddingTop: p,
  paddingLeft: p,
  width: '100%',
  borderRadius: 8,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme?.palette?.['grey200'],
  },
}));
