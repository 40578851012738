import { ContentTemplateGetDTO } from '@connected-core-system/utils/content-http-dto';
import { ColumnDefinition } from '../../shared/components';
import { formatDate, formatTime, DateFormatsEnum, isArrayEmpty } from '../../shared/utils';
import { StatusEnum } from '../SecondTierSidebar/types';
import { ContentStreamTableDataType } from './types/ContentStreamTableDataType';

export type TableLocation =
  | typeof tableContentStreamPage
  | typeof tableContentStreamPriority
  | typeof tableFallbackDrawer;
export const tableContentStreamPage = 'content-stream-page';
export const tableContentStreamPriority = 'content-stream-priority';
export const tableFallbackDrawer = 'content-stream-fallback-drawer';

const getContentStreamColumnDefinitions = (isSortable?: boolean) => {
  const contentStreamColumnDefinitions: {
    [key in keyof ContentStreamTableDataType]: ColumnDefinition & {
      fraction?: number;

      minwidth?: number;
    };
  } = {
    id: {
      field: '',
      minWidth: 56,
      fraction: 0.02,

      headerCheckboxSelection: true,
      checkboxSelection: true,
    },
    order: {
      field: 'order',
      minWidth: 100,
      fraction: 0.01,
      rowDrag: true,
      cellStyle: { display: 'flex' },
    },
    isPublished: { field: '', colId: 'isPublished', minWidth: 82, fraction: 0.01, headerName: 'Publish' },
    name: {
      field: 'name',
      sortable: !!isSortable,
      cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' },
    },
    contentStatus: { field: 'contentStatus', minWidth: 40, fraction: 0.01, headerName: '' },
    isFallback: {
      field: 'isFallback',
      fraction: 0.1,
      sortable: true,
      headerName: 'Type',
    },
    status: { field: 'status', sortable: !!isSortable, fraction: 0.1 },
    campaignName: {
      field: 'campaignName',
      sortable: !!isSortable,
      fraction: 0.1,
      cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' },
      headerName: 'Campaign',
    },
    campaignId: { field: '', minWidth: 60, fraction: 0.01 },
    tags: { field: 'tags', fraction: 0.1 },
    lastUpdate: {
      field: 'lastUpdate',
      sortable: !!isSortable,
      fraction: 0.1,
    },
    contentStreamTags: { field: '', minWidth: 60, fraction: 0.01 },
    scheduleTargetRule: { field: '', minWidth: 60, fraction: 0.01 },
    timeTargetRule: { field: '', minWidth: 60, fraction: 0.01 },
    locationTargetRule: { field: '', minWidth: 60, fraction: 0.01 },
    scanHistoryTargetRule: { field: '', minWidth: 60, fraction: 0.01 },
    linkedChannelList: { field: '', minWidth: 60, fraction: 0.01 },
    linkedContent: { field: '', minWidth: 60, fraction: 0.01 },
    linkedStory: { field: '', minWidth: 60, fraction: 0.01 },
  };
  return contentStreamColumnDefinitions;
};

export const getColumns = (columnsToExclude?: Array<string>, isSortable?: boolean) => {
  const allColumns = getContentStreamColumnDefinitions(isSortable);
  const filteredColumns: { [x: string]: any } = {};

  if (isArrayEmpty(columnsToExclude)) return allColumns;
  else {
    for (const column in allColumns) {
      if (!columnsToExclude?.includes(column as keyof typeof allColumns)) {
        filteredColumns[column] = allColumns[column as keyof typeof allColumns];
      }
    }
    return filteredColumns;
  }
};

export const formatLastUpdatedDate = (lastUpdate: Date) => {
  return {
    date: formatDate(lastUpdate, { format: DateFormatsEnum['LLL dd yyyy'] }),
    time: formatTime(lastUpdate, { withRegion: true }),
  };
};

export const filterContents = (contents: Partial<ContentStreamTableDataType>[], searchTerm: string) => {
  return contents.filter((c) => {
    return (
      c.name?.toLocaleLowerCase().includes(searchTerm.toLowerCase()) ||
      c.status?.toLocaleLowerCase().includes(searchTerm.toLowerCase()) ||
      c.campaignName?.toLocaleLowerCase().includes(searchTerm.toLowerCase())
    );
  });
};

export const hasContentsThatArentActive = (
  data: ContentStreamTableDataType,
  contentData: ContentTemplateGetDTO[] | null,
) => {
  if (!data.linkedContent?.length && !data.linkedStory) {
    return false;
  }
  let hasStoryActive = true;
  const hasAllContentsActive = data.linkedContent?.every((content) => {
    const contentTemplate = contentData?.find((template) => template.contentId === content.contentId);
    return contentTemplate && contentTemplate?.status === StatusEnum.ACTIVE;
  });
  if (data.linkedStory) {
    hasStoryActive =
      contentData?.find((template) => template.contentId === data.linkedStory)?.status === StatusEnum.ACTIVE;
  }
  return !(hasAllContentsActive && hasStoryActive);
};

export const filterContentIds = (data: ContentStreamTableDataType[]) => {
  const storyIds: string[] = [];
  const ids = data
    .map((element) => {
      if (element.linkedStory) storyIds.push(element.linkedStory as string);
      return element.linkedContent?.map((content) => content.contentId) ?? [];
    })
    .flat();
  const allIds = ids.concat(storyIds);
  const uniqueIds = allIds.filter((id, index) => {
    return allIds.indexOf(id) === index;
  });
  return uniqueIds as Array<string>;
};
