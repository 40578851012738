/* eslint-disable @typescript-eslint/no-empty-interface */
import { useState } from 'react';
import { ChannelsSideModal } from './styles/ContentStreamForm.styled';
import { translate } from '../../../shared/locales';
import { MenuItemEnum } from '../../../shared/types';
import { ChannelTableDataType } from '../../Channel/types/ChannelTableDataType';
import { ChannelTable } from '../../Channel';
import { TitleText } from './styles/Targets.styled';
import { compareArrays } from '../../../shared/utils';
import { DrawerActionButtons } from '../../../shared/components';

export interface ILinkedChannelsDrawerProps {
  selectedLinkedChannels: Partial<ChannelTableDataType>[];
  setSelectedLinkedChannels: (selectedLinkedChannels: Partial<ChannelTableDataType>[]) => void;
  isSideModalOpen: boolean;
  setIsSideModalOpen: (isSideModalOpen: boolean) => void;
}

const LinkedChannelsDrawer: React.FC<ILinkedChannelsDrawerProps> = ({
  selectedLinkedChannels,
  setSelectedLinkedChannels,
  isSideModalOpen,
  setIsSideModalOpen,
}) => {
  const [selectedChannelsState, setSelectedChannelsState] =
    useState<Partial<ChannelTableDataType>[]>(selectedLinkedChannels);

  const handleSaveChannelsModal = () => {
    setSelectedLinkedChannels([...selectedChannelsState]);
    setIsSideModalOpen(false);
  };

  return (
    <ChannelsSideModal
      data-testid={'channel-modal'}
      open={isSideModalOpen}
      disableOutsideClick
      onWppSideModalClose={() => setIsSideModalOpen(false)}
      size="l">
      <TitleText type="xl-heading" slot="header" data-testid={'title-modal'}>
        {translate('txtChannel')}
      </TitleText>
      <div slot="body">
        <ChannelTable
          location={MenuItemEnum.CONTENT_STREAM}
          handleOnSelectRows={setSelectedChannelsState}
          listSelectedRowId={selectedChannelsState.map((channel) => channel.id || '')}
          data-testid={'channel-table'}
        />
      </div>
      <DrawerActionButtons
        variant="primary"
        size="s"
        onClick={() => handleSaveChannelsModal()}
        disabled={compareArrays(
          selectedChannelsState?.map((channelState) => channelState.id),
          selectedLinkedChannels?.map((channel) => channel.id),
        )}
        buttonText={translate('btnSave')}
      />
    </ChannelsSideModal>
  );
};

export default LinkedChannelsDrawer;
