import styled from '@emotion/styled';

export const ContainerBare = styled.div({
  position: 'absolute',
  width: '100%',
  minHeight: '100vh',
});

export const ContainerOs = styled.div({
  position: 'absolute',
  width: '100%',
  minHeight: 'inherit',
});
