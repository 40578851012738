import React, { PropsWithChildren } from 'react';

interface State {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<PropsWithChildren, State> {
  constructor(props: any) {
    super(props);

    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    return { hasError: true };
  }

  override componentDidCatch(error: Error, errorInfo: any) {
    console.log({ error, errorInfo });
  }

  override render(): React.ReactNode {
    if (this.state.hasError) {
      return <div>Something went wrong</div>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
