import { ConflictException, ExceptionCode, UniquePropertyExistsException } from '@core/exceptions';
import { SecondTierSidebarItem } from '../../SecondTierSidebar/types';
import { IBrandPageRepository } from '../types';
import repoInstance from './BrandPageRepository';

export class BrandPageManager {
  constructor(private repo: IBrandPageRepository) {}

  async createNewBrand(organisationId: string, name: string, brandUrl?: string) {
    try {
      const newBrandId = await this.repo.createBrand(organisationId, name, brandUrl);
      return newBrandId;
    } catch (error) {
      if ((error as UniquePropertyExistsException).code === ExceptionCode.UniquePropertyExistsException) {
        throw new UniquePropertyExistsException('name', 'value');
      }
      if ((error as ConflictException).code === ExceptionCode.Conflict) {
        throw new ConflictException('name');
      }
      return this.handleError(error);
    }
  }

  async updateBrand(organisationId: string, item: SecondTierSidebarItem, brandUrl?: string) {
    try {
      const updatedBrand = await this.repo.updateBrand(organisationId, item, brandUrl);
      return updatedBrand;
    } catch (error) {
      if ((error as UniquePropertyExistsException).code === ExceptionCode.UniquePropertyExistsException) {
        throw new UniquePropertyExistsException('name', 'value');
      }
      if ((error as ConflictException).code === ExceptionCode.Conflict) {
        throw new ConflictException('name');
      }
      return this.handleError(error);
    }
  }

  handleError(error?: unknown) {
    throw error;
  }
}

export default new BrandPageManager(repoInstance);
