/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { useSliceState } from '../../../shared/hooks/useStore';
import { ISideMenuState } from '../sliceStore/sideMenuSliceStore';
import { Container } from './styles/LogoText.styled';

export interface ILogoTextProps {}

const LogoText: React.FC<ILogoTextProps> = () => {
  const isMenuOpened = useSliceState<ISideMenuState, 'isMenuOpened'>('isMenuOpened');

  const renderFullLogo = () => {
    return (
      <>
        <svg width="97" height="16" viewBox="0 0 97 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.0820312 7.25997C0.0820312 6.3533 0.263365 5.55864 0.626031 4.87597C0.999365 4.18264 1.51136 3.6493 2.16203 3.27597C2.8127 2.90264 3.55936 2.71597 4.40203 2.71597C5.4687 2.71597 6.3487 2.97197 7.04203 3.48397C7.74603 3.9853 8.2207 4.7053 8.46603 5.64397H6.49803C6.33803 5.20664 6.08203 4.8653 5.73003 4.61997C5.37803 4.37464 4.93536 4.25197 4.40203 4.25197C3.65536 4.25197 3.05803 4.51864 2.61003 5.05197C2.1727 5.57464 1.95403 6.31064 1.95403 7.25997C1.95403 8.2093 2.1727 8.95064 2.61003 9.48397C3.05803 10.0173 3.65536 10.284 4.40203 10.284C5.45803 10.284 6.1567 9.81997 6.49803 8.89197H8.46603C8.21003 9.78797 7.73003 10.5026 7.02603 11.036C6.32203 11.5586 5.44736 11.82 4.40203 11.82C3.55936 11.82 2.8127 11.6333 2.16203 11.26C1.51136 10.876 0.999365 10.3426 0.626031 9.65997C0.263365 8.96664 0.0820312 8.16664 0.0820312 7.25997Z"
            fill="black"
          />
          <path
            d="M14.1078 11.82C13.2758 11.82 12.5238 11.6333 11.8518 11.26C11.1798 10.876 10.6518 10.3426 10.2678 9.65997C9.88378 8.96664 9.69178 8.16664 9.69178 7.25997C9.69178 6.36397 9.88911 5.5693 10.2838 4.87597C10.6784 4.18264 11.2171 3.6493 11.8998 3.27597C12.5824 2.90264 13.3451 2.71597 14.1878 2.71597C15.0304 2.71597 15.7931 2.90264 16.4758 3.27597C17.1584 3.6493 17.6971 4.18264 18.0918 4.87597C18.4864 5.5693 18.6838 6.36397 18.6838 7.25997C18.6838 8.15597 18.4811 8.95064 18.0758 9.64397C17.6704 10.3373 17.1158 10.876 16.4118 11.26C15.7184 11.6333 14.9504 11.82 14.1078 11.82ZM14.1078 10.236C14.5771 10.236 15.0144 10.124 15.4198 9.89997C15.8358 9.67597 16.1718 9.33997 16.4278 8.89197C16.6838 8.44397 16.8118 7.89997 16.8118 7.25997C16.8118 6.61997 16.6891 6.0813 16.4438 5.64397C16.1984 5.19597 15.8731 4.85997 15.4678 4.63597C15.0624 4.41197 14.6251 4.29997 14.1558 4.29997C13.6864 4.29997 13.2491 4.41197 12.8438 4.63597C12.4491 4.85997 12.1344 5.19597 11.8998 5.64397C11.6651 6.0813 11.5478 6.61997 11.5478 7.25997C11.5478 8.2093 11.7878 8.9453 12.2678 9.46797C12.7584 9.97997 13.3718 10.236 14.1078 10.236Z"
            fill="black"
          />
          <path
            d="M24.9349 2.71597C25.6282 2.71597 26.2469 2.85997 26.7909 3.14797C27.3456 3.43597 27.7776 3.86264 28.0869 4.42797C28.3962 4.9933 28.5509 5.67597 28.5509 6.47597V11.676H26.7429V6.74797C26.7429 5.95864 26.5456 5.35597 26.1509 4.93997C25.7562 4.5133 25.2176 4.29997 24.5349 4.29997C23.8522 4.29997 23.3082 4.5133 22.9029 4.93997C22.5082 5.35597 22.3109 5.95864 22.3109 6.74797V11.676H20.4869V2.85997H22.3109V3.86797C22.6096 3.5053 22.9882 3.22264 23.4469 3.01997C23.9162 2.8173 24.4122 2.71597 24.9349 2.71597Z"
            fill="black"
          />
          <path
            d="M35.3255 2.71597C36.0189 2.71597 36.6375 2.85997 37.1815 3.14797C37.7362 3.43597 38.1682 3.86264 38.4775 4.42797C38.7869 4.9933 38.9415 5.67597 38.9415 6.47597V11.676H37.1335V6.74797C37.1335 5.95864 36.9362 5.35597 36.5415 4.93997C36.1469 4.5133 35.6082 4.29997 34.9255 4.29997C34.2429 4.29997 33.6989 4.5133 33.2935 4.93997C32.8989 5.35597 32.7015 5.95864 32.7015 6.74797V11.676H30.8775V2.85997H32.7015V3.86797C33.0002 3.5053 33.3789 3.22264 33.8375 3.01997C34.3069 2.8173 34.8029 2.71597 35.3255 2.71597Z"
            fill="black"
          />
          <path
            d="M49.3482 7.05197C49.3482 7.38264 49.3268 7.6813 49.2842 7.94797H42.5482C42.6015 8.65197 42.8628 9.2173 43.3322 9.64397C43.8015 10.0706 44.3775 10.284 45.0602 10.284C46.0415 10.284 46.7348 9.8733 47.1402 9.05197H49.1082C48.8415 9.86264 48.3562 10.5293 47.6522 11.052C46.9588 11.564 46.0948 11.82 45.0602 11.82C44.2175 11.82 43.4602 11.6333 42.7882 11.26C42.1268 10.876 41.6042 10.3426 41.2202 9.65997C40.8468 8.96664 40.6602 8.16664 40.6602 7.25997C40.6602 6.3533 40.8415 5.55864 41.2042 4.87597C41.5775 4.18264 42.0948 3.6493 42.7562 3.27597C43.4282 2.90264 44.1962 2.71597 45.0602 2.71597C45.8922 2.71597 46.6335 2.8973 47.2842 3.25997C47.9348 3.62264 48.4415 4.13464 48.8042 4.79597C49.1668 5.44664 49.3482 6.19864 49.3482 7.05197ZM47.4442 6.47597C47.4335 5.80397 47.1935 5.2653 46.7242 4.85997C46.2548 4.45464 45.6735 4.25197 44.9802 4.25197C44.3508 4.25197 43.8122 4.45464 43.3642 4.85997C42.9162 5.25464 42.6495 5.7933 42.5642 6.47597H47.4442Z"
            fill="black"
          />
          <path
            d="M50.5352 7.25997C50.5352 6.3533 50.7165 5.55864 51.0792 4.87597C51.4525 4.18264 51.9645 3.6493 52.6152 3.27597C53.2658 2.90264 54.0125 2.71597 54.8552 2.71597C55.9218 2.71597 56.8018 2.97197 57.4952 3.48397C58.1992 3.9853 58.6738 4.7053 58.9192 5.64397H56.9512C56.7912 5.20664 56.5352 4.8653 56.1832 4.61997C55.8312 4.37464 55.3885 4.25197 54.8552 4.25197C54.1085 4.25197 53.5112 4.51864 53.0632 5.05197C52.6258 5.57464 52.4072 6.31064 52.4072 7.25997C52.4072 8.2093 52.6258 8.95064 53.0632 9.48397C53.5112 10.0173 54.1085 10.284 54.8552 10.284C55.9112 10.284 56.6098 9.81997 56.9512 8.89197H58.9192C58.6632 9.78797 58.1832 10.5026 57.4792 11.036C56.7752 11.5586 55.9005 11.82 54.8552 11.82C54.0125 11.82 53.2658 11.6333 52.6152 11.26C51.9645 10.876 51.4525 10.3426 51.0792 9.65997C50.7165 8.96664 50.5352 8.16664 50.5352 7.25997Z"
            fill="black"
          />
          <path
            d="M62.8649 4.34797V9.22797C62.8649 9.55864 62.9396 9.79864 63.0889 9.94797C63.2489 10.0866 63.5156 10.156 63.8889 10.156H65.0089V11.676H63.5689C62.7476 11.676 62.1182 11.484 61.6809 11.1C61.2436 10.716 61.0249 10.092 61.0249 9.22797V4.34797H59.9849V2.85997H61.0249V0.667969H62.8649V2.85997H65.0089V4.34797H62.8649Z"
            fill="black"
          />
          <path
            d="M75.0168 7.08599C75.0168 7.33133 75.0008 7.56599 74.9688 7.78999H69.2568C69.3101 8.56866 69.6354 8.95799 70.2328 8.95799C70.6168 8.95799 70.8941 8.78199 71.0648 8.42999H74.8248C74.6968 9.06999 74.4248 9.64599 74.0088 10.158C73.6034 10.6593 73.0861 11.0593 72.4568 11.358C71.8381 11.646 71.1554 11.79 70.4088 11.79C69.5128 11.79 68.7128 11.6033 68.0088 11.23C67.3154 10.8567 66.7714 10.3233 66.3768 9.62999C65.9928 8.92599 65.8008 8.10466 65.8008 7.16599C65.8008 6.22733 65.9928 5.41133 66.3768 4.71799C66.7714 4.01399 67.3154 3.47533 68.0088 3.10199C68.7128 2.72866 69.5128 2.54199 70.4088 2.54199C71.3048 2.54199 72.0994 2.72866 72.7928 3.10199C73.4968 3.46466 74.0408 3.98733 74.4248 4.66999C74.8194 5.35266 75.0168 6.15799 75.0168 7.08599ZM71.4168 6.23799C71.4168 5.93933 71.3208 5.71533 71.1288 5.56599C70.9368 5.40599 70.6968 5.32599 70.4088 5.32599C69.7794 5.32599 69.4114 5.62999 69.3048 6.23799H71.4168Z"
            fill="#7D20C7"
          />
          <path
            d="M82.0602 11.678L80.5402 9.64599L79.4042 11.678H75.5002L78.5242 6.92599L75.3402 2.65399H79.3082L80.8282 4.70199L81.9802 2.65399H85.8842L82.7642 7.29399L86.0282 11.678H82.0602Z"
            fill="#7D20C7"
          />
          <path
            d="M90.3797 3.88599C90.6037 3.46999 90.9237 3.14466 91.3397 2.90999C91.7663 2.66466 92.273 2.54199 92.8597 2.54199C93.5637 2.54199 94.1983 2.72866 94.7637 3.10199C95.329 3.46466 95.7717 3.99799 96.0917 4.70199C96.4223 5.39533 96.5877 6.21666 96.5877 7.16599C96.5877 8.11533 96.4223 8.94199 96.0917 9.64599C95.7717 10.3393 95.329 10.8727 94.7637 11.246C94.1983 11.6087 93.5637 11.79 92.8597 11.79C92.273 11.79 91.7663 11.6727 91.3397 11.438C90.9237 11.1927 90.6037 10.862 90.3797 10.446V15.998H86.8277V2.65399H90.3797V3.88599ZM92.9877 7.16599C92.9877 6.68599 92.865 6.31266 92.6197 6.04599C92.3743 5.77933 92.0597 5.64599 91.6757 5.64599C91.2917 5.64599 90.977 5.77933 90.7317 6.04599C90.4863 6.31266 90.3637 6.68599 90.3637 7.16599C90.3637 7.64599 90.4863 8.01933 90.7317 8.28599C90.977 8.55266 91.2917 8.68599 91.6757 8.68599C92.0597 8.68599 92.3743 8.55266 92.6197 8.28599C92.865 8.01933 92.9877 7.64599 92.9877 7.16599Z"
            fill="#7D20C7"
          />
        </svg>
      </>
    );
  };
  const renderShortLogo = () => {
    return (
      <>
        <svg width="20" height="9" viewBox="0 0 20 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.972656 4.5546C0.972656 3.67914 1.14775 2.91182 1.49793 2.25265C1.85842 1.58318 2.3528 1.0682 2.98107 0.707719C3.60934 0.347235 4.33031 0.166992 5.14397 0.166992C6.17393 0.166992 7.02364 0.414182 7.69311 0.90856C8.37288 1.39264 8.83121 2.08786 9.0681 2.99422H7.16784C7.01334 2.57194 6.76615 2.24235 6.42627 2.00546C6.08638 1.76857 5.65895 1.65013 5.14397 1.65013C4.42301 1.65013 3.84623 1.90762 3.41365 2.42259C2.99137 2.92727 2.78023 3.63794 2.78023 4.5546C2.78023 5.47126 2.99137 6.18708 3.41365 6.70206C3.84623 7.21703 4.42301 7.47452 5.14397 7.47452C6.16363 7.47452 6.83825 7.02649 7.16784 6.13043H9.0681C8.82091 6.99559 8.35743 7.68566 7.67766 8.20064C6.99789 8.70532 6.15333 8.95766 5.14397 8.95766C4.33031 8.95766 3.60934 8.77742 2.98107 8.41693C2.3528 8.04615 1.85842 7.53117 1.49793 6.872C1.14775 6.20253 0.972656 5.43006 0.972656 4.5546Z"
            fill="black"
          />
          <path
            d="M19.1254 4.38761C19.1254 4.6245 19.1099 4.85109 19.079 5.06738H13.5636C13.6151 5.81925 13.9293 6.19518 14.506 6.19518C14.8768 6.19518 15.1446 6.02524 15.3094 5.68535H18.94C18.8164 6.30333 18.5537 6.8595 18.1521 7.35388C17.7607 7.83796 17.2612 8.22419 16.6535 8.51258C16.0561 8.79067 15.3969 8.92971 14.676 8.92971C13.8108 8.92971 13.0383 8.74947 12.3586 8.38898C11.6891 8.0285 11.1638 7.51352 10.7827 6.84405C10.412 6.16428 10.2266 5.37122 10.2266 4.46486C10.2266 3.5585 10.412 2.77058 10.7827 2.10111C11.1638 1.42134 11.6891 0.90121 12.3586 0.540726C13.0383 0.180242 13.8108 0 14.676 0C15.5411 0 16.3084 0.180242 16.9779 0.540726C17.6577 0.890911 18.183 1.39559 18.5537 2.05476C18.9348 2.71393 19.1254 3.49155 19.1254 4.38761ZM15.6493 3.56879C15.6493 3.28041 15.5566 3.06412 15.3712 2.91992C15.1858 2.76543 14.9541 2.68818 14.676 2.68818C14.0683 2.68818 13.713 2.98172 13.61 3.56879H15.6493Z"
            fill="#7D20C7"
          />
        </svg>
      </>
    );
  };

  return (
    <Container flex align="center" center={!isMenuOpened}>
      {isMenuOpened ? renderFullLogo() : renderShortLogo()}
    </Container>
  );
};

export default LogoText;
