/* eslint-disable @typescript-eslint/no-explicit-any */
import { translate } from '../../../shared/locales';
import {
  ContentStreamChannelDTO,
  ContentStreamChannelPostDTO,
  ContentStreamContentDTO,
  ContentStreamGetDTO,
  ContentStreamPostDTO,
  ContentStreamTagDTO,
  TargetingLocationDTO,
  TargetingScanHistoryDTO,
  TargetingScanHistoryPostValidatorDTO,
  TargetingScheduleDTO,
  TargetingSchedulePostValidatorDTO,
  TargetingTimeDTO,
} from '@connected-core-system/utils/content-stream-http-dto';
import { ConditionComparatorEnum, LevelEnum, StatusEnum } from '@connected-core-system/utils/types';
import { ColumnDefinition } from '../../../shared/components';
import { ICategoryTag, IOrganisationUnit } from '../../../shared/types';
import {
  compareArrays,
  compareArraysWithoutSort,
  compareDates,
  compareObjects,
  isArrayEmpty,
  compareSortedArraysByKeys,
} from '../../../shared/utils';
import { ChannelTableDataType } from '../../Channel/types/ChannelTableDataType';
import { TCategoryFormField } from '../../ChannelCreateEdit/types/CategoryFormField';
import { TOption } from '../../TagCategory/types/TagCategoryFormField';
import { ContentFormCreate } from '../types/ContentFormField';
import { LinkedChannelsTableDataType } from '../types/LinkedChannelsDataType';
import { LocationTableDataType } from '../types/LocationTableDataType';
import { ContentStreamTableDataType } from '../../ContentStream/types/ContentStreamTableDataType';
import { UniquePropertyExistsException } from '@core/exceptions';
import { IContentStreamRequestsDoneStates } from '../types/IContentStreamRequestsDoneStates';
import { ScanHistoryTableDataType } from '../types/ScanHistoryTableDataType';

const LocationColumnDefinitions: {
  [key in keyof LocationTableDataType]: ColumnDefinition & {
    fraction?: number;

    minwidth?: number;
  };
} = {
  name: { field: 'name', sortable: true, cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' } },
  status: { field: 'status', sortable: true, fraction: 0.1 },
  isDeleted: { field: '', fraction: 0.1 },
};

const ScanHistoryColumnDefinitions: {
  [key in keyof ScanHistoryTableDataType]: ColumnDefinition & {
    fraction?: number;

    minwidth?: number;
  };
} = {
  level: { field: 'Scan Level', cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' } },
  operator: { field: 'Operator', fraction: 0.1 },
  numberOfScans: { field: 'Scans', fraction: 0.1 },
  isDeleted: { field: '', fraction: 0.1 },
};

const linkdeChannelsColumnDefinitions: {
  [key in keyof LinkedChannelsTableDataType]: ColumnDefinition & {
    fraction?: number;

    minwidth?: number;
  };
} = {
  id: {
    field: 'id',
    minWidth: 56,
    fraction: 0.02,
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  name: { field: 'name', sortable: true, cellStyle: { whiteSpace: 'nowrap', overflow: 'hidden' } },
  status: { field: 'status', sortable: true, minWidth: 64, fraction: 0.1 },
  deleted: { field: 'status', minWidth: 56, fraction: 0.02 },
};

export const getColumnsLinkedChannels = (columnsToExclude?: Array<keyof typeof linkdeChannelsColumnDefinitions>) => {
  const allColumns = linkdeChannelsColumnDefinitions;
  const filteredColumns: { [x: string]: any } = {};

  if (isArrayEmpty(columnsToExclude)) return allColumns;
  else {
    for (const column in allColumns) {
      if (!columnsToExclude?.includes(column as keyof typeof allColumns)) {
        filteredColumns[column] = allColumns[column as keyof typeof allColumns];
      }
    }
    return filteredColumns;
  }
};

export const generateScanString = (obj: { value?: number; minValue?: number; maxValue?: number }): string => {
  if (obj.value !== 0) {
    return `${obj.value} Scans`;
  } else {
    return `From ${obj.minValue} to ${obj.maxValue} Scans`;
  }
};

export const getColumnsScanHistoryTable = (columnsToExclude?: Array<keyof typeof ScanHistoryColumnDefinitions>) => {
  const allColumns = ScanHistoryColumnDefinitions;
  const filteredColumns: { [x: string]: any } = {};

  if (isArrayEmpty(columnsToExclude)) return allColumns;
  else {
    for (const column in allColumns) {
      if (!columnsToExclude?.includes(column as keyof typeof allColumns)) {
        filteredColumns[column] = allColumns[column as keyof typeof allColumns];
      }
    }
    return filteredColumns;
  }
};

export const getColumnsLocationTable = (columnsToExclude?: Array<keyof typeof LocationColumnDefinitions>) => {
  const allColumns = LocationColumnDefinitions;
  const filteredColumns: { [x: string]: any } = {};

  if (isArrayEmpty(columnsToExclude)) return allColumns;
  else {
    for (const column in allColumns) {
      if (!columnsToExclude?.includes(column as keyof typeof allColumns)) {
        filteredColumns[column] = allColumns[column as keyof typeof allColumns];
      }
    }
    return filteredColumns;
  }
};

export const tabDetailsValue = 'details';
export const tabTargetsValue = 'targets';
export const tabContentValue = 'content';

export const contentFormTabs = (isInvalidContentTab: boolean, isInvalidTargetsTab: boolean) => [
  {
    title: translate('tabDetails'),
    value: tabDetailsValue,
  },
  {
    title: translate('tabContent'),
    value: tabContentValue,
    hasWarning: isInvalidContentTab,
  },
  {
    title: translate('tabTargets'),
    value: tabTargetsValue,
    hasWarning: isInvalidTargetsTab,
  },
];

export const transformIntoOptions = (units: (IOrganisationUnit & { id: string })[]) => {
  return units.map((u) => ({
    value: u.id,
    label: u.name,
  }));
};

export const validateContentForm = (content: Partial<ContentFormCreate> | null, selectedCampaign: string) => {
  return !!content?.name && !!selectedCampaign;
};

const compareValues = (a: any, b: any) =>
  (a == null ? '' : a) > (b == null ? '' : b) ? 1 : (b == null ? '' : b) > (a == null ? '' : a) ? -1 : 0;

export const validateContentEditForm = (
  content: Partial<ContentStreamTableDataType> | undefined | null,
  newContentStream: ContentFormCreate,
  contentStreamTagsFromScreen: TCategoryFormField[],
  requestsDone: IContentStreamRequestsDoneStates,
) => {
  const validTargetingTime = compareSortedArraysByKeys(
    newContentStream.targetingTime,
    content?.timeTargetRule ?? [],
    (a, b) => compareValues(a.dayOfWeekEnum, b.dayOfWeekEnum),
    [
      (item) => item.dayOfWeekEnum,
      (item) => (item.startTime ? new Date(item.startTime) : null),
      (item) => (item.endTime ? new Date(item.endTime) : null),
    ],
  );

  const validTargetingLocation = compareSortedArraysByKeys(
    newContentStream.targetingLocation,
    content?.locationTargetRule ?? [],
    (a, b) => compareValues(a.location, b.location),
    [(item) => item.location, (item) => item.conditionComparatorEnum],
  );

  const categories = contentStreamTagsFromScreen
    .map((cat) => {
      return cat.tags.map((tag) => {
        return { tag: tag.label, category: cat.label } as ContentStreamTagDTO;
      });
    })
    .flat() as ContentStreamTagDTO[];

  let validTags = true;
  if (requestsDone.tags) {
    validTags = compareSortedArraysByKeys(
      categories,
      content?.contentStreamTags ?? [],
      (a, b) => compareValues(a.category, b.category) || compareValues(a.tag, b.tag),
      [(item) => item.tag, (item) => item.category],
    );
  }

  let validTargetingSchedule = true;
  const validStartTime = compareDates(
    newContentStream.targetingSchedule?.startDatetime
      ? new Date(newContentStream.targetingSchedule.startDatetime)
      : null,
    content?.scheduleTargetRule?.startDatetime ? new Date(content.scheduleTargetRule.startDatetime) : null,
  );

  const validEndTime = compareDates(
    newContentStream?.targetingSchedule?.endDatetime ? new Date(newContentStream.targetingSchedule.endDatetime) : null,
    content?.scheduleTargetRule?.endDatetime ? new Date(content.scheduleTargetRule.endDatetime) : null,
  );
  validTargetingSchedule = validStartTime && validEndTime;

  const validDetails = compareObjects(
    {
      name: content?.name,
      isFallback: content?.isFallback,
      campaignId: requestsDone.campaign ? content?.campaignId : undefined,
      linkedStory: requestsDone.contents ? content?.linkedStory : '',
    },
    {
      name: newContentStream?.name,
      isFallback: newContentStream?.isFallbackContent,
      campaignId: requestsDone.campaign ? newContentStream?.campaign?.id : undefined,
      linkedStory: requestsDone.contents ? newContentStream?.storyContentId : '',
    },
  );

  let validLinkedChannels = true;
  if (requestsDone.channels) {
    validLinkedChannels = compareArrays(
      content?.linkedChannelList?.map((channel) => channel.channelId) as string[],
      newContentStream?.selectedChannels.map((channel) => channel.channelId) as string[],
    );
  }

  let validContents = true;
  if (requestsDone.contents) {
    validContents = compareArraysWithoutSort(
      content?.linkedContent?.map((contents) => contents.contentId) as string[],
      newContentStream?.contents.map((contents) => contents.contentId) as string[],
    );
  }

  let validScanHistory = true;
  const validOperator = content?.scanHistoryTargetRule?.operator === newContentStream.targetingScanHistory?.operator;
  const validLevel = content?.scanHistoryTargetRule?.level === newContentStream.targetingScanHistory?.level;
  if (newContentStream.targetingScanHistory?.operator !== ConditionComparatorEnum.IN_RANGE) {
    const validValue =
      content?.scanHistoryTargetRule?.numberOfScans.value ===
      newContentStream.targetingScanHistory?.numberOfScans.value;
    validScanHistory = validOperator && validLevel && validValue;
  } else {
    const validMinValue =
      content?.scanHistoryTargetRule?.numberOfScans.minValue ===
      newContentStream.targetingScanHistory?.numberOfScans.minValue;
    const validMaxValue =
      content?.scanHistoryTargetRule?.numberOfScans.maxValue ===
      newContentStream.targetingScanHistory?.numberOfScans.maxValue;
    validScanHistory = validOperator && validLevel && validMinValue && validMaxValue;
  }

  return (
    validDetails &&
    validLinkedChannels &&
    validContents &&
    validTargetingSchedule &&
    validTargetingTime &&
    validTargetingLocation &&
    validScanHistory &&
    validTags
  );
};

export const validateContentStreamHasFields = (
  contentStream: ContentFormCreate,
  contentStreamTagsFromScreen: TCategoryFormField[],
) => {
  if (contentStreamTagsFromScreen.length > 0) return false;

  for (const [key, value] of Object.entries(contentStream)) {
    if (typeof value === 'boolean') continue;
    if (key === 'organisationId') continue;
    if (key === 'campaign' && !contentStream.campaign?.id) continue;
    if (Array.isArray(value) && value.length === 0) continue;
    if (!value) continue;
    return false;
  }
  return true;
};

export const transformContentToSave = (
  content: ContentFormCreate,
  contentStreamTags: TCategoryFormField[],
): ContentStreamPostDTO => {
  const contentSave: ContentStreamPostDTO = {
    name: content.name || '',
    campaign: {
      id: content.campaign?.id || '',

      name: content.campaign?.name || '',
    },
    isFallbackContent: content.isFallbackContent,
    categories: contentStreamTags
      .filter((contentStreamTag) => contentStreamTag.label && contentStreamTag.tags.length > 0)
      .map((contentStreamTag) => ({
        categoryName: contentStreamTag.label,
        tags: contentStreamTag.tags.map((tag: TOption) => ({
          name: tag.label,
          categoryId: contentStreamTag.value,
        })),
      })),
    targetingLocation: content.targetingLocation,
    ...(content?.targetingSchedule && { targetingSchedule: content.targetingSchedule }),
    targetingTime: content.targetingTime.map((targetTime: TargetingTimeDTO) => ({
      startTime: targetTime.startTime,
      endTime: targetTime.endTime,
      dayOfWeekEnum: targetTime.dayOfWeekEnum,
    })),
    targetingSchedule: content.targetingSchedule as TargetingSchedulePostValidatorDTO,
    targetingScanHistory: content.targetingScanHistory as TargetingScanHistoryPostValidatorDTO,
    contentStreamChannel: content.selectedChannels,
    contents: content.contents,
    storyContentId: content.storyContentId,
  };
  return contentSave;
};

export const isCardInViewport = (cardHeight: number, elementToCompare: Element | null) => {
  if (!elementToCompare) return false;
  const rect = elementToCompare.getBoundingClientRect();
  return rect.y + cardHeight > window.innerHeight;
};

export const mapContentStreamTagToCategoryTag = (tags: ContentStreamTagDTO[]): ICategoryTag[] => {
  return tags.map(
    (tag) =>
      ({
        tag: tag.tag,
        category: tag.category,
      } as ICategoryTag),
  );
};

export const mapIdsFromContentArray = (contents?: ContentStreamContentDTO[]): string[] => {
  return contents ? contents.sort((a, b) => b.priority - a.priority).map((content) => content.contentId) : [];
};

export const mapLinkedChannelListToTableDataType = (
  linkedChannelList: ContentStreamChannelDTO[],
): ChannelTableDataType[] => {
  return linkedChannelList.map((channel) => {
    return {
      id: channel.channelId,
      name: channel.name,
      status: StatusEnum.ACTIVE,
      unit: '',
      brand: '',
      unitId: '',
      brandId: '',
      tags: [],
      channelTags: [],
      lastUpdate: channel.updatedAt,
      updatedBy: channel.updatedBy,
      updatedAt: channel.updatedAt,
      channelContentStreams: [],
    } as ChannelTableDataType;
  });
};

export const validateTabTargetsToPublish = (
  linkedChannels: ChannelTableDataType[],
  targetingSchedule: TargetingScheduleDTO | null,
  targetingTime: TargetingTimeDTO[],
  targetingLocation: TargetingLocationDTO[],
  targetingScanHistory: TargetingScanHistoryDTO | null,
  IsInvalidTargetSchedule: boolean,
  IsInvalidTargetTime: boolean,
  IsInvalidTargetLocation: boolean,
  isInvalidTargetScanHistory: boolean,
) => {
  const hasLinkedChannels = !isArrayEmpty(linkedChannels);
  const hasTargets =
    !!targetingSchedule || !isArrayEmpty(targetingTime) || !isArrayEmpty(targetingLocation) || !!targetingScanHistory;
  const hasOnlyValidTargets =
    !IsInvalidTargetSchedule && !IsInvalidTargetTime && !IsInvalidTargetLocation && !isInvalidTargetScanHistory;
  return hasLinkedChannels && hasTargets && hasOnlyValidTargets;
};

export const createLinkedChannels = (selectedLinkedChannels: Partial<ChannelTableDataType>[]) => {
  const linkedChannels = selectedLinkedChannels?.map((channel) => {
    return {
      channelId: channel.id,
      name: channel.name,
    };
  }) as ContentStreamChannelPostDTO[];
  return linkedChannels;
};

export const transformToContentStreamTableDataType = (result: ContentStreamGetDTO) => {
  const contentStreamTableData: ContentStreamTableDataType = {
    id: result.id,
    name: result.name,
    campaignName: result.campaign.name,
    campaignId: result.campaign.id,
    tags: result.contentStreamTags.map((t) => {
      return t.tag;
    }),
    isPublished: result.isPublished,
    status: result.status,
    timeTargetRule: result.targetingTime,
    scheduleTargetRule: result.targetingSchedule,
    locationTargetRule: result.targetingLocation,
    scanHistoryTargetRule: result.targetingScanHistory,
    contentStreamTags: result.contentStreamTags,
    linkedChannelList: result.contentStreamChannel,
    isFallback: result.isFallbackContent,
    lastUpdate: result.lastUpdate,
    linkedStory: result.storyContentId,
    linkedContent: result.contentStreamContent,
    updatedBy: result.updatedBy,
  };

  return contentStreamTableData;
};

export const getErrorMessage = (error: unknown) => {
  if (error instanceof UniquePropertyExistsException) {
    return translate('txtTitleUsed');
  } else {
    return translate('txtErrorMessage');
  }
};

export const mapExistingOperator = (
  operatorOptions: TOption[],
  operator?: ConditionComparatorEnum | null,
): TOption[] | null => {
  const option = operatorOptions.find((option) => option.value === operator);
  return operator && option ? [option] : null;
};

export const mapExistingLevel = (levelOptions: TOption[], level?: LevelEnum | null): TOption[] | null => {
  const option = levelOptions.find((option) => option.label === level);
  return level && option ? [option] : null;
};
