import { StrictMode } from 'react';
import {
  AppOs,
  BackofficeAppContext,
  MicroAppCustomProps,
  OsProvider,
} from '@connected-core-system/backoffice-frontend';
import { environment } from './environments/environment';
import './styles.css';

const Main = (props: MicroAppCustomProps) => {
  return (
    <StrictMode>
      <OsProvider {...props}>
        <BackofficeAppContext.Provider value={{ insideWppOs: true }}>
          <AppOs environmentConfiguration={environment} />
        </BackofficeAppContext.Provider>
      </OsProvider>
    </StrictMode>
  );
};
export default Main;
