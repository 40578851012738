import { TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { WppTypography, WppCard } from '@wppopen/components-library-react';

export const TargetingText = styled(WppTypography)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey700'],
  width: '100%',
  paddingBottom: 12,
  wordBreak: 'normal',
  textAlign: 'justify',
}));

export const TitleText = styled(WppTypography)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey1000'],
  width: '100%',
  paddingBottom: 16,
}));

export const SelectText = styled(WppTypography)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey800'],
  marginRight: 12,
  paddingBottom: 6,
  paddingTop: 6,
  paddingLeft: 6,
  width: '100%',
  borderRadius: 8,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: theme?.palette?.['grey200'],
  },
}));

export const ExternalContainer = styled.div({ position: 'relative' });
export const Card = styled(WppCard)({
  position: 'fixed',
  '&::part(card)': {
    padding: 10,
  },
});
