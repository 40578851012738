/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-empty-interface */
import { BoxContentCenter } from '@connected-core-system/utils/frontend';
import React from 'react';
import TextHighlight from '../TextHighlight/TextHighlight';

export interface ITableDefaultCellProps {
  value: string;
  searchTerm?: string;
}

export function TableDefaultCell({ value, searchTerm }: React.PropsWithChildren<ITableDefaultCellProps>) {
  return (
    <BoxContentCenter justify="flex-start">
      <TextHighlight fullString={value || ''} subString={searchTerm || ''} />;
    </BoxContentCenter>
  );
}
