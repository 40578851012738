/* eslint-disable @typescript-eslint/no-empty-interface */
import { WppTooltip } from '@wppopen/components-library-react';
import { DropdownConfig } from '@wppopen/components-library/dist/types/types/common';
import React, { PropsWithChildren } from 'react';

export interface IConditionalTooltipProps {
  showTooltip: boolean;
  tooltipText: string;
  config?: DropdownConfig;
}

const ConditionalTooltip: React.FC<IConditionalTooltipProps & PropsWithChildren> = ({
  children,
  showTooltip,
  tooltipText,
  config,
}) => {
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return showTooltip ? (
    <WppTooltip text={tooltipText} config={{ ...config }}>
      {children}
    </WppTooltip>
  ) : (
    <>{children}</>
  );
};

export default ConditionalTooltip;
