/* eslint-disable react-hooks/rules-of-hooks */
import { WppModal, WppButton, WppActionButton, WppIconWarning } from '@wppopen/components-library-react';
import { BoxContent } from '..';
import { translate } from '../../locales';
import { ModalActions, ModalTitle, ModalHeader, ModalText } from './CofirmationModal.style';

export interface IConfirmationModalProps {
  title: string;
  text?: string;
  buttonCancelText?: string;
  buttonText: string;
  buttonAction: () => void;
  buttonCancelAction?: () => void;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  isWarning: boolean;
}

const ConfirmationModal: React.FC<IConfirmationModalProps> = ({
  title,
  text = '',
  buttonText,
  buttonAction,
  buttonCancelAction,
  buttonCancelText = '',
  isOpen,
  setIsOpen,
  isWarning,
}) => {
  const btnCancel = translate('btnCancel');
  function cancelAction() {
    if (buttonCancelAction) buttonCancelAction();
    setIsOpen(false);
  }
  return (
    <WppModal open={isOpen} disableOutsideClick={true}>
      <ModalHeader slot="header">
        {isWarning && <WppIconWarning slot="icon-start" />}
        <ModalTitle>{title}</ModalTitle>
      </ModalHeader>
      {text && <ModalText slot="body">{text}</ModalText>}
      <ModalActions slot="actions">
        <BoxContent flex justify="flex-end">
          <BoxContent w="auto" marg="0 12 0 0">
            <WppActionButton variant="secondary" onClick={() => cancelAction()}>
              {buttonCancelText ? buttonCancelText : btnCancel}
            </WppActionButton>
          </BoxContent>
          <BoxContent w="auto">
            <WppButton onClick={() => buttonAction()} variant={isWarning ? 'destructive' : 'primary'} size="s">
              {' '}
              {buttonText}
            </WppButton>
          </BoxContent>
        </BoxContent>
      </ModalActions>
    </WppModal>
  );
};

export default ConfirmationModal;
