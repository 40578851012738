import React, { useCallback, useContext, useEffect, useState } from 'react';
import { BoxContent } from '@connected-core-system/utils/frontend';
import SelectedContents from './SelectedContents';
import SelectedStory from './SelectedStory';
import ContentPreview from './ContentPreview';
import { Container } from './styles/ContentStreamForm.styled';
import { ContentsTableDataType } from '../types/ContentsTableDataType';
import { usePromise } from '../../../shared/hooks';
import { mapIdsFromContentArray } from '../utils/contentStreamCreateUtils';
import contentCreateManager from '../services/ContentStreamCreateManager';
import { IOrganisation } from '../../../shared/types';
import { ContentStreamTableDataType } from '../../ContentStream/types/ContentStreamTableDataType';
import { validateTabContentToPublish } from '../utils/contentStreamContentUtils';
import { IContentStreamRequestsDoneStates } from '../types/IContentStreamRequestsDoneStates';
import { BackofficeAppContext } from '../../../app/context/BackofficeAppContext';

export interface ITabContentProps {
  selectedStory: Partial<ContentsTableDataType>[];
  setSelectedStory: (selectedStory: Partial<ContentsTableDataType>[]) => void;
  selectedContent: Partial<ContentsTableDataType>[];
  setSelectedContent: (selectedContent: Partial<ContentsTableDataType>[]) => void;
  isInvalidContentTab: boolean;
  setIsInvalidContentTab: (isInvalidContentTab: boolean) => void;
  selectedContentStream?: ContentStreamTableDataType | null;
  selectedOrganisation?: IOrganisation | null;
  setRequestsDone: (requestsDone: React.SetStateAction<IContentStreamRequestsDoneStates>) => void;
}

const TabContent: React.FC<ITabContentProps> = ({
  selectedStory,
  setSelectedStory,
  selectedContent,
  setSelectedContent,
  selectedContentStream,
  selectedOrganisation,
  isInvalidContentTab,
  setIsInvalidContentTab,
  setRequestsDone,
}) => {
  const [selectedContentToPreview, setSelectedContentToPreview] = useState<Partial<ContentsTableDataType> | null>(
    selectedContent ? selectedContent[0] : null,
  );

  const { insideWppOs } = useContext(BackofficeAppContext);

  useEffect(() => {
    if (selectedContent && selectedContent.length > 0) {
      if (selectedContentToPreview && !selectedContent.includes(selectedContentToPreview))
        setSelectedContentToPreview(selectedContent[0]);
      else if (!selectedContentToPreview) {
        setSelectedContentToPreview(selectedContent[0]);
      }
    }
  }, [selectedContent, selectedContentToPreview, selectedContent.length]);

  useEffect(() => {
    if (isInvalidContentTab && validateTabContentToPublish(selectedContent, selectedStory)) {
      setIsInvalidContentTab(false);
    }
  }, [isInvalidContentTab, selectedContent, selectedStory, setIsInvalidContentTab]);

  const loadExistentContents = useCallback(() => {
    const existentStoryId = selectedContentStream?.linkedStory || '';
    const existentContentId = mapIdsFromContentArray(selectedContentStream?.linkedContent);
    if (selectedOrganisation?.id && (existentStoryId || existentContentId) && selectedContentStream) {
      return contentCreateManager
        .listContentsFromIds(selectedOrganisation.id, existentStoryId, existentContentId)
        .then((contentsFromIds) => {
          if (contentsFromIds && existentStoryId) {
            const story = contentsFromIds.find((content) => content.id === existentStoryId);
            setSelectedStory(story ? [story] : []);
          }
          if (contentsFromIds && existentContentId) {
            setSelectedContent(contentsFromIds.filter((content) => existentContentId.includes(content.id)));
          }
          setRequestsDone((prevLoadingStates) => ({
            ...prevLoadingStates,
            contents: true,
          }));
        });
    }
    return null;
  }, [selectedContentStream, selectedOrganisation?.id, setRequestsDone, setSelectedStory, setSelectedContent]);

  const { ...promiseStatusOptions } = usePromise(loadExistentContents);

  return (
    <Container flex marg="24 0 16 0" data-testid="tab-content-container">
      <BoxContent w="75%" marg="0 16 0 0">
        <SelectedStory
          selectedStory={selectedStory}
          setSelectedStory={setSelectedStory}
          isLoading={promiseStatusOptions.loading}
          isInvalidContentTab={isInvalidContentTab}
        />
        <SelectedContents
          selectedContent={selectedContent}
          setSelectedContent={setSelectedContent}
          isLoading={promiseStatusOptions.loading}
          selectedContentToPreview={selectedContentToPreview}
          setSelectedContentToPreview={setSelectedContentToPreview}
          isInvalidContentTab={isInvalidContentTab}
        />
      </BoxContent>
      <BoxContent w="25%">
        <ContentPreview
          selectedContents={selectedContent}
          selectedContentToPreview={selectedContentToPreview}
          setSelectedContentToPreview={setSelectedContentToPreview}
          selectedOrganisation={selectedOrganisation}
        />
      </BoxContent>
    </Container>
  );
};

export default TabContent;
