import { HttpService } from '@core/http-service';
import { interpolateWithValues } from '../../../shared/utils';
import { ITagCategory } from '../../../shared/types';
import { HttpResolver, BFF_ENDPOINTS } from '../../../shared/api';
import { ITagCategoryRepository } from '../types/ITagCategoryRepository';

export class TagCategoryRepository implements ITagCategoryRepository {
  constructor(private readonly httpService: HttpService) {}
  fetchOrganisationTags(organisationId: string) {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_TAGS, organisationId);
    return this.httpService.get<ITagCategory[]>(url);
  }
}

export default new TagCategoryRepository(HttpResolver.getHttpService());
