import React, { useContext, ReactNode } from 'react';
import { BackofficeAppContext } from '../../../app/context/BackofficeAppContext';

interface IPlatformWrapperProps {
  componentBare: React.ComponentType<any>;
  componentOs: React.ComponentType<any>;
  children?: ReactNode;
  [key: string]: any;
}

const PlatformWrapper: React.FC<IPlatformWrapperProps> = ({
  componentBare: ComponentBare,
  componentOs: ComponentOs,
  children,
  ...props
}) => {
  const { insideWppOs } = useContext(BackofficeAppContext);

  if (insideWppOs) {
    return <ComponentOs {...props}>{children}</ComponentOs>;
  }
  return <ComponentBare {...props}>{children}</ComponentBare>;
};

export default PlatformWrapper;
