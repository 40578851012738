import { useEffect } from 'react';
import { useUserInfo } from './useUserInfo';
import { RolesEnum } from '@connected-core-system/utils/types';
import { useWppOpenApi, useWppOpenContext } from '../../WppOs';
import authManager from '../services/AuthorizationManager';

export const useAuthBackofficeOs = (): { isAuthenticated: boolean; error: boolean } => {
  const { osContext } = useWppOpenContext();
  const { osApi } = useWppOpenApi();

  const { userInfo, setUserInfo } = useUserInfo();
  useEffect(() => {
    const user = osContext.userDetails;
    const token = osApi.getAccessToken();
    const permissions = osContext.permissions;

    authManager.saveUserToken(token);

    setUserInfo({
      id: user.id,
      roles: [RolesEnum.ADMIN], // MUST MAP permissions TO EQUIVALENT RolesEnum
      isAuthenticated: !!(token && user.email),
      email: user.email,
      name: `${user.firstname} ${user.lastname}`,
    });
  }, [osApi, osContext.permissions, osContext.userDetails, setUserInfo]);

  return {
    isAuthenticated: !!userInfo?.isAuthenticated,
    error: false,
  };
};
