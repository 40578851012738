import styled from '@emotion/styled';

export const SecondTierSidebarContainerBare = styled.div({
  width: '30rem',
  height: '100%',
  borderRight: '1px solid #e7e0ec',
  display: 'grid',
  gridTemplateRows: 'auto auto 1fr auto',
});

export const SecondTierSidebarContainerOs = styled.div({
  width: '32rem',
  height: '100%',
  borderRight: '1px solid #e7e0ec',
  display: 'grid',
  gridTemplateRows: 'auto auto 1fr auto',
});
