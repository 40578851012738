import React, { PropsWithChildren, forwardRef } from 'react';
import { BoxContainer, IBoxContainerProps } from './BoxContent.styled';

export interface IBoxContentProps extends IBoxContainerProps {
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

const BoxContent = forwardRef<HTMLDivElement, IBoxContentProps & PropsWithChildren>((props, ref) => {
  const { children, ...rest } = props;

  return (
    <BoxContainer ref={ref} {...rest}>
      {children}
    </BoxContainer>
  );
});

export default BoxContent;
