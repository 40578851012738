import styled from '@emotion/styled';
import { WppFileUpload } from '@wppopen/components-library-react';

export interface StyledModalProps {
  isSmallScreen: boolean;
}

export const StyledWppFileUpload = styled(WppFileUpload)<StyledModalProps>(({ isSmallScreen }) => ({
  '&::part(file-list)': {
    width: '100%',
  },
  maxWidth: 300,
  ...(!isSmallScreen && {
    padding: 24,
  }),
}));
