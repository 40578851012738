import React from 'react';
import { WppIconCross, WppIconSearch, WppInput } from '@wppopen/components-library-react';
import { BoxContent } from '../../../../shared/components';
import { translate } from '../../../../shared/locales/i18n';

export interface IContentsHeaderProps {
  onSearch: (text: string) => void;
  searchTerm: string;
}

const ContentsHeader: React.FC<IContentsHeaderProps> = ({ searchTerm, onSearch }) => {
  const renderSearchBar = () => {
    return (
      <BoxContent flex marg="0 0 28 0">
        <BoxContent w={'100%'}>
          <WppInput
            data-testid="search-channel"
            name="search-channel"
            placeholder={translate('txtPlaceholderSearch')}
            value={searchTerm}
            onWppChange={(e: CustomEvent) => onSearch(e.detail.value)}>
            <WppIconSearch slot="icon-start" aria-label="Search icon" />
            <WppIconCross slot="icon-end" aria-label="Clear icon" onClick={() => onSearch('')} />
          </WppInput>
        </BoxContent>
      </BoxContent>
    );
  };

  return (
    <BoxContent flex align="center">
      <>{renderSearchBar()}</>
    </BoxContent>
  );
};

export default ContentsHeader;
