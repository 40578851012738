/**
 * Use this to apply business rules on data input/output
 */
import { ITagCategoryRepository } from '../types/ITagCategoryRepository';
import tagCategoryRepositoryInstance from './TagCategoryRepository';
import { ITagCategory } from '../../../shared/types';

export class TagCategoryManager {
  constructor(private repo: ITagCategoryRepository) {}

  async fetchOrganisationTags(organisationId: string): Promise<ITagCategory[] | void> {
    try {
      const res = await this.repo.fetchOrganisationTags(organisationId);
      return res.data;
    } catch (error) {
      return this.handleError(error);
    }
  }

  handleError(error: unknown) {
    throw error;
  }
}

export default new TagCategoryManager(tagCategoryRepositoryInstance);
