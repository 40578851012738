import { ExceptionBase } from '../exception.base';
import { ExceptionCode, ExceptionMessages } from '../enum';

export class ForbiddenException extends ExceptionBase {
  static readonly message = ExceptionMessages.Forbidden;

  constructor(message = ForbiddenException.message, cause?: Error) {
    super(message, cause);
  }

  readonly code = ExceptionCode.Forbbiden;
}
