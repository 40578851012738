import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import {
  IContentStreamCreateEditActions,
  IContentStreamCreateEditState,
} from '../sliceStore/contentStreamCreateEditSliceStore';

export const useIsFallbackContentStreamState = () => {
  const isFallbackContentStream = useSliceState<IContentStreamCreateEditState, 'isFallbackContentStream'>(
    'isFallbackContentStream',
  );
  const setIsFallbackContentStream = useSliceSetter<IContentStreamCreateEditActions, 'setIsFallbackContentStream'>(
    'setIsFallbackContentStream',
  );

  return { isFallbackContentStream, setIsFallbackContentStream };
};
