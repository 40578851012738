import { ReactNode } from 'react';

export const DSListItemMock = ({
  children,
  onClick,
}: {
  children?: ReactNode;
  onClick?: React.MouseEventHandler<HTMLWppListItemElement>;
}) => (
  <span data-testid="mock-list-item" onClick={onClick}>
    {children}
  </span>
);
