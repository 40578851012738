const DEFAULT_CELL_WITDTH = 180;
const DEFAULT_SCROLL_BAR = 8;

export const calculateDynamicTableColumnsWidth = (columnDefs: any[], width: number) => {
  if (width) {
    let calculatedWidth = width - DEFAULT_SCROLL_BAR;

    const columnsNoFraction = columnDefs.filter((c) => !c.fraction).length;

    const columnsWithFraction = columnDefs
      .map((c) => {
        if (c.fraction) {
          const cellNewWidth = Math.max(
            Math.min(DEFAULT_CELL_WITDTH, c.minWidth || DEFAULT_CELL_WITDTH),
            c.fraction * width,
          );
          calculatedWidth = calculatedWidth - cellNewWidth;
          return { ...c, width: cellNewWidth };
        }
        return c;
      })
      .map((c) => {
        if (!c.fraction) {
          const cellNewWidth = Math.max(
            Math.min(DEFAULT_CELL_WITDTH, c.minWidth || DEFAULT_CELL_WITDTH),
            calculatedWidth / columnsNoFraction,
          );

          return { ...c, width: cellNewWidth };
        }
        return c;
      });
    return columnsWithFraction;
  }
  return columnDefs;
};

export const capitalize = (str: string) => str.toUpperCase();
