export enum ExceptionCode {
  ArgumentInvalid = 'GENERIC.ARGUMENT_INVALID',
  ArgumentNotProvided = 'GENERIC.ARGUMENT_NOT_PROVIDED',
  ArgumentOutOfRange = 'GENERIC.ARGUMENT_OUT_OF_RANGE',
  BadRequest = 'GENERIC.BAD_REQUEST',
  Conflict = 'GENERIC.CONFLICT',
  Forbbiden = 'GENERIC.FORBBIDEN',
  InternalServerError = 'GENERIC.INTERNAL_SERVER_ERROR',
  NotFound = 'GENERIC.NOT_FOUND',
  NotSupported = 'GENERIC.NOT_SUPPORTED',
  Unauthorized = 'AUTH.UNAUTHORIZED',
  UniquePropertyExistsException = 'COSMOS.UNIQUE-PROPERTY.EXISTS',
}
