/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { TitleContainer, StyledWppTyphography } from './styles/Title.styled';

export interface ITitleProps {
  name: string;
}

const Title: React.FC<ITitleProps> = ({ name }) => {
  return (
    <TitleContainer>
      <StyledWppTyphography tag="h1" type="m-strong">
        {name}
      </StyledWppTyphography>
    </TitleContainer>
  );
};

export default Title;
