import React, { useCallback, useEffect, useState } from 'react';
import { BoxContent } from '../../../shared/components';
import { WppTypography, WppRadio, WppIconError } from '@wppopen/components-library-react';
import ExpandableCard from '../../../shared/components/ExpandableCard/ExpandableCard';
import {
  LastGridContainer,
  RadioContainer,
  GridContainer,
  StyledInput,
  StyledRadioGroup,
  StyledSelectDropDown,
} from '../styles/ExpandablesStyle.styled';
import { QRColorType, QRCornersDotsOptions, QRGradientType } from '../types/QRStyleTypes';
import { translate } from '../../../shared/locales';
import ColorField from '../../../shared/components/SelectColor/ColorField';
import { useCornersDotsOptionsState } from '../hooks/useCornersDotsOptionsState';
import { CornerDotType, Options } from 'qr-code-styling';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar';
import { DEFAULT_COLOR, QRSTYLE_FORM } from '../../../shared/Constants/Constants';
import { useValueChangesState } from '../hooks/useValueChangesState';
import { WppRadioGroupCustomEvent, WppSelectCustomEvent } from '@wppopen/components-library/dist/types/components';
import { RadioGroupChangeEvent, SelectChangeEventDetail } from '@wppopen/components-library';
import { useBackgroundOptionsState } from '../hooks/useBackgroundOptionsState';
import { getColorErrorMessage } from '../utils/QrStyleUtils';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface ICornersDotsStyleProps {
  setHasError: (hasError: boolean) => void;
}

const CORNERS_DOTS_STYLE_OPTIONS: Array<{ value: CornerDotType | 'default'; label: string }> = [
  { label: 'Dot', value: 'dot' },
  { label: 'Square', value: 'square' },
  { label: 'None', value: 'default' },
];

const DEFAULT_OPTIONS = {
  CORNERS_DOTS_TYPE: 'square' as CornerDotType,
  COLOR_TYPE: 'single' as QRColorType,
  ROTATION: '0',
  GRADIENT_TYPE: 'linear' as QRGradientType,
  COLOR: [
    {
      color: DEFAULT_COLOR,
      offset: 0,
    },
    {
      color: DEFAULT_COLOR,
      offset: 1,
    },
  ],
};

const CornersDotsStyle: React.FC<ICornersDotsStyleProps> = ({ setHasError }) => {
  const [cornersDotsType, setCornersDotsType] = useState<CornerDotType | 'default'>(DEFAULT_OPTIONS.CORNERS_DOTS_TYPE);
  const [colorType, setColorType] = useState<QRColorType>(DEFAULT_OPTIONS.COLOR_TYPE);
  const [rotation, setRotation] = useState<string>(DEFAULT_OPTIONS.ROTATION);
  const [gradientType, setGradientType] = useState<QRGradientType>(DEFAULT_OPTIONS.GRADIENT_TYPE);
  const [color, setColor] = useState(DEFAULT_OPTIONS.COLOR);
  const [errorMessage, setErrorMessage] = useState<string[]>([]);

  const { backgroundOptions } = useBackgroundOptionsState();

  const { setCornersDotOptions } = useCornersDotsOptionsState();
  const { selectedSecondTierItem } = useSecondTierSelectedItemState();

  const { valueChanges, setValueChanges } = useValueChangesState();

  const isGradientTypeSelected = colorType === 'gradient';
  const isCornersDotsStyleSelectedDefault = cornersDotsType === 'default';

  const fillStyleFields = useCallback(() => {
    if ((selectedSecondTierItem as any)?.configuration) {
      const options: Options = (selectedSecondTierItem as any)?.configuration;
      const cornersDotOptions: QRCornersDotsOptions = options.cornersDotOptions;

      const setCornersDotOptionsData = () => {
        if (cornersDotOptions?.type) setCornersDotsType(cornersDotOptions?.type);

        if (cornersDotOptions?.gradient) {
          setColorType('gradient');
          setGradientType(cornersDotOptions.gradient.type);
          setColor(cornersDotOptions.gradient.colorStops);
          if (cornersDotOptions.gradient.rotation) setRotation(cornersDotOptions.gradient.rotation?.toString());
        } else {
          setColorType('single');
          if (cornersDotOptions?.color) setColor([{ color: cornersDotOptions.color, offset: 0 }]);
        }
      };

      setCornersDotOptionsData();
    } else {
      const setDefaultOptions = () => {
        setCornersDotsType(DEFAULT_OPTIONS.CORNERS_DOTS_TYPE);
        setColorType(DEFAULT_OPTIONS.COLOR_TYPE);
        setRotation(DEFAULT_OPTIONS.ROTATION);
        setGradientType(DEFAULT_OPTIONS.GRADIENT_TYPE);
        setColor(DEFAULT_OPTIONS.COLOR);
      };

      setDefaultOptions();
    }
  }, [selectedSecondTierItem]);

  useEffect(() => {
    fillStyleFields();
  }, [fillStyleFields, selectedSecondTierItem]);

  useEffect(() => {
    setErrorMessage(getColorErrorMessage(color, backgroundOptions, isGradientTypeSelected));
  }, [backgroundOptions, color, isGradientTypeSelected]);

  useEffect(() => {
    if (errorMessage[0] || errorMessage[1]) {
      setHasError(true);
    } else setHasError(false);
  }, [errorMessage, setHasError]);

  const handleChange = (
    field: string,
    e: WppSelectCustomEvent<SelectChangeEventDetail> | WppRadioGroupCustomEvent<RadioGroupChangeEvent>,
  ) => {
    switch (field) {
      case QRSTYLE_FORM.cornersDotsType:
        setCornersDotsType(e.detail.value as CornerDotType);
        break;
      case QRSTYLE_FORM.colorType:
        setColorType(e.target.value as QRColorType);
        break;
      case QRSTYLE_FORM.rotation:
        setRotation(e.detail.value as string);
        break;
      case QRSTYLE_FORM.gradient:
        setGradientType(e.target.value as QRGradientType);
        break;
    }
    setValueChanges({ ...valueChanges, newValue: true });
  };

  useEffect(() => {
    setCornersDotOptions({
      color: color[0].color,
      ...(!isCornersDotsStyleSelectedDefault && {
        type: cornersDotsType,
      }),
      gradient: isGradientTypeSelected
        ? {
            rotation: Number(rotation),
            type: gradientType,
            colorStops: color,
          }
        : undefined,
    });
  }, [
    color,
    cornersDotsType,
    colorType,
    rotation,
    gradientType,
    isGradientTypeSelected,
    setCornersDotOptions,
    isCornersDotsStyleSelectedDefault,
  ]);

  return (
    <ExpandableCard title={translate('txtCornersDotsTitle')}>
      {(errorMessage[0] || errorMessage[1]) && <WppIconError slot="actions" />}
      <BoxContent direction="column" data-testid="corners-dots-style-container">
        <GridContainer>
          <WppTypography type="s-strong">{translate('txtCornersDotsStyleLabel')}</WppTypography>

          <WppTypography type="s-strong">{translate('txtColorTypeLabel')}</WppTypography>
          <div>&nbsp;</div>

          <StyledSelectDropDown
            options={CORNERS_DOTS_STYLE_OPTIONS}
            value={cornersDotsType}
            onWppChange={(e) => handleChange(QRSTYLE_FORM.cornersDotsType, e)}
          />
          <StyledRadioGroup
            value={colorType}
            onWppChange={(e) => handleChange(QRSTYLE_FORM.colorType, e)}
            data-testid="color-type-radio-group">
            <RadioContainer>
              <WppRadio
                name="single-color"
                value="single"
                labelConfig={{ text: translate('txtSingleColor') }}
                required
                checked={colorType === 'single'}
              />
              <WppRadio
                name="gradient-color"
                value="gradient"
                labelConfig={{ text: translate('txtGradientColor') }}
                required
                checked={isGradientTypeSelected}
              />
            </RadioContainer>
          </StyledRadioGroup>
        </GridContainer>
        {isGradientTypeSelected && (
          <GridContainer>
            <WppTypography type="s-strong">Rotation</WppTypography>
            <WppTypography type="s-strong">Gradient type</WppTypography>
            <div>&nbsp;</div>

            <StyledInput
              type="number"
              value={rotation as string}
              onWppChange={(e) => handleChange(QRSTYLE_FORM.rotation, e)}
              data-testid="rotation-input"
            />

            <StyledRadioGroup
              value={gradientType}
              onWppChange={(e) => handleChange(QRSTYLE_FORM.gradient, e)}
              data-testid="gradient-type-radio-group">
              <RadioContainer>
                <WppRadio
                  name="linear"
                  value="linear"
                  labelConfig={{ text: 'Linear' }}
                  required
                  checked={gradientType === 'linear'}
                  data-testid="linear-radio"
                />
                <WppRadio
                  name="radial"
                  value="radial"
                  labelConfig={{ text: 'Radial' }}
                  required
                  checked={gradientType === 'radial'}
                  data-testid="radial-radio"
                />
              </RadioContainer>
            </StyledRadioGroup>
          </GridContainer>
        )}
        <LastGridContainer>
          <WppTypography type="s-strong">{translate('txtColor')}</WppTypography>
          <div>&nbsp;</div>
          <div>&nbsp;</div>

          <ColorField
            color={color}
            setColor={setColor}
            disabled={false}
            isGradient={isGradientTypeSelected}
            errorMessage={errorMessage}
          />
        </LastGridContainer>
      </BoxContent>
    </ExpandableCard>
  );
};

export default CornersDotsStyle;
