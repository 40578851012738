import { DayOfWeekEnum } from '@connected-core-system/utils/types';
import { TargetingTimeDTO } from '@connected-core-system/utils/content-stream-http-dto';

export const getTargetTime = (startTimeValue: string, endTimeValue: string, weekDays: DayOfWeekEnum[]) => {
  const startTime = startTimeValue ? createDateFormat(startTimeValue) : null;
  const endTime = endTimeValue ? createDateFormat(endTimeValue) : null;
  const data = weekDays?.map((day) => {
    return {
      startTime: startTime,
      endTime: endTime,
      dayOfWeekEnum: day,
    };
  });
  return data;
};

const createDateFormat = (hour: string) => {
  const [hours, minutes] = hour.split(':').map(Number);
  const date = new Date(1970, 0, 1, hours, minutes);
  const offSet = date.getTimezoneOffset() / -60;
  date.setHours(hours + offSet);
  date.setMinutes(minutes);
  return date;
};

export function mapExistingTargetingTime(targetingTime: TargetingTimeDTO[]) {
  let startTime = '',
    endTime = '',
    timeDay: DayOfWeekEnum[] = [];

  const hoursTimeZone = new Date().getTimezoneOffset() / 60;
  if (targetingTime.length > 0) {
    timeDay = targetingTime.map((time) => {
      if (time.startTime) {
        const startDate = new Date(time.startTime);
        startDate.setHours(new Date(time.startTime).getHours() + hoursTimeZone);
        startTime = startDate.toTimeString().slice(0, 5);
      }
      if (time.endTime) {
        const endDate = new Date(time.endTime);
        endDate.setHours(new Date(time.endTime).getHours() + hoursTimeZone);
        endTime = endDate.toTimeString().slice(0, 5);
      }
      return time.dayOfWeekEnum;
    });
  }
  return { timeDay, startTime, endTime };
}
