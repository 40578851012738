/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { IFadeProps, Container } from './FadeWrapper.styled';

export interface IFadeWrapperProps extends IFadeProps { }

const FadeWrapper: React.FC<IFadeWrapperProps & React.PropsWithChildren> = (props) => {
  return (
    <Container {...props}>
      {props.children}
    </Container>
  );
};

export default FadeWrapper;
