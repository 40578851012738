import { SetCallback } from '../../../shared/store';

export interface IOrganisationUnitPageState {
  unitValueChanges: boolean;
}

export const initialState: IOrganisationUnitPageState = {
  unitValueChanges: false,
};

const actions = (set: SetCallback<IOrganisationUnitPageState>) => ({
  setUnitValueChanges: (unitValueChanges: boolean) => set((state) => ({ ...state, unitValueChanges })),
});

export const slice = (set: SetCallback<IOrganisationUnitPageState>) => ({
  ...initialState,
  ...actions(set),
});

export type IOrganisationUnitPageActions = ReturnType<typeof actions>;

export default {
  slice,
  initialState,
};
