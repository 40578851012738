import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { ExceptionBase, UniquePropertyExistsException } from '@core/exceptions';

import { DSIconArchive, DSTabsChangeEventDetail } from '../../shared';
import { Header, HeaderActions, Tabs } from '../../shared/components';
import { IHeaderActionsItem } from '../../shared/components/HeaderActions/HeaderActions';
import { translate } from '../../shared/locales/i18n';
import { MenuItemEnum, StatusEnum } from '../../shared/types';
import { ChannelTable } from '../Channel';
import FallbackWrapper from '../Feedback/views/FallbackWrapper';
import { tabs } from '../Home/utils/homeUtils';
import {
  useSecondTierItemIdToSelectOnReloadState,
  useSecondTierReloadState,
  useSecondTierSelectedItemState,
} from '../SecondTierSidebar';
import { SecondTierSidebarItem } from '../SecondTierSidebar/types';
import { useSelectedOrganisationState } from '../SideMenu/hooks/useSelectedOrganisarionState';
import TabDetails from './TabDetails';
import {
  ContainerBare,
  ContainerOs,
  HeaderActionsContainer,
  HeaderContainer,
  MainContentTableContainer,
  TabsContainer,
} from './UnitPage.styled';
import { useUnitValueChangesState } from './hooks';
import { organisationUnitPageManager } from './services';
import PlatformWrapper from '../../shared/components/PlatformWrapper/PlatformWrapper';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IOrganisationUnitPageProps {}

const OrganisationUnitPage: React.FC<IOrganisationUnitPageProps> = () => {
  const [selectedTab, setSelectedTab] = useState('details');

  const { setSecondTierReload } = useSecondTierReloadState();
  const { selectedSecondTierItem, setSelectedSecondTierItem } = useSecondTierSelectedItemState();
  const { setItemIdToSelectOnReload } = useSecondTierItemIdToSelectOnReloadState();
  const { selectedOrganisation } = useSelectedOrganisationState();
  const { setUnitValueChanges } = useUnitValueChangesState();
  const navigate = useNavigate();
  const [newUnitTitle, setNewUnitTitle] = useState(selectedSecondTierItem?.name || '');
  const [promise, setPromise] = useState({
    loading: false,
    error: false,
    success: false,
    successToastMessage: '',
    errorToastMessage: '',
  });

  useEffect(() => {
    if (selectedSecondTierItem && selectedSecondTierItem.status !== StatusEnum.UNPUBLISHED) {
      setNewUnitTitle(selectedSecondTierItem?.name);
      setSelectedTab('details');
    } else {
      setNewUnitTitle('');
    }
  }, [selectedSecondTierItem]);

  const handleTabChange = (event: CustomEvent<DSTabsChangeEventDetail>) => {
    setSelectedTab(event.detail.value);
  };

  const getSaveActionButtonState = () => {
    if (selectedSecondTierItem) {
      if (newUnitTitle === '') return true;
      return newUnitTitle === selectedSecondTierItem.name;
    }
    return false;
  };

  const archiveAction: IHeaderActionsItem = {
    title: translate('txtArchive'),
    type: 'secondary',
    icon: <DSIconArchive slot="icon-start" />,
    action: () => {
      alert('Archive Clicked');
    },
  };

  const saveAction: IHeaderActionsItem = {
    title: translate('txtSave'),
    type: 'primary',
    disabled: getSaveActionButtonState(),
    loading: promise.loading,
    action: () => {
      if (selectedOrganisation && selectedSecondTierItem) {
        setPromise({
          loading: true,
          error: false,
          success: false,
          successToastMessage: '',
          errorToastMessage: '',
        });
        if (selectedSecondTierItem.status === StatusEnum.UNPUBLISHED) createUnit(selectedOrganisation.id);
        else updateUnit(selectedOrganisation.id, selectedSecondTierItem);
      }
    },
  };

  const createUnit = (organisationId: string) => {
    organisationUnitPageManager
      .createNewOrganisationUnit(organisationId, newUnitTitle)
      .then((returnedId) => {
        setPromise({
          loading: false,
          error: false,
          success: true,
          successToastMessage: translate('txtSuccessCreated', { something: translate('txtUnit') }),
          errorToastMessage: '',
        });
        setUnitValueChanges(false);
        setSecondTierReload(true);
        setItemIdToSelectOnReload(returnedId ?? null);
        setSelectedSecondTierItem({ name: newUnitTitle, id: returnedId ?? '', status: StatusEnum.ACTIVE });
        navigate(`/organisation/${organisationId}/units/${returnedId}`);
      })
      .catch((error) => {
        catchUnitError(error);
      });
  };

  const updateUnit = (organisationId: string, secondTierItem: SecondTierSidebarItem) => {
    organisationUnitPageManager
      .updateOrganisationUnit(organisationId, { ...secondTierItem, name: newUnitTitle })
      .then(() => {
        setPromise({
          loading: false,
          error: false,
          success: true,
          successToastMessage: translate('txtChangesSaved'),
          errorToastMessage: '',
        });
        setUnitValueChanges(false);
        setSecondTierReload(true);
        setItemIdToSelectOnReload(secondTierItem.id ?? null);
        navigate(`/organisation/${organisationId}/units/${secondTierItem.id}`);
      })
      .catch((error) => {
        catchUnitError(error);
      });
  };

  const catchUnitError = (error: ExceptionBase) => {
    const errorMessage =
      error instanceof UniquePropertyExistsException ? translate('txtTitleUsed') : translate('txtErrorMessage');
    setPromise({
      loading: false,
      error: true,
      success: false,
      successToastMessage: '',
      errorToastMessage: errorMessage,
    });
  };

  const getTagColor = () => {
    return selectedSecondTierItem?.status === StatusEnum.ACTIVE ? 'positive' : 'neutral';
  };

  const getHeaderActions = () => {
    return selectedSecondTierItem?.status === StatusEnum.ACTIVE ? [archiveAction, saveAction] : [saveAction];
  };

  const detailsContent = () => {
    return <TabDetails newUnitTitle={newUnitTitle} setNewUnitTitle={setNewUnitTitle} />;
  };

  const channelsContent = () => {
    return (
      <MainContentTableContainer>
        <ChannelTable location={MenuItemEnum.UNIT} data-testid={'channel-table-unit'} />
      </MainContentTableContainer>
    );
  };

  return selectedSecondTierItem ? (
    <PlatformWrapper componentBare={ContainerBare} componentOs={ContainerOs}>
      <FallbackWrapper {...promise} />
      <HeaderContainer>
        <Header
          title={selectedSecondTierItem?.name || ''}
          tag={selectedSecondTierItem.status}
          tagColor={getTagColor()}
        />
      </HeaderContainer>
      <HeaderActionsContainer>
        <HeaderActions actions={getHeaderActions()} />
      </HeaderActionsContainer>
      <TabsContainer>
        <Tabs tabs={tabs} handleTabChange={handleTabChange} selectedTab={selectedTab} />
      </TabsContainer>
      {selectedTab === 'channels' ? channelsContent() : detailsContent()}
    </PlatformWrapper>
  ) : null;
};

export default OrganisationUnitPage;
