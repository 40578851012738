import styled from '@emotion/styled';
import { WppButton } from '@wppopen/components-library-react';

export const ButtonContainer = styled.div({
  textAlign: 'center',
  padding: 16,
  paddingTop: 0,
});

export const ButtonInnerContainer = styled.div({
  borderTop: '1px solid #e7eaee',
  paddingTop: 16,
  width: '100%',
});

export const StyledWppButton = styled(WppButton)({
  width: '100%',
  '&::part(button)': {
    width: '100%',
    fontSize: 12,
  },
});
