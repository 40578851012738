/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useContext } from 'react';
import {
  WppGrid,
  WppIconDoubleChevron,
  WppIconLogout,
  WppIconPeople,
  WppIconShieldCheckmark,
  WppTypography,
} from '@wppopen/components-library-react';
import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { BoxContent, Divider, RotateIcon } from '../../../shared/components';
import { translate } from '../../../shared/locales/i18n';
import { ISideMenuState, ISideMenuActions } from '../sliceStore/sideMenuSliceStore';
import MenuItem from './MenuItem';
import { Container, HearderText, LogoutContainerBare, LogoutContainerOs } from './styles/AdminSection.styled';
import { FooterConteinerBare, FooterConteinerOs, IconContainer } from './styles/MenuItem.styled';
import { MenuItemEnum } from '../../../shared/types';
import { useUserInfo } from '../../Authorization/hooks/useUserInfo';
import { useTheme } from '@emotion/react';
import { ITheme } from '@connected-core-system/utils/frontend';
import { BackofficeAppContext } from '../../../app/context/BackofficeAppContext';
import PlatformWrapper from '../../../shared/components/PlatformWrapper/PlatformWrapper';

export interface IAdminSectionProps {}

const AdminSection: React.FC<IAdminSectionProps> = () => {
  const theme = useTheme() as ITheme;
  const isMenuOpened = useSliceState<ISideMenuState, 'isMenuOpened'>('isMenuOpened');
  const setSideMenu = useSliceSetter<ISideMenuActions, 'switchSideMenu'>('switchSideMenu');
  const { userInfo } = useUserInfo();
  const { insideWppOs } = useContext(BackofficeAppContext);

  const renderItems = () => {
    return (
      <Container>
        <MenuItem
          id={MenuItemEnum.USERS}
          value={translate('txtUsers')}
          onlyIcon={!isMenuOpened}
          icon={<WppIconPeople slot="left" />}
        />
        <MenuItem
          id={MenuItemEnum.ROLES}
          value={translate('txtRoles')}
          onlyIcon={!isMenuOpened}
          icon={<WppIconShieldCheckmark slot="left" />}
        />
      </Container>
    );
  };
  const renderExpanded = () => {
    return (
      <>
        <Container>
          <HearderText type="s-strong">
            {translate('txtAdmin')}
            {renderItems()}
          </HearderText>
        </Container>
        <Divider {...(!isMenuOpened && { width: '50%' })} />
        <PlatformWrapper
          componentBare={LogoutContainerBare}
          componentOs={LogoutContainerOs}
          flex
          justify="space-between"
          align={insideWppOs ? 'start' : 'center'}>
          <BoxContent flex align={insideWppOs ? 'start' : 'center'}>
            <WppTypography type="s-strong">{userInfo?.name}</WppTypography>
            <Container>
              <WppIconLogout color={theme.palette['danger400']} />
            </Container>
          </BoxContent>
          <div>
            <RotateIcon degrees={180}>
              <WppIconDoubleChevron onClick={() => setSideMenu(!isMenuOpened)} size={insideWppOs ? 's' : 'm'} />
            </RotateIcon>
          </div>
        </PlatformWrapper>
      </>
    );
  };
  const renderColapsed = () => {
    return (
      <>
        <Container>
          <WppGrid container item all={24} fullWidth justifyContent="center">
            <WppGrid item all={24} container justifyContent="center" direction="column">
              {renderItems()}
              <Divider {...(!isMenuOpened && { width: '50%' })} />
            </WppGrid>
          </WppGrid>
          <IconContainer>
            <WppIconLogout color={theme.palette['danger400']} />
          </IconContainer>
        </Container>
        <Divider {...(!isMenuOpened && { width: '50%' })} />
        <PlatformWrapper componentBare={FooterConteinerBare} componentOs={FooterConteinerOs}>
          <WppIconDoubleChevron onClick={() => setSideMenu(!isMenuOpened)} size={insideWppOs ? 's' : 'm'} />
        </PlatformWrapper>
      </>
    );
  };
  return isMenuOpened ? renderExpanded() : renderColapsed();
};

export default AdminSection;
