import { interpolateWithValues as interpolateString } from '@connected-core-system/utils/types';

export const getStringEllipsed = (string: string, charLimit: number): string => {
  if (string?.length > charLimit) return string.slice(0, charLimit - 2) + '...';
  return string;
};

export const interpolateWithValues = interpolateString;

export const addQueryString = (url: string, params: object) => {
  const entries = Object.entries(params);
  entries.forEach((value, i) => {
    if (!url.includes('?')) {
      url += value[1] ? `?${value[0]}=${value[1]}` : '';
    } else {
      url += value[1] ? `&${value[0]}=${value[1]}` : '';
    }
  });
  return url;
};
