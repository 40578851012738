import { TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import {
  WppAccordion,
  WppIconTrash,
  WppTypography,
  WppDatepicker,
  WppCheckbox,
} from '@wppopen/components-library-react';

export const TargetsCard = styled(WppAccordion)(({ theme }: TStyledDefaultProps) => ({
  backgroundColor: theme?.palette?.['grey100'],
  marginBottom: 12,
  width: 'calc(100% - 12px)',
  borderRadius: 12,
  paddingRight: 12,
  position: 'relative',
  '&::part(section)': { width: '100%' },
  '&::part(title-wrapper)': { paddingLeft: 12 },
}));

export const StyledWppIconTrash = styled(WppIconTrash)({ position: 'absolute', right: 12, top: 8, cursor: 'pointer' });

export const ContainerContent = styled.div({
  marginLeft: -10,
  width: '100%',
});

export const ContainerFields = styled.div({
  display: 'inline-block',
  width: '50%',
  minWidth: 340,

  paddingTop: 8,
  paddingBottom: 8,
});

export const TimeZoneContainer = styled.div({
  width: '20%',
  display: 'inline-block',
  minWidth: 200,
  paddingTop: 8,
  verticalAlign: 'top',
  paddingBottom: 8,
});

export const DatePickerAndTimeSelectContainer = styled.div({
  width: '80%',
  display: 'inline-block',
  alignItems: 'center',

  flexWrap: 'nowrap',
});

export const InputContainer = styled.div({
  width: 'auto',
  minWidth: 160,
  display: 'inline-block',
  paddingRight: 8,
  paddingTop: 8,
});

export const CheckboxContainer = styled.div({
  marginLeft: 12,
});

export const ContainerLabel = styled.div({
  display: 'inline-block',
});

export const Label = styled(WppTypography)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey800'],
}));

export const StyledWppDatePicker = styled(WppDatepicker)({
  width: '100%',
  display: 'block',
  '&::part(datepicker-container)': {
    width: '100%',
    display: 'block',
  },
  '&::part(message)': {
    display: 'block',
  },
});

export const StyledWppCheckbox = styled(WppCheckbox)({
  marginLeft: 12,
});
