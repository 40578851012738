/**
 * Use this to apply business rules on data input/output
 */
import { IOrganisationRepository } from '../types/IOrganisationRepository';
import organisationRepoInstance from './OrganisationRepository';
import { CONFIG, BFF_ENDPOINTS } from '../../../shared/api';
import { getRandomRGB } from '../../OrganisationCreate/utils';
import { IOrganisation } from '../../../shared/types';

export class OrganisationManager {
  constructor(private repo: IOrganisationRepository) {}

  async getOrganisations() {
    try {
      const res = await this.repo.fetchOrganisations();
      return res.data.map(
        (o) =>
          ({
            ...o,
            filePath: `${CONFIG.baseURL}/${BFF_ENDPOINTS.ORGANISATION_GET}/${o.filePath}`,
            avatarColor: getRandomRGB(),
          } as IOrganisation),
      );
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getOrganisationByTenantId(tenantId: string) {
    try {
      const res = await this.repo.fetchOrganisationByTenantId(tenantId);
      const org = res.data;
      return {
        ...org,
        filePath: `${CONFIG.baseURL}/${BFF_ENDPOINTS.ORGANISATION_GET}/${org.filePath}`,
        avatarColor: getRandomRGB(),
      } as IOrganisation;
    } catch (error) {
      return this.handleError(error);
    }
  }

  handleError(error?: unknown) {
    throw error;
  }
}

export default new OrganisationManager(organisationRepoInstance);
