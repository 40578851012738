import styled from '@emotion/styled';
import { WppTypography } from '@wppopen/components-library-react';

export const TitleContainer = styled.div({
  padding: 16,
  minHeight: 40,
});

export const StyledWppTyphography = styled(WppTypography)({
  paddingTop: 8,
});
