import styled from '@emotion/styled';
import { WppCard, WppInlineMessage } from '@wppopen/components-library-react';

export const ContainerCard = styled(WppCard)({
  '--wpp-card-header-margin-s': '0',
  '--wpp-card-padding-s': '0',
});

export const InlineMessage = styled(WppInlineMessage)({
  '&::part(message)': {
    display: 'flex',
    justifyItems: 'center',
  },
});
