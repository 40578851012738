import { ExceptionBase } from '../exception.base';
import { ExceptionCode, ExceptionMessages } from '../enum';

export class InternalServerErrorException extends ExceptionBase {
  static readonly message = ExceptionMessages.InternalServerError;

  constructor(message = InternalServerErrorException.message, cause?: Error) {
    super(message, cause);
  }

  readonly code = ExceptionCode.InternalServerError;
}
