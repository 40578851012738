import styled from '@emotion/styled';
import { WppCard, WppTypography, WppModal } from '@wppopen/components-library-react';
import { BoxContent } from '../../../../shared/components';

export interface StyledModalProps {
  isSmallScreen: boolean;
}

export const StyledModal = styled(WppModal)<StyledModalProps>(({ isSmallScreen }) => ({
  '&::part(content)': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,  calc(-50% - 50px))',
  },
  ...(isSmallScreen && {
    '&::part(actions-wrapper)': {
      paddingTop: '0px',
    },
  }),
}));

export const StyledWarningMessage = styled(WppTypography)<StyledModalProps>(({ isSmallScreen }) => ({
  ...(!isSmallScreen && {
    margin: '0px 30px',
    maxWidth: '380px',
  }),
}));

export const UrlsContainer = styled(BoxContent)({
  display: 'flex',
  gap: '30px',
});

export const StyledWppDomain = styled(WppTypography)({
  lineBreak: 'anywhere',
});

export const WppCardWarning = styled(WppCard)({
  width: '100%',
  marginTop: '12px',
  display: 'flex',
  flexDirection: 'column',
});

export const WppCardWarningMainTitleContainer = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const WppCardWarningMainTitle = styled.div({
  display: 'flex',
  alignItems: 'center',
});

export const StyledWppWarningTitle = styled(WppTypography)({
  color: '#B91C1C',
});
