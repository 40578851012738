import styled from '@emotion/styled';

export interface IFadeProps {
  show: boolean;
  ms?: number;
  delay?: number;
}
export const Container = styled.div(({ show, ms = 0.5, delay = 1 }: IFadeProps) => ({
  opacity: show ? 1 : 0,
  transition: `opacity ${ms}s linear`,
  transitionDelay: `${delay}s`
}))