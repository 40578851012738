import { useOs } from '@wppopen/react';

export const useWppOpenContext = () => {
  const { osContext } = useOs();
  return {
    osContext,
  };
};

export const useWppOpenApi = () => {
  const { osApi } = useOs();
  return {
    osApi,
  };
};
