export const interpolateWithValues = (strMakeChanges: string, ...values: Array<string>) => {
  values.forEach((value) => {
    const matches = strMakeChanges.match(/{[A-Za-z]+}/g);
    if (matches) {
      // eslint-disable-next-line no-param-reassign
      strMakeChanges = strMakeChanges.replace(matches[0], value);
    }
  });
  return strMakeChanges;
};

export const addQueryString = (url: string, params: object) => {
  const entries = Object.entries(params);
  entries.forEach((value, i) => {
    if (!url.includes('?')) {
      url += value[1] ? `?${value[0]}=${value[1]}` : '';
    } else {
      url += value[1] ? `&${value[0]}=${value[1]}` : '';
    }
  });
  return url;
};
