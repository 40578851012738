import React, { ReactNode } from 'react';
import { StyledExpandableBare, StyledExpandableOs } from './ExpandableCard.styled';
import PlatformWrapper from '../PlatformWrapper/PlatformWrapper';

export interface IExpandableCardProps {
  title: string;
  children?: ReactNode;
}

const ExpandableCard: React.FC<IExpandableCardProps> = ({ title, children }) => {
  return (
    <PlatformWrapper componentBare={StyledExpandableBare} componentOs={StyledExpandableOs} header={title} size="m">
      {children}
    </PlatformWrapper>
  );
};

export default ExpandableCard;
