import styled from '@emotion/styled';

export const ModalActions = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const ModalTitle = styled.span({
  paddingLeft: 8,
});

export const ModalHeader = styled.h3({
  width: 'auto',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  paddingBottom: 0,
  paddingLeft: 4,
  paddingTop: 4,
  marginBottom: 0,
  wordBreak: 'break-word',
});

export const ModalText = styled.p({
  maxWidth: 440,
  paddingTop: 0,
  paddingLeft: 8,
});
