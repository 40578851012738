import { ExceptionBase } from '../exception.base';
import { ExceptionCode, ExceptionMessages } from '../enum';

export class UnauthorizedException extends ExceptionBase {
  static readonly message = ExceptionMessages.Unauthorized;

  constructor(message = UnauthorizedException.message, cause?: Error) {
    super(message, cause);
  }
  readonly code = ExceptionCode.Unauthorized;
}
