import { TStyledDefaultProps, BoxContent } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { DSListItem } from '../../../../shared';

export const IconContainer = styled(BoxContent)(({ pad = false }: { pad?: boolean }) => ({
  padding: `1.2rem ${pad ? '1.6rem' : '0'}`,
  boxSizing: 'border-box',
  cursor: 'pointer',
  maxHeight: '4rem',
}));

IconContainer.defaultProps = {
  flex: true,
  center: true,
};

export const ItemContainerBare = styled(DSListItem)(
  ({ pad = false, onlyIcon = false }: { pad?: boolean; onlyIcon?: boolean }) => ({
    width: onlyIcon ? '64px' : '100%',
    '&::part(item)': pad
      ? {
          padding: 0,
          paddingLeft: '2rem',
          height: '3rem',
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
          borderRadius: 0,
        }
      : {
          height: '3rem',
          marginTop: '0.5rem',
          marginBottom: '0.5rem',
        },
  }),
);

export const ItemContainerOs = styled(DSListItem)(
  ({ pad = false, onlyIcon = false }: { pad?: boolean; onlyIcon?: boolean }) => ({
    width: onlyIcon ? '64px' : '100%',
    '&::part(item)': pad
      ? {
          padding: 0,
          paddingLeft: '2rem',
          height: '3.2rem',
          marginTop: '.8rem',
          borderRadius: 0,
        }
      : {
          height: '3.2rem',
          marginTop: '.8rem',
        },
  }),
);

export const CustomText = styled.span(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey800'],
  fontStyle: 'normal',
  fontWeight: '500',
  fontSize: '1.4rem',
  lineHeight: '2.2rem',
}));

export const FooterConteinerBare = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '75px',
  cursor: 'pointer',
});

export const FooterConteinerOs = styled.div({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '24px',
  padding: '0 .8rem',
  margin: '.8rem 0',
  cursor: 'pointer',
});
