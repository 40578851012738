import React from 'react';
import { StyledWppIconTrash } from '../styles/Schedule.styled';

export interface ILocationDeleteCellProps {
  name: string;
  handleDelete: (name: string) => void;
}

const LocationDeleteCell: React.FC<ILocationDeleteCellProps> = ({ name, handleDelete }) => {
  return (
    <div>
      <StyledWppIconTrash
        onClick={() => {
          handleDelete(name);
        }}
        data-testid="icon-remove"
      />
    </div>
  );
};

export default LocationDeleteCell;
