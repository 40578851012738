export const USER_TOKEN_LOCAL_KEY = '@conn-exp-user-token';
export const REDIRECT_TO_PATH_LOCAL_KEY = '@conn-exp-redirect-to-path';

export interface ILocalPersistense {
  saveToken: (token: string, key?: string) => void;
  getToken: (key?: string) => string | null;
  clear: (key?: string) => void;
}

export class Storage implements ILocalPersistense {
  saveToken(token: string, key = USER_TOKEN_LOCAL_KEY) {
    localStorage.setItem(key, token);
  }

  getToken(key = USER_TOKEN_LOCAL_KEY) {
    return localStorage.getItem(key);
  }

  clear(key?: string) {
    if (key) {
      localStorage.removeItem(key);
    }
    localStorage.clear();
  }
}

export default new Storage();
