import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import { IAthorizationState, IAuthorizationActions } from '../sliceStore/authorizationSliceStore';

export const useUserInfo = () => {
  const userInfo = useSliceState<IAthorizationState, 'userInfo'>('userInfo');
  const setUserInfo = useSliceSetter<IAuthorizationActions, 'setUserInfo'>('setUserInfo');
  return {
    userInfo,
    setUserInfo,
  };
};
