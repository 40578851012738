/* eslint-disable @typescript-eslint/no-empty-interface */
import { BoxContent } from '../../../../shared/components';
import React, { useContext } from 'react';

import { StyledWppIconTrash } from '../styles/DeleteCell.styled';
import { WppActionButton } from '@wppopen/components-library-react';
import { useTheme } from '@emotion/react';
import { ITheme } from '@connected-core-system/utils/frontend';
import { BackofficeAppContext } from '../../../../app/context/BackofficeAppContext';

export interface IDeleteCellStoryProps {
  handleClick: () => void;
}

const DeleteCellStory: React.FC<IDeleteCellStoryProps> = ({ handleClick }) => {
  const theme = useTheme() as ITheme;
  const { insideWppOs } = useContext(BackofficeAppContext);

  return (
    <BoxContent flex align="center">
      <WppActionButton slot="trigger-button" onClick={() => handleClick()}>
        <StyledWppIconTrash
          color={insideWppOs ? 'inherit' : theme.palette['primary500']}
          data-testid="icon-remove"></StyledWppIconTrash>
      </WppActionButton>
    </BoxContent>
  );
};

export default DeleteCellStory;
