/* eslint-disable @typescript-eslint/no-empty-interface */

import { useContext } from 'react';
import { useIsChannelModalOpenedState } from '../hooks/useIsChannelModalOpenedState';
import ChannelForm from './ChannelForm';
import { Container, ModalContainer } from './styles/ChannelFormModal.styled';
import { BackofficeAppContext } from '../../../app/context/BackofficeAppContext';

export interface IChannelFormModalProps {}

const ChannelFormModal: React.FC<IChannelFormModalProps> = () => {
  const { isChannelModalOpened } = useIsChannelModalOpenedState();
  const { insideWppOs } = useContext(BackofficeAppContext);

  return (
    <ModalContainer open={isChannelModalOpened} disableOutsideClick style={insideWppOs ? { top: '64px' } : {}}>
      <Container slot="body">{isChannelModalOpened && <ChannelForm />}</Container>
    </ModalContainer>
  );
};

export default ChannelFormModal;
