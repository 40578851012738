/* eslint-disable @typescript-eslint/no-empty-interface */
import { CellTextTruncate } from '../../../shared/components';
import React from 'react';
import { ContentStreamTableDataType } from '../types/ContentStreamTableDataType';

export interface IContentStreamNameCellProps {
  name: ContentStreamTableDataType['name'];
  searchTerm: string;
}

const ContentStreamNameCell: React.FC<IContentStreamNameCellProps> = ({ name, searchTerm }) => {
  return <CellTextTruncate text={name} textHighlightProps={{ subString: searchTerm }} />;
};

export default ContentStreamNameCell;
