/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { TextHighlight } from '../../../../shared/components';

export interface IScanHistoryCellProps {
  value: string;
  searchTerm?: string;
}

const ScanHistoryCell: React.FC<IScanHistoryCellProps> = ({ value, searchTerm }) => {
  return (
    <div>
      <TextHighlight fullString={value} subString={searchTerm ? searchTerm : ''} />
    </div>
  );
};

export default ScanHistoryCell;
