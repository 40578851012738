import { useEffect } from 'react';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisarionState';
import { OrganisationManager } from '../../Organisation/services';
import { IOrganisation, MenuItemEnum } from '../../../shared/types';
import { useParams } from 'react-router';
import { getMenuItemEnumValue } from '../utils/homeUtils';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';
import { useSecondTierItemIdToSelectOnReloadState } from '../../SecondTierSidebar';

const useLoadStatesAfterReload = () => {
  const { organisationId } = useParams();
  const { selectedOrganisation, setSelectedOrganisation } = useSelectedOrganisationState();
  const location = useLocation();
  const pathSegments = location.pathname.split('/');
  const { setSelectedSideMenuItem } = useSelectedSideMenuItemState();
  const { setItemIdToSelectOnReload } = useSecondTierItemIdToSelectOnReloadState();
  const navigate = useNavigate();

  useEffect(() => {
    if (organisationId && !selectedOrganisation) handleOrganisationReload(organisationId);
  }, [organisationId, selectedOrganisation]);

  const handleOrganisationReload = (organisationId: string) => {
    OrganisationManager.getOrganisations().then((organisations) => {
      const selectedOrg = (organisations as IOrganisation[]).find((o) => o.id === organisationId);
      if (selectedOrg) {
        setSelectedOrganisation(selectedOrg);
        saveMenuItemState();
        secondTierReload();
      } else navigate(`${pathSegments[0]}/organisation`);
    });
  };

  const secondTierReload = () => {
    if (pathSegments.length > 3) {
      const menuItem = pathSegments[3];
      if (
        menuItem.toLowerCase() === 'brands' ||
        menuItem.toLowerCase() === 'units' ||
        menuItem.toLowerCase() === 'qrstyles'
      ) {
        const selectedItem = pathSegments[4];
        setItemIdToSelectOnReload(selectedItem);
      }
    }
    return;
  };

  const saveMenuItemState = () => {
    if (pathSegments.length <= 3) {
      navigate(`${location.pathname}/units`);
      setSelectedSideMenuItem(MenuItemEnum.UNIT);
      return;
    }
    const menuItem = pathSegments[3];
    setSelectedSideMenuItem(getMenuItemEnumValue(menuItem));
  };

  return { isOrganisationFilled: !!selectedOrganisation || organisationId === undefined || organisationId == null };
};

export default useLoadStatesAfterReload;
