import { useSliceState, useSliceSetter } from '../../../shared/hooks/useStore';
import { ISideMenuActions, ISideMenuState } from '../sliceStore/sideMenuSliceStore';

export const useSelectedOrganisation = () => {
  const selectedOrganisation = useSliceState<ISideMenuState, 'selectedOrganisation'>('selectedOrganisation');
  const setSelectedOrganisation = useSliceSetter<ISideMenuActions, 'setSelectedOrganisation'>(
    'setSelectedOrganisation',
  );

  return {
    selectedOrganisation,
    setSelectedOrganisation,
  };
};
