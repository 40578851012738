import { MenuItemEnum } from '../../../shared/types';

export const tabs = [
  {
    title: 'Details',
    value: 'details',
  },
  {
    title: 'Channels',
    value: 'channels',
  },
];

export const getMenuItemEnumValue = (menuItem: string): MenuItemEnum | null => {
  const enumKeys = Object.keys(MenuItemEnum) as Array<keyof typeof MenuItemEnum>;

  for (const key of enumKeys) {
    if (MenuItemEnum[key].toLowerCase() === menuItem.toLowerCase()) {
      return MenuItemEnum[key];
    }
  }

  return null;
};
