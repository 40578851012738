import { BoxContent, TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { WppCard, WppTypography } from '@wppopen/components-library-react';

export const WppStyledCard = styled(WppCard)({
  marginBottom: 16,
});

export const FallbackAssociationHeader = styled(BoxContent)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  columnGap: 10,
  marginBottom: 10,
});

export const FallbackTitleText = styled(WppTypography)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey1000'],
}));

export const FallbackMainText = styled(WppTypography)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey700'],
  width: '100%',
  paddingBottom: 12,
  wordBreak: 'normal',
  textAlign: 'justify',
}));
