/**
 * Use this to apply business rules on data input/output
 */
//import { transformContentToSave } from '../contentCreateUtils';
import { TOption } from '../../../shared/types/TOption';
import { TCategoryFormField } from '../../ChannelCreateEdit/types/CategoryFormField';
import { ICardOption } from '../../../shared/types/ICardOption';
import { GetLocationDTO } from '@connected-core-system/utils/bff-http-dto';
import { ContentsTableDataType } from '../types/ContentsTableDataType';
import { ContentFormCreate } from '../types/ContentFormField';
import { IContentStreamCreateRepository } from '../types/IContentStreamCreateRepository';
import { transformContentToSave, transformIntoOptions } from '../utils/contentStreamCreateUtils';
import contentCreateRepositoryInstance from './ContentStreamCreateRepository';
import { ExceptionCode, UniquePropertyExistsException } from '@core/exceptions';
import { ContentStreamChannelDTO } from '@connected-core-system/utils/content-stream-http-dto';
import { ChannelTableDataType } from '../../Channel/types/ChannelTableDataType';
import { StatusEnum } from '../../SecondTierSidebar/types';

export class ContentStreamCreateManager {
  constructor(private repo: IContentStreamCreateRepository) {}

  async listContents(organisationId: string, linkedContentsIds: string[]): Promise<ContentsTableDataType[]> {
    const contents = await this.repo.listContents(organisationId, linkedContentsIds);
    const dataTable = contents
      ? contents.data.map((content) => {
          return {
            id: content.contentId.trim(),
            name: content.name,
            collectionType: content.collectionType,
            updatedAt: new Date(content.updatedAt),
            languages: content.locales
              ? content.locales.map(
                  (locale) =>
                    ({
                      locale: locale.language,
                      status: locale.status,
                    } as { locale: string; status: StatusEnum }),
                )
              : [],
            status: content.status,
            cmsId: content.cmsId,
          };
        })
      : [];

    return dataTable;
  }

  async listStory(organisationId: string, linkedStoryId: string): Promise<ContentsTableDataType[]> {
    const story = await this.repo.listStory(organisationId, linkedStoryId);

    const dataTable = story
      ? story.data.map((story) => {
          return {
            id: story.contentId.trim(),
            name: story.name,
            collectionType: story.collectionType,
            updatedAt: new Date(story.updatedAt),
            languages: story.locales
              ? story.locales.map(
                  (locale) =>
                    ({
                      locale: locale.language,
                      status: locale.status,
                    } as { locale: string; status: StatusEnum }),
                )
              : [],
            status: story.status,
            cmsId: story.cmsId,
          };
        })
      : [];

    return dataTable;
  }

  async listContentsFromIds(
    organisationId: string,
    storyId: string | null,
    contentId: string[],
  ): Promise<ContentsTableDataType[]> {
    const contentIdQueryString = storyId ? `${storyId},${contentId.toString()}` : contentId.toString();
    const content = await this.repo.listContentById(organisationId, contentIdQueryString);
    const dataTable = content
      ? content.data.map((content, index) => {
          return {
            id: content.contentId.trim(),
            name: content.name,
            collectionType: content.collectionType,
            updatedAt: new Date(content.updatedAt),
            languages: content.locales
              ? content.locales.map(
                  (locale) =>
                    ({
                      locale: locale.language,
                      status: locale.status,
                    } as { locale: string; status: StatusEnum }),
                )
              : [],
            order: storyId ? index.toString() : (index + 1).toString(),
            status: content.status,
            cmsId: content.cmsId,
          };
        })
      : [];
    return dataTable;
  }

  async listChannelsFromIds(
    organisationId: string,
    contentStreamChannels: ContentStreamChannelDTO[],
  ): Promise<ChannelTableDataType[]> {
    const channelIds = contentStreamChannels.map((channel) => channel.channelId);
    const channels = await this.repo.listChannelsFromIds(organisationId, channelIds);
    const linkedChannels = channels.data.map((channel) => {
      return {
        id: channel.id,
        name: channel.name,
        brand: channel.brandId,
        unit: channel.unitId,
        status: channel.status,
        tags: channel.channelTags
          ? channel.channelTags.map((channelTag) => {
              return channelTag.tag;
            })
          : [],
        fallbackContentStreamId: channel.fallbackContentStreamId,
        channelTags: channel.channelTags,
        qrCodeTemplateId: channel.qrCodeTemplateId,
        channelContentStreams: channel.channelContentStreams,
        lastUpdate: new Date(channel.lastUpdate),
        updatedBy: channel.updatedBy,
        updatedAt: channel.updatedAt,
      } as ChannelTableDataType;
    });
    return linkedChannels;
  }

  async getFormSelectOptions(organisationId: string) {
    try {
      const promises = [this.repo.fetchOrganisationCampaigns(organisationId)];
      const response = await Promise.all(promises);
      const [campaigns] = response;
      return {
        campaigns: transformIntoOptions(campaigns.data),
      };
    } catch (error) {
      return this.handleError(error);
    }
  }

  async saveContentStream(content: ContentFormCreate, contentStreamTags: TCategoryFormField[]) {
    try {
      const contentSave = transformContentToSave(content, contentStreamTags);
      const res = await this.repo.postContentStream(content.organisationId, contentSave);
      return {
        ...res.data,
      };
    } catch (error) {
      if ((error as UniquePropertyExistsException).code === ExceptionCode.UniquePropertyExistsException) {
        throw new UniquePropertyExistsException('name', 'value');
      }
      return this.handleError(error);
    }
  }

  async updateContentStream(content: ContentFormCreate, contentStreamTags: TCategoryFormField[]) {
    try {
      const contentSave = transformContentToSave(content, contentStreamTags);
      const res = await this.repo.updateContentStream(content.id, content.organisationId, contentSave);

      return { ...res.data };
    } catch (error) {
      if ((error as UniquePropertyExistsException).code === ExceptionCode.UniquePropertyExistsException) {
        throw new UniquePropertyExistsException('name', 'value');
      }
      return this.handleError(error);
    }
  }

  async getCampaigns(organisationId: string): Promise<TOption[] | void> {
    try {
      const res = await this.repo.fetchOrganisationCampaigns(organisationId);
      return res.data.map((campaign) => {
        return { label: campaign.name, value: campaign.id };
      });
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getSuggestedLocations(
    search: string,
  ): Promise<{ options: ICardOption[]; search: string; locations: GetLocationDTO[] } | void> {
    try {
      const res = await this.repo.fetchLocations(search);
      const options = res.data.map((location: GetLocationDTO) => {
        return {
          value: location.locationString,
        } as ICardOption;
      });
      return {
        options: options,
        locations: res.data,
        search: search,
      };
    } catch (error) {
      return this.handleError(error);
    }
  }

  handleError(error: unknown) {
    throw error;
  }
}

export default new ContentStreamCreateManager(contentCreateRepositoryInstance);
