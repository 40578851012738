/**
 * Use this to apply business rules on data input/output
 */

import { BrandGetDTO } from '@connected-core-system/utils/brand-http-dto';
import { OrganisationUnitGetDTO } from '@connected-core-system/utils/organisation-unit-http-dto';
import { QRCodeTemplateDTO } from '@connected-core-system/utils/qrcodetemplate-http-dto';
import { MenuItemEnum } from '../../../shared/types';
import { SecondTierSidebarItem } from '../types';
import repoInstance, { SecondTierSidebarRepository } from './SecondTierSidebarRepository';

export class SecondTierSidebarManager {
  constructor(private repo: SecondTierSidebarRepository) {}

  async getUnitList(organisationId: string) {
    try {
      const res = await this.repo.getOrganisationUnitsList(organisationId);
      const units: SecondTierSidebarItem[] = res.data as Omit<OrganisationUnitGetDTO, 'organisationId'>[];

      return { menuItem: MenuItemEnum.UNIT, data: units };
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getBrandList(organisationId: string) {
    try {
      const res = await this.repo.getBrandList(organisationId);
      const brands: SecondTierSidebarItem[] = res.data as Omit<BrandGetDTO, 'organisationId'>[];

      return { menuItem: MenuItemEnum.BRANDS, data: brands };
    } catch (error) {
      return this.handleError(error);
    }
  }

  async getQRStyleList(organisationId: string) {
    try {
      const res = await this.repo.getQRStyleList(organisationId);
      const qrStyles = res.data as Omit<QRCodeTemplateDTO, 'organisationId'>[];
      const menuItems: SecondTierSidebarItem[] = qrStyles
        .filter((qr) => qr.templateId)
        .map((qr) => ({ ...qr, id: qr.templateId }));

      return { menuItem: MenuItemEnum.QRSTYLES, data: menuItems };
    } catch (error) {
      return this.handleError(error);
    }
  }

  handleError(error?: unknown) {
    throw error;
  }
}

export default new SecondTierSidebarManager(repoInstance);
