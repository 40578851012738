import { ExceptionBase } from '../exception.base';
import { ExceptionCode, ExceptionMessages } from '../enum';

export class BadRequestException extends ExceptionBase {
  static readonly message = ExceptionMessages.BadRequest;

  constructor(message = BadRequestException.message, cause?: Error) {
    super(message, cause);
  }
  readonly code = ExceptionCode.BadRequest;
}
