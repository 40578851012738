import { Table } from '../../../../shared/components';
import React from 'react';
import { getColumnsLocationTable } from '../../utils/contentStreamCreateUtils';
import LocationDeleteCell from './LocationDeleteCell';
import LocationNameCell from './LocationNameCell';
import LocationStatusCell from './LocationStatusCell';
import { LocationTableDataType } from '../../types/LocationTableDataType';

export interface ILocationTableProps {
  data: LocationTableDataType[];
  handleDelete: (name: string) => void;
  forceReloadDimensions?: boolean;
}

const LocationTable: React.FC<ILocationTableProps> = ({ data, handleDelete, forceReloadDimensions = false }) => {
  const columnsToExclude: Array<keyof LocationTableDataType> = [];

  const contentStreamLocationColumnDefinitions = getColumnsLocationTable(columnsToExclude);

  contentStreamLocationColumnDefinitions['name'].cellRenderer = ({ data }: { data: LocationTableDataType }) => (
    <LocationNameCell name={data.name} />
  );

  contentStreamLocationColumnDefinitions['status'].cellRenderer = ({ data }: { data: LocationTableDataType }) => (
    <LocationStatusCell status={data.status} />
  );

  contentStreamLocationColumnDefinitions['isDeleted'].cellRenderer = ({ data }: { data: LocationTableDataType }) => (
    <LocationDeleteCell data-testid="delete-cell" name={data.name} handleDelete={handleDelete} />
  );

  const renderContent = () => {
    return (
      <Table<Partial<LocationTableDataType>>
        searchable
        searchTerm={''}
        displayData={data}
        columnDefs={Object.values(contentStreamLocationColumnDefinitions)}
        onSelectRows={(selectedRows: Partial<LocationTableDataType>[]) => {
          return false;
        }}
        backgroundColor={'#f8f9fb'}
        delayUseDimensions={forceReloadDimensions ? 0 : 100}
        rowHeight={48}
      />
    );
  };
  return renderContent();
};
export default LocationTable;
