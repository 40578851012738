import { AxiosError } from 'axios';
import { ExceptionBase, ExceptionCode, InternalServerErrorException } from '@core/exceptions';

export const errorHandler = (error: Error) => {
  if ((error as AxiosError).isAxiosError) {
    const businessException = (error as AxiosError)?.response?.data as ExceptionBase;
    if (businessException && businessException.code) {
      throw new HttpCallBusinessException(businessException);
    }
  }
  throw new InternalServerErrorException(error.message);
};

export class HttpCallBusinessException extends ExceptionBase {
  code: string = ExceptionCode.InternalServerError;
  exception: string;

  constructor(innerError: ExceptionBase) {
    super(innerError.message, innerError);
    this.code = innerError.code ?? ExceptionCode.InternalServerError;
    this.exception = JSON.stringify(innerError);
  }
}
