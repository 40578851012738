import React from 'react';

import { DSCard, DSInput } from '../../shared';
import { BoxContent } from '../../shared/components';
import { translate } from '../../shared/locales/i18n';
import { StatusEnum } from '../../shared/types';
import { useSecondTierSelectedItemState } from '../SecondTierSidebar';
import { SecondTierSidebarItem } from '../SecondTierSidebar/types';
import { UpdatedCardInfo } from '../UpdatedInfo';
import { useUnitValueChangesState } from './hooks/useUnitValueChangesState';
import { MainContentContainer } from './UnitPage.styled';

export interface ITabDetailsProps {
  newUnitTitle: string;
  setNewUnitTitle: (unitTitle: string) => void;
}

const TabDetails: React.FC<ITabDetailsProps> = ({ newUnitTitle, setNewUnitTitle }) => {
  const { selectedSecondTierItem } = useSecondTierSelectedItemState();
  const { setUnitValueChanges } = useUnitValueChangesState();

  const isCreateMode = !!(selectedSecondTierItem?.id === 'new');

  const getInputPlaceHolder = () => {
    if (selectedSecondTierItem)
      return selectedSecondTierItem.status === StatusEnum.UNPUBLISHED
        ? translate('txtEnterYourUnitName')
        : selectedSecondTierItem.name;
    else return translate('txtEnterYourUnitName');
  };

  const handleInputChange = (title: string) => {
    setNewUnitTitle(title);
    if (selectedSecondTierItem?.name !== undefined) {
      if (selectedSecondTierItem.status !== StatusEnum.UNPUBLISHED)
        setUnitValueChanges(title !== selectedSecondTierItem?.name);
      else setUnitValueChanges(title !== '');
    }
  };

  return (
    <MainContentContainer>
      <DSCard>
        <BoxContent>
          <DSInput
            data-testid="input-unit-title"
            autoFocus
            name="title"
            labelConfig={{ text: translate('txtTitle') }}
            size="m"
            required
            placeholder={getInputPlaceHolder()}
            value={newUnitTitle}
            onWppChange={(event: CustomEvent) => handleInputChange(event.detail.value)}
          />
        </BoxContent>
      </DSCard>

      <UpdatedCardInfo
        userEmail={selectedSecondTierItem?.updatedBy}
        createMode={isCreateMode}
        lastUpdated={(selectedSecondTierItem as SecondTierSidebarItem & { updatedAt: Date }).updatedAt}
      />
    </MainContentContainer>
  );
};

export default TabDetails;
