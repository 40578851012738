export const isValidTime = (val: string): boolean => {
  const [hoursStr, minutesStr] = val.split(':');

  const timeRegexp = /^\d{0,2}?:?\d{0,2}$/;

  if (!timeRegexp.test(val)) {
    return false;
  }

  const hours = Number(hoursStr);
  const minutes = Number(minutesStr);

  const isValidHour = (hour: number) => Number.isInteger(hour) && hour >= 0 && hour < 24;
  const isValidMinutes = (minutes: number) =>
    (Number.isInteger(minutes) && hours >= 0 && hours < 24) || Number.isNaN(minutes);

  if (!isValidHour(hours) || !isValidMinutes(minutes)) {
    return false;
  }

  if (minutes < 10 && Number(minutesStr[0]) > 5) {
    return false;
  }

  const valArr = val.indexOf(':') !== -1 ? val.split(':') : [val];

  if (valArr[0]?.length && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)) {
    return false;
  }

  if (valArr[1]?.length && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59)) {
    return false;
  }

  return true;
};

export const convertToGMTDate = (date: string, time: string, gmtOffset: number): Date => {
  const DEFAULT_TIME = '00:00';
  const timeValue = time || DEFAULT_TIME;
  const [months, days, years] = date.split('/').map(Number);
  const [hours, minutes] = timeValue.split(':').map(Number);
  if (gmtOffset === 0) return new Date(years, months - 1, days, hours, minutes);
  const resultHours = hours - gmtOffset;
  return new Date(Date.UTC(years, months - 1, days, resultHours, minutes));
};

export const convertToUTC = (date: string, time: string, timeZone: number): Date => {
  const DEFAULT_TIME = '00:00';
  const timeValue = time || DEFAULT_TIME;
  const [day, month, year] = date.split('/');
  const [hours, minutes] = timeValue.split(':');
  const inputDate = new Date(Number(year), Number(month) - 1, Number(day), Number(hours), Number(minutes));
  const utcOffset = inputDate.getTimezoneOffset() / 60;
  const localDate = new Date(inputDate.getTime() - (utcOffset + timeZone) * 60 * 60 * 1000);
  return localDate;
};

export const isValidDate = (val: string): boolean => {
  if (val.length !== 10) return false;

  const [dayStr, monthStr, yearStr] = val.split('/');

  const dateRegexp = /^[0-9]*$/;
  if (!dateRegexp.test(dayStr) || !dateRegexp.test(monthStr) || !dateRegexp.test(yearStr)) return false;

  return true;
};

export const compareDates = (date1: string, date2: string): number => {
  const [day1, month1, year1] = date1.split('/');
  const [day2, month2, year2] = date2.split('/');

  const dateObj1 = new Date(`${year1}-${month1}-${day1}`);
  const dateObj2 = new Date(`${year2}-${month2}-${day2}`);

  if (dateObj1 < dateObj2) {
    return -1;
  } else if (dateObj1 > dateObj2) {
    return 1;
  } else {
    return 0;
  }
};
