import Colorful from '@uiw/react-color-colorful';
import { ColorDemo, SketchPicker, SelectColorContainer, InputsColors } from './SelectColor.styled';
import { useEffect, useState } from 'react';
import { ColorResult } from '@uiw/react-color';
import { DEFAULT_COLOR } from '../../Constants/Constants';

export interface ISelectColorProps {
  stateValue: Array<{ color: string; offset: number }>;
  setStateValue: React.Dispatch<React.SetStateAction<Array<{ color: string; offset: number }>>>;
  disabled: boolean;
  offset: number;
  errorMessage?: string;
}

const SelectColor: React.FC<ISelectColorProps> = ({ stateValue, setStateValue, disabled, offset, errorMessage }) => {
  const [openSelector, setOpenSelector] = useState(false);
  const [color, setColor] = useState(stateValue[offset]?.color || DEFAULT_COLOR);

  const showSelectorColor = () => {
    if (!disabled) setOpenSelector(true);
  };

  const updateColor = (e: ColorResult) => {
    handleChange(e.hex);
  };

  const updateInput = (e: CustomEvent) => {
    handleChange(e.detail.value);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLWppInputElement>) => {
    const charCode = event.charCode;
    const currentLength = event.currentTarget.value.length;

    if (currentLength >= 7) event.preventDefault();
    if (
      !(charCode >= 65 && charCode <= 90) &&
      !(charCode >= 97 && charCode <= 122) &&
      !(charCode >= 48 && charCode <= 57) &&
      charCode !== 35
    ) {
      event.preventDefault();
    }

    handleChange(event.currentTarget.value);
  };

  const handleChange = (newColor: string) => {
    setColor(newColor);
    setStateValue((prev) => {
      const newArr = [...prev];
      newArr[offset] = { color: newColor, offset };
      return newArr;
    });
  };

  useEffect(() => {
    if (stateValue[offset]?.color && stateValue[offset]?.color !== color) setColor(stateValue[offset]?.color);
  }, [stateValue, color, setColor, offset]);

  return (
    <SelectColorContainer>
      <InputsColors
        data-testid="select-color"
        value={color?.toUpperCase()}
        onClick={showSelectorColor}
        onWppChange={(e: CustomEvent) => updateInput(e)}
        onWppBlur={() => setOpenSelector(false)}
        disabled={disabled}
        message={errorMessage}
        messageType={errorMessage ? 'error' : undefined}
        onKeyPress={handleKeyPress}>
        <ColorDemo backgroundColor={color} slot="icon-start" />
      </InputsColors>

      {openSelector && (
        <SketchPicker>
          <Colorful
            style={{ marginLeft: 20 }}
            color={color?.toUpperCase()}
            onChange={(e) => updateColor(e)}
            disableAlpha
          />
        </SketchPicker>
      )}
    </SelectColorContainer>
  );
};

export default SelectColor;
