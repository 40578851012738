/* eslint-disable @typescript-eslint/no-empty-interface */
import { BoxContentCenter } from '@connected-core-system/utils/frontend';
import { WppSpinner } from '@wppopen/components-library-react';
import { Table } from '../../../../shared/components';
import { LinkedChannelsTableDataType } from '../../types/LinkedChannelsDataType';
import ChannelStatusCell from '../../../Channel/views/ChannelStatusCell';
import ChannelNameCell from '../../../Channel/views/ChannelNameCell';
import { getColumnsLinkedChannels } from '../../utils/contentStreamCreateUtils';
import DeletedLinkedChannelCell from './DeletedLinkedChannelCell';

export interface ILinkedChannelsTableProps {
  isLoading: boolean;
  data: LinkedChannelsTableDataType[];
  onDelete: (id: string) => void;
  forceReloadDimensions?: boolean;
  isFallbackContent: boolean;
}

const LinkedChannelsTable: React.FC<ILinkedChannelsTableProps> = ({
  isLoading,
  data,
  onDelete,
  forceReloadDimensions = false,
  isFallbackContent,
}) => {
  const columnsToExclude: Array<keyof LinkedChannelsTableDataType> = ['id', 'status'];
  const channelColumnDefinitions = getColumnsLinkedChannels(columnsToExclude);

  channelColumnDefinitions['name'].cellRenderer = ({ data }: { data: LinkedChannelsTableDataType }) => (
    <ChannelNameCell name={data.name} />
  );

  if (channelColumnDefinitions['status'])
    channelColumnDefinitions['status'].cellRenderer = ({ data }: { data: LinkedChannelsTableDataType }) => (
      <ChannelStatusCell status={data.status} />
    );

  channelColumnDefinitions['deleted'].cellRenderer = ({ data }: { data: LinkedChannelsTableDataType }) =>
    !isFallbackContent && <DeletedLinkedChannelCell onDelete={onDelete} id={data.id} />;

  const renderLoading = () => {
    return (
      <BoxContentCenter>
        <WppSpinner size="m" />
      </BoxContentCenter>
    );
  };

  return (
    <Table
      renderLoading={renderLoading}
      isLoading={isLoading}
      displayData={data}
      columnDefs={Object.values(channelColumnDefinitions)}
      rowHeight={48}
      hasHeader={false}
      delayUseDimensions={forceReloadDimensions ? 0 : 100}
    />
  );
};

export default LinkedChannelsTable;
