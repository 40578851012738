import { useAuthBackofficeBare } from '../../features/Authorization';
import AppWrapper from './AppWrapper';
import { ContainerBare } from './styles/AppWrapper.styled';

interface IAppBareProps {
  environmentConfiguration: { [key: string]: object };
}

const AppBare: React.FC<IAppBareProps> = ({ environmentConfiguration }) => {
  const { isAuthenticated, error } = useAuthBackofficeBare();

  return (
    <ContainerBare id="app-bare-container">
      <AppWrapper
        baseUrl="/"
        isAuthenticated={isAuthenticated}
        errorAuth={error}
        environmentConfiguration={environmentConfiguration}
      />
    </ContainerBare>
  );
};

export default AppBare;
