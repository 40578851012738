import { PropsWithChildren, useContext } from 'react';
import { BackofficeAppContext } from '../context/BackofficeAppContext';

const AppOsHider = (props: PropsWithChildren) => {
  const { insideWppOs } = useContext(BackofficeAppContext);

  if (insideWppOs) {
    return null;
  }

  return <>{props.children}</>;
};

export default AppOsHider;
