import { TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { WppTypography } from '@wppopen/components-library-react';

export const WppStyledTypography = styled(WppTypography)({
  display: 'block',
  fontSize: '16px',
});

export const HourText = styled(WppTypography)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey800'],
  fontWeight: 400,
  fontSize: '12px',
  display: 'block',
}));
