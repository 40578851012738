import React, { useRef, useEffect, useState } from 'react';
import { useToastState } from '../hooks/useToastState';

import { StyledWppToastContainer } from './ToastMessage.styled';

export const useToast = () => {
  const [toastRef, setToastRef] = useState<HTMLWppToastContainerElement | null | undefined>();

  const showToast = (config: any) => {
    toastRef?.addToast(config);
  };

  useEffect(() => {
    setToastRef(document.querySelector('.wpp-toast-container') as HTMLWppToastContainerElement);
  });

  return {
    showToast,
    toastRef,
  };
};

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IToastMessageProps {}

const ToastMessage: React.FC<IToastMessageProps> = () => {
  const { toastMessage, setToastMessage } = useToastState();

  const childRef = useRef(null);

  const { showToast } = useToast();

  useEffect(() => {
    if (toastMessage) {
      const config = {
        message: toastMessage.secondaryText,
        type: toastMessage.type,
        header: toastMessage.primaryText,
        duration: 5000,
      };
      showToast(config);
      setToastMessage(null);
    }
  }, [toastMessage]);

  return <StyledWppToastContainer maxToastsToDisplay={5} ref={childRef} />;
};

export default ToastMessage;
