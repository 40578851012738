/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useCallback, useEffect, useState } from 'react';
import { translate } from '../../../shared/locales';
import { BoxContent, TimeInput, TimezoneSelect, BoxContentCenter } from '../../../shared/components';
import {
  StyledWppCheckbox,
  ContainerContent,
  ContainerFields,
  InputContainer,
  Label,
  StyledWppDatePicker,
  StyledWppIconTrash,
  TargetsCard,
  ContainerLabel,
  TimeZoneContainer,
  DatePickerAndTimeSelectContainer,
} from './styles/Schedule.styled';
import { convertToUTC, isValidDate } from '../../../shared/utils/timeUtils';
import { TargetingScheduleDTO } from '@connected-core-system/utils/content-stream-http-dto';
import { addDays, addHours } from 'date-fns';

export interface ITargetScheduleProps {
  removeScheduleTargeting: () => void;
  targetingSchedule: TargetingScheduleDTO | null;
  setTargetingSchedule: (targetingSchedule: TargetingScheduleDTO | null) => void;
  isInvalidTargetsTab: boolean;
  setIsInvalidTargetSchedule: (isInvalidTargetschedule: boolean) => void;
}

const TargetSchedule: React.FC<ITargetScheduleProps> = ({
  removeScheduleTargeting,
  targetingSchedule,
  setTargetingSchedule,
  isInvalidTargetsTab,
  setIsInvalidTargetSchedule,
}) => {
  const getDateFromTargetingSchedule = (date: Date | undefined, isTime: boolean, timezone: number | undefined) => {
    if (!date) {
      return '';
    }
    let timezoneOffset = 0;
    if (timezone || timezone === 0) {
      const localTimezone = new Date().getTimezoneOffset() / -60;
      timezoneOffset = timezone - localTimezone;
    }
    const dateTimeConvertedToTimezone = new Date(date);
    dateTimeConvertedToTimezone.setHours(dateTimeConvertedToTimezone.getHours() + timezoneOffset);
    if (isTime) {
      return dateTimeConvertedToTimezone.toTimeString().slice(0, 5);
    }
    return new Date(date).toLocaleDateString('pt-BR');
  };

  const [startDate, setStartDate] = useState<string | string[] | undefined>(
    getDateFromTargetingSchedule(targetingSchedule?.startDatetime, false, targetingSchedule?.timezone),
  );
  const [startTime, setStartTime] = useState<string>(
    getDateFromTargetingSchedule(targetingSchedule?.startDatetime, true, targetingSchedule?.timezone),
  );
  const [enableEndDate, setEnableEndDate] = useState<boolean>(!!targetingSchedule?.endDatetime);
  const [endDate, setEndDate] = useState<string | string[] | undefined>(
    getDateFromTargetingSchedule(targetingSchedule?.endDatetime, false, targetingSchedule?.timezone),
  );
  const [endTime, setEndTime] = useState<string>(
    getDateFromTargetingSchedule(targetingSchedule?.endDatetime, true, targetingSchedule?.timezone),
  );
  const [timezone, setTimezone] = useState<number>(targetingSchedule?.timezone ?? new Date().getTimezoneOffset() / -60);
  const [minDate, setMinDate] = useState<string>();

  const [startDateError, setStartDateError] = useState<string>('');
  const [startTimeError, setStartTimeError] = useState<string>('');
  const [endDateError, setEndDateError] = useState<string>('');
  const [endTimeError, setEndTimeError] = useState<string>('');

  const [endDateInputValueOnBlur, setEndDateInputValueOnBlur] = useState<string>('');
  const [startDateInputValueOnBlur, setStartDateInputValueOnBlur] = useState<string>('');

  const [refreshEndDateTimeFields, setRefreshEndDateTimeFields] = useState<boolean>(false);
  const [refreshStartDateTimeFields, setRefreshStartDateTimeFields] = useState<boolean>(false);

  const handleCheckbox = (checked: boolean) => {
    if (enableEndDate) {
      setEndTime('');
      setEndDate('');
      setEndDateInputValueOnBlur('');
      setEndDateError('');
      setEndTimeError('');
      setRefreshEndDateTimeFields(!refreshEndDateTimeFields);
    }
    setEnableEndDate(checked);
  };

  const handleDatepickerChange = (date: Date | Date[], setStateValue: (dateString: string) => void) => {
    const value = date
      ? date.toLocaleString('pt-BR', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        })
      : '';

    setStateValue(value);
  };

  useEffect(() => {
    setRefreshEndDateTimeFields(!refreshEndDateTimeFields);
  }, [startDate]);

  const handleDatepickerBlur = (
    inputText: string | undefined,
    setStateValue: (dateString: string) => void,
    setInputValueOnBlur: (value: string) => void,
  ) => {
    if (inputText !== undefined) {
      if (!isValidDate(inputText)) {
        setStateValue('');
      }
      setInputValueOnBlur?.(inputText);
    }
  };

  const handleSaveScheduleTargeting = useCallback(() => {
    if (!startDate) {
      setTargetingSchedule(null);
      return;
    }

    const convertedStartDate = convertToUTC(startDate as string, startTime, timezone);

    const convertedEndDate = endDate ? convertToUTC(endDate as string, endTime, timezone) : undefined;

    const targetingSchedule: TargetingScheduleDTO = {
      startDatetime: convertedStartDate,
      timezone: timezone,
      ...(enableEndDate && endDate && !endDateError && { endDatetime: convertedEndDate }),
    };

    setTargetingSchedule(targetingSchedule);
  }, [startDate, endDate, startTime, endTime, endDateError, timezone, enableEndDate, setTargetingSchedule]);

  useEffect(() => {
    const hasInvalidEndDate = endDateInputValueOnBlur && !isValidDate(endDateInputValueOnBlur);

    //Set end date and time error messages
    if (!endDate && !endTime) {
      setEndDateError(hasInvalidEndDate ? translate('txtSelectValidDate') : '');
      setEndTimeError('');
    } else if (!endDate) {
      setEndDateError(hasInvalidEndDate ? translate('txtSelectValidDate') : translate('txtInvalidEmptyField'));
      setEndTimeError('');
    } else {
      const end = new Date(
        parseInt(endDate.slice(6, 10) as string),
        parseInt(endDate.slice(3, 5) as string) - 1,
        parseInt(endDate.slice(0, 2) as string),
      );
      let start = new Date('');
      if (startDate) {
        start = new Date(
          parseInt(startDate.slice(6, 10) as string),
          parseInt(startDate.slice(3, 5) as string) - 1,
          parseInt(startDate.slice(0, 2) as string),
        );
      }

      const newEndDateErrorMessage =
        end < new Date(new Date().toDateString())
          ? translate('txtSelectValidEndDateCurrent')
          : end < start
          ? translate('txtSelectValidEndDate')
          : '';
      setEndDateError(newEndDateErrorMessage);

      const startDateTime = convertToUTC(startDate as string, startTime, timezone);
      const endDateTime = convertToUTC(endDate as string, endTime, timezone);

      const newEndTimeErrorMessage = newEndDateErrorMessage
        ? ''
        : endDateTime <= new Date()
        ? translate('txtEnterValidEndTimeCurrent')
        : endDateTime <= startDateTime
        ? translate('txtEnterValidEndTime')
        : '';
      setEndTimeError(newEndTimeErrorMessage);
    }
  }, [startDate, startTime, endDate, endTime, endDateInputValueOnBlur, timezone]);

  useEffect(() => {
    const dateInUTC = addHours(new Date(), new Date().getTimezoneOffset() / 60);
    const dateInSelectedTimezone = addHours(dateInUTC, timezone);
    const day = dateInSelectedTimezone.getDate();
    const month = (dateInSelectedTimezone.getMonth() + 1).toString().padStart(2, '0');
    const year = dateInSelectedTimezone.getFullYear();
    const minDate = `${day}/${month}/${year}`;
    setMinDate(minDate);

    setRefreshStartDateTimeFields(!refreshStartDateTimeFields);
  }, [timezone]);

  useEffect(() => {
    const hasInvalidStartDate = startDateInputValueOnBlur && !isValidDate(startDateInputValueOnBlur);

    //Set start date error messages
    if (!startDate && !startTime) {
      if (hasInvalidStartDate) {
        setStartDateError(translate('txtSelectValidDate'));
      } else if (isInvalidTargetsTab && targetingSchedule === null) {
        setStartDateError(translate('txtInvalidEmptyField'));
      } else {
        setStartDateError('');
      }
    } else if (!startDate) {
      setStartDateError(hasInvalidStartDate ? translate('txtSelectValidDate') : translate('txtInvalidEmptyField'));
    } else {
      setStartDateError('');
    }
  }, [isInvalidTargetsTab, targetingSchedule, startDate, startTime, startDateInputValueOnBlur]);

  useEffect(() => {
    setIsInvalidTargetSchedule(!!startDateError || !!endDateError || !!startTimeError || !!endTimeError || !startDate);
  }, [startDateError, endDateError, startTimeError, endTimeError, startDate, setIsInvalidTargetSchedule]);

  useEffect(() => {
    handleSaveScheduleTargeting();
  }, [startDate, endDate, endTime, startTime, enableEndDate, handleSaveScheduleTargeting]);

  const renderStartDatepicker = () => {
    return (
      <StyledWppDatePicker
        value={startDate}
        onWppChange={(event) => handleDatepickerChange(event.detail.date, setStartDate)}
        onWppBlur={(event) =>
          handleDatepickerBlur(
            (event?.target as HTMLWppDatepickerElement)?.value as string,
            setStartDate,
            setStartDateInputValueOnBlur,
          )
        }
        locale={{ dateFormat: 'dd/MM/yyyy' }}
        placeholder={'dd/mm/yyyy'}
        minDate={minDate}
        message={startDateError}
        messageType={startDateError ? 'error' : undefined}
        data-testid="start-datepicker"
      />
    );
  };

  const renderEndDatepicker = () => {
    return (
      <StyledWppDatePicker
        value={endDate}
        onWppChange={(event) => handleDatepickerChange(event.detail.date, setEndDate)}
        onWppBlur={(event) =>
          handleDatepickerBlur(
            (event?.target as HTMLWppDatepickerElement)?.value as string,
            setEndDate,
            setEndDateInputValueOnBlur,
          )
        }
        onWppDateClear={() => setEndDateInputValueOnBlur('')}
        disabled={!enableEndDate}
        locale={{ dateFormat: 'dd/MM/yyyy' }}
        placeholder="dd/mm/yyyy"
        minDate={startDate as string}
        message={endDateError}
        messageType={endDateError ? 'error' : undefined}
        data-testid="end-datepicker"
      />
    );
  };

  const renderEndTimeInput = () => {
    return (
      <TimeInput
        stateValue={endTime}
        setStateValue={setEndTime}
        disabled={!enableEndDate}
        data-testid="end-time"
        errorMessage={endTimeError}
        setErrorMessage={setEndTimeError}
      />
    );
  };

  return (
    <TargetsCard
      expandedByDefault
      size="s"
      text={translate('txtSchedule')}
      data-testid="expandable-target"
      withDivider={false}>
      <StyledWppIconTrash onClick={removeScheduleTargeting} data-testid="icon-remove" />
      <ContainerContent>
        <TimeZoneContainer>
          <ContainerLabel>
            <Label type="s-strong">{translate('txtTimeZone')}</Label>
          </ContainerLabel>
          <BoxContentCenter justify="flex-start" align="flex-start" w={'100%'}>
            <InputContainer>
              <TimezoneSelect stateValue={timezone} setStateValue={setTimezone} />
            </InputContainer>
          </BoxContentCenter>
        </TimeZoneContainer>
        <DatePickerAndTimeSelectContainer>
          <ContainerFields>
            <div>
              <ContainerLabel>
                <Label type="s-strong">{translate('txtStartDate')}</Label>
              </ContainerLabel>
            </div>
            <BoxContentCenter justify="flex-start" align="flex-start">
              <InputContainer>
                {refreshStartDateTimeFields && renderStartDatepicker()}
                {!refreshStartDateTimeFields && renderStartDatepicker()}
              </InputContainer>
              <InputContainer>
                <TimeInput
                  stateValue={startTime}
                  setStateValue={setStartTime}
                  errorMessage={startTimeError}
                  setErrorMessage={setStartTimeError}
                />
              </InputContainer>
            </BoxContentCenter>
          </ContainerFields>
          <ContainerFields>
            <BoxContent flex align="end">
              <ContainerLabel>
                <Label type="s-strong">{translate('txtEndDate')}</Label>
              </ContainerLabel>
              <StyledWppCheckbox
                checked={enableEndDate}
                labelConfig={{ text: translate('txtSetEndDate') }}
                name="set-end-date"
                required
                onWppChange={({ detail: { checked } }) => handleCheckbox(checked)}
                data-testid="end-checkbox"
              />
            </BoxContent>
            <BoxContentCenter justify="flex-start" align="flex-start">
              <InputContainer>
                {refreshEndDateTimeFields && renderEndDatepicker()}
                {!refreshEndDateTimeFields && renderEndDatepicker()}
              </InputContainer>
              <InputContainer>
                {refreshEndDateTimeFields && renderEndTimeInput()}
                {!refreshEndDateTimeFields && renderEndTimeInput()}
              </InputContainer>
            </BoxContentCenter>
          </ContainerFields>
        </DatePickerAndTimeSelectContainer>
      </ContainerContent>
    </TargetsCard>
  );
};

export default TargetSchedule;
