import React, { useCallback, useMemo, useState } from 'react';
import { WppIconPlus, WppIconSearch, WppSpinner, WppTypography } from '@wppopen/components-library-react';
import { Table, BoxContentCenter, BaseTableDataType } from '../../../shared/components';
import { filterChannels, getColumns } from '../channelUtils';
import { ChannelTableDataType } from '../types/ChannelTableDataType';
import ChannelHeader from './ChannelHeader';
import ChannelLastUpdateCell from './ChannelLastUpdateCell';
import ChannelNameCell from './ChannelNameCell';
import ChannelStatusCell from './ChannelStatusCell';
import ChannelTagsCell from './ChannelTagsCell';
import { WppStyledCard, WppStyledCardOs } from './styles/ChannelPage.styled';
import { MenuItemEnum } from '../../../shared/types';
import { channelPageManager } from '../services';
import { usePromise } from '../../../shared/hooks';
import { useSelectedOrganisation } from '../../SideMenu';
import { isArrayEmpty, percentOfAppHeight } from '../../../shared/utils';
import { translate } from '../../../shared/locales';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar';
import { useSelectedBrandState, useSelectedUnitState } from '../hooks';
import { useSelectedChannelState } from '../hooks';
import EmptyState from '../../../shared/components/EmptyState/EmptyState';
import { ITheme } from '@connected-core-system/utils/frontend';
import { useTheme } from '@emotion/react';
import ChannelLoadingCell from './ChannelLoadingCell';
import { useIsChannelModalOpenedState } from '../../ChannelCreateEdit/hooks/useIsChannelModalOpenedState';
import { useChannelTableTryAgainState } from '../hooks/useChannelTableTryAgainState';
import { useContentStreamModalZIndexState } from '../../ContentStreamCreate/hooks/useContentStreamModalZIndexState';
import PlatformWrapper from '../../../shared/components/PlatformWrapper/PlatformWrapper';

export interface IChannelTableProps {
  location: MenuItemEnum;
  handleOnSelectRows?: (rows: Partial<ChannelTableDataType>[]) => void;
  listSelectedRowId?: string[];
}

const ChannelTable: React.FC<IChannelTableProps> = ({ location, handleOnSelectRows, listSelectedRowId = [] }) => {
  const theme = useTheme() as ITheme;
  const { setIsChannelModalOpened } = useIsChannelModalOpenedState();
  const [searchTerm, setSearch] = useState('');
  const { tryAgain, setTryAgain } = useChannelTableTryAgainState();
  const [unitIds, setUnitIds] = useState<string[]>([]);
  const [brandIds, setBrandIds] = useState<string[]>([]);
  const columnsToExclude: Array<keyof ChannelTableDataType> =
    location === MenuItemEnum.CHANNELS
      ? ['channelTags', 'id', 'fallbackContentStreamId', 'channelContentStreams', 'status']
      : location === MenuItemEnum.CONTENT_STREAM
      ? ['channelTags', 'lastUpdate', 'fallbackContentStreamId', 'channelContentStreams', 'status']
      : ['brand', 'unit', 'channelTags', 'id', 'fallbackContentStreamId', 'channelContentStreams', 'status'];
  const channelColumnDefinitions = getColumns(columnsToExclude);
  const { selectedOrganisation } = useSelectedOrganisation();
  const { selectedSecondTierItem } = useSecondTierSelectedItemState();
  const { setSelectedBrandId } = useSelectedBrandState();
  const { setSelectedUnitId } = useSelectedUnitState();
  const { setSelectedChannel } = useSelectedChannelState();
  const { setContentStreamModalZIndex } = useContentStreamModalZIndexState();

  const isContentStreamPage = location === MenuItemEnum.CONTENT_STREAM;

  const onRowClicked = (data: Partial<ChannelTableDataType> & BaseTableDataType) => {
    if (isContentStreamPage) return;
    setSelectedChannel(data as ChannelTableDataType);
    setIsChannelModalOpened(true);
  };

  const onSelectRows = (rows: Partial<ChannelTableDataType>[]) => {
    const channelsSelectedButOutsideFilter: Partial<ChannelTableDataType>[] = [];
    for (let i = 0; i < listSelectedRowId.length; i++) {
      if (!filteredChanels.find((channel: Partial<ChannelTableDataType>) => channel.id === listSelectedRowId[i])) {
        const channel = data?.find((channel: Partial<ChannelTableDataType>) => channel.id === listSelectedRowId[i]);
        if (channel) channelsSelectedButOutsideFilter.push(channel);
      }
    }
    handleOnSelectRows?.(channelsSelectedButOutsideFilter.concat(rows));
  };

  const loadDataTable = useCallback(() => {
    if (tryAgain) setTryAgain(false);
    if (selectedOrganisation) {
      return channelPageManager
        .listChannels(
          selectedOrganisation.id,
          location === MenuItemEnum.BRANDS ? selectedSecondTierItem?.id : null,
          location === MenuItemEnum.UNIT ? selectedSecondTierItem?.id : null,
        )
        .then((result) => {
          setUnitIds([...new Set(result.map((tableData) => tableData.unit))]);
          setBrandIds([...new Set(result.map((tableData) => tableData.brand))]);
          return result;
        });
    }
    return null;
  }, [location, selectedOrganisation, selectedSecondTierItem?.id, setTryAgain, tryAgain]);

  const { data, ...promiseStatusOptions } = usePromise(loadDataTable);

  channelColumnDefinitions['name'].cellRenderer = ({ data }: { data: ChannelTableDataType }) => (
    <ChannelNameCell name={data.name} searchTerm={searchTerm} />
  );

  if (channelColumnDefinitions['status'])
    channelColumnDefinitions['status'].cellRenderer = ({ data }: { data: ChannelTableDataType }) => (
      <ChannelStatusCell status={data.status} />
    );

  channelColumnDefinitions['tags'].cellRenderer = ({ data }: { data: ChannelTableDataType }) => (
    <ChannelTagsCell tags={data.tags} />
  );

  if (channelColumnDefinitions['lastUpdate']) {
    channelColumnDefinitions['lastUpdate'].cellRenderer = ({ data }: { data: ChannelTableDataType }) => (
      <ChannelLastUpdateCell lastUpdate={data.lastUpdate} />
    );
  }

  const loadUnits = useCallback(() => {
    if (unitIds && unitIds.length && selectedOrganisation?.id) {
      return channelPageManager.getUnits(selectedOrganisation.id, unitIds);
    }
    return null;
  }, [unitIds, selectedOrganisation?.id]);

  const { data: unitData, ...promiseStatusUnit } = usePromise(loadUnits);

  const loadBrands = useCallback(() => {
    if (brandIds && brandIds.length && selectedOrganisation?.id) {
      return channelPageManager.getBrands(selectedOrganisation.id, brandIds);
    }
    return null;
  }, [selectedOrganisation?.id, brandIds]);

  const { data: brandData, ...promiseStatusBrand } = usePromise(loadBrands);

  if (channelColumnDefinitions['brand']) {
    channelColumnDefinitions['brand'].cellRenderer = ({ data }: { data: ChannelTableDataType }) => (
      <ChannelLoadingCell
        cellName={brandData?.find((brand) => brand.id === data.brand)?.name || ''}
        isLoading={promiseStatusBrand.loading}
        searchTerm={searchTerm}
      />
    );
    channelColumnDefinitions['brand'].comparator = function (
      valueA: string,
      valueB: string,
      _nodeA: any,
      _nodeB: any,
      _isDescending: any,
    ) {
      const cellNameA = brandData?.find((brand) => brand.id === valueA)?.name;
      const cellNameB = brandData?.find((brand) => brand.id === valueB)?.name;
      return cellNameA && cellNameB ? cellNameA.localeCompare(cellNameB) : 0;
    };
  }

  if (channelColumnDefinitions['unit']) {
    channelColumnDefinitions['unit'].cellRenderer = ({ data }: { data: ChannelTableDataType }) => (
      <ChannelLoadingCell
        cellName={unitData?.find((unit) => unit.id === data.unit)?.name || ''}
        isLoading={promiseStatusUnit.loading}
        searchTerm={searchTerm}
      />
    );
    channelColumnDefinitions['unit'].comparator = function (
      valueA: string,
      valueB: string,
      _nodeA: any,
      _nodeB: any,
      _isDescending: any,
    ) {
      const cellNameA = unitData?.find((unit) => unit.id === valueA)?.name;
      const cellNameB = unitData?.find((unit) => unit.id === valueB)?.name;
      return cellNameA && cellNameB ? cellNameA.localeCompare(cellNameB) : 0;
    };
  }

  const filteredChanels = useMemo(() => {
    if (data) {
      return filterChannels(data, brandData || [], unitData || [], searchTerm);
    }
    return [];
  }, [brandData, data, searchTerm, unitData]);

  const renderLoading = () => {
    return (
      <BoxContentCenter>
        <WppSpinner size="l" />
      </BoxContentCenter>
    );
  };

  const renderEmpty = () => {
    if (promiseStatusOptions?.done) {
      if (isArrayEmpty(data)) {
        return (
          <EmptyState
            title={translate('txtNoChannelsYet')}
            titleIcon={
              <WppIconSearch slot="icon-start" aria-label="Search icon" color={theme.palette['grey1000']} size="m" />
            }
            text={translate('txtNoChannelsCreated')}
            buttonText={translate('txtCreateChannel')}
            buttonIcon={<WppIconPlus slot="icon-start" />}
            buttonAction={() => {
              setSelectedBrandId(location === MenuItemEnum.BRANDS ? selectedSecondTierItem?.id : null);
              setSelectedUnitId(location === MenuItemEnum.UNIT ? selectedSecondTierItem?.id : null);
              setSelectedChannel(null);
              setContentStreamModalZIndex(5);
              setIsChannelModalOpened(true);
            }}
          />
        );
      }
      if (!filteredChanels.length) {
        return (
          <BoxContentCenter>
            <WppTypography>{`No results found with "${searchTerm}"`}</WppTypography>
          </BoxContentCenter>
        );
      }
    }
    return null;
  };

  const renderError = () => {
    return (
      <EmptyState
        title={translate('txtSomethingWentWrong')}
        text={translate('txtErrorMessage')}
        buttonText={translate('txtTryAgain')}
        buttonAction={() => {
          setTryAgain(true);
        }}
      />
    );
  };

  const renderTable = () => {
    return (
      <>
        <ChannelHeader onSearch={setSearch} searchTerm={searchTerm} location={location} />
        <Table<Partial<ChannelTableDataType>>
          searchable
          searchTerm={searchTerm || ''}
          displayData={filteredChanels}
          columnDefs={Object.values(channelColumnDefinitions)}
          onSelectRows={onSelectRows}
          fixedHeight={
            location === MenuItemEnum.CHANNELS
              ? `calc(${percentOfAppHeight(100)} - 24rem)`
              : `calc(${percentOfAppHeight(100)} - 30rem)`
          }
          renderLoading={renderLoading}
          isLoading={promiseStatusOptions?.loading}
          renderEmptyState={renderEmpty}
          renderError={renderError}
          hasError={promiseStatusOptions?.error}
          handleCellClicked={onRowClicked}
          rowMultiSelectWithClick={isContentStreamPage}
          selectedRowsId={listSelectedRowId}
        />
      </>
    );
  };

  const renderContent = () => {
    if (isContentStreamPage) return renderTable();
    else
      return (
        <PlatformWrapper componentBare={WppStyledCard} componentOs={WppStyledCardOs}>
          {renderTable()}
        </PlatformWrapper>
      );
  };
  return renderContent();
};

export default ChannelTable;
