import { SetCallback } from '../../../shared/store';

export interface IBrandPageState {
  brandValueChanges: boolean;
}

export const initialState: IBrandPageState = {
  brandValueChanges: false,
};

const actions = (set: SetCallback<IBrandPageState>) => ({
  setBrandValueChanges: (brandValueChanges: boolean | null) => set((state) => ({ ...state, brandValueChanges })),
});

export const slice = (set: SetCallback<IBrandPageState>) => ({
  ...initialState,
  ...actions(set),
});

export type IBrandPageActions = ReturnType<typeof actions>;

export default {
  slice,
  initialState,
};
