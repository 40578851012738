import { useEffect } from 'react';
import { DEFAULT_COLOR } from '../../Constants/Constants';
import SelectColor from './SelectColor';

export interface IColorFieldProps {
  color: Array<{ color: string; offset: number }>;
  setColor: React.Dispatch<React.SetStateAction<Array<{ color: string; offset: number }>>>;
  disabled: boolean;
  isGradient: boolean;
  errorMessage?: string[];
}

const ColorField: React.FC<IColorFieldProps> = ({ color, setColor, isGradient, disabled, errorMessage }) => {
  useEffect(() => {
    const offset = 1;
    setColor((prev) => {
      const newArr = [...prev];
      newArr[offset] = { color: color[offset]?.color || DEFAULT_COLOR, offset };
      return newArr;
    });
  }, [isGradient]);

  return (
    <>
      <SelectColor
        stateValue={color}
        disabled={disabled}
        setStateValue={setColor}
        offset={0}
        errorMessage={errorMessage ? errorMessage[0] : ''}
      />
      {isGradient && (
        <SelectColor
          stateValue={color}
          disabled={disabled}
          setStateValue={setColor}
          offset={1}
          errorMessage={errorMessage ? errorMessage[1] : ''}
        />
      )}
    </>
  );
};

export default ColorField;
