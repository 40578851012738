import { useEffect, FC, useState, useCallback, useRef, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  WppActionButton,
  WppIconInfo,
  WppIconDownload,
  WppSpinner,
  WppIconQrCode,
} from '@wppopen/components-library-react';
import QRCodeStyling, { Options } from 'qr-code-styling';
import { BoxContent, BoxContentCenter, ConditionalTooltip } from '../../../shared/components';
import ConfirmationModal from '../../../shared/components/Modals/ConfirmationModal';
import { translate } from '../../../shared/locales';
import { MenuItemEnum, StatusEnum } from '../../../shared/types';
import {
  Container,
  ContainerCreateStyleButton,
  ContainerChannelShortUrl,
  ContainerToolTip,
  ShortIdInput,
  FeedbackInfoQRCode,
  IconCopyBare,
  IconCopyOs,
  ContainerDomainInput,
  DomainInput,
  PreviewContainer,
  QRChannelContainer,
  QRCodeContainer,
  QRCodePreviewTitle,
  QrStyleInput,
  ContainerShortIdInput,
  Slash,
  StyleContainer,
} from './styles/ChannelQRCode.styled';
import channelManager from '../services/ChannelCreateEditManager';
import { useSelectedOrganisationState } from '../../SideMenu/hooks/useSelectedOrganisarionState';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';
import FallbackWrapper from '../../Feedback/views/FallbackWrapper';
import { useSelectedChannelState } from '../../Channel/hooks';
import { useIsChannelModalOpenedState } from '../hooks/useIsChannelModalOpenedState';
import QRChannelPreview from './QRChannelPreview';
import { ChannelFormCreate } from '../types/ChannelCreate';
import { copyTextToClipboard } from '../../../shared/utils';
import { initializeDomainAlert, initializeSelectedDomain } from '../channelCreateUtils';
import PlatformWrapper from '../../../shared/components/PlatformWrapper/PlatformWrapper';
import { BackofficeAppContext } from '../../../app/context/BackofficeAppContext';

interface IChannelQRCodeProps {
  channel: ChannelFormCreate & { channelURL?: string; status: StatusEnum };
  setChannel: (channel: ChannelFormCreate & { channelURL?: string; status: StatusEnum }) => void;
  baseDomain: string;
  isSaving?: boolean;
  handleSelectChange: (name: string, event: CustomEvent) => void;
  areObjectsEqual?: boolean;
  qrCodeDataFlag?: boolean;
}

const NO_QR_STYLE_SELECTED = 'NO_QR_STYLE_SELECTED';

const ChannelQRCode: FC<IChannelQRCodeProps> = ({
  channel,
  setChannel,
  baseDomain,
  isSaving,
  handleSelectChange,
  areObjectsEqual,
  qrCodeDataFlag,
}) => {
  const { selectedOrganisation } = useSelectedOrganisationState();
  const { selectedChannel } = useSelectedChannelState();
  const { setIsChannelModalOpened } = useIsChannelModalOpenedState();
  const { setSelectedSideMenuItem } = useSelectedSideMenuItemState();

  const { insideWppOs } = useContext(BackofficeAppContext);

  const [selectedQrStyleConfig, setSelectedQrStyleConfig] = useState<Options>();
  const [exitConfirmationModalOpened, setExitConfirmationModalOpened] = useState<boolean>(false);

  const [qrStyles, setQRStyles] = useState<{ label: string; value: string; configuration: Options }[]>([]);
  const [qrStyleSelected, setQRStyleSelected] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [promise, setPromise] = useState({
    loading: false,
    error: false,
    success: false,
    successToastMessage: '',
    errorToastMessage: '',
  });
  const [textCopied, setTextCopied] = useState<boolean>(false);

  const domains = [{ label: baseDomain, value: baseDomain }];

  if (selectedOrganisation?.vanityDomain && domains.length <= 1)
    domains.unshift({
      label: 'https://' + selectedOrganisation?.vanityDomain,
      value: selectedOrganisation?.vanityDomain,
    });

  const [domainSelected, setDomainSelected] = useState<string>(
    initializeSelectedDomain(domains, channel.isOrganisationShortDomain),
  );
  const [inputDomainAlert, setInputDomainAlert] = useState<string>(
    initializeDomainAlert(domains, channel.isOrganisationShortDomain, selectedOrganisation?.vanityDomain),
  );

  const navigate = useNavigate();

  const ref = useRef<HTMLDivElement>(null);

  const download = async () => {
    if (channel.shortUrlId && selectedChannel) {
      const qrCode = new QRCodeStyling({
        ...selectedQrStyleConfig,
        data: handleUrlDisplay(channel.isOrganisationShortDomain),
      });
      qrCode.download({
        name: selectedChannel.name,
        extension: 'svg',
      });
    }
  };

  const renderLoading = () => {
    return (
      <BoxContentCenter>
        <WppSpinner size="m" />
      </BoxContentCenter>
    );
  };

  const renderQrContent = () => {
    if (isSaving) {
      return <WppSpinner size="m" data-testid="loading-qr-code" />;
    }
    if (channel.shortUrlId && selectedQrStyleConfig) {
      return (
        <PreviewContainer>
          {qrCodeDataFlag ? (
            <QRChannelPreview
              configuration={{
                ...selectedQrStyleConfig,
                width: 120,
                height: 120,
                data: 'https://' + domains[0].value + '/s/' + channel.shortUrlId,
              }}
            />
          ) : (
            <QRChannelPreview
              configuration={{
                ...selectedQrStyleConfig,
                width: 120,
                height: 120,
                data: domains[domains.length - 1].value + '/s/' + channel.shortUrlId,
              }}
            />
          )}
        </PreviewContainer>
      );
    }
    return (
      <PreviewContainer>
        <WppIconQrCode width={110} />
      </PreviewContainer>
    );
  };

  const displayQrCodeSection = () => {
    return (
      <>
        {renderQrContent()}
        {selectedQrStyleConfig && channel.shortUrlId && areObjectsEqual ? (
          <WppActionButton onClick={download} disabled={!channel.shortUrlId} data-testid="donwload-btn">
            <WppIconDownload slot="icon-start" />
            {translate('btnDownloadQRCode')}
          </WppActionButton>
        ) : (
          <FeedbackInfoQRCode>
            <WppIconInfo /> <span>{translate('txtSaveToUpdate')}</span>
          </FeedbackInfoQRCode>
        )}
      </>
    );
  };

  const navigateToQrStyles = () => {
    navigate(`${selectedOrganisation?.id}/${MenuItemEnum.QRSTYLES.toLowerCase()}`);
    setSelectedSideMenuItem(MenuItemEnum.QRSTYLES);
    setIsChannelModalOpened(false);
  };

  const createQRStyle = () => {
    if (areObjectsEqual) {
      return navigateToQrStyles();
    }
    return setExitConfirmationModalOpened(true);
  };

  const exitEditModal = () => {
    return (
      exitConfirmationModalOpened && (
        <ConfirmationModal
          title={translate('txtConfirmationModalLeaveEditTitle')}
          text={translate('txtConfirmationModalLeaveEditText')}
          buttonCancelText={translate('txtButtonModalCancel')}
          buttonText={translate('txtButtonModal')}
          buttonAction={() => setExitConfirmationModalOpened(false)}
          buttonCancelAction={navigateToQrStyles}
          isOpen={exitConfirmationModalOpened}
          setIsOpen={setExitConfirmationModalOpened}
          isWarning={false}
        />
      )
    );
  };

  const fillQRStylesList = useCallback(async () => {
    if (selectedOrganisation?.id) {
      try {
        setLoading(true);
        const qrList = await channelManager.getQRStyleList(selectedOrganisation?.id);
        if (qrList && qrList.length) {
          const selectOptions = qrList.map((qr) => ({
            label: qr.name,
            value: qr.templateId,
            configuration: qr.configuration,
          }));
          setQRStyles(selectOptions);
          if (selectedChannel && selectedChannel.qrCodeTemplateId) {
            setQRStyleSelected(selectedChannel.qrCodeTemplateId);
          } else {
            setQRStyleSelected(selectOptions[0].value);
            setChannel({
              ...channel,
              qrCodeTemplateId: selectOptions[0].value,
            });
          }
        }
      } catch (e) {
        setPromise({
          loading: false,
          success: false,
          error: true,
          errorToastMessage: translate('txtSomethingWentWrongTryAgain'),
          successToastMessage: '',
        });
      } finally {
        setLoading(false);
      }
    }
  }, [channel, selectedChannel, selectedOrganisation?.id, setChannel]);

  const handleChange = (e: CustomEvent) => {
    setQRStyleSelected(e.detail.value);
    handleSelectChange('qrCodeTemplateId', {
      ...e,
      detail: { value: e.detail.value === NO_QR_STYLE_SELECTED ? '' : e.detail.value },
    });
  };

  const handleChangeDomain = (e: CustomEvent) => {
    setDomainSelected(e.detail.value);
    setInputDomainAlert(
      e.detail.value === selectedOrganisation?.vanityDomain ? translate('txtOrganisationExternalDomainAlert') : '',
    );
    setChannel({
      ...channel,
      isOrganisationShortDomain: e.detail.value === selectedOrganisation?.vanityDomain,
    });
  };

  const getQRStyleConfig = useCallback(() => {
    setSelectedQrStyleConfig(qrStyles.find((qr) => qr.value === qrStyleSelected)?.configuration);
  }, [qrStyleSelected, qrStyles]);

  useEffect(() => {
    fillQRStylesList();
  }, []);

  useEffect(() => {
    getQRStyleConfig();
  }, [getQRStyleConfig]);

  const handleUrlDisplay = (isOrganisationShortDomain?: boolean) => {
    if (isOrganisationShortDomain) {
      return 'https://' + domainSelected + '/s/' + channel.shortUrlId;
    }
    return domainSelected + '/s/' + channel.shortUrlId;
  };

  return (
    <Container>
      {exitEditModal()}
      <FallbackWrapper {...promise} />
      <BoxContent>
        <QRCodePreviewTitle type="m-strong">QR Code</QRCodePreviewTitle>
      </BoxContent>
      {loading ? renderLoading() : null}
      <QRChannelContainer flex justify="space-between">
        {!loading ? (
          <BoxContent flex direction="column" w={'auto'}>
            <ContainerChannelShortUrl marg="12 0">
              <ContainerDomainInput>
                <div ref={ref}>
                  <DomainInput
                    options={domains}
                    disabled={domains.length <= 1}
                    required
                    value={domainSelected}
                    onWppChange={handleChangeDomain}
                    labelConfig={{
                      text: translate('txtDomain'),
                    }}
                    message={inputDomainAlert}
                    messageType={inputDomainAlert ? 'warning' : undefined}
                  />
                </div>
                <Slash flex marg={insideWppOs ? '40 14 0 14' : '40 15 0 15'} w="auto">
                  /s/
                </Slash>
              </ContainerDomainInput>
              <ContainerShortIdInput marg="30 0 0 0">
                <ShortIdInput
                  data-testid="input-qr-code-url"
                  autoFocus
                  name="title"
                  disabled
                  size="m"
                  required
                  placeholder={'Channel URL'}
                  value={channel.shortUrlId}
                />
                <ContainerToolTip>
                  <ConditionalTooltip
                    showTooltip={textCopied}
                    tooltipText={translate('txtTextCopied')}
                    config={{ showOnCreate: true }}>
                    <WppActionButton
                      onClick={async () => {
                        copyTextToClipboard(handleUrlDisplay(channel.isOrganisationShortDomain))
                          .then(() => {
                            setTextCopied(true);
                          })
                          .catch((error) => {
                            setTextCopied(false);
                          });
                      }}
                      disabled={!channel.shortUrlId}
                      data-testid="copy-btn">
                      <PlatformWrapper
                        componentBare={IconCopyBare}
                        componentOs={IconCopyOs}
                        aria-label="Copy icon"
                        copyEnabled={!!channel.shortUrlId}
                      />
                    </WppActionButton>
                  </ConditionalTooltip>
                </ContainerToolTip>
              </ContainerShortIdInput>
            </ContainerChannelShortUrl>
            {!loading && (
              <StyleContainer flex>
                <QrStyleInput
                  options={qrStyles}
                  disabled={!qrStyles.length}
                  required
                  value={qrStyleSelected}
                  onWppChange={handleChange}
                  labelConfig={{
                    text: translate('txtStyle'),
                  }}
                />
                <ContainerCreateStyleButton>
                  <BoxContent>
                    <WppActionButton onClick={createQRStyle} disabled={loading} data-testid="create-btn">
                      {translate('txtCreateQRStyle')}
                    </WppActionButton>
                  </BoxContent>
                </ContainerCreateStyleButton>
              </StyleContainer>
            )}
          </BoxContent>
        ) : null}
        <QRCodeContainer flex align="center" direction="column" w="125px">
          {!loading ? displayQrCodeSection() : null}
        </QRCodeContainer>
      </QRChannelContainer>
    </Container>
  );
};

export default ChannelQRCode;
