/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { WppActionButton, WppCheckbox, WppInput } from '@wppopen/components-library-react';
import { TOption } from '../../types/TOption';
import { translate } from '../../locales';
import {
  ContainerSelectedItem,
  MainContainer,
  StyledItemListContainer,
  StyledWppIconCross,
  StyledWppTag,
  WppStyledBoxContentBottom,
  WppStyledBoxContentHeader,
  WppStyledCard,
  WppStyledChevronIcon,
  WppStyledHeaderTypography,
  WppStyledListItem,
} from './AutoCompleteDropDown.styled';
import TextHighlight from '../TextHighlight/TextHighlight';

export interface IAutoCompleteDropDownProps {
  dataTestId?: string;
  entityName?: string;
  isMultipleSelection: boolean;
  options: TOption[];
  selectedOptions: TOption[];
  setSelectedOptions: (options: TOption[]) => void;
  placeHolder: string;
  isLoading?: boolean;
  disabled?: boolean;
  showTags?: boolean;
  handleCreateNewOption?: (newItem: TOption) => void;
  showCreateNewElement?: boolean;
  maxItemsToScroll?: number;
  useTextHighLight?: boolean;
  isSearchAllowed?: boolean;
  errorMessage?: string;
  style?: any;
}

const AutoCompleteDropDown: React.FC<IAutoCompleteDropDownProps> = ({
  dataTestId,
  entityName,
  isMultipleSelection,
  options,
  errorMessage,
  disabled = false,
  selectedOptions,
  placeHolder,
  isLoading,
  showTags = false,
  handleCreateNewOption,
  setSelectedOptions,
  showCreateNewElement = true,
  maxItemsToScroll = 5,
  useTextHighLight = true,
  isSearchAllowed = true,
  style = {},
}: IAutoCompleteDropDownProps) => {
  const [inputValue, setInputValue] = useState('');
  const [isInputFocused, setIsInputFocused] = useState<boolean>(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredItems, setFilteredItems] = useState<TOption[]>(options);

  const handleChange = (event: CustomEvent) => {
    if (isSearchAllowed) {
      const value = event.detail.value;
      setInputValue(value);
      const filtered = options.filter((option) => option.label.toLowerCase().includes(value.toLowerCase()));
      if (filtered) setFilteredItems(filtered);
    } else setInputValue('');
  };

  const isItemSelected = (item: TOption) => {
    return (
      selectedOptions.filter((selectedItem) => selectedItem.label.toLowerCase() === item.label.toLowerCase()).length !==
      0
    );
  };

  const handleItemClick = (item: TOption) => {
    if (isMultipleSelection) {
      setInputValue('');
      if (isItemSelected(item)) {
        setSelectedOptions(
          selectedOptions.filter((selectedItem) => {
            return selectedItem.label !== item.label;
          }),
        );
      } else {
        setSelectedOptions([...selectedOptions, item]);
      }
    } else {
      setSelectedOptions([item]);
      setInputValue(item.label);
      setIsDropdownOpen(false);
      setIsInputFocused(false);
    }
  };

  const handleInputFocus = () => {
    setIsInputFocused(true);
    setIsDropdownOpen(true);
  };

  const handleSelectAll = () => {
    setSelectedOptions(filteredItems);
    setInputValue('');
    setFilteredItems(options);
  };

  const handleClearAll = () => {
    setSelectedOptions([]);
    setInputValue('');
    setFilteredItems(options);
  };

  const handleChevronClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    setInputValue('');
    setFilteredItems(options);
  }, [options]);

  const enableCreateNewButton = () => {
    const hasPerfectMatching =
      options.filter((option) => option.label.toLowerCase() === inputValue.toLowerCase()).length === 0;
    if (options.length && !!inputValue) return !hasPerfectMatching;
    if (inputValue) return false;
    return true;
  };

  const getPlaceholder = useCallback(() => {
    if (isLoading) return translate('txtLoading', { something: entityName ? entityName : translate('txtOptions') });
    if (selectedOptions.length === 0 && placeHolder) return placeHolder;
    if (!isMultipleSelection) return selectedOptions[0].label;
    return `${selectedOptions.length} ${translate('txtSelected')}`;
  }, [isLoading, entityName, selectedOptions, placeHolder, isMultipleSelection]);

  return (
    <MainContainer>
      <div>
        <WppInput
          message={errorMessage}
          messageType={errorMessage ? 'error' : undefined}
          type="text"
          style={style}
          data-testid={dataTestId ? dataTestId : 'input-autocomplete'}
          value={inputValue}
          placeholder={getPlaceholder()}
          onWppChange={handleChange}
          disabled={disabled}
          onWppFocus={handleInputFocus}
          onWppBlur={() => setIsInputFocused(false)}>
          {options.length && (
            <WppStyledChevronIcon
              isOpened={isDropdownOpen || isInputFocused}
              slot="icon-end"
              onClick={handleChevronClick}
            />
          )}
        </WppInput>
      </div>

      {(isInputFocused || isDropdownOpen) && (
        <WppStyledCard onMouseEnter={() => setIsDropdownOpen(true)} onMouseLeave={() => setIsDropdownOpen(false)}>
          {filteredItems.length ? (
            filteredItems.length === options.length &&
            isMultipleSelection && (
              <WppStyledBoxContentHeader>
                <WppActionButton
                  slot="header"
                  variant="secondary"
                  onClick={handleClearAll}
                  disabled={!selectedOptions.length}>
                  {translate('txtClearAll')}
                </WppActionButton>
                <WppActionButton
                  slot="header"
                  variant="secondary"
                  onClick={handleSelectAll}
                  disabled={selectedOptions.length === options.length}>
                  {translate('txtSelectAll')}
                </WppActionButton>
              </WppStyledBoxContentHeader>
            )
          ) : (
            <WppStyledBoxContentHeader>
              {options.length && inputValue ? (
                <WppStyledHeaderTypography type="s-body">{translate('txtNothingFound')}</WppStyledHeaderTypography>
              ) : (
                <WppStyledHeaderTypography type="s-body">
                  {translate('txtNothingFoundTypeToCreateANewOne')}
                </WppStyledHeaderTypography>
              )}
            </WppStyledBoxContentHeader>
          )}
          <StyledItemListContainer data-testid="list-container" maxItems={maxItemsToScroll}>
            {filteredItems.length !== 0 &&
              filteredItems.map((item) => (
                <WppStyledListItem
                  data-testid={`${item.value}`}
                  isSelected={isItemSelected(item)}
                  isMultipleSelection={isMultipleSelection}
                  onClick={() => handleItemClick(item)}>
                  {isMultipleSelection && (
                    <WppCheckbox slot="left" checked={isItemSelected(item)} onWppChange={() => handleItemClick(item)} />
                  )}
                  <label slot="label">
                    {useTextHighLight ? (
                      <TextHighlight fullString={item.label} subString={inputValue}></TextHighlight>
                    ) : (
                      item.label
                    )}
                  </label>
                </WppStyledListItem>
              ))}
          </StyledItemListContainer>

          {showCreateNewElement && (
            <WppStyledBoxContentBottom>
              <WppActionButton
                slot="header"
                disabled={enableCreateNewButton()}
                onClick={() => handleCreateNewOption?.({ label: inputValue, value: '' })}>
                {`${translate('txtCreate')} ${inputValue && " '"}${inputValue}${inputValue && "'"}`}
              </WppActionButton>
            </WppStyledBoxContentBottom>
          )}
        </WppStyledCard>
      )}
      {showTags && (
        <div>
          {selectedOptions.map((option) => (
            <ContainerSelectedItem>
              <StyledWppTag key={option.value} variant="neutral" label={option.label} />
              <StyledWppIconCross size="s" onClick={() => handleItemClick(option)} />
            </ContainerSelectedItem>
          ))}
        </div>
      )}
    </MainContainer>
  );
};

export default AutoCompleteDropDown;
