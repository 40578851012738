/* eslint-disable @typescript-eslint/no-empty-interface */
import { BoxContentCenter, MenuGroup, TextHighlight } from '../../../../shared/components';
import React from 'react';
import { getLanguageOptions, getSortedLanguagesText } from '../../utils/contentStreamContentUtils';
import { StatusEnum } from '../../../SecondTierSidebar/types';
import { useSelectedOrganisationState } from '../../../SideMenu/hooks/useSelectedOrganisarionState';
import { getStringEllipsed } from '../../../../shared/utils/stringUtils';
import { LanguageContainer } from '../styles/LanguageCell.styled';

export interface ILanguageCellProps {
  languages: Array<{ locale: string; status: StatusEnum }>;
  searchTerm?: string;
}

const LanguageCell: React.FC<ILanguageCellProps> = ({ languages, searchTerm }) => {
  const MAX_LANGUAGES_LENGHT = 8;
  const { selectedOrganisation } = useSelectedOrganisationState();
  return (
    <BoxContentCenter justify="flex-start">
      <LanguageContainer>
        <TextHighlight
          fullString={getStringEllipsed(
            getSortedLanguagesText(languages, selectedOrganisation?.defaultLanguage ?? ''),
            MAX_LANGUAGES_LENGHT,
          )}
          subString={searchTerm ? searchTerm : ''}
        />
      </LanguageContainer>
      <MenuGroup options={getLanguageOptions(languages, selectedOrganisation?.defaultLanguage ?? '')} />
    </BoxContentCenter>
  );
};

export default LanguageCell;
