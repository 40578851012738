import { TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { WppAccordion } from '@wppopen/components-library-react';

export const TargetsCard = styled(WppAccordion)(({ theme }: TStyledDefaultProps) => ({
  backgroundColor: theme?.palette?.['grey100'],
  marginBottom: 12,
  width: 'calc(100% - 12px)',
  borderRadius: 12,
  paddingRight: 12,
  position: 'relative',
  '&::part(section)': { width: '100%' },
  '&::part(title-wrapper)': {
    paddingLeft: 12,
  },
}));

export const ContainerlocationSelection = styled.div({
  marginTop: 10,
  display: 'flex',
  justifyContent: 'space-between',
});

export const ContainerSelectDropDown = styled.div({
  width: '15%',
  minWidth: '100px',
  marginRight: '6px',
});

export const ContainerInput = styled.div({ width: '85%', position: 'relative' });

export const ContainerTable = styled.div({ marginTop: '20px', width: '100%' });
