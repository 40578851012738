/**
 * Use this to call BFF endpoints
 */

import { HttpService } from '@core/http-service';
import {
  OrganisationUnitGetDTO,
  OrganisationUnitPutDTO,
} from '@connected-core-system/utils/organisation-unit-http-dto';
import { interpolateWithValues } from '../../../shared/utils';
import { BFF_ENDPOINTS, HttpResolver } from '../../../shared/api';
import { SecondTierSidebarItem } from '../../SecondTierSidebar/types';
import { IOrganisationUnitPageRepository } from '../types';
export class OrganisationUnitPageRepository implements IOrganisationUnitPageRepository {
  constructor(private readonly httpService: HttpService) {}

  async createOrganisationUnit(organisationId: string, name: string): Promise<string> {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_UNITS_GET, organisationId);
    const newUnit = await this.httpService.post<string>(url, { name: name });
    return newUnit.data;
  }

  async updateOrganisationUnit(organisationId: string, item: SecondTierSidebarItem): Promise<OrganisationUnitGetDTO> {
    const unitToBeUpdated: OrganisationUnitPutDTO = {
      name: item.name,
      status: item.status,
    };
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_UNITS_POST, organisationId, item.id);
    const unitUpdated = await this.httpService.put<OrganisationUnitGetDTO>(url, unitToBeUpdated);
    return unitUpdated.data;
  }
}

export default new OrganisationUnitPageRepository(HttpResolver.getHttpService());
