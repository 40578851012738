import styled from '@emotion/styled';

import { WppProgressIndicator } from '@wppopen/components-library-react';

export const ModalActions = styled.div({
  display: 'flex',
  justifyContent: 'flex-end',
});

export const StyledWppProgressIndicator = styled(WppProgressIndicator)({
  paddingTop: '1.4rem',
  paddingBottom: '1.4rem',
  width: '100%',
});
