import { SetCallback } from '../../../shared/store';

export interface IContentStreamState {
  tryAgain: boolean;
}

export const initialState: IContentStreamState = {
  tryAgain: false,
};

const actions = (set: SetCallback<IContentStreamState>) => ({
  setTryAgain: (tryAgain: boolean) => set((state) => ({ ...state, tryAgain: tryAgain })),
});

export const slice = (set: SetCallback<IContentStreamState>) => ({
  ...initialState,
  ...actions(set),
});

export type IContentStreamActions = ReturnType<typeof actions>;

export default {
  slice,
  initialState,
};
