import { HttpService } from '@core/http-service';
import { HttpResolver, BFF_ENDPOINTS } from '../../../shared/api';
import { IOrganisation } from '../../../shared/types';
import { IOrganisationRepository } from '../types/IOrganisationRepository';
import { interpolateWithValues } from '../../../shared/utils';

export class OrganisationRepository implements IOrganisationRepository {
  constructor(private readonly httpService: HttpService) {}

  fetchOrganisations() {
    return this.httpService.get<Array<IOrganisation>>(BFF_ENDPOINTS.ORGANISATION_GET);
  }

  fetchOrganisationByTenantId(tenantId: string) {
    const url = interpolateWithValues(BFF_ENDPOINTS.ORGANISATION_BY_TENANT_ID_GET, tenantId);
    return this.httpService.get<IOrganisation>(url);
  }
}

export default new OrganisationRepository(HttpResolver.getHttpService());
