import { BoxContent, TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { WppCard, WppIconChevron, WppTypography } from '@wppopen/components-library-react';

export const IconUp = styled(WppIconChevron)({
  transform: 'rotate(270deg)',
});

export const IconDown = styled(WppIconChevron)({
  transform: 'rotate(90deg)',
  alignItems: 'end',
});

export const Margin = styled.div({
  marginTop: 16,
  marginBottom: 16,
});

export const MarginOs = styled.div({
  marginTop: 10,
  marginBottom: 10,
});

export const Navigation = styled(BoxContent)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  margin: 'auto',
});

export const NavigateText = styled(WppTypography)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey800'],
  fontWeight: '500',
  fontSize: '12px',
  fontFamily: 'Inter',
}));

export const PreviewCard = styled(WppCard)({
  height: 482,
  width: 258,
  '&::part(card)': {
    background: 'linear-gradient(180deg, #FFFFFF 0%, rgba(202, 184, 255, 0.7) 100%)',
  },
  position: 'relative',
  overflow: 'hidden',
  borderRadius: '26px',
  margin: 'auto',
});

export const NavigationCard = styled(WppCard)({
  width: 258,
  margin: 'auto',
});

export const WppStyledTypography = styled(WppTypography)({
  color: '#B91C1C',
});

export const WppStyledContainer = styled.div({
  color: '#B91C1C',
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  top: '50%',
  transform: 'translateY(-50%)',
  justifyContent: 'center',
});
