import styled from '@emotion/styled';
import { WppTag, WppIconCross, WppActionButton } from '@wppopen/components-library-react';
import { BoxContent } from '../../../../shared/components';
import { StyledWppAutoComplete } from '../../../../shared/components/AutoComplete/AutoComplete.styled';

export const ContainerCategories = styled(BoxContent)({
  boxShadow: '0px 0px 1px rgba(52, 58, 63, 0.1), 0px 1px 5px rgba(52, 58, 63, 0.1)',
  borderRadius: 8,
  padding: '2rem 2.4rem',
});

export const StyledWppAutocomplete = styled(StyledWppAutoComplete)({
  width: '100%',
  '&::part(dropdown)': {
    position: 'relative',
    zIndex: 3,
  },
});

export const StyledWppTag = styled(WppTag)({
  marginRight: 4,
  marginTop: 4,
  paddingRight: 24,
});

export const StyledWppIconCross = styled(WppIconCross)({
  position: 'absolute',
  right: 8,
  top: 8,
  cursor: 'pointer',
});

export const TrashIconButton = styled(WppActionButton)({
  '&::part(button)': {
    padding: '4px',
  },
});

export const ContainerSelectedItem = styled.div({
  position: 'relative',
  display: 'inline-block',
});

export const ContainerCategoryField = styled.div({
  display: 'inline-block',
  position: 'relative',
  verticalAlign: 'top',
  width: '30%',
  paddingRight: 16,
  minWidth: 160,
  '@media (max-width: 980px)': {
    width: 'calc(100% - 36px)',
  },
});

export const ContainerTagField = styled.div({
  display: 'inline-block',
  position: 'relative',
  verticalAlign: 'top',
  width: 'calc(70% - 52px)',
  paddingRight: 12,
  minWidth: 160,
  '@media (max-width: 980px)': {
    paddingTop: 12,
    width: 'calc(100% - 36px)',
  },
});

export const ContainerTrashIconButton = styled.div({
  display: 'inline-block',
  position: 'relative',
  paddingTop: 5,
  width: 24,
  '@media (max-width: 980px)': {
    paddingTop: 19,
  },
});

export const ContainerCategoryFirst = styled.div({
  minWidth: 150,
});
