/* eslint-disable @typescript-eslint/no-empty-interface */
import React from 'react';
import { StyledWppGrid } from './ContentPage.styled';

export interface IContentPageProps {}

const ContentPage = (props: React.PropsWithChildren<IContentPageProps>) => {
  return (
    <StyledWppGrid item all={24} fullHeight>
      {props.children}
    </StyledWppGrid>
  );
};

export default ContentPage;
