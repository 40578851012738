import { useSliceSetter, useSliceState } from '../../../shared/hooks/useStore';
import {
  IContentStreamCreateEditActions,
  IContentStreamCreateEditState,
} from '../sliceStore/contentStreamCreateEditSliceStore';

export const useIsAddTargetButtonOpenedState = () => {
  const isAddTargetButtonOpened = useSliceState<IContentStreamCreateEditState, 'isAddTargetButtonOpened'>(
    'isAddTargetButtonOpened',
  );
  const setIsAddTargetButtonOpened = useSliceSetter<IContentStreamCreateEditActions, 'setIsAddTargetButtonOpened'>(
    'setIsAddTargetButtonOpened',
  );

  return { isAddTargetButtonOpened, setIsAddTargetButtonOpened };
};
