/* eslint-disable @typescript-eslint/no-empty-interface */
import { BoxContentCenter, TextHighlight } from '../../../../shared/components';
import React from 'react';
import { ContentsTableDataType } from '../../types/ContentsTableDataType';

export interface ITemplateCellProps {
  collectionType: ContentsTableDataType['collectionType'];
  searchTerm?: string;
}

const TemplateCell: React.FC<ITemplateCellProps> = ({ collectionType, searchTerm }) => {
  return (
    <BoxContentCenter justify="flex-start">
      <TextHighlight fullString={collectionType} subString={searchTerm ? searchTerm : ''} />
    </BoxContentCenter>
  );
};

export default TemplateCell;
