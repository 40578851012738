/* eslint-disable @typescript-eslint/no-empty-interface */
import { BoxContentCenter } from '@connected-core-system/utils/frontend';
import { StatusEnum } from '@connected-core-system/utils/types';
import { WppTag } from '@wppopen/components-library-react';
import React from 'react';
import { ContentStreamTableDataType } from '../types/ContentStreamTableDataType';

export interface IContentStreamStatusCellProps {
  status: ContentStreamTableDataType['status'];
}

const ContentStreamStatusCell: React.FC<IContentStreamStatusCellProps> = ({ status }) => {
  const color = status === StatusEnum.ACTIVE ? 'positive' : 'neutral';

  return (
    <BoxContentCenter justify="flex-start">
      <WppTag variant={color} label={status} />
    </BoxContentCenter>
  );
};

export default ContentStreamStatusCell;
