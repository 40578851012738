import { TreeType } from '@wppopen/components-library';
import { SecondTierSidebarItem, StatusEnum } from '../types';
import { getStringEllipsed } from './../../../shared/utils';

export const treeActiveId = StatusEnum.ACTIVE;
export const treeArchivedId = StatusEnum.ARCHIVED;
export const treeCharacterLimit = 31;

export function generateData(
  itemList: SecondTierSidebarItem[],
  activeListOpen = false,
  archivedListOpen = false,
): TreeType[] {
  const activeItemList = itemList
    .filter((item) => item.status === 'Active')
    .map((item) => {
      return {
        title: getStringEllipsed(item.name, treeCharacterLimit),
        id: item.id,
      };
    });
  const archivedItemList = itemList
    .filter((item) => item.status === 'Archived')
    .map((item) => {
      return {
        title: getStringEllipsed(item.name, treeCharacterLimit),
        id: item.id,
      };
    });
  return [
    {
      id: treeActiveId,
      title: 'Active',
      open: activeListOpen,
      disabled: activeItemList.length === 0,
      children: activeItemList,
    },
    {
      id: treeArchivedId,
      title: 'Archived',
      open: archivedListOpen,
      disabled: archivedItemList.length === 0,
      children: archivedItemList,
    },
  ];
}

export const getDataWithoutChildren = (itemList: SecondTierSidebarItem[]): TreeType[] => {
  return itemList.map((i) => ({
    id: i.id,
    title: i.name,
  }));
};
