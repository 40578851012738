import React from 'react';
import { StyledWppIconTrash } from '../styles/Schedule.styled';

export interface IScanHistoryCellProps {
  handleDelete: () => void;
}

const ScanHistoryDeleteCell: React.FC<IScanHistoryCellProps> = ({ handleDelete }) => {
  return (
    <div>
      <StyledWppIconTrash
        onClick={() => {
          handleDelete();
        }}
        data-testid="icon-remove"
      />
    </div>
  );
};

export default ScanHistoryDeleteCell;
