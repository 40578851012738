import styled from '@emotion/styled';
import { WppAutocomplete, WppCard, WppTypography } from '@wppopen/components-library-react';
import { BoxContent } from '../../../../shared/components';

export const ContainerCard = styled(WppCard)({
  '&::part(card)': {
    overflow: 'visible',
  },
});

export const FallbackHeader = styled(WppTypography)({
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  width: '100%',
});

export const FallbackCard = styled(WppCard)({ '--card-header-margin-m': '0' });

export const FormContainer = styled.div({
  display: 'inline-block',
  width: '100%',
  padding: 8,
});

export const ContainerCategories = styled(BoxContent)({
  boxShadow: '0px 0px 1px rgba(52, 58, 63, 0.1), 0px 1px 5px rgba(52, 58, 63, 0.1)',
  borderRadius: 8,
});

export const ContainerSelectUnitBrand = styled.div({
  width: 'calc(50% - 16px)',
  minWidth: '160px',
  display: 'inline-block',
  marginRight: 16,
  '@media (max-width: 1077px)': {
    marginTop: 16,
  },
});

export const WrapperSelectUnitBrand = styled.div({
  width: '50%',
  minWidth: 352,
  display: 'inline-block',
  '@media (max-width: 590px)': {
    minWidth: 0,
  },
});

export const ContainerTitle = styled.div({
  width: 'calc(50% - 16px)',
  minWidth: 336,
  display: 'inline-block',
  marginRight: 16,
  float: 'left',
  '@media (max-width: 590px)': {
    minWidth: 160,
  },
});

export const StyledMultiSelect = styled(WppAutocomplete)({
  '&::part(dropdown)': {
    minHeight: 60,
    position: 'relative',
    zIndex: 3,
  },
});
