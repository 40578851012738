import { ChannelPostDTO } from '@connected-core-system/utils/channel-http-dto';
import { TCategoryFormField } from './types/CategoryFormField';
import { ChannelFormCreate } from './types/ChannelCreate';
import { translate } from '../../shared/locales';
import { TOption } from '../../shared/types/TOption';
import { ICategoryTag, IOrganisation, IOrganisationUnit, StatusEnum } from '../../shared/types';
import { interpolateWithValues } from '../../shared/utils';
import { ChannelTableDataType } from '../Channel/types/ChannelTableDataType';
import { IChannelRequestsDoneStates } from './types/IChannelRequestsDoneStates';

export const tabDetailsValue = 'details';
export const tabContentStreamPriorityValue = 'content-stream-priority';

export const channelFormTabs = () => [
  {
    title: translate('tabDetails'),
    value: tabDetailsValue,
  },
  {
    title: translate('tabContentStreamPriority'),
    value: tabContentStreamPriorityValue,
  },
];

export const transformIntoOptions = (units: (IOrganisationUnit & { id: string })[]) => {
  return units.map((u) => ({
    value: u.id,
    label: u.name,
  }));
};

export const validateChannelForm = (
  channel: Partial<ChannelFormCreate> | null,
  selectedUnitId: string,
  selectedBrandId: string,
) => {
  return !!channel?.name && !!(channel?.brandId || selectedBrandId) && !!(channel?.unitId || selectedUnitId);
};

export const generateNewChannelObject = (organisation: IOrganisation | null | undefined) => {
  return {
    brandId: '',
    channelTags: [],
    name: '',
    organisationId: '',
    unitId: '',
    status: StatusEnum.UNPUBLISHED,
    channelContentStreams: [],
    isOrganisationShortDomain: !!organisation?.vanityDomain,
  };
};

export const areObjectsEqualChannelCreateForm = (
  channel: Partial<ChannelFormCreate & { channelURL?: string; status: StatusEnum }>,
  channelTagsFromScreen: TCategoryFormField[],
  newFallbackContentStreamId: string,
) => {
  if (channelTagsFromScreen.length > 0) return false;

  if (channel.unitId || channel.brandId || channel.name || newFallbackContentStreamId) return false;

  return true;
};

export const transformChannelToSave = (
  channel: ChannelFormCreate,
  channelTags: TCategoryFormField[],
  destinationDomainUrl?: string,
): ChannelPostDTO => {
  const channelSave: ChannelPostDTO = {
    brandId: channel.brandId || '',
    unitId: channel.unitId || '',
    name: channel.name || '',
    organisationId: channel.organisationId || '',
    qrCodeTemplateId: channel.qrCodeTemplateId || '',
    fallbackContentStreamId: channel.fallbackContentStreamId,
    categories: channelTags
      .filter((channelTag) => channelTag.label && channelTag.tags.length > 0)
      .map((channelTag) => ({
        categoryName: channelTag.label,
        tags: channelTag.tags.map((tag: TOption) => ({
          name: tag.label,
          categoryId: channelTag.value,
        })),
      })),
    channelContentStreams: channel.channelContentStreams,
    isOrganisationShortDomain: channel.isOrganisationShortDomain,
    destinationDomainUrl: destinationDomainUrl,
  };

  return channelSave;
};

export const transformReturnedChannelToChannelForm = (
  channel: ChannelTableDataType | void,
  organisationId: string,
  channelUrl: string,
): ChannelFormCreate & { channelURL?: string; status: StatusEnum } => {
  return {
    brandId: channel?.brand ?? '',
    unitId: channel?.unit ?? '',
    name: channel?.name.trim() ?? '',
    organisationId: organisationId,
    channelTags: channel?.channelTags ?? [],
    status: channel?.status ?? StatusEnum.UNPUBLISHED,
    qrCodeTemplateId: channel?.qrCodeTemplateId,
    fallbackContentStreamId: channel?.fallbackContentStreamId,
    channelURL: channelUrl,
    channelContentStreams: channel?.channelContentStreams ?? [],
    shortUrlId: channel?.shortUrlId,
    isOrganisationShortDomain: channel?.isOrganisationShortDomain,
  };
};

export const getQrCodeUrl = (baseHostName: string, domain: string, channelId?: string) => {
  return interpolateWithValues(baseHostName, domain) + `/consumer/channel/${channelId ? channelId?.trim() : ''}`;
};

export const areObjectsEqualChannelEditForm = (
  newChannel: ChannelFormCreate,
  newBrandId: string,
  newUnitId: string,
  newFallbackContentStreamId: string,
  channelTagsFromScreen: TCategoryFormField[],
  selectedChannel: ChannelFormCreate,
  requestsDone: IChannelRequestsDoneStates,
): boolean => {
  const newChannelCopy = { ...newChannel };
  const selectedChannelCopy = { ...selectedChannel };

  const nameEqual = newChannelCopy.name === selectedChannelCopy.name;
  const qrCodeEqual = newChannelCopy.qrCodeTemplateId === selectedChannelCopy.qrCodeTemplateId;

  const domainEqual = newChannelCopy.isOrganisationShortDomain === selectedChannelCopy.isOrganisationShortDomain;

  const newContentStreamsCopy = [...newChannelCopy.channelContentStreams];
  const selectedContentStreamsCopy = [...selectedChannelCopy.channelContentStreams];

  const contentStreamsEqual = newContentStreamsCopy.every((newContentStream) => {
    const selectedContentStream = selectedContentStreamsCopy.find(
      (selectedContentStream) => selectedContentStream.contentStreamId === newContentStream.contentStreamId,
    );
    return selectedContentStream?.priority === newContentStream.priority;
  });

  const brandIdEqual = newBrandId === selectedChannelCopy.brandId;
  const unitIdEqual = newUnitId === selectedChannelCopy.unitId;
  const fallbackContentStreamIdEqual =
    newFallbackContentStreamId === (selectedChannelCopy.fallbackContentStreamId || '');

  const tagsFromScreen = channelTagsFromScreen
    .map((cat) => {
      return cat.tags.map((tag) => {
        return { tag: tag.label, category: cat.label } as ICategoryTag;
      });
    })
    .flat() as ICategoryTag[];

  let tagsEqual = true;
  if (requestsDone.tags) {
    tagsEqual =
      selectedChannelCopy.channelTags.length !== tagsFromScreen.length
        ? false
        : selectedChannelCopy.channelTags.every((selectedTag) =>
            tagsFromScreen.find(
              (screenTag) => screenTag.tag === selectedTag.tag && screenTag.category === selectedTag.category,
            ),
          );
  }
  const existsShortUrlId = selectedChannelCopy && selectedChannelCopy.shortUrlId;

  return (
    nameEqual &&
    qrCodeEqual &&
    domainEqual &&
    contentStreamsEqual &&
    brandIdEqual &&
    unitIdEqual &&
    fallbackContentStreamIdEqual &&
    tagsEqual &&
    !!existsShortUrlId
  );
};

export const getTagColor = (status: StatusEnum) => {
  if (status === StatusEnum.ACTIVE) {
    return 'positive';
  } else {
    return 'neutral';
  }
};

export const getValue = (selectedDropdownId: string | undefined, channelSelectedId: string) => {
  if (selectedDropdownId) {
    return selectedDropdownId;
  }
  return channelSelectedId;
};

export const initializeSelectedDomain = (domains: TOption[], isShortDomain?: boolean) => {
  if (isShortDomain === undefined) {
    return domains[0].value;
  }
  return isShortDomain ? domains[0].value : domains[domains.length - 1].value;
};

export const initializeDomainAlert = (
  domains: TOption[],
  isShortDomain?: boolean,
  organisationExternalDomain?: string,
) => {
  if (isShortDomain === undefined || isShortDomain) {
    return domains[0].value === organisationExternalDomain ? translate('txtOrganisationExternalDomainAlert') : '';
  }
  return domains[domains.length - 1].value === organisationExternalDomain
    ? translate('txtOrganisationExternalDomainAlert')
    : '';
};
