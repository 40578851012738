import styled from '@emotion/styled';
import { WppTypography } from '@wppopen/components-library-react';

export const Typography = styled(WppTypography)(({ tagArchived }: { tagArchived: boolean }) => ({
  marginRight: 8,
  whiteSpace: 'nowrap',
  maxWidth: tagArchived ? 'calc(100% - 80px)' : 'calc(100% - 64px)',
  '&::part(typography)': {
    overflow: 'visible',
  },
}));

export const Container = styled.div({ display: 'flex', alignItems: 'center' });
