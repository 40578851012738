import { IAnalyticsRepository } from '../types';
import repoInstance from './AnalyticsRepository';

export class AnalyticsManager {
  constructor(private repo: IAnalyticsRepository) {}

  async getEmbedUrl(organisationId: string) {
    try {
      const powerBiEmbed = await this.repo.getEmbedUrl(organisationId);
      return powerBiEmbed;
    } catch (error) {
      return this.handleError(error);
    }
  }

  handleError(error?: unknown) {
    throw error;
  }
}

export default new AnalyticsManager(repoInstance);
