import styled from '@emotion/styled';
import { WppCard, WppFilterButton } from '@wppopen/components-library-react';
import { percentOfAppHeight } from '../../../../shared/utils';

export const WppSyledFilterButton = styled(WppFilterButton)({
  '&::part(button)': {
    height: '4rem',
  },
});

export const WppStyledCard = styled(WppCard)({
  '&::part(card)': {
    padding: '2.4rem 2.8rem',
  },
});

export const WppStyledCardOs = styled(WppCard)({
  '&::part(card)': {
    padding: '1.6rem',
  },
});

export const WppStyledCardNoContent = styled(WppCard)({
  '&::part(card)': {
    padding: '2.4rem 2.8rem',
    maxHeight: `calc(${percentOfAppHeight(90)} - 130px)`,
    minHeight: `calc(${percentOfAppHeight(90)} - 130px)`,
    display: 'flex',
    alignItems: 'center',
  },
});
