/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useState } from 'react';
import { WppActionButton, WppAvatar, WppIconCross, WppIconError, WppInput } from '@wppopen/components-library-react';
import { BoxContent } from '../../../shared/components';
import { translate } from '../../../shared/locales/i18n';
import { IOrganisation } from '../../../shared/types';
import { useTheme } from '@emotion/react';
import { ITheme } from '@connected-core-system/utils/frontend';
import { useHostName } from '../../../shared/hooks';
import { getStringEllipsed, interpolateWithValues } from '../../../shared/utils';
import {
  StyledWarningMessage,
  StyledWppDomain,
  StyledWppWarningTitle,
  UrlsContainer,
  WppCardWarning,
  WppCardWarningMainTitle,
  WppCardWarningMainTitleContainer,
} from './styles/FormInput.styled';

export interface IFormBodyProps extends Partial<IOrganisation> {
  setOrganisationTitle: (text: string) => void;
  setOrganisationDomain: (text: string) => void;
  setOrganisationExternalDomain: (text: string) => void;
  domain?: string;
  organisationExternalDomain?: string;
  fileUploader: React.ReactElement;
  isEditingMode?: boolean;
  isSmallScreen: boolean;
}

const FormBody: React.FC<IFormBodyProps> = ({
  title,
  domain,
  organisationExternalDomain,
  filePath,
  setOrganisationTitle,
  setOrganisationDomain,
  setOrganisationExternalDomain,
  fileUploader,
  isEditingMode,
  isSmallScreen,
}) => {
  const theme = useTheme() as ITheme;
  const txtTitle = translate('txtTitle');
  const txtDomain = translate('txtDomain');
  const txtOrganisationExternalDomain = translate('txtOrganisationExternalDomain');
  const prefixAvatar = title || 'O';

  const baseHostName = useHostName();
  const [inputDomainError, setInputDomainError] = useState<string>('');
  const [shouldHaveWarning, setShouldHaveWarning] = useState<boolean>(!isEditingMode);

  function onDomainChange(changedDomain: string) {
    let safeDomain = '';
    for (const letter of changedDomain) {
      if (/[a-z0-9_]/i.test(letter)) {
        safeDomain += letter;
      }
    }

    if (changedDomain?.length > 20) {
      setInputDomainError(translate('txtValidDomainInput'));
    } else setInputDomainError('');

    setOrganisationDomain(safeDomain);
  }

  function onOrganisationExternalDomainChange(changedOrganisationExternalDomain: string) {
    let safeOrganisationExternalDomain = '';

    for (const letter of changedOrganisationExternalDomain) {
      safeOrganisationExternalDomain += letter;
    }

    setOrganisationExternalDomain(safeOrganisationExternalDomain);
  }

  const renderWarning = () => (
    <WppCardWarning variant="secondary" data-testid="warning-card">
      <WppCardWarningMainTitleContainer>
        <WppCardWarningMainTitle>
          <WppIconError style={{ marginRight: '10px' }} />
          <StyledWppWarningTitle type="s-strong">{translate('txtUniqueDomainTitle')}</StyledWppWarningTitle>
        </WppCardWarningMainTitle>
        <WppActionButton
          onClick={() => {
            setShouldHaveWarning(false);
          }}
          variant="secondary"
          data-testid="close-warning">
          <WppIconCross slot="icon-start" />
        </WppActionButton>
      </WppCardWarningMainTitleContainer>
      <StyledWarningMessage type="xs-body" isSmallScreen={isSmallScreen}>
        {translate('txtUniqueDomainMessage')}
      </StyledWarningMessage>
    </WppCardWarning>
  );

  return (
    <div slot="body">
      <BoxContent>
        <BoxContent flex align="center">
          <BoxContent w="auto" marg="0 16 0 0">
            <WppAvatar name={prefixAvatar} size="l" color={theme.palette['datavizCatNeutral9']} src={filePath} />
          </BoxContent>
          <BoxContent>
            <WppInput
              autoFocus
              data-testid="title-input"
              name="title"
              labelConfig={{ text: txtTitle }}
              size="s"
              required
              value={title}
              onWppChange={(event: CustomEvent) => setOrganisationTitle(event.detail.value)}
            />
          </BoxContent>
        </BoxContent>
        <UrlsContainer marg="16 0 0 0">
          <BoxContent>
            <WppInput
              autoFocus
              data-testid="domain-input"
              name="domain"
              labelConfig={{ text: txtDomain }}
              size="s"
              required
              value={domain}
              onWppChange={(event: CustomEvent) => onDomainChange(event.detail.value)}
              message={inputDomainError}
              messageType={inputDomainError ? 'error' : undefined}
              disabled={isEditingMode}
            />
            <StyledWppDomain type="xs-strong">
              {interpolateWithValues(baseHostName, domain ? getStringEllipsed(domain, 45) : '')}
            </StyledWppDomain>
          </BoxContent>
          <BoxContent>
            <WppInput
              autoFocus
              data-testid="organisationExternalDomain-input"
              name="organisationExternalDomain"
              labelConfig={{ text: txtOrganisationExternalDomain }}
              size="s"
              required
              value={organisationExternalDomain}
              onWppChange={(event: CustomEvent) => onOrganisationExternalDomainChange(event.detail.value)}
            />
            <StyledWppDomain type="xs-strong">
              {'https://' + (organisationExternalDomain ? organisationExternalDomain : '')}
            </StyledWppDomain>
          </BoxContent>
        </UrlsContainer>
        {shouldHaveWarning && renderWarning()}
      </BoxContent>

      {fileUploader}
    </div>
  );
};

export default FormBody;
