import { EnumLanguages } from './EnumLanguages';
import { ILanguageTexts } from './ILanguageTexts';
import en_USLanguage from './dictionaries/en_US';
import pt_BRLanguage from './dictionaries/pt_BR';
import i18next from 'i18next';

export const setLanguage = (language = EnumLanguages.EN_US) => {
  i18next.init(
    {
      lng: language,
      fallbackLng: 'en-US',
      supportedLngs: ['en', 'pt', 'en-US', 'pt-BR'],
      debug: false,
      resources: {
        'en-US': { translation: en_USLanguage },
        'pt-BR': { translation: pt_BRLanguage },
      },
    },
    (err, t) => {
      if (err) {
        // return console.log('something went wrong loading', err);
      }
      t('key');
    },
  );
};

export const translate = (key: keyof ILanguageTexts, options?: unknown) => {
  if (options) {
    return i18next.t(key as string, options);
  }
  return i18next.t(key as string);
};

export const translateMulti = (keys: [keyof ILanguageTexts], options?: unknown) => {
  return keys.reduce((c, n) => {
    return {
      ...c,
      [n]: translate(n, options),
    };
  }, {});
};

export const changeLanguage = (language: EnumLanguages) => {
  return i18next.changeLanguage(language);
};

export const getSelectedLanguage = (): EnumLanguages => {
  return i18next.language as EnumLanguages;
};
