/* eslint-disable @typescript-eslint/no-empty-interface */
import { WppActionButton } from '@wppopen/components-library-react';
import React from 'react';
import { DeleteButton, RemoveIconBare, RemoveIconOs } from '../styles/LinkedChannels.styled';
import PlatformWrapper from '../../../../shared/components/PlatformWrapper/PlatformWrapper';

export interface IDeletedLinkedChannelCellProps {
  onDelete: (id: string) => void;
  id: string;
}

const DeletedLinkedChannelCell: React.FC<IDeletedLinkedChannelCellProps> = ({ onDelete, id }) => {
  return (
    <DeleteButton>
      <WppActionButton>
        <PlatformWrapper componentBare={RemoveIconBare} componentOs={RemoveIconOs} onClick={() => onDelete(id)} />
      </WppActionButton>
    </DeleteButton>
  );
};

export default DeletedLinkedChannelCell;
