import React, { useCallback, useMemo, useState } from 'react';
import { WppIconPlus, WppIconSearch, WppSpinner, WppTypography } from '@wppopen/components-library-react';
import { Table, BoxContentCenter, BaseTableDataType } from '../../../shared/components';
import {
  filterContentIds,
  filterContents,
  getColumns,
  hasContentsThatArentActive,
  tableContentStreamPage,
  tableContentStreamPriority,
  tableFallbackDrawer,
  TableLocation,
} from '../contentStreamUtils';
import { ContentStreamTableDataType } from '../types/ContentStreamTableDataType';
import { WppStyledCardBare, WppStyledCardOs } from './styles/ContentStreamPage.styled';
import { contentStreamPageManager } from '../services';
import { usePromise } from '../../../shared/hooks';
import { useSelectedOrganisation } from '../../SideMenu';
import { isArrayEmpty, percentOfAppHeight, reorderDataTableArray } from '../../../shared/utils';
import { translate } from '../../../shared/locales';
import ContentStreamNameCell from './ContentStreamNameCell';
import ContentStreamTagsCell from './ContentStreamTagsCell';
import ContentStreamLastUpdateCell from './ContentStreamLastUpdateCell';
import ContentStreamStatusCell from './ContentStreamStatusCell';
import ContentStreamCampaignNameCell from './ContentStreamCampaignNameCell';
import ContentStreamHeader from './ContentStreamPageHeader';
import ContentStreamPublishToggleCell from './ContentStreamPublishToggleCell';
import ContentStreamOrderCell from './ContentStreamOrderCell';
import EmptyState from '../../../shared/components/EmptyState/EmptyState';
import { useTheme } from '@emotion/react';
import { ITheme } from '@connected-core-system/utils/frontend';
import { useSelectedContentStreamState } from '../../ContentStreamCreate/hooks/useSelectedContentStreamState';
import { RowDragEvent } from 'ag-grid-community';
import { useIsContentStreamModalOpenedState } from '../../ContentStreamCreate/hooks/useIsContentStreamModalOpenedState';
import { useContentStreamTableTryAgainState } from '../hooks/useContentStreamTableTryAgainState';
import ContentStreamFallbackCell from './ContentStreamFallbackCell';
import { useSelectedChannelState } from '../../Channel/hooks';
import { useIsFallbackContentStreamState } from '../../ContentStreamCreate/hooks/useIsFallbackContentStreamState';
import { useNavigate } from 'react-router-dom';
import { MenuItemEnum } from '../../../shared/types';
import { useIsChannelModalOpenedState } from '../../ChannelCreateEdit/hooks/useIsChannelModalOpenedState';
import { useSelectedSideMenuItemState } from '../../SideMenu/hooks/useSelectedSideMenuItemState';
import ContentStreamContentStatusCell from './ContentStreamContentStatusCell';
import FallbackWrapper from '../../Feedback/views/FallbackWrapper';
import { UNCLICKABLE_TABLE_IDS } from '../../../shared/Constants/Constants';
import PlatformWrapper from '../../../shared/components/PlatformWrapper/PlatformWrapper';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IContentStreamTableProps {
  location: TableLocation;
  forceReloadDimensions?: boolean;
  listSelectedRowsId?: string[];
  onRowClicked?: (data: Partial<ContentStreamTableDataType> & BaseTableDataType) => void;
  handleRowDrag?: (orderedArray: ContentStreamTableDataType[]) => void;
}

const ContentStreamTable: React.FC<IContentStreamTableProps> = ({
  location,
  forceReloadDimensions = false,
  listSelectedRowsId = [],
  onRowClicked,
  handleRowDrag,
}) => {
  const theme = useTheme() as ITheme;
  const navigate = useNavigate();
  const { setIsContentStreamModalOpened } = useIsContentStreamModalOpenedState();
  const { setIsChannelModalOpened } = useIsChannelModalOpenedState();
  const { setIsFallbackContentStream } = useIsFallbackContentStreamState();
  const { setSelectedSideMenuItem } = useSelectedSideMenuItemState();
  const { tryAgain, setTryAgain } = useContentStreamTableTryAgainState();
  const [searchTerm, setSearch] = useState('');
  const { selectedChannel } = useSelectedChannelState();
  const isContentStreamPage = location === tableContentStreamPage;
  const [tableData, setTableData] = useState<ContentStreamTableDataType[]>([]);
  const [publishState, setPublishState] = useState<{ id: string; isPublished: boolean }[]>([]);
  const [publishCellId, setPublishCellId] = useState<string>('');
  const [publishPromise, setPublishPromise] = useState<{ error: boolean; success: boolean; errorToastMessage: string }>(
    {
      error: false,
      success: false,
      errorToastMessage: translate('txtErrorMessage'),
    },
  );
  const [contentIds, setContentIds] = useState<Array<string>>([]);
  const [publishStatusChange, setPublishStatusChange] = useState<{ id: string; isPublished: boolean }[]>([]);

  function getExcludedColumns(): string[] {
    switch (location) {
      case tableContentStreamPage:
        return [
          'id',
          'order',
          'contentStreamTags',
          'scheduleTargetRule',
          'timeTargetRule',
          'locationTargetRule',
          'scanHistoryTargetRule',
          'linkedChannelList',
          'campaignId',
          'linkedContent',
          'linkedStory',
          'status',
        ];
      case tableContentStreamPriority:
        return [
          'id',
          'contentStreamTags',
          'scheduleTargetRule',
          'timeTargetRule',
          'locationTargetRule',
          'scanHistoryTargetRule',
          'isFallback',
          'linkedChannelList',
          'campaignId',
          'linkedContent',
          'linkedStory',
          'status',
        ];
      case tableFallbackDrawer:
        return [
          'id',
          'order',
          'isFallback',
          'campaignName',
          'campaignId',
          'isPublished',
          'contentStreamTags',
          'scheduleTargetRule',
          'scanHistoryTargetRule',
          'timeTargetRule',
          'locationTargetRule',
          'linkedChannelList',
          'linkedContent',
          'linkedStory',
          'status',
        ];
    }
  }

  const contentStreamColumnDefinitions = getColumns(
    getExcludedColumns() as (keyof ContentStreamTableDataType)[],
    location !== tableContentStreamPriority,
  );
  const { selectedOrganisation } = useSelectedOrganisation();
  const { setSelectedContentStream } = useSelectedContentStreamState();

  const loadDataTable = useCallback(() => {
    if (tryAgain) setTryAgain(false);
    if (selectedOrganisation) {
      return contentStreamPageManager
        .listContentStream(isContentStreamPage, selectedOrganisation.id, selectedChannel?.id, location)
        .then((data) => {
          setTableData(data);
          const publishState = data.map((item) => {
            return { id: item.id, isPublished: item.isPublished };
          });
          setPublishState(publishState);
          setContentIds(filterContentIds(data));
        });
    }
    return null;
  }, [tryAgain, setTryAgain, selectedOrganisation, isContentStreamPage, selectedChannel?.id, location]);

  const { data, ...promiseStatusOptions } = usePromise(loadDataTable);

  if (contentStreamColumnDefinitions['order'])
    contentStreamColumnDefinitions['order'].cellRenderer = ({ data }: { data: ContentStreamTableDataType }) => (
      <ContentStreamOrderCell order={data.order} data-testid="content-stream-priority-order" />
    );

  contentStreamColumnDefinitions['name'].cellRenderer = ({ data }: { data: ContentStreamTableDataType }) => (
    <ContentStreamNameCell name={data.name} searchTerm={searchTerm} />
  );

  if (contentStreamColumnDefinitions['status'])
    contentStreamColumnDefinitions['status'].cellRenderer = ({ data }: { data: ContentStreamTableDataType }) => (
      <ContentStreamStatusCell status={data.status} />
    );

  if (contentStreamColumnDefinitions['campaignName']) {
    contentStreamColumnDefinitions['campaignName'].cellRenderer = ({ data }: { data: ContentStreamTableDataType }) => (
      <ContentStreamCampaignNameCell campaignName={data.campaignName} searchTerm={searchTerm} />
    );
  }

  contentStreamColumnDefinitions['tags'].cellRenderer = ({ data }: { data: ContentStreamTableDataType }) => (
    <ContentStreamTagsCell tags={data.tags} />
  );

  contentStreamColumnDefinitions['lastUpdate'].cellRenderer = ({ data }: { data: ContentStreamTableDataType }) => (
    <ContentStreamLastUpdateCell lastUpdate={data.lastUpdate} />
  );

  const loadContentStatus = useCallback(() => {
    if (contentIds && contentIds.length && selectedOrganisation?.id) {
      return contentStreamPageManager.listContents(selectedOrganisation.id, contentIds);
    }
    return null;
  }, [contentIds, selectedOrganisation]);

  const { data: contentData, ...promiseStatusContent } = usePromise(loadContentStatus);

  contentStreamColumnDefinitions['contentStatus'].cellRenderer = ({ data }: { data: ContentStreamTableDataType }) => (
    <ContentStreamContentStatusCell
      isLoading={promiseStatusContent.loading}
      hasWarning={!promiseStatusContent.error && hasContentsThatArentActive(data, contentData)}
    />
  );

  if (contentStreamColumnDefinitions['isPublished']) {
    contentStreamColumnDefinitions['isPublished'].cellRenderer = ({ data }: { data: ContentStreamTableDataType }) => (
      <ContentStreamPublishToggleCell
        isPublished={getPublishState(data.id)}
        contentId={data.id}
        handleToogleState={handleToggleState}
        setPublishCellId={setPublishCellId}
        isDisabled={!!publishCellId}
        isLoading={publishCellId === data.id}
        promise={publishPromise}
        setPromise={setPublishPromise}
      />
    );
  }

  if (contentStreamColumnDefinitions['isFallback'])
    contentStreamColumnDefinitions['isFallback'].cellRenderer = ({ data }: { data: ContentStreamTableDataType }) => (
      <ContentStreamFallbackCell type={data.isFallback ? 'Fallback' : 'Targeted'} data-testid="type-cell" />
    );

  const filteredChanels = useMemo(() => {
    if (tableData) {
      return filterContents(tableData, searchTerm);
    }
    return [];
  }, [tableData, searchTerm]);

  const getPublishState = (id: string) => {
    return !!publishState.find((element) => element.id === id)?.isPublished;
  };

  const handleOnDrag = (row: RowDragEvent) => {
    const reorderedArray = reorderDataTableArray(tableData, row) as ContentStreamTableDataType[];
    setTableData([...reorderedArray]);
    handleRowDrag?.(reorderedArray);
  };

  const handleToggleState = (id: string, isPublished: boolean) => {
    const tempArray = publishState.map((data) => {
      if (data.id === id) {
        return {
          ...data,
          isPublished,
        };
      }
      return data;
    });
    setPublishState(tempArray);
    const statusChange = publishStatusChange.find((statusChange) => statusChange.id === id);
    if (statusChange) {
      statusChange.isPublished = isPublished;
    } else {
      publishStatusChange.push({
        id,
        isPublished,
      });
    }
    setPublishStatusChange(publishStatusChange);
  };

  const renderLoading = () => {
    return (
      <BoxContentCenter>
        <WppSpinner size="l" />
      </BoxContentCenter>
    );
  };

  const handleActionButtonClick = () => {
    setSelectedContentStream(null);
    if (isContentStreamPage) {
      setIsContentStreamModalOpened(true);
    } else {
      setSelectedSideMenuItem(MenuItemEnum.CONTENT_STREAM);
      navigate(`/organisation/${selectedOrganisation?.id}/${MenuItemEnum.CONTENT_STREAM}`);
      setIsChannelModalOpened(false);
    }
  };

  const renderEmpty = () => {
    if (promiseStatusOptions?.done) {
      if (isArrayEmpty(tableData)) {
        return (
          <EmptyState
            title={
              isContentStreamPage ? translate('txtNoContentStreamYet') : translate('txtNoContentStreamAssociatedYet')
            }
            titleIcon={
              <WppIconSearch slot="icon-start" aria-label="Search icon" color={theme.palette['grey1000']} size="m" />
            }
            text={isContentStreamPage ? translate('txtNoContentCreated') : translate('txtNoContentStreamAssociated')}
            buttonText={
              isContentStreamPage ? translate('txtCreateContentStream') : translate('txtAssociateContentStream')
            }
            hasButton={isContentStreamPage || !!selectedChannel}
            buttonIcon={<WppIconPlus slot="icon-start" />}
            buttonAction={() => handleActionButtonClick()}
          />
        );
      }
      if (!filteredChanels.length) {
        return (
          <BoxContentCenter>
            <WppTypography>{`No results found with "${searchTerm}"`}</WppTypography>
          </BoxContentCenter>
        );
      }
    }
    return null;
  };

  const renderError = () => {
    return (
      <EmptyState
        title={translate('txtSomethingWentWrong')}
        text={translate('txtErrorMessage')}
        buttonText={translate('txtTryAgain')}
        buttonAction={() => {
          setTryAgain(true);
        }}
      />
    );
  };

  const defaultRowClickedHandler = (data: Partial<ContentStreamTableDataType> & BaseTableDataType) => {
    const contentStreamToSelect = data as ContentStreamTableDataType;
    data.isPublished = publishStatusChange.find((statusChange) => statusChange.id === data.id)
      ? publishStatusChange.find((statusChange) => statusChange.id === data.id)?.isPublished
      : data.isPublished;
    setSelectedContentStream(contentStreamToSelect);
    setIsFallbackContentStream(data.isFallback ? data.isFallback : false);
    setIsContentStreamModalOpened(true);
  };

  const renderTable = () => {
    return (
      <Table<Partial<ContentStreamTableDataType>>
        searchable
        searchTerm={searchTerm || ''}
        displayData={filteredChanels}
        columnDefs={Object.values(contentStreamColumnDefinitions)}
        fixedHeight={
          isContentStreamPage ? `calc(${percentOfAppHeight(100)} - 24rem)` : `calc(${percentOfAppHeight(100)} - 29rem)`
        }
        renderLoading={renderLoading}
        isLoading={promiseStatusOptions?.loading}
        handleCellClicked={onRowClicked || defaultRowClickedHandler}
        renderEmptyState={renderEmpty}
        renderError={renderError}
        hasError={promiseStatusOptions?.error}
        delayUseDimensions={forceReloadDimensions ? 0 : 100}
        handleRowDrag={handleOnDrag}
        selectedRowsId={listSelectedRowsId}
        columnsToPreventSelection={[{ column: 'isPublished', elementsIds: [UNCLICKABLE_TABLE_IDS.publishIcon] }]}
        selectionType="single"
      />
    );
  };
  const renderContent = () => {
    return location === tableFallbackDrawer ? (
      <>
        <ContentStreamHeader onSearch={setSearch} searchTerm={searchTerm} location={location} />
        {forceReloadDimensions && renderTable()}
        {!forceReloadDimensions && renderTable()}
      </>
    ) : (
      <PlatformWrapper componentBare={WppStyledCardBare} componentOs={WppStyledCardOs}>
        {isContentStreamPage && (
          <ContentStreamHeader onSearch={setSearch} searchTerm={searchTerm} location={location} />
        )}
        <FallbackWrapper {...promiseStatusContent} errorToastMessage={translate('txtContentLoadingErrorMessage')} />
        {forceReloadDimensions && renderTable()}
        {!forceReloadDimensions && renderTable()}
      </PlatformWrapper>
    );
  };
  return renderContent();
};

export default ContentStreamTable;
