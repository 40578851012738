import React, { useMemo, useEffect, useState } from 'react';
import { CornerDotType, CornerSquareType, Options } from 'qr-code-styling';
import { useSecondTierSelectedItemState } from '../../SecondTierSidebar';
import QRCodePreview from '../../../shared/components/QRCodePreview/QRCodePreview';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface IQRChannelPreviewProps {
  configuration: Options;
}

const QRChannelPreview: React.FC<IQRChannelPreviewProps> = ({ configuration }) => {
  const dotsOptions = configuration?.dotsOptions;
  const cornersSquareOptions = configuration?.cornersSquareOptions;
  const cornersDotOptions = configuration?.cornersDotOptions;
  const backgroundOptions = configuration?.backgroundOptions;
  const imageOptions = configuration?.imageOptions;
  const qrOptions = configuration?.qrOptions;
  const image = configuration?.image;
  const data = configuration?.data;

  const { selectedSecondTierItem } = useSecondTierSelectedItemState();

  const validImage = useMemo(() => {
    const isValidDataURL = (url: string): boolean => {
      const regex = /^data:([a-z]+\/[a-z-]+(;[a-z-]+=[a-z-]+)?)?(;base64)?,[a-zA-Z0-9!$&',()*+;=\-._~:@/?\s]*$/i;
      return regex.test(url);
    };
    return configuration?.image && isValidDataURL(configuration.image) ? configuration.image : undefined;
  }, [configuration?.image]);

  const [options, setOptions] = useState<Options>(configuration);

  useEffect(() => {
    setOptions((options) => ({
      ...options,
      dotsOptions: {
        ...dotsOptions,
        gradient: !dotsOptions?.gradient ? undefined : dotsOptions?.gradient,
      },
      cornersSquareOptions: {
        ...cornersSquareOptions,
        type: !cornersSquareOptions?.type ? ('' as CornerSquareType) : cornersSquareOptions.type,
        gradient: !cornersSquareOptions?.gradient ? undefined : cornersSquareOptions.gradient,
      },
      cornersDotOptions: {
        ...cornersDotOptions,
        type: !cornersDotOptions?.type ? ('' as CornerDotType) : cornersDotOptions.type,
        gradient: !cornersDotOptions?.gradient ? undefined : cornersDotOptions.gradient,
      },
      backgroundOptions: {
        ...backgroundOptions,
        gradient: !backgroundOptions?.gradient ? undefined : backgroundOptions.gradient,
      },
      imageOptions: {
        ...imageOptions,
      },
      qrOptions: {
        ...qrOptions,
      },
      image: validImage,
      data: data,
    }));
  }, [
    dotsOptions,
    cornersSquareOptions,
    cornersDotOptions,
    backgroundOptions,
    imageOptions,
    qrOptions,
    image,
    validImage,
    data,
    configuration,
  ]);

  useEffect(() => {
    const resetStateOnCreation = () => {
      if (selectedSecondTierItem?.id === 'new') setOptions(configuration);
    };

    resetStateOnCreation();
  }, [configuration, selectedSecondTierItem]);

  return <QRCodePreview configuration={options} />;
};

export default QRChannelPreview;
