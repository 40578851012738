import { TStyledDefaultProps } from '@connected-core-system/utils/frontend';
import styled from '@emotion/styled';
import { WppTypography } from '@wppopen/components-library-react';

export const LabelText = styled(WppTypography)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey500'],
}));

export const ValueText = styled(WppTypography)(({ theme }: TStyledDefaultProps) => ({
  color: theme?.palette?.['grey800'],
}));

export const StyledWppTypographyOs = styled(WppTypography)({
  paddingBottom: '1.6rem',
});
