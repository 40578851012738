import React, { useEffect, useState } from 'react';
import { ISideMenuState } from '../../features/SideMenu';
import { useSliceState } from './useStore';

export const useContainerDimensions = (myRef: React.RefObject<any>, delay = 100) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const isMenuOpened = useSliceState<ISideMenuState, 'isMenuOpened'>('isMenuOpened');

  useEffect(() => {
    function timeout(ms: number) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    }
    const getDimensions = () => ({
      width: myRef.current?.offsetWidth,
      height: myRef.current?.offsetHeight,
    });
    const handleResize = () => {
      setDimensions(getDimensions());
    };
    async function execute() {
      await timeout(100);
      if (myRef.current) {
        setDimensions(getDimensions());
      }
      window.addEventListener('resize', handleResize);
    }
    execute();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [myRef.current, isMenuOpened]); //TODO: Não está faltando dependências, refatorar para receber somente o current

  return dimensions;
};
