import { create } from 'zustand';
import sideMenu, { ISideMenuState } from '../../features/SideMenu/sliceStore/sideMenuSliceStore';
import organisationCreate, {
  IOrganisationCreateState,
} from '../../features/OrganisationCreate/sliceStore/organisationCreateSliceStore';
import secondTierSidebar, {
  ISecondTierSidebarState,
} from '../../features/SecondTierSidebar/sliceStore/secondTierSidebarSlice';
import toastMessage, { IToastMessageState } from '../../features/Feedback/sliceStore/toastMessageSlice';
import organisationUnitPage, {
  IOrganisationUnitPageState,
} from '../../features/UnitPage/sliceStore/OrganisationUnitPageSlice';
import authorization, { IAthorizationState } from '../../features/Authorization/sliceStore/authorizationSliceStore';
import brandPage, { IBrandPageState } from '../../features/BrandPage/sliceStore/BrandPageSlice';
import channelPage, { IChannelPageState } from '../../features/Channel/sliceStore/ChannelSlice';
import contentStreamCreateEdit, {
  IContentStreamCreateEditState,
} from '../../features/ContentStreamCreate/sliceStore/contentStreamCreateEditSliceStore';
import channelCreateEdit, {
  IChannelCreateEditState,
} from '../../features/ChannelCreateEdit/sliceStore/channelCreateEditSliceStore';
import qrStyle, { IQRStyleState } from '../../features/QRStyle/sliceStore/qrStyleSliceStore';
import contentStreamPage, {
  IContentStreamState,
} from '../../features/ContentStream/sliceStore/ContentStreamSliceStore';

export const GLOBAL_INITIAL_STATE = {
  ...sideMenu.initialState,
  ...secondTierSidebar.initialState,
  ...toastMessage.initialState,
  ...organisationUnitPage.initialState,
  ...authorization.initialState,
  ...channelPage.initialState,
  ...channelCreateEdit.initialState,
  ...contentStreamPage.initialState,
  ...contentStreamCreateEdit.initialState,
  ...qrStyle.initialState,
  ...brandPage.initialState,
};

export const slices = {
  sideMenuSlice: sideMenu.slice,
  secondTierSlice: secondTierSidebar.slice,
  organisationCreateSlice: organisationCreate.slice,
  toastMessageSlice: toastMessage.slice,
  organisationUnitPageSlice: organisationUnitPage.slice,
  authorizationSlice: authorization.slice,
  channelPageSlice: channelPage.slice,
  channelCreateEditSlice: channelCreateEdit.slice,
  contentStreamPageSlice: contentStreamPage.slice,
  contentStreamCreateEditSlice: contentStreamCreateEdit.slice,
  qrStyleSlice: qrStyle.slice,
  brandPageSlice: brandPage.slice,
};

export const zustandStore = create<Store>((set) => ({
  ...sideMenu.slice(set as unknown as SetCallback<ISideMenuState>),
  ...secondTierSidebar.slice(set as unknown as SetCallback<ISecondTierSidebarState>),
  ...organisationCreate.slice(set as unknown as SetCallback<IOrganisationCreateState>),
  ...toastMessage.slice(set as unknown as SetCallback<IToastMessageState>),
  ...organisationUnitPage.slice(set as unknown as SetCallback<IOrganisationUnitPageState>),
  ...authorization.slice(set as unknown as SetCallback<IAthorizationState>),
  ...channelPage.slice(set as unknown as SetCallback<IChannelPageState>),
  ...channelCreateEdit.slice(set as unknown as SetCallback<IChannelCreateEditState>),
  ...contentStreamCreateEdit.slice(set as unknown as SetCallback<IContentStreamCreateEditState>),
  ...contentStreamPage.slice(set as unknown as SetCallback<IContentStreamState>),
  ...qrStyle.slice(set as unknown as SetCallback<IQRStyleState>),
  ...brandPage.slice(set as unknown as SetCallback<IBrandPageState>),
}));

export type Store = ReturnType<(typeof slices)['sideMenuSlice']> &
  ReturnType<(typeof slices)['secondTierSlice']> &
  ReturnType<(typeof slices)['organisationCreateSlice']> &
  ReturnType<(typeof slices)['toastMessageSlice']> &
  ReturnType<(typeof slices)['organisationUnitPageSlice']> &
  ReturnType<(typeof slices)['authorizationSlice']> &
  ReturnType<(typeof slices)['channelPageSlice']> &
  ReturnType<(typeof slices)['contentStreamCreateEditSlice']> &
  ReturnType<(typeof slices)['channelCreateEditSlice']> &
  ReturnType<(typeof slices)['contentStreamPageSlice']> &
  ReturnType<(typeof slices)['qrStyleSlice']> &
  ReturnType<(typeof slices)['brandPageSlice']>;

export type StateCallback = (state: typeof GLOBAL_INITIAL_STATE) => typeof GLOBAL_INITIAL_STATE;
export type SetCallback<T> = (set: (state: T) => void) => void;
